import React, { FC, useState, useEffect } from 'react'
import { Button, Spin, message } from 'antd'

import Prospects from './components/Prospects'
import { useFetchProspects } from './api/prospectsQueries'
import { useGenProspectsIcebreakers } from '../Icebreaker/api/icebreakerMutations'
import {
  useAddProspects,
  useRemoveProspects,
  useUpdateProspect,
  useUpdateProspects,
} from './api/prospectsMutations'

interface Props {
  campaignId: string
}

const ProspectsContainer: FC<Props> = ({ campaignId }) => {
  const [selectedProspects, setSelectedProspects] = useState<string[]>([])
  const {
    removeProspects,
    isLoading: isRemoveProspectsLoading,
    isSuccess: isRemoveProspectsSuccess,
  } = useRemoveProspects()

  const { addProspects } = useAddProspects(campaignId)

  const {
    genProspectsIcebreakers,
    isLoading: isGenProspectsIBLoading,
    isSuccess: isGenProspectsIBSuccess,
  } = useGenProspectsIcebreakers()

  useEffect(() => {
    if (isRemoveProspectsLoading) return
    if (isRemoveProspectsSuccess) {
      setSelectedProspects([])
      message.success('Prospect(s) have been removed from this campaign')
    }
  }, [isRemoveProspectsLoading, isRemoveProspectsSuccess])

  if (!campaignId) {
    // TODO: handle redirect
    return null
  }

  const onAddProspects = (prospects: any[]) => {
    addProspects(prospects)
  }

  const onRemoveProspects = (prospectIds: string[]) => {
    if (!prospectIds || !prospectIds.length) return

    removeProspects({ campaignId, prospectIds })
    setSelectedProspects([])
  }

  const onPersonalizeProspects = (prospectIds: string[]) => {
    if (!prospectIds || !prospectIds.length) return

    genProspectsIcebreakers({ prospectIds })
    setSelectedProspects([])
  }

  return (
    <Prospects
      onRemoveProspects={onRemoveProspects}
      onAddProspects={onAddProspects}
      onPersonalizeProspects={onPersonalizeProspects}
      selectedProspects={selectedProspects}
      setSelectedProspects={setSelectedProspects}
      loading={isRemoveProspectsLoading || isGenProspectsIBLoading}
      campaignId={campaignId}
    />
  )
}

export default ProspectsContainer
