import React, { useEffect, useCallback, useState } from 'react'
import fetch from '../../../../../services/fetch'
import { Modal, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import history from '../../../../../router/history'

interface Props {}

const OutlookRedirectHandler: React.FC<Props> = () => {
  const [modal, contextHolder] = Modal.useModal()

  const onComplete = useCallback(() => {
    history.push(`/app/settings/mailboxes/`)
  }, [])

  const onError = useCallback(() => {
    history.push(`/app/settings/mailboxes/`)
  }, [])

  const location = useLocation()
  const authCode = new URLSearchParams(location.search).get('code')
  const authState = new URLSearchParams(location.search).get('state')

  const [hasRequested, setHasRequested] = useState(false)

  useEffect(() => {
    if (!location.search || hasRequested || !authCode || !authState) return
    setHasRequested(true)
    const saveAccessToken = async (authCode: string, authState: string) => {
      try {
        const parsedState = new URLSearchParams(authState)
        const mailboxId = parsedState.get('mailboxId')
        const clientStateToken = parsedState.get('clientStateToken')
        await fetch.put('/mailboxes/outlook/connect-mailbox/', {
          authCode,
          mailboxId,
          clientStateToken,
        })
        return modal.success({
          title: `Your email has been ${mailboxId ? 're' : ''}connected`,
          ...(mailboxId && {
            content: `Make sure to unpause all campaigns you'd like to continue sending.`,
          }),
          style: { width: 500 },
          okText: 'Ok',
          onOk: () => onComplete(),
        })
      } catch (error: any) {
        console.error('Error saveAccessToken:', error)
        if (error?.response?.data.message === 'mailbox_exists') {
          return modal.error({
            title: 'This email is already connected to Mailarrow.',
            style: { width: 500 },
            icon: <ExclamationCircleOutlined />,
            content: <>You can not connect the same email account twice.</>,
            okText: 'Ok',
            onOk: () => onError(),
          })
        }
        if (error?.response?.data.message === 'mailbox_mismatch') {
          return modal.error({
            title: `You've selected incorrect mailbox`,
            style: { width: 500 },
            icon: <ExclamationCircleOutlined />,
            content: <>Please authorize correct mailbox in Microsoft popup</>,
            okText: `Ok, I'll try again`,
            onOk: () => onError(),
          })
        }
        return modal.error({
          title: 'There has been an error while connecting your Outlook account',
          style: { width: 500 },
          content: <>Try again and reach out to support if the error persists..</>,
          okText: 'Ok',
          onOk: () => onError(),
        })
      }
    }
    saveAccessToken(authCode, authState)
  }, [authCode, authState, hasRequested, onComplete, location.search, modal, onError])

  return (
    <>
      <Spin />
      {contextHolder}
    </>
  )
}

export default OutlookRedirectHandler
