import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'

const fetchCustomProperties = async () => {
  try {
    const response = await fetch.get(`/custom-properties/`)
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchCustomProperties = () => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['properties'],
    fetchCustomProperties,
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    properties: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
