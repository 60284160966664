export const getExampleData = (data: string[][], perColumn: number): Record<string, string[]> => {
  if (!data) return {}
  const result: Record<string, string[]> = {}

  // Iterate through each column (assuming data[0] contains column headers)
  for (let col = 0; col < data[0].length; col++) {
    const key = data[0][col] || `empty_${col}`

    if (!result[key]) {
      result[key] = []
    }

    // Iterate through each row, starting from the second row (since the first row is the header)
    for (let row = 1; row < data.length && result[key].length < perColumn; row++) {
      const value = data[row][col]

      // Only add the value if it is present
      if (value !== undefined && value !== null && value !== '') {
        result[key].push(value)
      }
    }
  }

  return result
}
