import React from 'react'
import { Button, Card, Modal } from 'antd'
import { GoogleOutlined, WindowsOutlined, MailOutlined } from '@ant-design/icons'
import { useFeatureToggle } from '../../../User'
import { FeatureToggles } from '@mailarrow/types'

interface Props {
  visible: boolean
  onCancel: () => void
  onAddGmail: () => void
  onAddGmailSMTP: () => void
  onAddOffice: () => void
  onAddMsExchange: () => void
  onAddSmtp: () => void
}

interface CardProps {
  icon: React.ReactNode
  title: string
  description: string
  onConnect: () => void
}

const MailCard: React.FC<CardProps> = ({ icon, title, description, onConnect }) => (
  <Card>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Card.Meta
        title={
          <>
            {icon}
            <span>{title}</span>
          </>
        }
        description={
          <>
            <div>{description}</div>
          </>
        }
      />
      <Button onClick={onConnect}>Select</Button>
    </div>
  </Card>
)

const AddMailboxModal: React.FC<Props> = ({
  visible,
  onCancel,
  onAddGmail,
  onAddGmailSMTP,
  onAddOffice,
  onAddMsExchange,
  onAddSmtp,
}) => {
  const showGmail = useFeatureToggle(FeatureToggles.GmailSSO)
  const showOutlook = useFeatureToggle(FeatureToggles.OutlookSSO)
  const showExchange = false
  return (
    <Modal title="Add Mail Account" open={visible} onCancel={onCancel} footer={null} width={600}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          gap: 10,
        }}
      >
        {showGmail && (
          <MailCard
            icon={<GoogleOutlined key="setting" style={{ marginRight: 10 }} />}
            title="Google Mail"
            description="Add Google Mailbox via Single-Sign-On"
            onConnect={onAddGmail}
          />
        )}
        <MailCard
          icon={<GoogleOutlined key="setting" style={{ marginRight: 10 }} />}
          title="Google Mailbox"
          description="Add Google Workspace or personal mailbox"
          onConnect={onAddGmailSMTP}
        />
        <MailCard
          icon={<WindowsOutlined key="setting" style={{ marginRight: 10 }} />}
          title="Office 365 Mail"
          description="Add Office 365, Outlook, hotmail.com mailbox"
          onConnect={onAddOffice}
        />
        {showExchange && (
          <MailCard
            icon={<WindowsOutlined key="setting" style={{ marginRight: 10 }} />}
            title="Exchange Mail"
            description="Add Exchange Mailbox"
            onConnect={onAddMsExchange}
          />
        )}
        <MailCard
          icon={<MailOutlined key="setting" style={{ marginRight: 10 }} />}
          title="Any Other Mailbox"
          description="Any other SMTP/IMAP mailbox "
          onConnect={onAddSmtp}
        />
      </div>
    </Modal>
  )
}

export default AddMailboxModal
