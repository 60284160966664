import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Spin } from 'antd'

interface Props {
  children: ReactNode
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { user, fetchUserDataLoading } = useSelector((state: RootState) => state.user)

  if (fetchUserDataLoading) return <Spin />

  if (!user) return <Navigate to="/login" replace />

  const { userId, isVerified, isActive, isBlocked } = user

  if (!userId) {
    return <Navigate to="/login" replace />
  }

  if (!isVerified) {
    return <Navigate to="/verify" replace />
  }

  if (!isActive || isBlocked) {
    return <Navigate to="/inactive" replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
