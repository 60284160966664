import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import { reverse } from 'lodash'
import { filterFalsySearchParams } from '../../../util/filter-falsy'
import type { SendErrorFilters } from '@mailarrow/types'

const getSendErrors = async (filters: SendErrorFilters = {}) => {
  const woFalsy = Object.entries(filterFalsySearchParams(filters))
  const query = new URLSearchParams(woFalsy).toString()
  const { data } = await fetch.get(`/send-errors/?${query}`)
  return reverse(data) as any[]
}

export const useFetchSendError = (filters: SendErrorFilters = {}) => {
  const { data, isLoading, isError, isSuccess, error, refetch } = useQuery(
    ['send-errors', filters],
    () => getSendErrors(filters),
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    sendErrors: data,
    refetch,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
