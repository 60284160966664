import { FeatureToggles } from '../feature-toggles/feature-toggles'

export interface Onboarding {
  experienceLevel?: string
  teamSize?: string
  hasLeads?: string
  emailsPerMonth?: string
  sendingMailboxes?: string

  companyName?: string
  companyWebsiteUrl?: string
  responsibility?: string
  companySize?: string
  companyType?: string
  leadSource?: string
  phone?: string
}

export enum PermissionsLevel {
  GUEST = 'GUEST',
  USER = 'USER',
  SUPPORT_STAFF = 'SUPPORT_STAFF',
  ADMIN = 'ADMIN',
}

export enum TeamRole {
  GUEST = 'GUEST',
  TEAM_MEMBER = 'TEAM_MEMBER',
  TEAM_OWNER = 'TEAM_OWNER',
  ADMIN = 'ADMIN',
}

export interface FeatureToggle {
  name: FeatureToggles
  isEnabled: boolean
}

export interface User {
  // personal info
  userId: string // does not have a prefix because it comes from external
  firstName?: string
  lastName?: string
  email: string
  onboarding?: Onboarding

  inviteId?: string
  analyticsId?: string

  // some utm metrics
  signupMarketingCampaignId?: string
  // website, optin, etc
  signupSource: string
  // email or gmail
  signupType: 'email' | 'gmail'

  permissions: PermissionsLevel[]
  featureToggles: FeatureToggle[]
  teamRoles: TeamRole[]
  teamIds: string[]

  // statuses
  isActive?: boolean
  // is user blocked under spam suspicion
  isBlocked?: boolean
  // is account cancelled
  isCancelled?: boolean
  // is the account requested to be removed
  isRemoved?: boolean
  // is the email account of the account verified
  isVerified: boolean

  accountVerificationId?: string

  createdAt: Date
  updatedAt?: Date
}
