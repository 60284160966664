import { Middleware } from 'redux'

const crashReporter: Middleware = (store) => (next) => (action) => {
  try {
    return next(action)
  } catch (err) {
    console.warn(`Failed to apply action ${action.type}`, {
      err,
      action,
    })
    throw err
  }
}

export default crashReporter
