import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Progress, Spin } from 'antd'
import { useLocation, Navigate, useParams } from 'react-router-dom'
import { actions as UserActions } from '../../../store/UserReducer'
import { RootState } from '../../../store/store'

const EmailVerified: React.FC = () => {
  const { confirmationDone, confirmationFailed, user } = useSelector(
    (state: RootState) => state.user
  )
  const location = useLocation()
  const dispatch = useDispatch()
  const { emailVerifyId, userId } = useParams<{ emailVerifyId: string; userId: string }>()

  useEffect(() => {
    document.title = 'Verify Email | Mailarrow App'
    if (emailVerifyId && !(confirmationDone || confirmationFailed)) {
      dispatch(UserActions.completeEmailVerification(emailVerifyId, userId))
    }
  }, [confirmationDone, confirmationFailed, dispatch, emailVerifyId, userId])

  if (confirmationDone && user?.isVerified) {
    return <Navigate to="/app/dashboard" replace />
  }

  if (confirmationDone) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Progress type="circle" percent={100} size="small" />
        <div style={{ margin: '20px 0 10px 0' }}>Your email has been confirmed.</div>
        <div>Redirecting..</div>
      </div>
    )
  }

  if (confirmationFailed) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Progress type="circle" percent={0} size="small" />
        <div style={{ margin: '20px 0' }}>
          Something went wrong. Please try again or contact support.
        </div>
      </div>
    )
  }

  if (location.search.includes('emailVerifyId') && !confirmationDone) {
    return <Spin />
  }

  return null
}

export default EmailVerified
