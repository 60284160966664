import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Card, Typography, Tag, Alert } from 'antd'
import { Mailbox } from '@mailarrow/types'
import {
  useRemoveMailboxTrackingDomain,
  useSetMailboxTrackingDomain,
} from '../api/mailboxesMutations'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

interface Props {
  mailbox: Mailbox
}

const TrackingDomain: React.FC<Props> = ({ mailbox }) => {
  const [trackingDomain, setTrackingDomain] = useState<string>(
    mailbox?.trackingDomain?.domain || ''
  )
  const [error, setError] = useState<string | null>(null)

  const {
    setMailboxTrackingDomain,
    data,
    isLoading: isSetTrackingDomainLoading,
  } = useSetMailboxTrackingDomain()

  const {
    removeMailboxTrackingDomain,
    reset: resetRemove,
    isLoading: isRemoveTrackingDomainLoading,
  } = useRemoveMailboxTrackingDomain()

  useEffect(() => {
    if (isSetTrackingDomainLoading) return
    if (typeof data === 'boolean' && data === false) {
      return setError(`Your domain's CNAME record should be pointing to hi.tailarrow.com`)
    }
    if (typeof data === 'boolean' && data === true) {
      return setError(null)
    }
  }, [isSetTrackingDomainLoading, data])

  const isTrackingDomainInputValid = (domain: string) => {
    function isValidSubdomain(str: string) {
      const regex =
        /^(?!https?:\/\/)([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})$/

      const matches = str.match(regex)
      return matches && matches.length > 1
    }

    if (!isValidSubdomain(domain)) {
      return false
    }
    return true
  }

  const onSave = () => {
    if (!isTrackingDomainInputValid(trackingDomain)) {
      setError('Please format your tracking subdomain as: *.yourdomain.com')
      return false
    }
    resetRemove()
    setMailboxTrackingDomain({ mailboxId: mailbox.mailboxId, domain: trackingDomain })
  }

  const onRemove = () => {
    removeMailboxTrackingDomain({
      mailboxId: mailbox.mailboxId,
    })
    setTrackingDomain('')
  }

  const getTrackingDomainPrefix = () => {
    if (!mailbox?.trackingDomain?.domain) return null
    if (mailbox?.trackingDomain?.status === 'connected') {
      return <CheckCircleOutlined style={{ color: 'green', marginRight: 5, fontSize: 12 }} />
    } else {
      return <CloseCircleOutlined style={{ color: 'orange', marginRight: 5, fontSize: 12 }} />
    }
  }

  if (!mailbox) return null

  return (
    <div>
      <Card title={<span>Custom Tracking Domain</span>} size="small" style={{ marginTop: 20 }}>
        <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginBottom: 10 }}>
          Use your own tracking domain to improve deliverability. Add and verify a mailbox-level
          custom tracking domain.
        </div>
        {mailbox?.trackingDomain?.status === 'disconnected' && (
          <Alert
            message="There is an issue with your custom tracking domain. Please reconnect"
            type="error"
            style={{ marginBottom: 20 }}
          />
        )}
        <div style={{ display: 'flex' }}>
          <Input
            size="middle"
            placeholder="hi.yourdomain.com"
            value={trackingDomain}
            onChange={(e) => setTrackingDomain(e.target.value)}
            style={{ marginRight: 10 }}
            prefix={getTrackingDomainPrefix()}
            disabled={Boolean(mailbox?.trackingDomain?.domain) || isSetTrackingDomainLoading}
          />
          {mailbox?.trackingDomain?.domain ? (
            <Button onClick={onRemove} loading={isRemoveTrackingDomainLoading}>
              Remove
            </Button>
          ) : (
            <Button
              onClick={onSave}
              loading={isSetTrackingDomainLoading}
              disabled={!trackingDomain}
            >
              Verify & Save
            </Button>
          )}
        </div>
        {error && <span style={{ color: '#e9573f', fontSize: 13 }}>Error: {error}</span>}
        <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginTop: 10 }}>
          Please add a CNAME record in your DNS (e.g. hi.yourdomain.com) that points to{' '}
          <Typography.Text copyable>hi.tailarrow.com</Typography.Text>
          <br /> <br /> We recommend to avoid using email-related words like tracking, mail, link,
          custom, etc. in the name of your tracking domain.
        </div>
      </Card>
    </div>
  )
}

export default TrackingDomain
