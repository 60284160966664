import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

window.location.href = 'https://forms.sendpulse.com/ab97ac3deb'

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#f45d48',
        colorLink: '#f45d48',
        colorLinkHover: '#f45d4882',
        fontFamily:
          'GCentra, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
      },
      components: {
        Menu: {
          colorItemBg: '#fbfafa',
          colorItemBgSelected: 'rgba(244, 93, 72, 0.1)',
          // colorItemText: 'white',
        },
        Button: {
          // fontSizeSM: 12,
          // fontSizeLG: 12,
          // fontSizeIcon: 12,
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
