// CumulativeChart.tsx
import React, { useEffect, useState } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'
import dayjs from 'dayjs'
import 'dayjs/plugin/isSameOrBefore' // import the plugin
import 'dayjs/plugin/isSameOrAfter' // import the plugin

dayjs.extend(require('dayjs/plugin/isSameOrBefore')) // use the plugin
dayjs.extend(require('dayjs/plugin/isSameOrAfter')) // use the plugin

interface CumulativeChartProps {
  users: any[]
}

const CumulativeChart: React.FC<CumulativeChartProps> = ({ users }) => {
  const [cumulativeData, setCumulativeData] = useState<any[]>([])

  useEffect(() => {
    if (users.length === 0) return

    let cumulativeCount = 0

    const sortedUsers = [...users].sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )

    const startDate = dayjs(sortedUsers[0].createdAt)
    const endDate = dayjs(sortedUsers[sortedUsers.length - 1].createdAt)

    let currentDate = startDate
    const allDatesCumulative: any[] = []

    sortedUsers.forEach((user) => {
      const userDate = dayjs(user.createdAt)

      while (currentDate.isSameOrBefore(userDate)) {
        allDatesCumulative.push({ date: currentDate.format('DD-MM-YY'), count: cumulativeCount })
        currentDate = currentDate.add(1, 'day')
      }

      cumulativeCount++
      allDatesCumulative.push({ date: userDate.format('DD-MM-YY'), count: cumulativeCount })
    })

    // Cover remaining dates up to the end date
    while (currentDate.isSameOrBefore(endDate)) {
      allDatesCumulative.push({ date: currentDate.format('DD-MM-YY'), count: cumulativeCount })
      currentDate = currentDate.add(1, 'day')
    }

    setCumulativeData(allDatesCumulative)
  }, [users])

  return (
    <LineChart width={500} height={300} data={cumulativeData}>
      <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} dot={{ r: 0 }} />
      <CartesianGrid stroke="transparent" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
    </LineChart>
  )
}

export default CumulativeChart
