import React, { useState } from 'react'

import AddMailboxModal from './AddMailboxModal'
import AddSMTPMailboxContainer from '../mailboxes/smtp/AddSMTPMailbox'
import AddOfficeMailbox from '../mailboxes/office/AddOutlookMailbox'
import AddExchangeMailbox from '../mailboxes/office/AddExchangeMailbox'
import AddGmailMailbox from '../mailboxes/gmail/AddGmailMailbox'

interface Props {
  visible: boolean
  onClose: () => void
}

const AddMailboxModalContainer: React.FC<Props> = ({ visible, onClose }) => {
  const [isAddSMTPOpen, setAddSMTPOpen] = useState(false)
  const [isAddGmailOpen, setAddGmailOpen] = useState(false)
  const [isAddGmailSMTPOpen, setAddGmailSMTPOpen] = useState(false)
  const [isAddOfficeOpen, setAddOfficeOpen] = useState(false)
  const [isAddExchangeOpen, setAddExchangeOpen] = useState(false)

  const onModalClose = () => {
    onClose()
  }

  return (
    <div style={{ width: '100%' }}>
      <AddMailboxModal
        visible={visible}
        onAddGmail={() => {
          setAddGmailOpen(true)
          onModalClose()
        }}
        onAddGmailSMTP={() => {
          setAddGmailSMTPOpen(true)
          onModalClose()
        }}
        onAddOffice={() => {
          setAddOfficeOpen(true)
          onModalClose()
        }}
        onAddMsExchange={() => {
          setAddExchangeOpen(true)
          onModalClose()
        }}
        onAddSmtp={() => {
          setAddSMTPOpen(true)
          onModalClose()
        }}
        onCancel={onModalClose}
      />
      <AddSMTPMailboxContainer
        open={isAddSMTPOpen || isAddGmailSMTPOpen}
        gmail={isAddGmailSMTPOpen}
        onClose={() => {
          setAddSMTPOpen(false)
          setAddGmailSMTPOpen(false)
          onModalClose()
        }}
      />
      <AddOfficeMailbox
        open={isAddOfficeOpen}
        onClose={() => {
          setAddOfficeOpen(false)
          onModalClose()
        }}
      />
      <AddGmailMailbox open={isAddGmailOpen} onClose={() => setAddGmailOpen(false)} />
      <AddExchangeMailbox
        open={isAddExchangeOpen}
        onClose={() => {
          setAddExchangeOpen(false)
          onModalClose()
        }}
      />
    </div>
  )
}

export default AddMailboxModalContainer
