import React, { ReactNode, useEffect, useState } from 'react'
import { Button, Drawer, Input, Modal, Progress, Select, Tag } from 'antd'
import { useFetchCampaigns } from '../../../Campaigns/api/campaignQueries'
import AddProspectsTable from './AddProspectsTable'
import { useFetchProspect, useFetchProspects } from '../../api/prospectsQueries'
import ExistingProspectFilter from './SearchBar'
import { useImportProspectsByFilter } from '../../api/prospectsMutations'

interface Props {
  isOpen: boolean
  onClose: () => void
  campaignId?: string
}

const AddExistingProspects: React.FC<Props> = ({ isOpen, onClose, campaignId }) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState('')
  const [searchFilterLoc, setSearchFilter] = useState<string>('')

  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()
  const {
    prospects,
    total,
    isLoading: fetchProspectsLoading,
    isFetching: fetchProspectsLoading2,
    isSuccess: fetchProspectsSuccess,
  } = useFetchProspects(
    {
      campaignId: selectedCampaignId,
      excludedCampaignIds: campaignId ? [campaignId] : [],
      search: searchFilterLoc,
    },
    { pageSize: 25, page: 1 }
  )

  const { importProspects } = useImportProspectsByFilter()

  const onImportProspects = () => {
    importProspects({
      filter: {
        campaignId: selectedCampaignId,
        excludedCampaignIds: campaignId ? [campaignId] : [],
        search: searchFilterLoc,
      },
      campaignId,
    })
  }

  const onCloseModal = () => {
    setSelectedCampaignId('')
    setSearchFilter('')
    onClose()
  }

  return (
    <Modal
      title={'Add Existing Prospects'}
      onCancel={onCloseModal}
      open={isOpen}
      keyboard={false}
      width={765}
      footer={
        <>
          <span style={{ marginRight: 10 }}>Total found: {total}</span>
          <span style={{ marginRight: 10 }}>Showing: {prospects?.length || 0}</span>
          <Button disabled={!total} type="primary" onClick={onImportProspects}>
            Add all to campaign
          </Button>
        </>
      }
      style={{ margin: '0 auto' }}
    >
      All prospects from this selection will be added.
      <ExistingProspectFilter
        campaigns={campaigns}
        campaignId={campaignId}
        isLoading={fetchCampaignsLoading}
        setSearchFilter={setSearchFilter}
        setSelectedCampaignId={setSelectedCampaignId}
      />
      <AddProspectsTable prospects={prospects} isLoading={fetchProspectsLoading} />
    </Modal>
  )
}

export default AddExistingProspects
