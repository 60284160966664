import React, { FC, useState, useEffect } from 'react'
import { Typography, Button, Spin, message } from 'antd'

import Prospects from './components/Prospects'
import { useAddProspects, useRemoveProspects } from './api/prospectsMutations'
import { useGenProspectsIcebreakers } from '../Icebreaker/api/icebreakerMutations'

const { Title } = Typography

interface Props {}

const ProspectsPageContainer: FC<Props> = ({}) => {
  const [selectedProspects, setSelectedProspects] = useState<string[]>([])
  const {
    removeProspects,
    isLoading: isRemoveProspectsLoading,
    isSuccess: isRemoveProspectsSuccess,
  } = useRemoveProspects()

  const { addProspects } = useAddProspects()

  useEffect(() => {
    if (isRemoveProspectsLoading) return
    if (isRemoveProspectsSuccess) {
      setSelectedProspects([])
      message.success('Prospect(s) have been removed')
    }
  }, [isRemoveProspectsLoading, isRemoveProspectsSuccess])

  const onAddProspects = (prospects: any[]) => {
    addProspects(prospects)
  }

  const onRemoveProspects = (prospectIds: string[]) => {
    if (false) return
    if (!prospectIds || !prospectIds.length) return

    removeProspects({ prospectIds })
    setSelectedProspects([])
  }

  const {
    genProspectsIcebreakers,
    isLoading: isGenProspectsIBLoading,
    isSuccess: isGenProspectsIBSuccess,
  } = useGenProspectsIcebreakers()

  const onPersonalizeProspects = (prospectIds: string[]) => {
    if (!prospectIds || !prospectIds.length) return

    genProspectsIcebreakers({ prospectIds })
    setSelectedProspects([])
  }

  return (
    <>
      <Title level={3} style={{ marginBottom: 20, marginTop: 0 }}>
        Prospects
      </Title>
      {/* <div style={{ margin: '10px 0' }}> */}
      {/* <Button onClick={onRefresh} icon={<ReloadOutlined />}>
          Refresh
        </Button> */}
      {/* </div> */}
      <Prospects
        onRemoveProspects={onRemoveProspects}
        onAddProspects={onAddProspects}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        onPersonalizeProspects={onPersonalizeProspects}
        loading={isRemoveProspectsLoading || isGenProspectsIBLoading}
        campaignId={undefined}
      />
    </>
  )
}

export default ProspectsPageContainer
