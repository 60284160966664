import React from 'react'
import { useFetchStatistics } from '../../Analytics'
import Analytics from '../../Analytics/Analtycs/Analytics'

interface Props {}

const CampaignListAnalyticsContainer: React.FC<Props> = () => {
  const { statistics, isLoading } = useFetchStatistics('all')
  return (
    <Analytics
      statistics={statistics}
      isLoading={isLoading}
      isSingleView={false}
      showStepView={false}
    />
  )
}

export default CampaignListAnalyticsContainer
