import React, { useEffect, useState } from 'react'
import { Input, message, Select, Modal, Button, List, Space, Typography, Tag } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

import { useFetchMailboxes, useSendTestEmail } from '../../../Mailboxes'
import { useFetchProspects } from '../../../Prospects/api/prospectsQueries'
import { useFetchSequence } from '../../../Sequence'
import { Campaign, isEmailSequenceStep } from '@mailarrow/types'
import history from '../../../../router/history'
import { MailboxTag } from '../../../Mailboxes/components/MailboxTag/MailboxTag'

const { Text } = Typography

interface Props {
  isOpen: boolean
  onClose: () => void
  onStartCampaign: () => void
  startCampaignLoading: boolean
  campaign?: Campaign
}

const StartCampaignModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onStartCampaign,
  campaign,
  startCampaignLoading,
}) => {
  const { total, isLoading: fetchProspectsLoading } = useFetchProspects({
    campaignId: campaign && campaign.campaignId,
  })

  const { sequence, isLoading: fetchSequenceLoading } = useFetchSequence(
    campaign && campaign.sequenceId
  )

  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()

  const campaignMailboxes = mailboxes?.filter((m) => campaign?.mailboxIds?.includes(m.mailboxId))

  const { user } = useSelector((state: RootState) => state.user)

  const { sendTestEmail, isLoading: sendTestEmailLoading } = useSendTestEmail()

  const onSendTest = () => {
    if (!campaignMailboxes?.length || !user?.email || !sequence) return
    const firstSequenceStep = sequence.steps[0]
    if (isEmailSequenceStep(firstSequenceStep)) {
      const { body, subject } = firstSequenceStep.variations[0]
      // just send test email from the first mailbox
      const { mailboxId } = campaignMailboxes[0]
      sendTestEmail({ mailboxId, body, subject, prospectEmail: user.email })
    }
  }

  const allowLaunchCampaign = Boolean(sequence?.steps.length && total && campaignMailboxes?.length)

  return (
    <Modal
      title="Campaign Summary"
      onCancel={onClose}
      open={isOpen}
      footer={<></>}
      style={{ maxWidth: 600, margin: '0 auto' }}
    >
      <List
        itemLayout="horizontal"
        loading={fetchProspectsLoading || fetchSequenceLoading || fetchMailboxesLoading}
        dataSource={[
          {
            label: 'Send from',
            value: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <MailboxTag mailboxes={campaignMailboxes} />
                </div>
                {!campaignMailboxes?.length && (
                  <Tag color="orange" style={{ marginLeft: 5 }}>
                    Add at least one mailbox
                  </Tag>
                )}
              </div>
            ),
          },
          {
            label: 'Sequence',
            value: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{sequence?.steps.length} steps </span>
                {!sequence?.steps.length && (
                  <Tag color="orange" style={{ marginLeft: 5 }}>
                    Add at least one step
                  </Tag>
                )}
              </div>
            ),
          },
          {
            label: 'Send to',
            value: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{total} prospects</span>
                {!total && (
                  <Tag color="orange" style={{ marginLeft: 5 }}>
                    Add at least one prospect
                  </Tag>
                )}
              </div>
            ),
          },
          {
            label: 'Enroll up to',
            value: `${campaign?.sendConfig.maxNewProspectsPerDay} new prospects per day`,
          },
          // { label: 'Send up to', value: `${mailbox?.maxPerDay} emails per day` },
          { label: 'Begin', value: 'Immideately' },
        ]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={<Text strong>{item.label}</Text>} description={item.value} />
          </List.Item>
        )}
        footer={
          <span style={{ fontSize: 12, color: 'gray' }}>
            The amount of daily emails sent depends on your{' '}
            <span
              onClick={() => history.push('/app/settings/mailboxes/')}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              mailbox settings
            </span>
          </span>
        }
      />
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <Space direction="horizontal">
          <Button
            type="dashed"
            onClick={onSendTest}
            disabled={!campaignMailboxes?.length || !sequence?.steps.length}
            loading={sendTestEmailLoading}
          >
            Send test email
          </Button>
          <Button onClick={() => message.success('Saved as draft')}>Save as Draft</Button>
          <Button
            loading={startCampaignLoading}
            disabled={!allowLaunchCampaign}
            onClick={onStartCampaign}
            type="primary"
          >
            Launch
          </Button>
        </Space>
      </div>
    </Modal>
  )
}

export default StartCampaignModal
