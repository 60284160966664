import React from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Button, Typography, MenuProps, Dropdown } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Campaign } from '@mailarrow/types'

const { Title } = Typography

interface Props {
  campaign: Campaign
  currentStep: number
  onDeleteCampaign: () => void
  onStartCampaign: () => void
  onChangeStep: (step: number) => void
}

const CampaignCreatorHeader: React.FC<Props> = ({
  campaign,
  currentStep,
  onDeleteCampaign,
  onStartCampaign,
  onChangeStep,
}) => {
  const { name } = campaign

  const items: MenuProps['items'] = [
    {
      key: '3',
      label: 'Remove Campaign',
      icon: <DeleteOutlined />,
      onClick: onDeleteCampaign,
      danger: true,
    },
  ]

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ margin: '0', display: 'flex' }}>
          <Title level={3} style={{ margin: '0px 10px 0 0' }}>
            {name}
          </Title>
          <Dropdown menu={{ items }}>
            <Button size="small" style={{ padding: '0 2px', alignSelf: 'center' }}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
        <div style={{ margin: '0' }}>
          {currentStep !== 0 && (
            <Button
              type="dashed"
              onClick={() => onChangeStep(currentStep - 1)}
              style={{ marginRight: '10px' }}
            >
              Back
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => (currentStep === 3 ? onStartCampaign() : onChangeStep(currentStep + 1))}
            style={{ marginRight: '10px' }}
          >
            {currentStep === 3 ? 'Review & Launch' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CampaignCreatorHeader
