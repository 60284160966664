import { BillingProfile } from '../billing-profile/billing-profile'

export enum BillingFrequency {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum PaymentProcessor {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  OTHER = 'other',
}

export interface Billing {
  userId: string

  stripeCustomerId: string
  stripeSubscriptionId?: string
  stripePriceId?: string

  plan?: string
  allowInviteTeam?: boolean
  // monthly or yearly
  billingFrequency?: BillingFrequency

  paymentProcessor?: PaymentProcessor

  allowedProspectCount?: number
  usedProspectCount?: number
  allowedEmailCount?: number
  usedEmailCount?: number

  // if an invoice has not been paid
  hasFailedCharge?: boolean
  // during checkout
  hasIncompletePaymentSession?: boolean
  // when we want to limit acces for incomplete payment
  hasDeactivatedSubscription?: boolean
  // has voluntarily cancelled subscription and not reactivated
  isCancelled?: boolean
  // the user's subscription has been paused
  isPaused?: boolean

  isTrial?: boolean
  trialStartDate?: Date
  trialEndDate?: Date

  referralId?: string

  billingProfile?: BillingProfile

  createdAt?: Date
  updatedAt?: Date
}

export interface AdditionalBillingInformation {
  willCancel: boolean
  hasActiveSubscription: boolean
  hasTrialSubscription: boolean
}
