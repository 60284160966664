import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import logger from '../../../services/errorlogger'
import { useState } from 'react'
import { chunk } from 'lodash'
import { FetchProspectsProps, FilterSettings, buildFilterQuery } from './build-filter-query'

export interface Prospect {
  prospectId?: string
  email: string
  firstName?: string
  lastName?: string
  timezone?: string | null
  [key: string]: any
}

interface AddProspectsResponse {
  createdCount: number
  updatedCount: number
  totalErrorCount: number
  errors: any[] // Replace 'any' with the specific error type
}

const CHUNK_SIZE = 1000

const addProspects = async ({
  campaignId,
  prospects = [],
  setProgress,
}: {
  campaignId?: string
  prospects: any[]
  setProgress: (progress: number) => void
}): Promise<AddProspectsResponse> => {
  setProgress(0)

  const prospectChunks = chunk(prospects, CHUNK_SIZE)
  const totalChunks = prospectChunks.length
  const totalResults: AddProspectsResponse = {
    createdCount: 0,
    updatedCount: 0,
    totalErrorCount: 0,
    errors: [],
  }

  for (let i = 0; i < prospectChunks.length; i++) {
    const response = await fetch.put(`/prospects/`, { prospects: prospectChunks[i], campaignId })
    const result = response.data

    totalResults.createdCount += result.createdCount
    totalResults.updatedCount += result.updatedCount
    totalResults.totalErrorCount += result.totalErrorCount
    totalResults.errors.push(...result.errors)

    if (setProgress) {
      setProgress((i + 1) / totalChunks)
    }
  }

  return totalResults
}

export const useAddProspects = (campaignId?: string) => {
  const [progress, setProgress] = useState<number>(0)
  const queryClient = useQueryClient()

  const { data, mutate, isLoading, isError, isSuccess, error, reset } = useMutation<
    AddProspectsResponse,
    Error,
    Prospect[]
  >((prospects) => addProspects({ campaignId, prospects, setProgress }), {
    onMutate: () => {
      setProgress(0)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['prospects'])
      queryClient.invalidateQueries(['journeys'])
      queryClient.refetchQueries(['journeys'])
    },
    onError: (error: Error) => {
      setProgress(0)
      message.error(`Error: ${error.message}`, 10)
      console.log('Error in useAddProspects')
      console.log(error)
      logger.logError(error)
    },
  })

  const resetWithProgress = () => {
    setProgress(0)
    reset()
  }

  return {
    result: data,
    addProspects: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
    progress,
    reset: resetWithProgress,
  }
}

const updateProspect = async (prospectId: string, prospect: Partial<Prospect>) => {
  return await fetch.patch(`/prospects/${prospectId}/`, { prospect })
}

export const useUpdateProspect = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: { prospectId: string; prospectProps: Partial<Prospect> }) =>
      updateProspect(args.prospectId, args.prospectProps),
    {
      onSuccess: (response) => {
        message.success('Prospect has been updated')
        const prospect = response.data

        queryClient.invalidateQueries(['prospects'])
        queryClient.refetchQueries(['prospects'])

        queryClient.invalidateQueries(['journeys'])
        queryClient.refetchQueries(['journeys'])

        queryClient.invalidateQueries(['prospect', prospect.prospectId])
        queryClient.refetchQueries(['prospect', prospect.prospectId])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    updateProspect: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const updateProspects = async (prospects: Partial<Prospect>[]) => {
  return await fetch.patch(`/prospects/`, { prospects })
}

export const useUpdateProspects = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (prospects: Partial<Prospect>[]) => updateProspects(prospects),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['prospects'])
        queryClient.refetchQueries(['prospects'])

        queryClient.invalidateQueries(['journeys'])
        queryClient.refetchQueries(['journeys'])

        queryClient.invalidateQueries(['prospect'])
        queryClient.refetchQueries(['prospect'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    updateProspects: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const removeProspects = async ({
  prospectIds,
  campaignId,
}: {
  prospectIds: string[]
  campaignId?: string
}) => {
  return await fetch.delete(`/prospects/`, { data: { campaignId, prospectIds } })
}

export const useRemoveProspects = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: { prospectIds: string[]; campaignId?: string }) => removeProspects(args),
    {
      onSuccess: () => {
        // TODO: find out if it refetches or not.
        // if not, figure out the api required to refetch
        // message.success('Prospect(s) have been removed')
        // from this campaign
        queryClient.invalidateQueries(['prospects'])
        queryClient.refetchQueries(['prospects'])

        queryClient.invalidateQueries(['journeys'])
        queryClient.refetchQueries(['journeys'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    removeProspects: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

export const importProspectsByFilter = async ({
  filter,
  campaignId,
}: {
  filter: FetchProspectsProps
  campaignId?: string
}) => {
  try {
    const params = buildFilterQuery(filter)
    // TODO: test this
    const response = await fetch.put(`/prospects/import/?${params}`, { campaignId })
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useImportProspectsByFilter = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (args: { filter: FilterSettings; campaignId?: string }) => importProspectsByFilter(args),
    {
      onSuccess: () => {
        // TODO: find out if it refetches or not.
        // if not, figure out the api required to refetch
        queryClient.invalidateQueries(['prospects'])
        queryClient.refetchQueries(['prospects'])

        queryClient.invalidateQueries(['journeys'])
        queryClient.refetchQueries(['journeys'])
        message.success('Prospects have been added')
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    importProspects: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
