export const toCamelCase = (str: string): string => {
  // Split the string into words using a regular expression
  const words = str.trim().split(/[\s_-]+/)

  // Convert the first word to lowercase
  const firstWord = words[0].toLowerCase()

  // Capitalize the first letter of each subsequent word and join them
  const camelCaseWords = words.slice(1).map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })

  // Combine the first word with the camelCaseWords
  return firstWord + camelCaseWords.join('')
}
