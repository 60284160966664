export const isEmailAddressValid = (email: string): boolean => {
  if (!email) return false

  const emailParts = email.split('@')

  if (emailParts.length !== 2) return false

  const account = emailParts[0]
  const address = emailParts[1]

  if (account.length > 64) return false
  else if (address.length > 255) return false

  const domainParts = address.split('.')

  if (
    domainParts.some(function (part) {
      return part.length > 63
    })
  ) {
    return false
  }

  // Regular expression to validate email address
  const emailRegex =
    /^(?:(?!\.)[\p{L}\p{N}_!#$%&'*+\/=?^`{|}~-]+(?:\.(?!\.)(?:[\p{L}\p{N}_!#$%&'*+\/=?^`{|}~-]+))*)@(?:(?!\.)[\p{L}\p{N}-]+(?:(?:\.(?!\.)(?:[\p{L}\p{N}-]+)))+)$/u

  // Test the email address against the regular expression
  return emailRegex.test(String(email).toLowerCase())
}
