import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions as authActions } from '../../modules/Auth/reducer'

const Logout: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Logout... | Mailarrow App'
    dispatch(authActions.signOutUser())
  }, [dispatch])

  return <div>Logging you out...</div>
}

export default Logout
