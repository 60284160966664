import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as AuthActions } from '../../store/UserReducer'
import { Card } from 'antd'

const VerifyEmail: React.FC = () => {
  const dispatch = useDispatch()
  const signOutUser = () => dispatch(AuthActions.signOutUser())

  useEffect(() => {
    document.title = 'Account Inactive | Mailarrow App'
  }, [])

  return (
    <div className="signup-form">
      <Card
        title={`Account Inactive`}
        className="signup-card"
        headStyle={{ fontSize: 20 }}
        style={{ width: '500px' }}
      >
        <div style={{ textAlign: 'left' }}>
          <span style={{ margin: '10px 0 20px 0', display: 'block' }}>
            This account is not active. Please refesh this page or contact support for more details.
          </span>
        </div>
      </Card>
      <div>
        Not Your Email?&nbsp;
        <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={signOutUser}>
          Logout
        </span>
      </div>
    </div>
  )
}

export default VerifyEmail
