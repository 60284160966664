import React from 'react'
import { Button, Table, Tag } from 'antd'

import { ColumnsType } from 'antd/es/table'

import './AddExistingProspectsTable.css'

interface Prospect {
  prospectId: string
  [key: string]: any
}

interface Props {
  prospects?: Prospect[]
  isLoading: boolean
}

const AddProspectsTable: React.FC<Props> = ({ prospects, isLoading }) => {
  const columns: ColumnsType = [
    {
      title: 'Email, Name',
      dataIndex: 'email',
      key: 'email',
      className: 'prospects-table-email',
      fixed: 'left',
      width: 300,
      ellipsis: true,
      // sorter: true,
      render: (email: string, item: any) => {
        const getName = (item: any) => {
          if (item.properties?.firstName || item.properties?.lastName)
            return `${item.properties?.firstName || ''} ${item.properties?.lastName || ''}`
          return item.email.split('@')[0]
        }
        // TODO: add loading and success values
        return (
          <div>
            <span style={{ fontWeight: 600 }}>{getName(item)}</span>
            <br />
            {email}
          </div>
        )
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        return item.properties?.companyName
      },
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        return (
          <a
            href={item.properties?.website}
            target="_blank"
            style={{ textDecoration: 'underline', color: '#6a6a6a' }}
            rel="noreferrer"
          >
            {item.properties?.website}
          </a>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={prospects}
      columns={columns as any}
      loading={isLoading}
      // onChange={onTableChange}
      rowKey="prospectId"
      // scroll={{ x: 1300, y: 540 }}
      className="add-existing-prosoects-table"
      size="small"
      rowClassName="prospects-table-row"
    />
  )
}

export default AddProspectsTable
