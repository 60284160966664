import React, { useState } from 'react'
import { Button, Input, Select, Tag } from 'antd'
import { Campaign } from '@mailarrow/types'

interface Props {
  setSearchFilter: (filter: string) => void
  setSelectedCampaignId: (campaignId: string) => void
  campaignId?: string
  campaigns?: Campaign[]
  isLoading: boolean
}

const ExistingProspectFilter: React.FC<Props> = ({
  setSearchFilter,
  setSelectedCampaignId,
  campaigns,
  campaignId,
  isLoading,
}) => {
  const [selectedCampaignIdLoc, setSelectedCampaignIdLoc] = useState('')
  const [searchFilterLoc, setSearchFilterLoc] = useState<string>('')

  const onSearch = () => {
    setSearchFilter(searchFilterLoc)
    setSelectedCampaignId(selectedCampaignIdLoc)
  }

  const otherCampaigns = (campaigns || [])?.filter((c) => c.campaignId !== campaignId)

  const campaignOptions = [
    { value: '', label: 'Not in campaign' },
    ...otherCampaigns.map(({ campaignId: value, name: label }) => ({ value, label })),
  ]

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Select
        placeholder="Filter by campaign"
        loading={isLoading}
        onChange={(id) => setSelectedCampaignIdLoc(id)}
        style={{
          width: 200,
          marginRight: 10,
        }}
        value={selectedCampaignIdLoc}
        defaultValue={selectedCampaignIdLoc}
        options={campaignOptions}
      ></Select>

      <Input.Search
        placeholder="Search by name or email.."
        value={searchFilterLoc}
        style={{ width: 200, marginRight: 10 }}
        onChange={(e) => setSearchFilterLoc(e.target.value)}
        // onSearch={() => setSearchFilter(searchFilterLoc)}
      />
      <Button onClick={onSearch}>Search</Button>
    </div>
  )
}

export default ExistingProspectFilter
