import React, { useState } from 'react'
import { Form, Input, Button, Typography, Card, message } from 'antd'
import copy from 'copy-to-clipboard'

const { TextArea } = Input
const { Title, Paragraph } = Typography

const PromptMakerContainer: React.FC = () => {
  const [context, setContext] = useState<string>(
    `You are a sales and cold email specialist. You are writing articles for the website of Mailarrow - a cold email outreach software.
    This article should be written in relation to cold email outreach.`
  )
  const [topic, setTopic] = useState<string>('')
  const [entities, setEntities] = useState<string>('')
  const [wordcount, setWordcount] = useState<number>(5000)
  const sectionLength = 1000
  const sections = Math.ceil(wordcount / sectionLength)
  const [faq, setFaq] = useState<string>('')
  const [output, setOutput] = useState<string>('')

  const onCopy = () => {
    copy(output)
    message.success('Copied!')
  }

  //   ${
  //  faqIndex + 2
  // }. You will write SEO Optimized Meta title ot 50 characters without the name of the company and with Emojis for the article

  const generatePrompt = (event: React.FormEvent) => {
    event.preventDefault()

    let conversationStructure = '1. I will provide you the entities for the blog post (done)\n'
    conversationStructure += `2. You will return a short 2 sentence friendly expert engaging introduction into the article`
    for (let i = 0; i < sections; i++) {
      const baseIndex = 3 + i * 3
      let contentOrder
      if (i === 0) {
        contentOrder = 'first'
      } else if (i === sections - 1) {
        contentOrder = 'last'
      } else {
        contentOrder = 'next'
      }
      conversationStructure += `
    ${baseIndex}. You will return the ${contentOrder} ${sectionLength} words part of the article with level 2 and 3 subheadings
    ${baseIndex + 1}. I will type ‘next’`
    }
    const faqIndex = 2 + sections * 3
    conversationStructure += `
    ${faqIndex}. You will write answers to the FAQ. Formatted: question as level 3 heading, answer as plain text underneath
    ${faqIndex + 1}. You will write JSON-LD for the FAQ
    ${
      faqIndex + 3
    }. You will write SEO Optimized Meta description of 150 characters with Emojis for the article. Include some of the entities here`

    const prompt = `
    Context:
    ${context}

    Goal:
    Start writing a long-form blog post in English language in a authoratitative tone on ${topic} using all the following entities the number of times indicated after '0/':
    ${entities}

    Wordcount:
    ${wordcount}
    Make sure that the article is split into ${sections} parts. Each part stops at ${sectionLength} words

    Please answer the following FAQs:
    ${faq}

    After you have answered those FAQs, then create JSON-LD structured data for those same FAQs using <script> tags so I can paste into wordpress.

    Throughout, please keep in mind the CTA to make the reader:
    Sign up for Mailarrow, our cold email outreach software

    CRITERIA OF A GOOD BLOG POST:
    - Your write clear sentences with concise phrases
    - You write two sentence paragraphs
    - You prioritize simple words, genuine and persuasive language.
    - Use headings, subheadings and lists
    - You are an expert in your field and the article is written from the perspective of a specialist

    STRUCTURE OF TODAY'S CONVERSATION:
    ${conversationStructure}

   FORMAT OF OUR INTERACTION:
    - I will reply next and only then you go into continuing the blog post `
    setOutput(prompt)
  }

  return (
    <div>
      <div style={{ display: 'flex', gap: 20 }}>
        <div style={{ width: 500 }}>
          <div>
            <label htmlFor="context">Context:</label>
            <TextArea
              id="context"
              value={context}
              onChange={(e) => setContext(e.target.value)}
              rows={3}
            />
          </div>
          <div>
            <label htmlFor="topic">Topic:</label>
            <Input id="topic" value={topic} onChange={(e) => setTopic(e.target.value)} />
          </div>
          <div>
            <label htmlFor="entities">Entities (Copy from Surfer, or add your own):</label>
            <TextArea
              id="entities"
              value={entities}
              onChange={(e) => setEntities(e.target.value)}
              rows={5}
            />
          </div>
          <div>
            <label htmlFor="wordcount">Wordcount:</label>
            <Input
              type="number"
              id="wordcount"
              value={wordcount}
              onChange={(e) => setWordcount(parseInt(e.target.value))}
            />
          </div>
          <div>
            <label htmlFor="sections">
              Sections (The number of 'next' prompts required to reach full wordcount):
            </label>
            <Input type="number" id="sections" value={sections} readOnly disabled />
          </div>
          <div>
            <label htmlFor="faq">FAQs (Copy from Surfer or add your own):</label>
            <TextArea id="faq" value={faq} onChange={(e) => setFaq(e.target.value)} rows={5} />
          </div>
          <div>
            <Button type="primary" onClick={generatePrompt}>
              Generate Prompt
            </Button>
          </div>
        </div>
        <div style={{ width: 'auto', flexGrow: 1 }}>
          <Card
            title="Prompt"
            style={{ width: '100%', maxWidth: 800 }}
            extra={
              <>
                <Button onClick={() => onCopy()} style={{ marginBottom: 10 }}>
                  Copy to clipboard
                </Button>
              </>
            }
          >
            <div style={{ whiteSpace: 'pre-line', fontSize: 13 }}>{output}</div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default PromptMakerContainer
