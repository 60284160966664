export const verifiedGroups = [
  [
    'https://www.yesware.com/blog/reminder-email/',
    'https://www.saleshandy.com/blog/politely-remind-someone-reply-email/',
    'https://www.yesware.com/blog/i-hope-this-email-finds-you-well/',
    'https://www.yesware.com/blog/emails-that-get-replies/',
  ],
  [
    'https://mailmeteor.com/blog/professional-email-address',
    'https://hunter.io/blog/how-to-write-a-professional-email/',
    'https://www.yesware.com/blog/how-to-write-a-professional-email/',
    'https://www.smartlead.ai/blog/how-to-write-professional-emails',
  ],
  [
    'https://www.saleshandy.com/blog/improve-cold-email-response-rate/',
    'https://blog.klenty.com/cold-email-response-rate/',
  ],
  [
    'https://mailmeteor.com/blog/spam-words',
    'https://woodpecker.co/blog/spam-words/',
    'https://hunter.io/blog/spam-trigger-words/',
  ],
  [
    'https://www.saleshandy.com/blog/how-to-write-a-follow-up-email/',
    'https://woodpecker.co/blog/how-to-send-a-follow-up-email-after-no-response/',
    'https://mailmeteor.com/blog/how-to-follow-up-on-an-email',
    'https://mailmeteor.com/blog/follow-up-examples',
    'https://hunter.io/blog/how-to-write-a-follow-up-email/',
    'https://mailmeteor.com/blog/follow-up',
    'https://woodpecker.co/blog/follow-up-statistics/',
    'https://www.yesware.com/blog/sales-follow-up-email-mistakes/',
    'https://woodpecker.co/blog/emails-followups-sending/',
    'https://www.yesware.com/blog/sales-follow-up-statistics/',
    'https://salesblink.io/blog/follow-up-email',
    'https://quickmail.io/follow-up-email',
    'https://woodpecker.co/blog/how-to-write-follow-up-email-if-you-havent-heard-back/',
  ],
  [
    'https://www.yesware.com/blog/email-signature/',
    'https://hunter.io/blog/professional-email-signature/',
    'https://mailmeteor.com/blog/email-signature-examples',
    'https://www.saleshandy.com/blog/email-signature-generators/',
    'https://woodpecker.co/blog/best-signatures-sales-emails/',
    'https://salesblink.io/blog/sales-email-signature',
    'https://woodpecker.co/blog/a-little-big-thing-what-do-i-put-in-my-email-signature/',
    'https://blog.klenty.com/cold-email-signature/',
  ],
  ['https://www.saleshandy.com/blog/chatgpt-cold-emails/'],
  [
    'https://www.yesware.com/blog/greatful-or-grateful/',
    'https://www.yesware.com/blog/greatful-or-grateful/',
  ],
  [
    'https://www.yesware.com/blog/business-development-manager/',
    'https://www.yesware.com/blog/business-development-manager/',
  ],
  [
    'https://salesblink.io/blog/fanatical-prospecting-book-summary',
    'https://salesblink.io/blog/fanatical-prospecting-book-summary',
  ],
  [
    'https://www.yesware.com/blog/just-checking-in/',
    'https://www.yesware.com/blog/just-checking-in/',
  ],
  [
    'https://quickmail.io/5-follow-up-email-subject-line',
    'https://salesblink.io/blog/cold-email-subject-lines',
    'https://www.smartlead.ai/blog/cold-email-subject-lines',
    'https://www.saleshandy.com/blog/best-subject-lines-for-cold-emails/',
    'https://www.smartlead.ai/blog/follow-up-email-subject-lines',
    'https://mailmeteor.com/blog/best-email-subject-lines',
    'https://blog.klenty.com/quotation-follow-up-email/',
  ],
  [
    'https://www.saleshandy.com/blog/verify-email-address/',
    'https://woodpecker.co/blog/what-is-email-validation-and-how-does-it-help-your-email-deliverability/',
    'https://salesblink.io/blog/verify-email-addresses-for-cold-emails',
    'https://salesblink.io/blog/email-verification-tool',
    'https://salesblink.io/blog/check-email-validity',
    'https://quickmail.io/email-verification-software',
    'https://salesblink.io/blog/top-13-benefits-of-email-verification',
    'https://help.quickmail.io/article/313-email-verification',
    'https://hunter.io/blog/how-to-verify-email-address/',
  ],
  [
    'https://www.yesware.com/blog/sales-goals/',
    'https://salesblink.io/blog/setting-sales-goals',
    'https://woodpecker.co/blog/how-to-set-achievable-sales-targets-for-an-outbound-sales-team/',
  ],
  ['https://quickmail.io/how-to-create-a-webinar-email-sequence-that-converts'],
  [
    'https://www.yesware.com/blog/email-sequences/',
    'https://www.saleshandy.com/blog/email-sequence-softwares/',
    'https://salesblink.io/blog/sales-cold-email-sequence',
    'https://quickmail.io/sales-sequence',
    'https://www.saleshandy.com/blog/email-sequence/',
  ],
  [
    'https://www.yesware.com/blog/looking-forward-to-hearing-from-you/',
    'https://www.yesware.com/blog/looking-forward-to-hearing-from-you/',
  ],
  [
    'https://blog.klenty.com/best-email-sign-offs/',
    'https://blog.klenty.com/follow-up-email-after-a-demo/',
  ],
  ['https://salesblink.io/blog/best-sales-movies', 'https://blog.klenty.com/best-sales-movies/'],
  [
    'https://salesblink.io/blog/sales-books',
    'https://www.yesware.com/blog/sales-books/',
    'https://blog.klenty.com/top-sales-books/',
  ],
  [
    'https://salesblink.io/blog/best-sales-techniques',
    'https://www.yesware.com/blog/sales-techniques/',
  ],
  [
    'https://www.saleshandy.com/blog/send-email-to-multiple-recipients/',
    'https://salesblink.io/blog/how-to-send-email-to-multiple-recipients',
    'https://www.smartlead.ai/blog/send-email-to-multiple-recipients',
  ],
  ['https://woodpecker.co/blog/cold-email-delivery-time-zones/'],
  ['https://www.smartlead.ai/blog/how-to-create-business-email'],
  [
    'https://hunter.io/blog/how-to-find-someones-email-address/',
    'https://quickmail.io/find-emails-on-linkedin',
    'https://www.yesware.com/blog/find-email-addresses/',
    'https://woodpecker.co/blog/how-find-email-linkedin-profile/',
    'https://www.saleshandy.com/blog/email-finding-tools/',
    'https://salesblink.io/blog/email-lookup-tools',
    'https://quickmail.io/reverse-email-lookup',
    'https://quickmail.io/practical-guide-to-finding-anyones-email-including-tools-methods-and-tips',
  ],
  ['https://blog.klenty.com/how-long-should-a-cold-email-be/'],
  ['https://www.yesware.com/blog/gap-selling/', 'https://www.yesware.com/blog/gap-selling/'],
  ['https://www.yesware.com/blog/pain-points/', 'https://salesblink.io/blog/customer-pain-point'],
  [
    'https://www.yesware.com/blog/feature-benefit-selling/',
    'https://woodpecker.co/blog/features-vs-benefits/',
  ],
  [
    'https://www.yesware.com/blog/out-of-office-examples/',
    'https://mailmeteor.com/blog/funny-out-of-office-messages',
    'https://www.saleshandy.com/blog/out-of-office-message/',
  ],
  [
    'https://woodpecker.co/blog/smtp-imap/',
    'https://salesblink.io/blog/difference-between-smtp-imap-pop3',
    'https://salesblink.io/blog/imap-vs-pop3',
    'https://www.saleshandy.com/blog/imap-pop3-smtp-google-account/',
    'https://mailmeteor.com/blog/smtp-relay',
  ],
  [
    'https://salesblink.io/blog/overcome-sales-objection',
    'https://www.yesware.com/blog/sales-objections/',
    'https://quickmail.io/sales-objections',
    'https://blog.klenty.com/sales-objection-handling/',
    'https://blog.klenty.com/11-common-cold-calling-objections-sdrs-face-responses-from-top-sellers/',
  ],
  [
    'https://salesblink.io/blog/lead-qualification-checklist',
    'https://www.yesware.com/blog/lead-qualification/',
    'https://www.yesware.com/blog/marketing-qualified-lead/',
    'https://www.saleshandy.com/blog/sales-qualifying-questions/',
    'https://www.saleshandy.com/blog/lead-qualification/',
    'https://www.yesware.com/blog/sales-qualified-leads/',
  ],
  ['https://hunter.io/blog/blogger-outreach/', 'https://hunter.io/blog/blogger-outreach/'],
  ['https://www.yesware.com/blog/tam-sam-som/', 'https://www.yesware.com/blog/tam-sam-som/'],
  [
    'https://www.yesware.com/blog/sales-methodology/',
    'https://salesblink.io/blog/sales-methodology',
  ],
  [
    'https://www.yesware.com/blog/personal-selling/',
    'https://blog.klenty.com/personal-selling-to-boost-conversions/',
  ],
  [
    'https://quickmail.io/cold-email/esp',
    'https://www.saleshandy.com/blog/cold-email-service-provider/',
    'https://quickmail.io/cold-email/software',
  ],
  [
    'https://www.saleshandy.com/blog/cold-email-software/',
    'https://blog.klenty.com/email-sequence-software-tools/',
    'https://blog.klenty.com/prospecting-email-sales/',
    'https://quickmail.io/email-sequence-software',
    'https://salesblink.io/blog/cold-email-software',
  ],
  [
    'https://www.yesware.com/blog/cold-calling-scripts/',
    'https://blog.klenty.com/cold-calling-scripts/',
    'https://www.yesware.com/blog/sales-call-script/',
  ],
  [
    'https://www.yesware.com/blog/sales-commission-structures/',
    'https://salesblink.io/blog/sales-commission',
  ],
  [
    'https://www.yesware.com/blog/high-ticket-sales/',
    'https://www.yesware.com/blog/high-ticket-sales/',
  ],
  [
    'https://www.saleshandy.com/blog/email-scraping-tools/',
    'https://www.saleshandy.com/blog/email-scraping-tools/',
  ],
  [
    'https://salesblink.io/blog/best-sales-automation-tools',
    'https://www.saleshandy.com/blog/sales-automation-tools/',
  ],
  [
    'https://www.yesware.com/blog/sales-voicemail-scripts/',
    'https://blog.klenty.com/sales-voicemail-script/',
  ],
  [
    'https://www.yesware.com/blog/sandler-sales-method/',
    'https://www.yesware.com/blog/sandler-sales-method/',
    'https://salesblink.io/blog/relationship-selling-examples',
  ],
  [
    'https://mailmeteor.com/blog/how-to-write-email-with-attachment',
    'https://hunter.io/blog/email-attachment/',
    'https://woodpecker.co/blog/attachments-cold-email/',
    'https://help.quickmail.io/article/368-can-i-add-attachments-to-quickmail-emails',
    'https://www.yesware.com/blog/attachment-tracking-know-happens-click-send/',
  ],
  [
    'https://salesblink.io/blog/spin-selling-book-summary',
    'https://blog.klenty.com/spin-selling-technique/',
    'https://salesblink.io/blog/spin-selling-method',
  ],
  [
    'https://www.saleshandy.com/blog/start-lead-generation-business/',
    'https://quickmail.io/starting-a-lead-generation-business',
    'https://quickmail.io/how-to-sell-leads',
    'https://quickmail.io/lead-generation-business-model',
    'https://woodpecker.co/blog/lead-generation-agencies/',
    'https://hunter.io/blog/lead-generation-tools/',
    'https://woodpecker.co/blog/how-start-lead-generation-business/',
  ],
  [
    'https://www.yesware.com/blog/hunter-vs-farmer-sales/',
    'https://www.yesware.com/blog/hunter-vs-farmer-sales/',
  ],
  [
    'https://www.saleshandy.com/blog/email-bounce-back/',
    'https://hunter.io/blog/soft-bounce-vs-hard-bounce/',
    'https://www.yesware.com/blog/decrease-your-email-bounce-rate/',
    'https://woodpecker.co/blog/why-emails-bounce-10-most-common-issues/',
    'https://www.smartlead.ai/blog/email-bounce-back-what-it-means-and-how-to-fix-it',
  ],
  [
    'https://www.yesware.com/blog/personalized-emails/',
    'https://hunter.io/blog/email-personalization/',
    'https://salesblink.io/blog/personalized-cold-emails',
    'https://quickmail.io/cold-email/personalization',
    'https://woodpecker.co/blog/15-things-you-need-to-know-about-domains-before-running-a-cold-email-campaign/',
    'https://hunter.io/blog/personalization-examples/',
    'https://www.saleshandy.com/blog/basho-email/',
    'https://hunter.io/blog/basho-email/',
    'https://www.saleshandy.com/blog/basho-email/',
    'https://hunter.io/blog/basho-email/',
  ],
  [
    'https://www.saleshandy.com/blog/saas-affiliate-programs/',
    'https://www.saleshandy.com/blog/saas-affiliate-programs/',
    'https://woodpecker.co/blog/partner-program-find-pitch-partners/',
  ],
  ['https://salesblink.io/blog/sales-automation', 'https://hunter.io/blog/sales-automation/'],
  [
    'https://www.yesware.com/blog/sales-plan/',
    'https://www.yesware.com/blog/strategic-sales-plans-examples/',
    'https://hunter.io/blog/ideal-customer-profile/',
    'https://salesblink.io/blog/ideal-customer-profile',
    'https://woodpecker.co/blog/rework-ideal-customer-profile/',
  ],
  ['https://www.yesware.com/blog/sales-training/', 'https://www.yesware.com/blog/sales-training/'],
  [
    'https://www.yesware.com/blog/how-to-end-an-email/',
    'https://hunter.io/blog/how-to-end-an-email/',
    'https://www.saleshandy.com/blog/end-email-professionally/',
    'https://salesblink.io/blog/best-email-closings',
    'https://woodpecker.co/blog/how-to-end-a-business-email-15-good-and-a-few-bad-email-sign-offs/',
  ],
  [
    'https://www.yesware.com/blog/b2b-digital-sales-strategy/',
    'https://www.yesware.com/blog/b2b-digital-sales-strategy/',
  ],
  [
    'https://www.yesware.com/blog/sales-pitch-examples/',
    'https://hunter.io/blog/sales-pitch-examples/',
    'https://www.yesware.com/blog/sales-pitch/',
    'https://blog.klenty.com/sales-pitch/',
    'https://www.yesware.com/blog/elevator-pitch-examples/',
  ],
  [
    'https://www.yesware.com/blog/sales-prospecting-techniques/',
    'https://quickmail.io/prospecting-methods',
    'https://quickmail.io/build-a-prospect-list',
    'https://hunter.io/blog/sales-prospecting/',
    'https://www.yesware.com/blog/prospect-list/',
    'https://hunter.io/blog/prospect-list/',
    'https://quickmail.io/lead-lists',
  ],
  ['https://woodpecker.co/blog/email-sending-limits/', 'https://quickmail.io/email-sending-limits'],
  ['https://salesblink.io/blog/value-based-selling', 'https://www.yesware.com/blog/value-selling/'],
  [
    'https://salesblink.io/blog/b2b-sales-strategies',
    'https://www.yesware.com/blog/b2b-sales-strategy/',
  ],
  [
    'https://quickmail.io/email-finder',
    'https://www.saleshandy.com/blog/personal-email-finder/',
    'https://www.saleshandy.com/blog/linkedin-email-finder/',
    'https://woodpecker.co/blog/voila-norbert/',
    'https://hunter.io/blog/email-finder-tools/',
  ],
  [
    'https://quickmail.io/email-tracking-software',
    'https://hunter.io/blog/email-tracking-tools/',
    'https://www.saleshandy.com/blog/best-email-tracking-tools/',
    'https://salesblink.io/blog/email-tracking-tools',
  ],
  [
    'https://salesblink.io/blog/sales-performance',
    'https://www.yesware.com/blog/sales-performance/',
  ],
  [
    'https://www.yesware.com/blog/how-to-introduce-yourself-in-an-email/',
    'https://hunter.io/blog/how-to-introduce-yourself-in-an-email/',
    'https://www.saleshandy.com/blog/write-cold-email/',
    'https://blog.klenty.com/how-to-introduce-yourself-in-a-sales-email/',
    'https://salesblink.io/blog/tips-to-write-cold-emails-that-get-responses',
    'https://hunter.io/blog/how-to-write-a-cold-email/',
    'https://quickmail.io/how-to-cold-email-a-potential-client',
  ],
  [
    'https://woodpecker.co/blog/spf-dkim/',
    'https://quickmail.io/cold-email/spf-dkim-dmarc',
    'https://help.quickmail.io/article/370-spf-dkim-and-dmarc-records',
    'https://www.saleshandy.com/blog/spf-dkim-record/',
    'https://hunter.io/blog/spf-dkim-dmarc/',
  ],
  [
    'https://www.yesware.com/blog/stronger-than-you-think/',
    'https://www.yesware.com/blog/stronger-than-you-think/',
  ],
  [
    'https://www.yesware.com/blog/cold-email/',
    'https://woodpecker.co/blog/best-cold-email-examples-part-one/',
    'https://woodpecker.co/blog/bad-cold-email-to-good-cold-email-examples/',
    'https://blog.klenty.com/bad-cold-email-examples/',
    'https://www.saleshandy.com/blog/cold-email/',
    'https://hunter.io/blog/cold-email/',
  ],
  ['https://salesblink.io/blog/cold-email-vs-email-marketing'],
  [
    'https://hunter.io/blog/meeting-request-email/',
    'https://quickmail.io/how-to-write-meeting-request-emails-that-get-replies',
    'https://www.yesware.com/blog/schedule-a-meeting/',
    'https://www.yesware.com/blog/meeting-schedule-emails/',
  ],
  [
    'https://salesblink.io/blog/sales-podcasts-for-beginners',
    'https://www.yesware.com/blog/best-sales-podcasts/',
    'https://www.smartlead.ai/blog/best-sales-podcasts',
  ],
  [
    'https://www.yesware.com/blog/sales-targeting/',
    'https://hunter.io/blog/targeted-selling/',
    'https://www.saleshandy.com/blog/ideal-customer-profile/',
    'https://woodpecker.co/blog/ideal-customer/',
    'https://www.yesware.com/blog/ideal-customer-profile-vs-buyer-persona/',
    'https://www.yesware.com/blog/target-account-selling/',
  ],
  [
    'https://salesblink.io/blog/difference-cold-warm-hot-sales-leads',
    'https://www.yesware.com/blog/warm-up-cold-leads-outreach-tips/',
  ],
  ['https://www.yesware.com/blog/bant/', 'https://blog.klenty.com/bant-sales-qualification/'],
  [
    'https://salesblink.io/blog/how-i-raised-myself-from-failure-to-success-in-selling-book-summary',
    'https://salesblink.io/blog/how-i-raised-myself-from-failure-to-success-in-selling-book-summary',
  ],
  [
    'https://www.yesware.com/blog/linkedin-connection-request-message/',
    'https://woodpecker.co/blog/personalize-linkedin-connection-request/',
  ],
  [
    'https://salesblink.io/blog/sales-outreach-strategy',
    'https://www.yesware.com/blog/sales-outreach-management/',
    'https://quickmail.io/outreach-strategy-examples',
  ],
  [
    'https://quickmail.io/outreach-software',
    'https://salesblink.io/blog/best-outreach-tools-for-sales',
    'https://woodpecker.co/blog/find-crm/',
    'https://hunter.io/blog/cold-email-tools/',
    'https://www.smartlead.ai/blog/cold-email-tools',
  ],
  [
    'https://salesblink.io/blog/sales-territory-management-plan',
    'https://www.yesware.com/blog/sales-territory-management/',
  ],
  [
    'https://www.yesware.com/blog/inbound-selling/',
    'https://salesblink.io/blog/inbound-sales',
    'https://woodpecker.co/blog/difference-inbound-outbound/',
    'https://www.yesware.com/blog/inbound-vs-outbound-sales/',
    'https://www.saleshandy.com/blog/inbound-outbound-sales/',
  ],
  [
    'https://quickmail.io/bulk-email-service',
    'https://salesblink.io/blog/send-emails-in-bulk',
    'https://www.smartlead.ai/blog/bulk-email-marketing',
    'https://www.smartlead.ai/blog/how-to-send-bulk-email',
    'https://mailmeteor.com/blog/best-email-marketing-services',
  ],
  [
    'https://quickmail.io/cold-email/deliverability/deliverability-tools',
    'https://www.saleshandy.com/blog/email-deliverability/',
  ],
  [
    'https://www.yesware.com/blog/frontline-sales/',
    'https://www.yesware.com/blog/frontline-sales/',
  ],
  [
    'https://blog.klenty.com/sales-enablement-content/',
    'https://salesblink.io/blog/sales-enablement-content',
  ],
  ['https://salesblink.io/blog/sales-onboarding', 'https://www.yesware.com/blog/sales-onboarding/'],
  [
    'https://www.yesware.com/blog/email-reminders/',
    'https://www.yesware.com/blog/email-reminders/',
  ],
  [
    'https://salesblink.io/blog/the-greatest-salesman-in-the-world-book-summary',
    'https://salesblink.io/blog/the-greatest-salesman-in-the-world-book-summary',
  ],
  [
    'https://salesblink.io/blog/upselling-and-cross-selling',
    'https://www.yesware.com/blog/upselling-cross-selling/',
    'https://blog.klenty.com/upselling-techniques/',
  ],
  [
    'https://blog.klenty.com/cold-calling-tools/',
    'https://quickmail.io/cold-calling-software',
    'https://blog.klenty.com/cold-calling/',
    'https://www.saleshandy.com/blog/how-to-cold-call/',
  ],
  [
    'https://salesblink.io/blog/best-sales-closing-techniques',
    'https://www.saleshandy.com/blog/sales-closing-techniques/',
  ],
  [
    'https://woodpecker.co/blog/how-to-write-a-cold-email-that-actually-works-six-step-tutorial/',
    'https://woodpecker.co/blog/how-to-write-a-cold-email-that-actually-works-six-step-tutorial/',
    'https://www.saleshandy.com/blog/how-to-start-an-email/',
  ],
  [
    'https://www.saleshandy.com/blog/cold-email-vs-email-marketing/',
    'https://quickmail.io/sales-prospecting-emails',
  ],
  [
    'https://woodpecker.co/blog/cold-email-course-and-other-resources/',
    'https://www.saleshandy.com/blog/perfect-cold-email-structure/',
    'https://blog.klenty.com/cold-email-structure/',
  ],
  [
    'https://quickmail.io/email-permutator',
    'https://www.saleshandy.com/blog/find-prospects-email-address/',
    'https://woodpecker.co/blog/how-we-found-our-prospects-email-addresses/',
  ],
  [
    'https://quickmail.io/17-best-linkedin-automation-tools-for-easy-outreach',
    'https://woodpecker.co/blog/linkedin-outreach/',
    'https://woodpecker.co/blog/linkedin-automation-tools/',
  ],
  [
    'https://salesblink.io/blog/tools-to-schedule-meetings',
    'https://blog.klenty.com/meeting-scheduler-tools/',
  ],
  [
    'https://www.saleshandy.com/blog/sales-acceleration-software/',
    'https://www.yesware.com/blog/sales-acceleration/',
  ],
  ['https://www.yesware.com/blog/sales-velocity/', 'https://blog.klenty.com/sales-velocity/'],
  [
    'https://blog.klenty.com/what-is-account-based-selling/',
    'https://woodpecker.co/blog/account-based-sales/',
  ],
  [
    'https://salesblink.io/blog/lead-generation-software',
    'https://www.saleshandy.com/blog/lead-generation-software/',
    'https://quickmail.io/18-essential-lead-generation-tools-to-win-at-outreach',
  ],
  [
    'https://salesblink.io/blog/the-challenger-sale-book-summary',
    'https://blog.klenty.com/challenger-sales-model/',
    'https://www.yesware.com/blog/the-challenger-sale/',
  ],
  [
    'https://www.yesware.com/blog/how-to-increase-email-engagement/',
    'https://woodpecker.co/blog/email-engagement-rate/',
  ],
  [
    'https://blog.klenty.com/6-negotiation-tactics-for-salespeople-from-chris-voss/',
    'https://blog.klenty.com/6-negotiation-tactics-for-salespeople-from-chris-voss/',
    'https://salesblink.io/blog/never-split-the-difference-book-summary',
  ],
  [
    'https://salesblink.io/blog/predictable-revenue-book-summary',
    'https://blog.klenty.com/predictable-revenue-book-summary/',
  ],
  [
    'https://www.yesware.com/blog/sales-cycle/',
    'https://salesblink.io/blog/sales-cycle',
    'https://www.saleshandy.com/blog/sales-cycle/',
    'https://blog.klenty.com/sales-cycle/',
  ],
  [
    'https://www.yesware.com/blog/outbound-sales/',
    'https://blog.klenty.com/outbound-sales/',
    'https://hunter.io/blog/outbound-sales/',
  ],
  [
    'https://www.yesware.com/blog/sales-definition/',
    'https://www.saleshandy.com/blog/sales-engagement/',
    'https://salesblink.io/blog/top-sales-engagement-tips',
  ],
  [
    'https://quickmail.io/business-introduction-email-sample',
    'https://www.yesware.com/blog/introduction-email/',
    'https://woodpecker.co/blog/how-should-i-start-my-cold-email/',
  ],
  [
    'https://www.yesware.com/blog/discovery-call/',
    'https://blog.klenty.com/discovery-call/',
    'https://quickmail.io/discovery-questions',
  ],
  ['https://www.yesware.com/blog/email-analyzer/', 'https://www.yesware.com/blog/email-analyzer/'],
  [
    'https://salesblink.io/blog/cold-outreach',
    'https://www.yesware.com/blog/email-outreach/',
    'https://salesblink.io/blog/email-outreach-practices',
  ],
  [
    'https://blog.klenty.com/meddic-sales-methodology/',
    'https://www.yesware.com/blog/meddic-sales-process/',
  ],
  [
    'https://woodpecker.co/blog/where-to-find-prospects/',
    'https://hunter.io/blog/b2b-lead-generation-strategies/',
    'https://www.yesware.com/blog/b2b-lead-generation/',
    'https://salesblink.io/blog/b2b-lead-generation-strategies',
    'https://quickmail.io/b2b-lead-generation-strategies',
  ],
  [
    'https://quickmail.io/cold-email/prospecting',
    'https://woodpecker.co/blog/b2b-sales-prospecting-techniques/',
    'https://blog.klenty.com/sales-prospecting/',
  ],
  [
    'https://www.yesware.com/blog/email-greetings/',
    'https://hunter.io/blog/how-to-start-an-email/',
  ],
  [
    'https://quickmail.io/ai-email-assistants',
    'https://woodpecker.co/blog/email-writing-service/',
    'https://woodpecker.co/blog/ai-email-writing-assistants-part-one/',
    'https://www.saleshandy.com/blog/ai-email-writing-tools/',
    'https://woodpecker.co/blog/ai-email-writing-assistants-part-two/',
  ],
  [
    'https://woodpecker.co/blog/subject-line-example/',
    'https://hunter.io/blog/cold-email-subject-line/',
    'https://www.yesware.com/blog/follow-up-email-subject-line/',
    'https://www.yesware.com/blog/email-subject-lines/',
    'https://blog.klenty.com/cold-email-subject-lines-sales/',
    'https://hunter.io/blog/follow-up-email-subject-line/',
    'https://woodpecker.co/blog/what-subject-line-will-make-my-addresses-open-my-cold-email/',
  ],
  [
    'https://www.yesware.com/blog/open-ended-sales-questions/',
    'https://woodpecker.co/blog/a-guide-to-asking-open-ended-questions-in-sales-emails/',
    'https://salesblink.io/blog/improve-sales-email',
    'https://salesblink.io/blog/prodomain_7-sales-questions',
  ],
  [
    'https://quickmail.io/cold-email/warm-up/email-warm-up-tools',
    'https://blog.klenty.com/email-warm-up-tools/',
    'https://woodpecker.co/blog/domain-reputation/',
    'https://www.saleshandy.com/blog/warmup-email-account/',
    'https://woodpecker.co/blog/cold-email-warm-up-tools/',
    'https://www.smartlead.ai/blog/email-warm-up',
    'https://salesblink.io/blog/email-warmup',
    'https://woodpecker.co/blog/warm-up-mailbox/',
  ],
  [
    'https://quickmail.io/how-to-generate-leads',
    'https://www.saleshandy.com/blog/lead-generation-software-development-companies/',
  ],
  [
    'https://hunter.io/blog/how-to-know-if-someone-read-your-email/',
    'https://www.yesware.com/blog/how-to-tell-email-read/',
    'https://woodpecker.co/blog/links-in-cold-email/',
    'https://www.saleshandy.com/blog/know-someone-opened-your-email/',
    'https://blog.klenty.com/how-to-track-links-in-cold-emails/',
    'https://quickmail.io/click-tracking',
  ],
  ['https://www.saleshandy.com/blog/linkedin-cold-messages/'],
  [
    'https://www.saleshandy.com/blog/linkedin-inmail-vs-email/',
    'https://hunter.io/blog/domain_9-inmail/',
    'https://www.yesware.com/blog/linkedin-inmail/',
    'https://quickmail.io/linkedin-prospecting-messages',
  ],
  [
    'https://www.yesware.com/blog/inside-sales-vs-outside-sales/',
    'https://blog.klenty.com/inside-sales-vs-outside-sales/',
  ],
  [
    'https://hunter.io/blog/link-building-outreach/',
    'https://www.saleshandy.com/blog/link-building-email-outreach/',
  ],
  [
    'https://www.yesware.com/blog/using-linkedin-for-sales/',
    'https://www.yesware.com/blog/using-linkedin-for-sales/',
  ],
  [
    'https://salesblink.io/blog/sales-skills',
    'https://www.yesware.com/blog/sales-representatives-skills/',
  ],
  [
    'https://quickmail.io/email-spam-checker',
    'https://hunter.io/blog/how-to-prevent-emails-from-going-to-spam-6-tips/',
    'https://woodpecker.co/blog/how-to-avoid-spam-folder/',
    'https://www.saleshandy.com/blog/spam-email/?noamp=mobile',
    'https://blog.klenty.com/avoid-email-spam-filters/',
    'https://quickmail.io/avoid-spam-filters',
    'https://www.yesware.com/blog/emails-are-going-to-spam/',
  ],
  [
    'https://woodpecker.co/blog/what-are-spam-traps-how-to-avoid-them/',
    'https://hunter.io/blog/spam-traps/',
  ],
  ['https://blog.klenty.com/account-executive/', 'https://www.yesware.com/blog/account-executive/'],
  [
    'https://quickmail.io/cold-email/domain',
    'https://woodpecker.co/blog/how-to-set-up-a-domain-and-mailbox-for-cold-outreach/',
    'https://woodpecker.co/blog/free-email-or-custom-domain-email-which-one-is-better-for-cold-emailing/',
    'https://quickmail.io/cold-email/tips',
    'https://woodpecker.co/blog/best-cold-email-tools-software/',
    'https://www.saleshandy.com/blog/email-outreach-tools/',
  ],
  [
    'https://www.yesware.com/blog/start-email/',
    'https://hunter.io/blog/email-subject-lines-for-sales/',
    'https://salesblink.io/blog/catchy-email-subject-lines-for-sales',
    'https://blog.klenty.com/email-subject-line-tester-tools/',
    'https://www.yesware.com/blog/cold-email-subject-lines/',
    'https://quickmail.io/cold-email/subject-lines',
    'https://blog.klenty.com/subject-lines-personalization-statistics/',
  ],
  [
    'https://quickmail.io/cold-email/statistics',
    'https://blog.klenty.com/how-to-improve-cold-email-open-rates/',
    'https://blog.klenty.com/cold-email-statistics/',
    'https://www.saleshandy.com/blog/open-rates/',
    'https://www.smartlead.ai/blog/cold-outreach-the-ultimate-guide',
    'https://blog.klenty.com/cold-calling-statistics/',
    'https://hunter.io/blog/cold-email-statistics/',
    'https://www.smartlead.ai/blog/use-chatgpt-in-cold-email-strategy',
    'https://quickmail.io/cold-email/cold-email-vs-email-marketing/tools',
  ],
  ['https://salesblink.io/blog/sales-process', 'https://hunter.io/blog/sales-process/'],
  [
    'https://www.yesware.com/blog/best-time-to-send-email/',
    'https://hunter.io/blog/best-time-to-send-an-email/',
    'https://salesblink.io/blog/best-time-to-send-an-email',
    'https://quickmail.io/cold-email-hubspot',
    'https://blog.klenty.com/whats-the-best-time-to-send-cold-emails/',
    'https://woodpecker.co/blog/optimal-time-for-cold-email-delivery-vol-1-stats/',
    'https://www.saleshandy.com/blog/best-time-to-send-emails/',
  ],
  [
    'https://woodpecker.co/blog/best-email-provider/',
    'https://mailmeteor.com/blog/best-email-warmup-tools',
    'https://www.saleshandy.com/blog/email-warm-up-tools/',
    'https://salesblink.io/blog/best-email-warmup-tools',
    'https://hunter.io/blog/email-warm-up/',
    'https://www.saleshandy.com/blog/warmup-email-account/',
    'https://salesblink.io/blog/email-warmup',
    'https://quickmail.io/cold-email/warm-up',
  ],
  [
    'https://www.yesware.com/blog/linkedin-summary-examples/',
    'https://www.yesware.com/blog/linkedin-summary-examples-to-master-your-first-impression/',
  ],
  [
    'https://quickmail.io/sales-engagement-questions',
    'https://www.yesware.com/blog/cold-calling-tips/',
    'https://salesblink.io/blog/best-cold-call-opening-lines',
    'https://salesblink.io/blog/cold-calling-techniques',
    'https://blog.klenty.com/cold-calling-tips-and-techniques/',
    'https://quickmail.io/cold-email/opening-lines',
    'https://blog.klenty.com/best-cold-call-opening-lines/',
  ],
  [
    'https://woodpecker.co/blog/what-is-bimi-email-deliverability/',
    'https://www.saleshandy.com/blog/what-is-bimi/',
  ],
  [
    'https://www.yesware.com/blog/sales-relationship-building/',
    'https://salesblink.io/blog/build-client-relationships',
  ],
  [
    'https://www.yesware.com/blog/decision-maker/',
    'https://www.saleshandy.com/blog/find-decision-makers/',
  ],
  [
    'https://www.yesware.com/blog/sales-cadence/',
    'https://woodpecker.co/blog/how-many-touches-multichannel-sales-cadence/',
    'https://hunter.io/blog/sales-cadence/',
    'https://salesblink.io/blog/sales-cadence',
    'https://blog.klenty.com/sales-cadence/',
    'https://woodpecker.co/blog/high-converting-sales-cadence/',
    'https://hunter.io/blog/cold-email-sequence/',
  ],
  ['https://blog.klenty.com/cold-email-to-ceo/', 'https://hunter.io/blog/emailing-a-ceo/'],
  [
    'https://quickmail.io/sender-reputation',
    'https://woodpecker.co/blog/reputation-domain-ip/',
    'https://woodpecker.co/blog/a-step-by-step-guide-to-regain-a-good-domain-reputation/',
  ],
  [
    'https://salesblink.io/blog/tips-to-improve-email-click-through-rate',
    'https://blog.klenty.com/cold-email-click-through-rate/',
  ],
  [
    'https://www.yesware.com/blog/how-to-close-a-sale/',
    'https://blog.klenty.com/sales-closing-techniques/',
  ],
  [
    'https://woodpecker.co/blog/embed-image-video/',
    'https://blog.klenty.com/klenty-image-personalization/',
    'https://www.yesware.com/blog/using-video-in-sales-emails/',
  ],
  ['https://www.saleshandy.com/blog/email-marketing-terms/'],
  [
    'https://quickmail.io/email-drip-campaign',
    'https://www.yesware.com/blog/drip-campaign-examples/',
  ],
  [
    'https://quickmail.io/cold-email/deliverability',
    'https://www.smartlead.ai/blog/email-deliverability',
    'https://www.saleshandy.com/blog/improve-email-deliverability/',
    'https://hunter.io/blog/email-deliverability/',
    'https://help.quickmail.io/article/344-testing-deliverability-with-mail-tester',
    'https://woodpecker.co/blog/improve-email-deliverability/',
    'https://www.saleshandy.com/blog/email-deliverability-tools/',
    'https://woodpecker.co/blog/deliverability-checks/',
    'https://salesblink.io/blog/cold-email-deliverability-guide',
  ],
  [
    'https://woodpecker.co/blog/what-is-a-cold-email-sales-funnel-and-how-to-create-it/',
    'https://www.smartlead.ai/blog/email-marketing-funnel',
  ],
  ['https://quickmail.io/cold-email/metrics', 'https://salesblink.io/blog/email-metrics'],
  [
    'https://quickmail.io/complete-guide-to-creating-email-nurturing-sequences',
    'https://www.smartlead.ai/blog/how-to-create-an-email-nurture-campaign-that-converts',
  ],
  [
    'https://woodpecker.co/blog/cold-email-opt-out/',
    'https://blog.klenty.com/unsubscribe-link-in-cold-emails/',
  ],
  [
    'https://quickmail.io/linkedin-export-tools',
    'https://woodpecker.co/blog/linkedin-contact-export-tools/',
  ],
  ['https://salesblink.io/blog/outreach-marketing', 'https://quickmail.io/outreach-marketing'],
  [
    'https://www.yesware.com/blog/sales-forecast/',
    'https://blog.klenty.com/sales-forecasting-methods/',
    'https://salesblink.io/blog/sales-forecasting-methods',
    'https://woodpecker.co/blog/sales-forecasting-methods/',
  ],
  [
    'https://quickmail.io/lemlist-alternative',
    'https://www.saleshandy.com/blog/lemlist-alternative/',
    'https://salesblink.io/blog/lemlist-vs-salesblink',
    'https://mailmeteor.com/blog/lemlist-pricing',
  ],
  [
    'https://www.saleshandy.com/blog/follow-up-email-subject-line/',
    'https://salesblink.io/blog/introduction-email-subject-line',
    'https://salesblink.io/blog/breakup-email-subject-lines',
    'https://blog.klenty.com/breakup-email/',
    'https://www.yesware.com/blog/best-email-subject-lines/',
    'https://blog.klenty.com/cold-email-subject-line-statistics/',
  ],
  [
    'https://quickmail.io/mailchimp-for-cold-emails',
    'https://woodpecker.co/blog/woodpecker-vs-mailchimp/',
  ],
  [
    'https://www.yesware.com/blog/sales-interview-questions-and-answers/',
    'https://blog.klenty.com/sales-interview-questions/',
  ],
  [
    'https://quickmail.io/mail-merge-software',
    'https://www.saleshandy.com/blog/mail-merge-software/',
    'https://quickmail.io/follow-up-software',
  ],
  ['https://quickmail.io/mixmax-alternative', 'https://www.yesware.com/blog/mixmax-alternatives/'],
  [
    'https://www.smartlead.ai/blog/networking-emails',
    'https://hunter.io/blog/networking-email/',
    'https://woodpecker.co/blog/networking-email/',
  ],
  [
    'https://www.yesware.com/blog/reply-alternatives/',
    'https://salesblink.io/blog/reply-io-vs-salesblink',
    'https://quickmail.io/hubspot-sales-integrations',
  ],
  [
    'https://quickmail.io/saas-sales-process-steps',
    'https://salesblink.io/blog/saas-sales-beginners-guide',
    'https://www.yesware.com/blog/saas-sales/',
  ],
  [
    'https://www.yesware.com/blog/sales-compensation-plans/',
    'https://salesblink.io/blog/sales-compensation-plan',
  ],
  [
    'https://www.yesware.com/blog/salesforce-sales-process/',
    'https://blog.klenty.com/increase-salesforce-adoption/',
  ],
  [
    'https://woodpecker.co/blog/outbound-prospect-list/',
    'https://www.smartlead.ai/blog/prospecting-list',
  ],
  [
    'https://www.yesware.com/blog/salesforce-tasks/',
    'https://www.yesware.com/blog/salesforce-tasks/',
  ],
  [
    'https://hunter.io/blog/sales-email/',
    'https://salesblink.io/blog/introductory-email-for-sales',
  ],
  ['https://www.saleshandy.com/blog/how-send-cold-emails/'],
  [
    'https://www.yesware.com/blog/spin-selling/',
    'https://www.saleshandy.com/blog/spin-selling/',
    'https://www.smartlead.ai/blog/spin-selling',
  ],
  ['https://woodpecker.co/blog/voila-norbert/', 'https://hunter.io/blog/email-finder-tools/'],
  ['https://www.yesware.com/blog/inside-sales/', 'https://www.saleshandy.com/blog/inside-sales/'],
  [
    'https://quickmail.io/sales-pipeline',
    'https://blog.klenty.com/sales-pipeline/',
    'https://salesblink.io/blog/how-to-build-a-sales-pipeline',
  ],
  [
    'https://help.quickmail.io/article/350-howtoaddzoho',
    'https://www.smartlead.ai/blog/zoho-imap-settings',
  ],
  [
    'https://woodpecker.co/blog/outbound-prospect-base/',
    'https://quickmail.io/outbound-prospecting',
    'https://www.yesware.com/blog/sales-prospecting/',
  ],
  [
    'https://quickmail.io/email-blacklist',
    'https://www.saleshandy.com/blog/email-blacklist/',
    'https://salesblink.io/blog/email-blacklist',
    'https://woodpecker.co/blog/avoid-email-blacklist/',
  ],
  [
    'https://quickmail.io/cold-email/cta',
    'https://woodpecker.co/blog/perfect-cta-or-how-to-end-up-my-cold-email/',
    'https://blog.klenty.com/cold-email-call-to-action/',
  ],
  [
    'https://www.smartlead.ai/blog/domain_9-prospecting-messages',
    'https://blog.klenty.com/domain_9-prospecting-tools/',
    'https://quickmail.io/linkedin-prospecting',
    'https://www.saleshandy.com/blog/linkedin-prospecting/',
    'https://hunter.io/blog/domain_9-prospecting/',
  ],
  [
    'https://quickmail.io/cold-email/copywriting',
    'https://woodpecker.co/blog/tips-to-write-a-cold-sales-email-from-a-copywriter/',
    'https://salesblink.io/blog/email-copywriting',
    'https://www.yesware.com/blog/email-copywriting/',
    'https://hunter.io/blog/email-copywriting/',
  ],
  [
    'https://www.saleshandy.com/blog/must-sales-blogs/',
    'https://blog.klenty.com/best-sales-blogs/',
  ],
  [
    'https://quickmail.io/yesware-alternative',
    'https://www.saleshandy.com/blog/yesware-alternative/',
    'https://www.smartlead.ai/blog/yesware-alternatives',
  ],
  [
    'https://blog.klenty.com/sales-engagement-platform-tools/',
    'https://quickmail.io/sales-engagement-platforms',
    'https://salesblink.io/blog/sales-engagement-platform',
    'https://www.yesware.com/blog/sales-engagement-platform/',
    'https://www.saleshandy.com/blog/sales-engagement-platforms/',
  ],
  ['https://woodpecker.co/blog/safely-scale-up-cold-email-outreach/'],
  [
    'https://www.yesware.com/blog/email-format/',
    'https://hunter.io/blog/how-to-format-an-email/',
    'https://www.yesware.com/blog/formal-email/',
  ],
  [
    'https://woodpecker.co/blog/what-should-you-do-before-hiring-a-b2b-lead-generation-agency/',
    'https://www.saleshandy.com/blog/cold-email-lead-generation/',
  ],
  [
    'https://www.yesware.com/blog/sales-prospecting-tools/',
    'https://woodpecker.co/blog/sales-prospecting-tools/',
    'https://quickmail.io/sales-prospecting-tools',
    'https://blog.klenty.com/sales-prospecting-tools/',
    'https://quickmail.io/cold-email/prospecting/prospecting-tools',
  ],
]
