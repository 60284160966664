import React, { FC } from 'react'
import { Button, Typography, Card } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import Logo from '../../../components/Logo/Logo'
import { Timeline } from 'antd'

const { Title } = Typography

interface SiderProps {
  onBookDemo: () => void
  step: number
  setStep: (step: number) => void
}

export const Sider: FC<SiderProps> = ({ onBookDemo, step, setStep }) => {
  const getStepIcon = (number: number) => {
    const isCompleted = step > number
    return isCompleted ? (
      <CheckCircleFilled style={{ color: '#40a9ff', fontSize: 19, marginTop: 2, opacity: '0.9' }} />
    ) : (
      <div
        style={{
          backgroundColor: 'rgb(232, 232, 232)',
          borderRadius: 19,
          height: 19,
          width: 20,
        }}
      >
        <div
          style={{
            color: '#262626',
            lineHeight: '18px',
            fontWeight: 600,
          }}
        >
          {number + 1}
        </div>
      </div>
    )
  }

  const timelineItems = [
    {
      dot: getStepIcon(-1),
      children: <Title level={5}>Sign Up</Title>,
    },
    {
      dot: getStepIcon(0),
      children: <Title level={5}>Introduce Yourself</Title>,
    },
    {
      dot: getStepIcon(1),
      children: <Title level={5}>Get a Demo</Title>,
    },
    {
      dot: getStepIcon(2),
      children: <Title level={5}>Get Started</Title>,
    },
    // {
    //   dot: getStepIcon(3),
    //   children: <Title level={5}>Complete First Crawl</Title>,
    // },
  ]

  return (
    <Card
      style={{ textAlign: 'left' }}
      title={
        <div style={{ display: 'flex' }}>
          <Logo style={{ marginRight: 10 }} height={20} />
          <Title level={5} style={{ marginBottom: 0 }}>
            Welcome to Mailarrow
          </Title>
        </div>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Timeline items={timelineItems} />
      </div>
      <div style={{ padding: '0 0' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 12.5 }}>Got questions?</div>
          <Button size="small" type="default" onClick={onBookDemo}>
            Book a demo
          </Button>
        </div>
      </div>
    </Card>
  )
}
