import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import logger from '../../../services/errorlogger'

interface CustomProperty {
  name: string
  slug: string
  default?: string
}

const addCustomProperty = async (property: CustomProperty) => {
  await fetch.put(`/custom-properties/`, { property })
}

export const useAddCustomProperty = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (property: CustomProperty) => addCustomProperty(property),
    {
      onSuccess: () => {
        message.success(`Custom variable has been created`)
        queryClient.invalidateQueries(['properties'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log('Error in useAddProperty')
        console.log(error)
        logger.logError(error)
      },
    }
  )

  return {
    addProperty: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const removeCustomProperty = async (slug: string) => {
  if (!slug) return

  return await fetch.delete(`/custom-properties/${slug}/`)
}

export const useRemoveCustomProperty = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (slug: string) => removeCustomProperty(slug),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['property'])
        queryClient.refetchQueries(['property'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    removeCustomProperty: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
