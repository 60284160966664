import React, { useState } from 'react'

import { Spin, message } from 'antd'

import DatePanel from './components/DatePanel'
import { useFetchSchedule } from './api/schedulesQueries'
import { useEditSchedule } from './api/schedulesMutations'
import { Campaign } from '@mailarrow/types'

type Props = {
  campaign: Campaign
}

const SchedulePickerContainer: React.FC<Props> = ({ campaign }) => {
  const { scheduleId } = campaign.sendConfig
  const scheduleQuery = useFetchSchedule(scheduleId)
  const editSchedule = useEditSchedule(scheduleId)

  if (!scheduleId) {
    message.error('This campaign does not have any schedules yet')
    return <></>
  }

  const onSave = (scheduleProps: any) => {
    editSchedule.editSchedule({ scheduleId, scheduleProps })
  }

  if (scheduleQuery.isLoading) return <Spin />

  return (
    <DatePanel
      schedule={scheduleQuery.schedule}
      onSave={onSave}
      loading={editSchedule.isLoading}
      error={editSchedule.isError}
    />
  )
}

export default SchedulePickerContainer
