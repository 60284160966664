import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, Spin, message } from 'antd'

import SequenceBuilder from './components/SequenceBuilder'
import { useParams } from 'react-router-dom'
import { useFetchSequence } from './api/sequencesQueries'
import { useEditSequence } from './api/sequencesMutations'
import { useFetchMailboxes } from '../Mailboxes'
import { useFetchCampaigns } from '../Campaigns/api/campaignQueries'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { createEmailSequenceStep } from './util/create-email'
import {
  DelayMetric,
  SequenceStep,
  SequenceStepVariation,
  isEmailSequenceStep,
} from '@mailarrow/types'
import { isEqual } from 'lodash'

interface Props {}

const SequenceBuilderContainer: React.FC<Props> = () => {
  const [modal, contextHolder] = Modal.useModal()
  const [steps, setSteps] = useState<SequenceStep[]>([])
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const [activeStepVariationIndex, setactiveStepVariationIndex] = useState<number>(0)

  const { campaignId } = useParams<{ campaignId: string }>()
  const { campaigns, isLoading: isCampaingsLoading } = useFetchCampaigns()
  const campaign = campaigns?.find((c: any) => c.campaignId === campaignId)

  useFetchMailboxes()

  const {
    sequence,
    isLoading: isSequenceLoading,
    isSuccess: isSequenceSuccess,
  } = useFetchSequence(campaign && campaign.sequenceId)
  const {
    editSequence,
    isLoading: editSequenceLoading,
    isError: editSequenceError,
    isSuccess: editSequenceDone,
  } = useEditSequence()

  React.useEffect(() => {
    if (isCampaingsLoading || isSequenceLoading || !isSequenceSuccess) return
    if (sequence) {
      if (sequence.steps.length) {
        setSteps(sequence.steps)
      } else {
        setSteps([createEmailSequenceStep()])
      }
    }
  }, [campaign, isCampaingsLoading, isSequenceLoading, isSequenceSuccess, sequence])

  const onEditStepDelay = (stepIndex: number, delayMetric: DelayMetric, delayLength: number) => {
    const nextSequence = steps.map((s, i) => {
      if (s.stepIndex !== stepIndex) return s
      return { ...s, ...(delayMetric && { delayMetric }), ...(delayLength && { delayLength }) }
    })
    setSteps(nextSequence)
    onSaveSteps(nextSequence)
  }

  const onAddStep = () => {
    // ensure that last step always has 0 delayLength
    // this is done to make sure that backend knows that this is the last step
    const correctedDelaySteps = steps.map((step, index, array) => {
      if (index + 1 !== array.length) return step
      return {
        ...step,
        delayLength: 1,
        delayMetric: 'days' as const,
      }
    })

    const nextSequence = correctedDelaySteps.concat(
      createEmailSequenceStep({
        stepIndex: steps.length,
        subject: `Re: ${steps[0].variations[0].subject}`,
        body: '',
      })
    )
    setSteps(nextSequence)
    onSaveSteps(nextSequence)
  }

  const onRemoveStep = (stepIndex: number) => {
    function removeRe(text: string): string {
      return text.replace(/^Re: /, '')
    }

    const nextSteps = steps
      .filter((s, i) => i !== stepIndex)
      .map((s, i) => {
        if (i === 0) {
          const variations = s.variations.map((v: any) => ({
            ...v,
            subject: removeRe(v.subject),
          }))
          return { ...s, stepIndex: i, variations }
        }
        return { ...s, stepIndex: i }
      })

    setSteps(nextSteps)
    onSaveSteps(nextSteps)
    if (stepIndex === activeStepIndex) {
      setActiveStepIndex(0)
    }
  }

  const hasUnsavedChanges = (stepIndex: number) => {
    if (!sequence) {
      // this is a very defensive failsafe which ignores the function
      return false
    }
    const currentStep = steps.find((step) => step.stepIndex === stepIndex)
    if (!currentStep) {
      // this is a very defensive failsafe which ignores the function
      return false
    }

    const savedStep = sequence?.steps.find((step) => step.stepIndex === stepIndex)

    if (savedStep) {
      const areStepsEqual = isEqual(savedStep.variations, currentStep.variations)

      if (areStepsEqual) {
        // when there are no changes, navigate freely
        return false
      }
      // show warning
      return true
    }

    // here we check if the step has any meaningful changes
    const exampleStep = createEmailSequenceStep()
    const stripVariationId = (variations: SequenceStepVariation[]) =>
      variations.map(({ stepVariationId, subject, ...rest }) => rest)

    const areStepsEqual = isEqual(
      stripVariationId(currentStep.variations),
      stripVariationId(exampleStep.variations)
    )

    if (areStepsEqual) {
      // if this is a new step and there are no changes, navigate freely
      return false
    }
    // show warning
    return true
  }

  const resetStepChanges = (stepIndex: number) => {
    if (!sequence) {
      // this is a very defensive failsafe which ignores the function
      return false
    }
    const currentStep = steps.find((step) => step.stepIndex === stepIndex)
    if (!currentStep) {
      // this is a very defensive failsafe which ignores the function
      return false
    }

    if (sequence.steps) {
      // we reset all initial steps because we assume that all
      // changes made up til now have been saved
      setSteps(sequence.steps)
    }
  }

  const onChangeActiveStep = (stepIndex: number) => {
    if (hasUnsavedChanges(activeStepIndex)) {
      return modal.confirm({
        title: 'You have unsaved changes',
        style: { width: 500 },
        icon: <ExclamationCircleOutlined />,
        content: <>All unsaved changes will be lost</>,
        okText: 'Confirm',
        onOk: () => {
          resetStepChanges(activeStepIndex)
          setActiveStepIndex(stepIndex)
          setactiveStepVariationIndex(0)
        },
        cancelText: 'Cancel',
      })
    }

    setActiveStepIndex(stepIndex)
    setactiveStepVariationIndex(0)
  }

  const onSaveSteps = (nextSteps: SequenceStep[]) => {
    if (!campaign) return null

    // ensure that last step always has 0 delayLength
    // this is done to make sure that backend knows that this is the last step
    const correctedDelaySteps = nextSteps.map((step, index, array) => {
      if (index + 1 !== array.length) return step
      return {
        ...step,
        delayLength: 0,
      }
    })

    editSequence({
      sequenceId: campaign.sequenceId,
      sequenceProps: {
        campaignId,
        steps: correctedDelaySteps,
      },
    })
  }

  if (!campaignId) {
    // TODO: redirect
    return null
  }

  if (!steps) return <Spin />

  return (
    <>
      <SequenceBuilder
        onSetSteps={(steps) => setSteps(steps)}
        onEditStepDelay={onEditStepDelay}
        editSequenceLoading={editSequenceLoading}
        // todo: optimize hasUnsavedChanges with memo
        isCurrentStepUnsaved={hasUnsavedChanges(activeStepIndex)}
        activeStepIndex={activeStepIndex}
        onChangeActiveStep={onChangeActiveStep}
        activeStepVariationIndex={activeStepVariationIndex}
        onRemoveStep={onRemoveStep}
        steps={steps}
        onAddStep={onAddStep}
        onSave={() => onSaveSteps(steps)}
      />
      {contextHolder}
    </>
  )
}

export default SequenceBuilderContainer
