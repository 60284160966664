import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'
import { usePermissions } from '../User/Permissions'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import useAuthState from '../Auth/use-auth-state'
import history from '../../router/history'

const MonitorTabs: React.FC = () => {
  const navigate = useNavigate()

  const tabs = [
    { path: 'userlist', label: 'User List' },
    { path: 'usermanager', label: 'User Manager' },
    { path: 'journeys', label: 'Journeys' },
    { path: 'emails', label: 'Emails' },
  ]

  const getActiveTopLevelRoutePath = () => {
    const currentPath = window.location.pathname
    const matchingTab = tabs.find((tab) => currentPath.startsWith(`/app/monitor/${tab.path}`))
    return matchingTab ? matchingTab.path : ''
  }

  const activeRoutePath = getActiveTopLevelRoutePath()

  const handleTabChange = (key: string) => {
    navigate(`/app/monitor/${key}`)
  }

  const tabItems: TabsProps['items'] = tabs.map((tab) => ({
    key: tab.path,
    label: tab.label,
  }))

  return (
    <Tabs
      defaultActiveKey={activeRoutePath}
      activeKey={activeRoutePath}
      onChange={handleTabChange}
      items={tabItems}
    />
  )
}

const Monitor: React.FC = () => {
  useEffect(() => {
    document.title = 'Monitor | Mailarrow App'
  }, [])

  const permissions = usePermissions()
  const { user } = useSelector((state: RootState) => state.user)
  const { isLoggedIn } = useAuthState()

  if (
    !permissions.admin ||
    !isLoggedIn ||
    !(
      user?.email.includes('luga') ||
      user?.email.includes('razze') ||
      user?.email.includes('shlyko')
    )
  ) {
    history.push('/app/dashboard')
    return null
  }

  return (
    <div>
      <div>
        <MonitorTabs />
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Monitor
