import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'
import { Prospect } from '@mailarrow/types'

import { buildFilterQuery, FetchProspectsProps, FilterSettings } from './build-filter-query'
import { PaginationSettings } from '../../../types/common'

export const fetchProspects = async (filter: FetchProspectsProps) => {
  try {
    const params = buildFilterQuery(filter)
    const response = await fetch.get(`/prospects/?${params}`)
    const { docs, totalDocs, page: currentPage } = response.data

    return {
      prospects: docs as Prospect[],
      total: totalDocs as number,
      page: currentPage,
    }
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchProspects = (filter: FilterSettings, pagination?: PaginationSettings) => {
  const { isLoading, isError, error, data, isFetching, isPreviousData, isSuccess } = useQuery(
    [
      'prospects',
      filter.campaignId,
      filter.excludedCampaignIds,
      pagination?.page,
      pagination?.pageSize,
      filter.search,
      filter.isUnsubscribed,
    ],
    () => fetchProspects({ ...pagination, ...filter }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    prospects: data?.prospects,
    total: data?.total,
    isLoading,
    isFetching,
    isPreviousData,
    isError,
    isSuccess,
    error,
  }
}

export const fetchProspect = async (prospectId?: string) => {
  try {
    if (!prospectId) return undefined
    const response = await fetch.get(`/prospects/${prospectId}`)
    return response.data as Prospect
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchProspect = (prospectId?: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['prospect', prospectId],
    () => fetchProspect(prospectId),
    {
      enabled: !!prospectId,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    prospect: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
