import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import type { RootState } from '../../store/store'

import { LoginState } from './reducer'

export const useAuthState = () => {
  const authState = useSelector((state: RootState) => state.auth, shallowEqual)
  const UserState = useSelector((state: RootState) => state.user, shallowEqual)

  const isLoggedOut = authState.loginState === LoginState.LoggedOut
  const isLoggingIn = authState.loginState === LoginState.LoggedIn && UserState.fetchUserDataLoading
  const isLoggedIn = authState.loginState === LoginState.LoggedIn && UserState.fetchUserDataSuccess
  const isLoading = UserState.fetchUserDataLoading || authState.loading

  // console.log({ isLoggingIn, isLoggedOut, isLoggedIn, isLoading })

  return useMemo(
    () => ({ isLoggedOut, isLoggingIn, isLoggedIn, isLoading } as const),
    [isLoggedOut, isLoggingIn, isLoggedIn, isLoading]
  )
}

export default useAuthState
