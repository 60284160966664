import React, { useState } from 'react'
import { Button, Input, MenuProps, Dropdown, Modal, Radio } from 'antd'
import { DownOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

interface Props {
  setSearchFilter: (filter: string) => void
  setImportCSVOpen: (isOpen: boolean) => void
  setAddManualOpen: (isOpen: boolean) => void
  setImportExistingOpen: (isOpen: boolean) => void
  selectedProspects: string[]
  onRemoveProspects: (prospectIds: string[]) => void
  setSelectedProspects: (prospects: string[]) => void
  isRemoveProspectsLoading: boolean
  statusFilter: Record<string, any>
  setStatusFilter: (filter: Record<string, any>) => void
}

const JourneysTableToolbar: React.FC<Props> = ({
  setSearchFilter,
  setImportCSVOpen,
  setAddManualOpen,
  setImportExistingOpen,
  selectedProspects,
  onRemoveProspects,
  setSelectedProspects,
  isRemoveProspectsLoading,
  statusFilter,
  setStatusFilter,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const onSetSearchFilter = (filter: string) => {
    setSelectedProspects([])
    setSearchFilter(filter)
  }

  const onSetStatusFilter = (filter: any) => {
    setStatusFilter(JSON.parse(filter))
  }

  const importItems: MenuProps['items'] = [
    {
      label: 'Add existing prospects',
      key: '1',
      onClick: () => setImportExistingOpen(true),
      // icon: <UserOutlined />,
    },
    {
      label: 'Import from CSV',
      key: '2',
      onClick: () => setImportCSVOpen(true),
      // icon: <UserOutlined />,
    },
    // {
    //   label: 'Import from integrations',
    //   key: '3',
    //   icon: <UserOutlined />,
    //   danger: true,
    // },
    {
      label: 'Import manually',
      key: '4',
      onClick: () => setAddManualOpen(true),
      // icon: <UserOutlined />,
    },
  ]

  const onRemoveProspectClick = () => {
    modal.confirm({
      title: 'Confirm removing prospect(s) from campaign',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ fontSize: 13 }}>
          Prospect will be removed from this campaign but will remain in the database. For full
          removal, go to the Prospects page.
        </span>
      ),
      okText: 'Remove',
      onOk: () => onRemoveProspects(selectedProspects),
      cancelText: 'Cancel',
    })
  }

  const actionItems: MenuProps['items'] = [
    {
      label: 'Remove from campaign',
      key: '1',
      icon: <DeleteOutlined />,
      onClick: () => onRemoveProspectClick(),
      disabled: !selectedProspects.length,
    },
  ]

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 20, alignItems: 'baseline' }}>
        <Dropdown menu={{ items: actionItems }} disabled={!selectedProspects.length}>
          <Button style={{ marginRight: 15 }}>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>
        <Dropdown menu={{ items: importItems }}>
          <Button style={{ marginRight: 15 }}>
            Add Prospects
            <DownOutlined />
          </Button>
        </Dropdown>
        <div style={{ marginRight: 10 }}>Status:</div>
        <Radio.Group
          value={JSON.stringify(statusFilter)}
          onChange={(e) => onSetStatusFilter(e.target.value)}
          style={{ marginRight: 15 }}
        >
          <Radio.Button value={JSON.stringify({})}>All</Radio.Button>
          <Radio.Button value={JSON.stringify({ isFinished: true })}>Finished</Radio.Button>
          <Radio.Button value={JSON.stringify({ isUnsubscribed: true })}>Unsubscribed</Radio.Button>
          <Radio.Button value={JSON.stringify({ isPaused: true })}>Paused</Radio.Button>
          <Radio.Button value={JSON.stringify({ isBounced: true })}>Bounced</Radio.Button>
        </Radio.Group>

        <Input.Search
          placeholder="Filter by email.."
          style={{ width: 300, marginRight: 15 }}
          onSearch={(filter) => onSetSearchFilter(filter)}
        />
      </div>
      {contextHolder}
    </>
  )
}

export default JourneysTableToolbar
