import { useEffect, useState } from 'react'
import { Button, Typography, Spin, Switch, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import CampaignsTable from './CampaignsTable'
import CreateCampaignModal from './CreateCampaignModal'
import { useFetchCampaigns } from '../api/campaignQueries'
import { useCreateCampaign, useEditCampaign, useRemoveCampaign } from '../api/campaignsMutations'
import CampaignListAnalyticsContainer from './CampaignListAnalytics'
import { useFetchAllCampaignsStatistics } from '../../Analytics/api/statisticsQueries'
import { enrichCampaign } from './enrich-stats'
import { useFetchMailboxes } from '../../Mailboxes'

const { Title } = Typography

const CampaignList = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [showArchived, setShowArchived] = useState(false)

  const {
    campaigns,
    isLoading: fetchCampaignsLoading,
    isSuccess: fetchCampaignsSuccess,
  } = useFetchCampaigns()
  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()
  const { editCampaign, isLoading: editCampaignLoading } = useEditCampaign()
  const { removeCampaign, isLoading: removeCampaignLoading } = useRemoveCampaign()
  const { createCampaign, isLoading: createCampaignLoading } = useCreateCampaign()
  const campaignIds = campaigns?.map((c: any) => ({
    campaignId: c.campaignId,
    fromDate: c.createdAt,
  }))
  const { statistics, isLoading: fetchStatisticsLoading } =
    useFetchAllCampaignsStatistics(campaignIds)

  useEffect(() => {
    document.title = 'Campaigns | MailArrow'
  }, [])

  const sendEmail = () => {
    // TODO: Implement sendEmail function
  }

  const copyCampaign = (id: string) => {
    // dispatch(CampaignsActions.copyCampaign(id))
  }

  const togglePauseCampaign = (campaignId: string, isPaused: boolean) => {
    editCampaign({ campaignId, campaignProps: { isPaused } })
  }

  const deleteCampaign = (id: string) => {
    removeCampaign(id)
  }

  const onArchiveCampaign = (campaignId: string, isArchived: boolean) => {
    editCampaign({ campaignId, campaignProps: { isArchived } })
  }

  const shouldShowOnboarding = () => {
    const shouldShowOnboarding = fetchCampaignsSuccess && campaigns?.length === 0

    return shouldShowOnboarding
  }

  const showOnboarding = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '60vh',
          justifyContent: 'center',
        }}
      >
        <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
          Welcome to Mailarrow
        </Title>
        <div>Create your first campaign to get started</div>
        <Button
          style={{ margin: '10px 10px 10px 10px' }}
          className="core-optin-cta"
          icon={<PlusOutlined />}
          onClick={() => setModalOpen(true)}
        >
          Create campaign
        </Button>
        <CreateCampaignModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      </div>
    )
  }

  if (shouldShowOnboarding()) return showOnboarding()
  if (fetchCampaignsLoading) return <Spin />

  const filteredCampaigns = (campaigns || []).filter((c) => c.isArchived === showArchived)
  const enrichedCampaigns = filteredCampaigns.map((campaign) =>
    enrichCampaign(campaign, statistics, mailboxes)
  )

  return (
    <div>
      <Title level={3} style={{ marginBottom: 5, marginTop: 0, textAlign: 'center' }}>
        All Campaigns
      </Title>
      <div style={{ textAlign: 'center' }}>Last 30 days' performance</div>
      <CampaignListAnalyticsContainer />
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button
          style={{ margin: '10px 20px 10px 10px' }}
          icon={<PlusOutlined />}
          onClick={() => setModalOpen(true)}
        >
          Create campaign
        </Button>
        <div style={{ display: 'flex' }}>
          <Switch
            style={{ marginRight: 10 }}
            checked={showArchived}
            onChange={(isSelected: boolean) => setShowArchived(isSelected)}
          />
          <span>Show Archived</span>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CampaignsTable
          campaigns={enrichedCampaigns}
          isLoading={
            fetchCampaignsLoading ||
            editCampaignLoading ||
            removeCampaignLoading ||
            createCampaignLoading ||
            fetchMailboxesLoading ||
            fetchStatisticsLoading
          }
          togglePauseCampaign={togglePauseCampaign}
          deleteCampaign={deleteCampaign}
          onArchiveCampaign={onArchiveCampaign}
        />
      </div>
      <CreateCampaignModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  )
}

export default CampaignList
