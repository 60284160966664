import React, { useState } from 'react'
import { Layout, Button, Dropdown, Badge } from 'antd'

import {
  CloseCircleOutlined,
  SettingOutlined,
  CommentOutlined,
  LogoutOutlined,
  BookOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

import history from '../../router/history'
import { useFetchMailboxes } from '../../modules/Mailboxes'

import type { MenuProps } from 'antd'

import './Header.less'

import { useDispatch, useSelector } from 'react-redux'
import { actions as authActions } from '../../modules/Auth/reducer'
import useAuthState from '../../modules/Auth/use-auth-state'
import TrialNotice from '../TrialNotice/TrialNotice'
import { RootState } from '../../store/store'
import { MailOutlined } from '@ant-design/icons'
import { ImpersonateUserNotice } from './ImpersonateUserNotice'
const pluralize = require('pluralize') // this is a require because otherwise ts fails

const { Header } = Layout

interface HeaderProps {
  collapsed: boolean
  setCollapsed: (state: boolean) => void
}

const HeaderComponent = (props: HeaderProps) => {
  const { collapsed, setCollapsed } = props
  const { user } = useSelector((state: RootState) => state.user)
  const { mailboxes } = useFetchMailboxes()

  const disconnectedMailboxes = mailboxes?.filter(
    (mailbox) => mailbox.connectionStatus !== 'connected'
  )

  const { isLoggedIn, isLoading } = useAuthState()
  const dispatch = useDispatch()

  const openCrisp = () => {
    const $crisp = window.$crisp
    if ($crisp && $crisp.is('chat:closed')) {
      window.$crisp.push(['do', 'chat:toggle'])
    }
  }

  const handleLogout = () => {
    dispatch(authActions.signOutUser())
    history.push('/login')
  }

  if (!isLoggedIn || !user?.isVerified) return null

  const notificationMenuItems: MenuProps['items'] = [
    disconnectedMailboxes?.length
      ? {
          key: '1',
          icon: <MailOutlined />,
          label: (
            <Link to="/app/settings/mailboxes">
              {disconnectedMailboxes.length} {pluralize('mailbox', disconnectedMailboxes.length)}{' '}
              disconnected
            </Link>
          ),
        }
      : null,
    // {
    //   key: '2',
    //   icon: <SettingOutlined />,
    //   label: <Link to="/app/settings">Settings</Link>,
    // },
    // {
    //   key: '3',
    //   icon: <LogoutOutlined />,
    //   label: <div onClick={handleLogout}>Logout</div>,
    // },
  ].filter((a) => !!a)

  const emptyNotificationMenu: MenuProps['items'] = [
    {
      key: 'done',
      label: <span style={{ color: 'grey' }}>All caught up</span>,
    },
  ]

  const userMenuItems: MenuProps['items'] = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: (
        // <span style={{ width: 105 }}>
        <Link to="/app/settings/profile/" style={{ marginRight: 10 }}>
          Profile
        </Link>
        // </span>
      ),
      // style: { width: '75px' },
    },
    {
      key: '2',
      icon: <SettingOutlined />,
      label: (
        // <div style={{ width: 75 }}>
        <Link to="/app/settings/billing/">Billing</Link>
        // </div>
      ),
    },
    {
      key: '3',
      icon: <LogoutOutlined />,
      label: <div onClick={handleLogout}>Logout</div>,
    },
  ]

  return (
    <div>
      <ImpersonateUserNotice />
      <Header className="header-container">
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button
              type="text"
              onClick={() => setCollapsed(!collapsed)}
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TrialNotice />
            <div>
              <Dropdown
                menu={{
                  items: notificationMenuItems.length
                    ? notificationMenuItems
                    : emptyNotificationMenu,
                }}
              >
                <Badge dot={Boolean(notificationMenuItems.length)} size="small">
                  <Button type="text" title="Notifications" icon={<NotificationOutlined />} />
                </Badge>
              </Dropdown>
              <Button
                type="text"
                title="Customer Support"
                style={{ marginRight: 10, marginLeft: 10 }}
                onClick={() => openCrisp()}
                icon={<CommentOutlined />}
              />
              <Button
                type="text"
                title="Help Articles"
                style={{ marginRight: 10 }}
                onClick={() =>
                  window.open(
                    'https://mailarrow.notion.site/mailarrow/d0be487a454f4d4fa0926258b818299c',
                    '_blank'
                  )
                }
                icon={<BookOutlined />}
              />
              <Dropdown menu={{ items: userMenuItems }}>
                <Button type="text" title="User" icon={<UserOutlined />} />
              </Dropdown>
            </div>
          </div>
        </div>
      </Header>
    </div>
  )
}

export default HeaderComponent
