import React from 'react'
import { Button, Table, Tag, Tooltip } from 'antd'

import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Campaign, Prospect } from '@mailarrow/types'

interface Props {
  prospects?: Prospect[]
  totalProspects: number
  currentPage: number
  pageSize: number
  onTableChange: (config: TablePaginationConfig) => void
  isLoading: boolean
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
  campaigns?: Campaign[]
}

const UnsubscribeTable: React.FC<Props> = ({
  prospects,
  totalProspects,
  onTableChange,
  currentPage,
  pageSize,
  isLoading,
  selectedProspects,
  setSelectedProspects,
  campaigns,
}) => {
  const columns: ColumnsType = [
    {
      title: 'Email, Name',
      dataIndex: 'email',
      key: 'email',
      className: 'prospects-table-email',
      fixed: 'left',
      width: 300,
      ellipsis: true,
      // sorter: true,
      render: (email: string, item: any) => {
        const getName = (item: any) => {
          if (item.properties?.firstName || item.properties?.lastName)
            return `${item.properties?.firstName || ''} ${item.properties?.lastName || ''}`
          return item.email.split('@')[0]
        }
        // TODO: add loading and success values
        return (
          <div>
            <span style={{ fontWeight: 600 }}>{getName(item)}</span>
            <br />
            {email}
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'prospects-table-detail-column',
      width: 150,
      render: (status: any, item: any) => {
        const getStatusString = () => {
          if (item.isPaused) return 'Paused'
          if (item.isUnsubscribed) return 'Unsubscribed'
          if (item.isBounced) return 'Bounced'
          // TODO: add finished
          return status
        }

        const toCapitalizedFirstLetter = (s: string) =>
          s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

        return <span style={{ fontSize: 12 }}>{toCapitalizedFirstLetter(getStatusString())}</span>
      },
    },
    {
      title: 'Campaigns',
      dataIndex: 'campaigns',
      key: 'campaigns',
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        const matchedCampaigns = item.campaigns.map((cId: string) =>
          campaigns?.find((campaign: Campaign) => campaign.campaignId === cId)
        )
        return matchedCampaigns.filter(Boolean).map((c: Campaign) => <Tag>{c.name}</Tag>)
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedProspects(selectedRowKeys as string[])
    },
    getCheckboxProps: (record: any) => ({
      name: record?.name,
    }),
    renderCell: (checked: boolean, record: any, index: any, originNode: any) => {
      // this piece of code stops propagation for the click event
      // it is caused to remove annoying effect of opening the drawer
      // which is called on onRowClick, which is triggered when you miss the checkbox
      // by a couple of pixels
      return <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
    },
    selectedRowKeys: selectedProspects,
  }

  return (
    <Table
      dataSource={prospects}
      columns={columns as any}
      loading={isLoading}
      pagination={{
        // position: ['topRight'],
        current: currentPage,
        pageSize: pageSize,
        hideOnSinglePage: true,
        // simple: true,
        total: totalProspects,
      }}
      onChange={onTableChange}
      rowKey="prospectId"
      size="small"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {}, // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        }
      }}
      rowClassName="prospects-table-row"
      rowSelection={rowSelection}
    />
  )
}

export default UnsubscribeTable
