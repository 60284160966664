import React from 'react'
import { Form, Input, Button, Card, Alert } from 'antd'
import { RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { actions as AuthActions } from '../../../modules/Auth/reducer'

const EditEmailForm: React.FC = () => {
  const dispatch = useDispatch()
  const { user, updateLoading } = useSelector((state: RootState) => state.user)

  const handleSubmit = (values: any) => {
    dispatch(AuthActions.updateUserEmail(values))
  }

  const confirmEmailValidator = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (value && value === getFieldValue('email')) {
        return Promise.reject('Please provide another email')
      }
      return Promise.resolve()
    },
  })

  if (!user) return null

  const { email, signupType } = user

  const formItemLayout = {
    labelCol: { xs: { span: 7 }, sm: { span: 7 } },
    wrapperCol: { xs: { span: 10 }, sm: { span: 10 } },
  }

  const tailFormItemLayout = {
    wrapperCol: { xs: { span: 10, offset: 7 }, sm: { span: 10, offset: 7 } },
  }

  const isSSO = signupType !== 'email'

  return (
    <div style={{ marginTop: '20px' }}>
      <Card title={<span>Email Address</span>} size="small">
        {isSSO && (
          <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginBottom: 20 }}>
            Changing your email is not possible if you signed up with a social account.
          </div>
        )}
        <Form
          {...formItemLayout}
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={{ email }}
        >
          <Form.Item label="Account Email" name="email">
            <Input placeholder="your@email.com" disabled />
          </Form.Item>
          <Form.Item
            label="New Email"
            name="newEmail"
            rules={[
              { required: true, message: 'Please provide your new email' },
              confirmEmailValidator,
            ]}
          >
            <Input placeholder="your@email.com" type="email" disabled={isSSO} />
          </Form.Item>
          <Form.Item
            label="Password"
            rules={[{ required: true, message: 'Please confirm your password' }]}
            name="password"
          >
            <Input placeholder="Confirm Password" type="password" disabled={isSSO} />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={updateLoading}
              disabled={updateLoading || isSSO}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default EditEmailForm
