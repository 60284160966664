import { CommentOutlined, BookFilled, ExportOutlined } from '@ant-design/icons'
import { Menu, MenuProps } from 'antd'
import history from '../../router/history'

type MenuItem = Required<MenuProps>['items'][number]

export const SidebarFooter = () => {
  const navItems: MenuItem[] = [
    {
      key: '/app/kb',
      icon: <BookFilled />,
      label: (
        <>
          Knowledge Base
          <ExportOutlined style={{ fontSize: 10, marginLeft: 5 }} />
        </>
      ),
      onClick: () => window.open('https://help.mailarrow.com/', '_blank'),
    },
    // {
    //   key: '/app/feedback',
    //   icon: <CommentOutlined />,
    //   label: (
    //     <>
    //       Give Feedback
    //       <ExportOutlined style={{ fontSize: 10, marginLeft: 5 }} />
    //     </>
    //   ),
    //   onClick: () => window.open('https://savvycal.com/serge-builds/mailarrow-feedback', '_blank'),
    // },
  ]

  return (
    <>
      <div>
        <Menu selectable={false} items={navItems} className="sidebar-menu-footer" />
      </div>
    </>
  )
}
