import React from 'react'
import { Button, Dropdown, message, Space, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import {
  DownOutlined,
  EditFilled,
  PlayCircleFilled,
  PlaySquareOutlined,
  // PlayOutlined,
  PauseOutlined,
  DeleteFilled,
  UserDeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons'

interface Props {
  prospectId: string
  isPaused: boolean
  isUnsubscribed: boolean
  onPauseProspect: (prospectId: string, isPaused: boolean) => void
  onEditProspect: (prospectId: string) => void
  onRemoveProspect: (prospectId: string) => void
  onUnsubscribeProspect: (prospectId: string, isUnsubscribed: boolean) => void
}

export const JourneysTableActions: React.FC<Props> = ({
  prospectId,
  isPaused,
  isUnsubscribed,
  onPauseProspect,
  onEditProspect,
  onRemoveProspect,
  onUnsubscribeProspect,
}) => {
  const items: MenuProps['items'] = [
    {
      label: 'Edit',
      key: 'edit',
      icon: <EditFilled />,
    },
    {
      label: isPaused ? 'Unpause' : 'Pause',
      key: 'pause',
      icon: isPaused ? <PlaySquareOutlined /> : <PauseOutlined />,
    },
    {
      label: 'Unsubscribe',
      key: 'unsubscribe',
      disabled: isUnsubscribed,
      icon: <UserDeleteOutlined />,
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteFilled />,
      danger: true,
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'pause':
        onPauseProspect(prospectId, !isPaused)
        break
      case 'unsubscribe':
        onUnsubscribeProspect(prospectId, true)
        break
      case 'edit':
        onEditProspect(prospectId)
        break
      case 'delete':
        onRemoveProspect(prospectId)
        break
      default:
        break
    }
  }

  const menuProps = {
    items,
    onClick: (e: any) => {
      console.log('event supposed to be here', e)
      e.domEvent.stopPropagation()
      handleMenuClick(e)
    },
  }

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button size="small" onClick={(e: any) => e.stopPropagation()} style={{ padding: '0 2px' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    </>
  )
}
