import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button, Table, Badge, Tooltip, List } from 'antd'
import { WarningOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import ReactJson from 'react-json-view'
import _ from 'lodash'
import { createDemoData } from './util'
import { ensureNumber } from '../../../util/ensure-number'

interface Props {
  data: any[]
  isLoading: boolean
}

const SequenceOverviewTable: FC<Props> = ({ data, isLoading }) => {
  const getColumns = () => {
    return [
      {
        title: '',
        dataIndex: 'emailName',
        key: 'emailName',
      },
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
      },
      {
        title: 'Opened',
        dataIndex: 'opened',
        key: 'opened',
        render: (x: number, item: any) => (
          <div>
            {ensureNumber(Math.ceil((item.opened / item.sent) * 100))}% ({x})
          </div>
        ),
      },
      {
        title: 'Replied',
        dataIndex: 'replied',
        key: 'replied',
        render: (x: number, item: any) => (
          <div>
            {ensureNumber(Math.ceil((item.replied / item.sent) * 100))}% ({x})
          </div>
        ),
      },
      {
        title: 'Clicked',
        dataIndex: 'clicked',
        key: 'clicked',
        render: (x: number, item: any) => (
          <div>
            {ensureNumber(Math.ceil((item.clicked / item.sent) * 100))}% ({x})
          </div>
        ),
      },
      {
        title: 'Bounced',
        dataIndex: 'bounced',
        key: 'bounced',
        render: (x: number, item: any) => (
          <div>
            {ensureNumber(Math.ceil((item.bounced / item.sent) * 100))}% ({x})
          </div>
        ),
      },
      {
        title: 'Unsubscribed',
        dataIndex: 'unsubscribed',
        key: 'unsubscribed',
        render: (x: number, item: any) => (
          <div>
            {ensureNumber(Math.ceil((item.unsubscribed / item.sent) * 100))}% ({x})
          </div>
        ),
      },
    ]
  }

  const enrichTableData = (data: any[] = []) => {
    const result = data.map(({ emailName, ...rest }, i) => {
      const res = { emailName: i === 0 ? `First Email` : `Follow Up ${i}`, ...rest }
      return res
    })
    return result
  }

  const expandIcon = (props: any) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="default"
          size="small"
          icon={
            props.expanded ? (
              <UpOutlined style={{ fontSize: 10 }} />
            ) : (
              <DownOutlined style={{ fontSize: 10 }} />
            )
          }
          onClick={(e) => props.onExpand(props.record, e)}
        ></Button>
      </span>
    )
  }

  const expandedRowRender = (record: any) => {
    const { html, ...rest } = record
    return (
      <div style={{ alignItems: 'center' }}>
        Page Data:
        <List style={{ margin: '20px 0' }} bordered>
          {Object.keys(rest).map((k) => (
            <List.Item key={k}>
              {k}: {JSON.stringify(rest[k])}
            </List.Item>
          ))}
        </List>
        <ReactJson src={record} collapsed={true} />
      </div>
    )
  }

  const tableData = _.sortBy(enrichTableData(data), 'date')

  return (
    <Table
      dataSource={tableData}
      columns={getColumns()}
      loading={isLoading}
      pagination={{
        pageSize: 100,
        hideOnSinglePage: true,
      }}
      rowKey="sequenceStepIndex"
      // expandIcon={expandIcon}
      // expandedRowRender={expandedRowRender}
    />
  )
}

export default SequenceOverviewTable
