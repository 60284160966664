import cookies from 'js-cookie'
import queryString from 'query-string'

import logger from '../../../services/errorlogger'

interface StoredProps {
  inviteId: string
  siteUrl: string
  campaign: string
  analyticsId: string
  source: string
}

export const getStoredProps = (): StoredProps => {
  try {
    const { search } = window.location
    const parsed = queryString.parse(search)
    let { inviteId, siteUrl, campaign, analyticsId, source } = parsed
    if (!campaign) {
      if (localStorage && localStorage.getItem('campaign')) {
        campaign = localStorage.getItem('campaign')!
      } else {
        const cookie = cookies.get('campaign')
        if (cookie) {
          campaign = cookie
        }
      }
    }

    if (!analyticsId) {
      if (localStorage && localStorage.getItem('analyticsId')) {
        analyticsId = localStorage.getItem('analyticsId')!
      } else {
        const cookie = cookies.get('analyticsId')
        if (cookie) {
          analyticsId = cookie
        }
      }
    }
    return {
      inviteId: (inviteId as string) || '',
      siteUrl: (siteUrl as string) || '',
      campaign: (campaign as string) || '',
      analyticsId: (analyticsId as string) || '',
      source: (source as string) || '',
    }
  } catch (e: any) {
    console.log(e)
    logger.logError(e)
    return {
      inviteId: '',
      siteUrl: '',
      campaign: '',
      analyticsId: '',
      source: '',
    }
  }
}
