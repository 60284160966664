import React from 'react'
import { Divider, Spin } from 'antd'
import {
  SendOutlined,
  FileDoneOutlined,
  SelectOutlined,
  CommentOutlined,
  StopOutlined,
  MehOutlined,
} from '@ant-design/icons'

import Bar from '../../../components/Bar/BarNew'
import InfoCard from '../../../components/InfoCard/InfoCard'
import { ensureNumber } from '../../../util/ensure-number'
import SequenceOverviewTable from './SequenceOverviewTable'

interface Props {
  statistics: any
  isLoading: boolean
  showStepView?: boolean
  isSingleView?: boolean
}

const Analytics: React.FC<Props> = ({
  statistics,
  isLoading,
  showStepView,
  isSingleView = true,
}) => {
  if (isLoading) return <Spin />
  if (!statistics) return <div>Something went wrong</div>

  const { total, byDay, byStep } = statistics
  const { sent, replied, opened, clicked, bounced, unsubscribed } = total

  return (
    <div>
      {/* <Divider /> */}
      <div>
        {isSingleView && (
          <div
            style={{ textAlign: 'center', fontWeight: 500, fontSize: 17, margin: '10px 0 20px 0' }}
          >
            Campaign Analytics
          </div>
        )}
        <div>
          <Bar data={byDay} />
        </div>
      </div>
      <div>
        {/* <div
          style={{ textAlign: 'center', fontWeight: 500, fontSize: 17, margin: '30px 0 20px 0' }}
        >
          Campaign Metrics
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '20px 0',
              width: '80%',
            }}
          >
            {/* <InfoCard loading={isLoading} metric={100} title="Prospects" icon="TeamOutlined" /> */}
            <InfoCard loading={isLoading} metric={sent} title="Sent" icon={<SendOutlined />} />

            {/* <InfoCard
            loading={isLoading}
            metric={sent - bounces}
            title="Delivered"
            icon="MailOutlined"
          /> */}
            <InfoCard
              loading={isLoading}
              metric={ensureNumber(Math.ceil((opened / sent) * 100))}
              suffix={<div style={{ fontSize: 13 }}>% ({opened})</div>}
              title="Opened"
              icon={<FileDoneOutlined />}
            />
            <InfoCard
              loading={isLoading}
              metric={ensureNumber(Math.ceil((clicked / sent) * 100))}
              suffix={<div style={{ fontSize: 13 }}>% ({clicked})</div>}
              title="Clicked"
              icon={<SelectOutlined />}
            />
            <InfoCard
              loading={isLoading}
              metric={ensureNumber(Math.ceil((replied / sent) * 100))}
              suffix={<div style={{ fontSize: 13 }}>% ({replied})</div>}
              title="Replied"
              icon={<CommentOutlined />}
            />
            <InfoCard
              loading={isLoading}
              metric={ensureNumber(Math.ceil((bounced / sent) * 100))}
              suffix={<div style={{ fontSize: 13 }}>% ({bounced})</div>}
              title="Bounced"
              icon={<StopOutlined />}
            />
            <InfoCard
              loading={isLoading}
              metric={ensureNumber(Math.ceil((unsubscribed / sent) * 100))}
              suffix={<div style={{ fontSize: 13 }}>% ({unsubscribed})</div>}
              title="Opted Out"
              icon={<MehOutlined />}
            />
          </div>
        </div>
      </div>
      {showStepView && (
        <>
          <Divider />
          <div style={{ margin: '20px 0' }}>
            <div style={{ textAlign: 'center', fontWeight: 500, fontSize: 17, margin: '30px 0' }}>
              Sequence Steps
            </div>
            <SequenceOverviewTable isLoading={isLoading} data={byStep} />
          </div>
        </>
      )}
    </div>
  )
}

export default Analytics
