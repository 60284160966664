import { Select, InputNumber, Tag, Button, Card, Switch } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { isUndefined } from 'lodash'
import { Campaign } from '@mailarrow/types'

interface Props {
  campaign: Campaign
  fetchLoading: boolean
  editLoading: boolean
  onEditCampaign: (props: any) => void
}

const TrackingSettings: React.FC<Props> = ({
  onEditCampaign,
  campaign,
  fetchLoading,
  editLoading,
}) => {
  const [opens, setOpens] = useState<boolean | undefined>(undefined)
  const [clicks, setClicks] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (!campaign) return
    if (isUndefined(opens)) setOpens(campaign.trackingConfig.trackOpens)
    if (isUndefined(clicks)) setClicks(campaign.trackingConfig.trackClicks)
  }, [campaign, clicks, opens])

  const onSaveClick = useCallback(() => {
    const updates = {
      trackingConfig: {
        ...campaign.trackingConfig,
        trackOpens: opens,
        trackClicks: clicks,
      },
    }
    onEditCampaign(updates)
  }, [campaign.trackingConfig, clicks, onEditCampaign, opens])

  return (
    <div>
      <Card title={<div>Tracking</div>} style={{ marginBottom: 20 }} size="small">
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Switch
            id="opens"
            style={{ marginRight: 10 }}
            checked={opens}
            onChange={(isActive: boolean) => setOpens(isActive)}
          />
          <label htmlFor="opens" style={{ marginRight: 10 }}>
            Track email opens
          </label>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Switch
            id="clicks"
            style={{ marginRight: 10 }}
            checked={clicks}
            onChange={(isActive: boolean) => setClicks(isActive)}
          />
          <label htmlFor="clicks" style={{ marginRight: 10 }}>
            Track link clicks
          </label>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onSaveClick}
            loading={fetchLoading || editLoading}
            size="small"
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default TrackingSettings
