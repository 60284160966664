import React, { useCallback, useEffect, useState } from 'react'
import { message, Select, Checkbox, Collapse, Button, Card, Tag } from 'antd'
import TimePicker from 'antd/lib/time-picker'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { dayRangeInMinutesToDate, dateToDayRangeInMinutes } from '../util/convert-date'
import { SaveOutlined } from '@ant-design/icons'

dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
  schedule: any
  onSave: (props: any) => void
  error: boolean
  loading: boolean
}

const DatePanel: React.FC<Props> = ({ schedule, onSave, error, loading }) => {
  const [allowedSendDays, setAllowedSendDays] = useState<number[] | undefined>()
  const [timezone, setTimezone] = useState<string | undefined>(undefined)
  const [sendTimeFrom, setSendTimeFrom] = useState<Dayjs | undefined>(undefined)
  const [sendTimeTo, setSendTimeTo] = useState<Dayjs | undefined>(undefined)
  const [useleadTimezone, setUseleadTimezone] = useState<boolean>(false)

  useEffect(() => {
    if (!schedule) return
    if (!allowedSendDays) setAllowedSendDays(schedule.days)
    if (!timezone) {
      setTimezone(schedule.timezone)
    }
    if (!sendTimeFrom) {
      setSendTimeFrom(dayRangeInMinutesToDate(schedule.from, dayjs()))
    }
    if (!sendTimeTo) {
      setSendTimeTo(dayRangeInMinutesToDate(schedule.to, dayjs()))
    }
  }, [allowedSendDays, schedule, sendTimeFrom, sendTimeTo, timezone])

  const onSaveClick = useCallback(() => {
    if (!sendTimeFrom || !sendTimeTo) {
      return message.warning('Please set send times before saving')
    }
    const scheduleUpdates = {
      days: allowedSendDays,
      timezone,
      from: dateToDayRangeInMinutes(sendTimeFrom),
      to: dateToDayRangeInMinutes(sendTimeTo),
    }
    onSave(scheduleUpdates)
  }, [allowedSendDays, onSave, sendTimeFrom, sendTimeTo, timezone])

  const getTimezones = () => {
    // const timezones = moment.tz.names()
    const timezones = (Intl as any).supportedValuesOf('timeZone')
    const forbiddenEls = ['Etc/', 'GMT', 'Universal']
    const allowedTimezones = timezones.filter(
      (t: string) => !forbiddenEls.some((e) => t.includes(e))
    )

    return allowedTimezones
  }

  return (
    <Card title={<div>Schedule</div>} size="small" style={{ marginBottom: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 5 }}>Allowed Days:</div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          value={allowedSendDays}
          onChange={(v: number[]) => setAllowedSendDays(v)}
          options={[
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ].map((d) => ({ label: d, value: d }))}
        />
      </div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 5 }}>Sending Time:</div>
        <div style={{ display: 'flex', gap: 20 }}>
          <div>
            From
            <TimePicker
              style={{ marginLeft: 10, width: 100 }}
              format={'HH:mm'}
              defaultValue={sendTimeFrom}
              value={sendTimeFrom}
              onChange={(day) => day && setSendTimeFrom(day)}
              allowClear={false}
              showNow={false}
            />
          </div>
          <div>
            To
            <TimePicker
              style={{ marginLeft: 10, width: 100 }}
              format={'HH:mm'}
              defaultValue={sendTimeTo}
              value={sendTimeTo}
              onChange={(day) => day && setSendTimeTo(day)}
              allowClear={false}
              showNow={false}
            />
          </div>
        </div>
        <div style={{ marginTop: 5, fontSize: 13 }}>
          Emails are spread evenly across the sending window.{' '}
          <a
            href="https://mailarrow.notion.site/Sending-Schedules-Explained-443bdb6efd644b93996fffd08f68e22e"
            target="_blank"
            style={{ color: 'gray', textDecoration: 'underline' }}
            rel="noreferrer"
          >
            Learn more.
          </a>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: 5 }}>Sending Timezone:</div>
        <Select
          style={{ width: '250px' }}
          showSearch
          placeholder="Please select timezone"
          defaultValue={timezone}
          value={timezone}
          onChange={(v: string) => setTimezone(v)}
          options={getTimezones().map((timezone: string) => ({
            value: timezone,
            label: timezone,
          }))}
        />
        {/* <Checkbox
          style={{ margin: '10px 0' }}
          onChange={(event) => setUseleadTimezone(event.target.checked)}
          checked={useleadTimezone}
          disabled
        >
          Use lead timezone when available{' '}
          <Tag style={{ marginLeft: 10, color: 'gray', fontSize: 10 }}>Coming Soon</Tag>
        </Checkbox> */}
      </div>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={onSaveClick} loading={loading} size="small" icon={<SaveOutlined />}>
          Save
        </Button>
      </div>
    </Card>
  )
}

export default DatePanel
