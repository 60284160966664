import React, { FC, useCallback, useState } from 'react'
import { Button, Card, Checkbox, Input, Popconfirm, Spin, Switch, message } from 'antd'
import cn from 'classnames'
import { createExampleContent, createExampleSubject } from './populate'
import { useSendTestEmail } from '../../../../Mailboxes'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { useFetchCampaigns } from '../../../../Campaigns/api/campaignQueries'
import { useParams } from 'react-router-dom'
import {
  EmailStepVariation,
  SequenceStep,
  SequenceStepVariation,
  isEmailSequenceStep,
} from '@mailarrow/types'
import { usePermissions } from '../../../../User/Permissions'
import RichEditor from './RichEditor/RichEditor'

interface Props {
  steps: SequenceStep[]
  stepIndex: number
  stepVariationIndex: number
  isCurrentStepUnsaved: boolean
  editSequenceLoading: boolean
  onSetSteps: (steps: SequenceStep[]) => void
  onSave: () => void
}

const EmailStepContainer: FC<Props> = ({
  steps,
  stepIndex,
  stepVariationIndex,
  isCurrentStepUnsaved,
  editSequenceLoading,
  onSetSteps,
  onSave,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { campaignId } = useParams()
  const { staff } = usePermissions()

  const { campaigns } = useFetchCampaigns()
  const campaign = campaigns?.find((c) => c.campaignId === campaignId)

  const { sendTestEmail, isLoading: sendTestEmailLoading } = useSendTestEmail()

  const { user } = useSelector((state: RootState) => state.user)

  const step = steps.find((step: any) => step.stepIndex === stepIndex)
  if (!step) return <Spin />

  const { variations } = step
  // AB testing not supported yet
  const stepVariation = variations[stepVariationIndex]
  const { subject, body, stepVariationId, continueThread } = stepVariation as EmailStepVariation

  const setStepProps = (
    stepIndex: number,
    variationIndex: number,
    variationProps: Partial<SequenceStepVariation>
  ) => {
    const nextSequence = steps.map((s, i) => {
      if (s.stepIndex !== stepIndex) return s
      if (!isEmailSequenceStep(s)) return s
      // we do not map other steps for now
      const nextVariations = s.variations.map((variation) => {
        if (stepVariationIndex !== variationIndex) return variation

        return {
          ...variation,
          ...variationProps,
        }
      })
      return { ...s, variations: nextVariations }
    })
    console.log('chaning step', variationProps)
    return nextSequence
  }

  const onEditStep = (
    stepIndex: number,
    stepVariationIndex: number,
    variationProps: Partial<SequenceStepVariation>
  ) => {
    const nextSequence = setStepProps(stepIndex, stepVariationIndex, variationProps)
    onSetSteps(nextSequence)
  }

  const setSubject = (stepIndex: number, stepVariationIndex: number, subject: string) => {
    const nextSequence = setStepProps(stepIndex, stepVariationIndex, { subject })
    onSetSteps(nextSequence)
  }

  const onSetSameThread = (enabled: boolean) => {
    return onEditStep(stepIndex, stepVariationIndex, {
      continueThread: enabled,
    })
  }

  const onSendTest = () => {
    if (!campaign || !user?.email) return
    const { mailboxIds } = campaign
    // todo: is this supposed to be fixed?
    // maybe when send test email is sent from a modal window
    sendTestEmail({ mailboxId: mailboxIds[0], body, subject, prospectEmail: user.email })
  }

  const onSaveChanges = () => {
    if (!subject) {
      return message.warning('Please add a subject')
    }
    if (!body) {
      return message.warning('Please add email body')
    }
    onSave()
  }

  return (
    <>
      <Card type="inner" title={<>Step {stepIndex + 1}: Email</>} extra={<></>} size="small">
        <div className={cn('sequence-step', continueThread && 'sequence-step-fllow-up')}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'last baseline' }}>
            <div style={{ marginRight: '10px' }}>Subject: </div>
            <Input
              placeholder="Subject"
              onChange={(event) => setSubject(stepIndex, stepVariationIndex, event.target.value)}
              value={continueThread ? '<same as previous email>' : subject}
              disabled={continueThread}
            />
            {stepIndex > 0 && (
              <div
                style={{
                  width: 180,
                  display: 'inline-flex',
                  marginLeft: 15,
                }}
              >
                <Switch
                  checked={continueThread}
                  size="small"
                  onChange={(checked) => onSetSameThread(checked)}
                  style={{ marginTop: 1 }}
                />
                <div style={{ marginLeft: 10, width: '100px', fontSize: 13 }}>Same Thread</div>
              </div>
            )}
          </div>
          <div style={{ width: '100%', marginTop: 10 }}>
            <RichEditor
              stepIndex={stepIndex}
              textHTML={body}
              onChange={(body: string) => onEditStep(stepIndex, stepVariationIndex, { body })}
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              unsubscribeText={campaign?.sendConfig.unsubscribeText}
              enableUnsubscribeText={campaign?.sendConfig.enableUnsubscribeText}
            />
          </div>
          <div style={{ width: '100%', marginTop: 10, gap: 10, display: 'flex' }}>
            <Button
              type="primary"
              onClick={onSaveChanges}
              disabled={!isCurrentStepUnsaved}
              loading={isCurrentStepUnsaved && editSequenceLoading}
            >
              Save
            </Button>
            <Button onClick={() => setModalOpen(true)}>Insert Variable</Button>
            {/* <Button>Preview</Button> */}
            <Button
              onClick={onSendTest}
              loading={sendTestEmailLoading}
              disabled={!Boolean(campaign?.mailboxIds.length)}
              title={!Boolean(campaign?.mailboxIds.length) ? 'Please connect a mailbox first' : ''}
            >
              Send Test
            </Button>
            {staff && (
              <Button
                style={{}}
                onClick={(e) => {
                  !continueThread &&
                    setSubject(stepIndex, stepVariationIndex, createExampleSubject())
                }}
                disabled={continueThread}
              >
                Add Subject
              </Button>
            )}
            {staff && (
              <Button
                style={{}}
                onClick={(e) => {
                  onEditStep(stepIndex, stepVariationIndex, {
                    body: createExampleContent(stepIndex, stepVariationId),
                  })
                }}
              >
                Add Step
              </Button>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}

export default EmailStepContainer
