import React, { useEffect, useState, useRef } from 'react'
import SMTPMailboxForm from './SMTPMailboxForm'
import { Modal } from 'antd'
import { useCreateMailbox, useTestMailboxConnection } from '../../../api/mailboxesMutations'

interface Props {
  open: boolean
  gmail?: boolean
  onClose: () => void
}

const AddSMTPMailboxContainer: React.FC<Props> = ({ open, gmail, onClose }) => {
  const [hasEdited, setHasEdited] = useState(false)
  const [openTime, setOpenTime] = useState<Date | undefined>(undefined)
  const [values, setValues] = useState<any>(null)
  const {
    createMailbox,
    reset: resetCreateMailbox,
    isSuccess: createMailboxSuccess,
    isLoading: createMailboxLoading,
    isError: createMailboxError,
  } = useCreateMailbox()
  const {
    testMailboxConnection,
    reset: resetTestMailboxConnection,
    isSuccess: isTestMailboxSuccess,
    isError: isTestMailboxError,
    isLoading: isTestMailboxLoading,
  } = useTestMailboxConnection()

  const formRef = useRef<any>(null)

  useEffect(() => {
    if (open) setOpenTime(new Date())
  }, [open])

  useEffect(() => {
    const handleClose = () => {
      setHasEdited(false)
      onClose()
      setValues({})
    }

    if (!hasEdited || createMailboxLoading || isTestMailboxLoading) return
    if (createMailboxSuccess) {
      return handleClose()
    }
    if (isTestMailboxSuccess && !isTestMailboxError && !createMailboxError) {
      return createMailbox(values)
    }
    if (isTestMailboxError) {
      return setHasEdited(false)
    }
  }, [
    hasEdited,
    createMailboxSuccess,
    onClose,
    isTestMailboxSuccess,
    isTestMailboxError,
    createMailbox,
    values,
    resetCreateMailbox,
    resetTestMailboxConnection,
    createMailboxLoading,
    isTestMailboxLoading,
    createMailboxError,
  ])

  const onSave = (values: any) => {
    testMailboxConnection(values.config)
    setValues(values)
    setHasEdited(true)
  }

  const onTestMailbox = (values: any) => {
    testMailboxConnection(values)
  }

  useEffect(() => {
    if (!open) {
      formRef.current && formRef.current.resetForm()
      resetCreateMailbox()
      resetTestMailboxConnection()
    }
  }, [open, resetCreateMailbox, resetTestMailboxConnection])

  return (
    <Modal open={open} onCancel={onClose} footer={null} title="Add Mailbox">
      {open && (
        <SMTPMailboxForm
          ref={formRef}
          onSave={onSave}
          gmail={gmail}
          loading={createMailboxLoading}
          isTestMailboxLoading={isTestMailboxLoading}
          onTestMailbox={onTestMailbox}
          mailbox={values}
          openTime={openTime}
        />
      )}
    </Modal>
  )
}

export default AddSMTPMailboxContainer
