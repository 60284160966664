import React from 'react'
import { MailboxType } from '@mailarrow/types'
import { GoogleOutlined, WindowsOutlined, MailOutlined } from '@ant-design/icons'

interface Props extends React.AllHTMLAttributes<HTMLElement> {
  type: MailboxType
}

export const MailboxIcon: React.FC<Props> = ({ type, ...rest }) => {
  if (type === MailboxType.GMAIL) {
    return <GoogleOutlined {...rest} />
  }
  if (type === MailboxType.OUTLOOK) {
    return <WindowsOutlined {...rest} />
  }
  if (type === MailboxType.EXCHANGE) {
    return <WindowsOutlined {...rest} />
  }
  if (type === MailboxType.SMTP) {
    return <MailOutlined {...rest} />
  }
  return <></>
}
