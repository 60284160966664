import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Tabs, Typography } from 'antd'

const { Title } = Typography

const SettingsTabs: React.FC = () => {
  const navigate = useNavigate()

  const tabs = [
    { path: 'smoothliner', label: 'SmoothLiner' },
    { path: 'kwmapper', label: 'KW Mapper' },
    { path: 'content-prompts', label: 'Content Prompts' },
    // { path: 'team', label: 'Team' },
    // { path: 'subscription', label: 'Subscription' },
    // { path: 'billing', label: 'Billing' },
    // { path: 'integrations', label: 'Integrations' },
  ]

  const getActiveTopLevelRoutePath = () => {
    const currentPath = window.location.pathname
    const matchingTab = tabs.find((tab) => currentPath.startsWith(`/app/tools/${tab.path}`))
    return matchingTab ? matchingTab.path : ''
  }

  const activeRoutePath = getActiveTopLevelRoutePath()

  const handleTabChange = (key: string) => {
    navigate(`/app/tools/${key}`)
  }

  return (
    <Tabs
      defaultActiveKey={activeRoutePath}
      activeKey={activeRoutePath}
      onChange={handleTabChange}
      items={tabs.map((tab) => ({ key: tab.path, label: tab.label }))}
    />
  )
}

const Tools: React.FC = () => {
  useEffect(() => {
    document.title = 'Tools | Mailarrow App'
  }, [])

  return (
    <div>
      <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
        Tools
      </Title>
      <div>
        <SettingsTabs />
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Tools
