import { Button, TablePaginationConfig, Typography } from 'antd'
import { useFetchEmail } from './api/emailQueries'
import EmailsTable from './EmailsTable'
import { useEffect, useState } from 'react'
import { useFetchCampaigns } from '../Campaigns/api/campaignQueries'
import { ReloadOutlined } from '@ant-design/icons'

const { Title } = Typography

interface Props {}

const ActivityContainer: React.FC<Props> = ({}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const {
    emails,
    total,
    isLoading: fetchEmailsLoading,
    isFetching: fetchEmails2Loading,
    refetch,
  } = useFetchEmail({}, { page: currentPage, pageSize })
  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()

  useEffect(() => {
    document.title = 'Activity | MailArrow'
  }, [])

  const onTableChange = async (pagination: TablePaginationConfig) => {
    const currentPage = pagination.current || 1
    setCurrentPage(currentPage)
    pagination.pageSize && pagination.pageSize !== pageSize && setPageSize(pagination.pageSize)
  }

  const onRefresh = () => {
    setCurrentPage(1)
    refetch()
  }

  return (
    <div>
      <Title level={3} style={{ marginBottom: 20, marginTop: 0 }}>
        Latest Activity
      </Title>
      <div style={{ margin: '10px 0' }}>
        <Button onClick={onRefresh} icon={<ReloadOutlined />}>
          Refresh
        </Button>
      </div>
      <EmailsTable
        emails={emails}
        total={total || 0}
        onTableChange={onTableChange}
        currentPage={currentPage}
        pageSize={pageSize}
        campaigns={campaigns}
        loading={fetchEmailsLoading || fetchEmails2Loading || fetchCampaignsLoading}
        isAdminView
      />
    </div>
  )
}

export default ActivityContainer
