import { useQuery, useMutation } from '@tanstack/react-query'
import { message } from 'antd'
import fetch from '../../../services/fetch'
import history from '../../../router/history'
import { queryClient } from '../../../store/queryClient'
import { Campaign } from '@mailarrow/types'

// Create campaign mutation
const createCampaign = async (campaignProperties: Partial<Campaign> = {}) => {
  try {
    const response = await fetch.put('/campaigns/', campaignProperties)
    return response.data as Campaign
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useCreateCampaign = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(createCampaign, {
    onSuccess: (campaignId) => {
      queryClient.invalidateQueries(['campaigns'])
      queryClient.refetchQueries(['campaigns'])
      message.success('Campaign has been created')
      history.push(`/app/campaigns/${campaignId}/new/`)
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    createCampaign: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

// Edit campaign mutation
const editCampaign = async ({
  campaignId,
  campaignProps,
}: {
  campaignId: string
  campaignProps: Partial<Campaign>
}) => {
  try {
    await fetch.patch(`/campaigns/${campaignId}/`, campaignProps)
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useEditCampaign = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(editCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns'])
      queryClient.refetchQueries(['campaigns'])
      message.success('Campaign has been updated', 10)
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    editCampaign: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

// Remove campaign mutation
const removeCampaign = async (campaignId: string) => {
  try {
    return await fetch.delete(`/campaigns/${campaignId}/`)
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useRemoveCampaign = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(removeCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns'])
      queryClient.refetchQueries(['campaigns'])
      message.success('Campaign has been deleted', 10)
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    removeCampaign: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

// Start campaign mutation
const startCampaign = async (campaignId: string) => {
  try {
    await fetch.put(`/campaigns/${campaignId}/`)
    return campaignId
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useStartCampaign = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(startCampaign, {
    onSuccess: (campaignId: string) => {
      queryClient.invalidateQueries(['campaigns', campaignId])
      queryClient.refetchQueries(['campaigns', campaignId])
      history.push(`/app/campaigns/${campaignId}/edit`)
      message.success('Campaign has been started', 10)
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    startCampaign: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

interface SendEmailProps {
  text: string
  address: string
  user: string
  email: string
}
// Send email mutation
const sendEmail = async ({ text, address, user, email }: SendEmailProps) => {
  try {
    const response = await fetch.post('/api/send/', { text, address, user, email })
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useSendEmail = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(sendEmail, {
    onSuccess: (data) => {
      message.info(JSON.stringify(data), 10)
    },
    onError: (error: any) => {
      message.error(JSON.stringify(error.message), 10)
      console.log(error)
    },
  })

  return {
    sendEmail: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
