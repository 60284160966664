import React from 'react'

type CustomTooltipProps = {
  active?: boolean
  payload?: { payload: DayData }[] | null
}

type DayData = {
  day: string
  sent: number
  replied: number
  opened: number
  clicked: number
  bounced: number
  unsubscribed: number
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload
    return (
      <div style={styles.tooltipContainer}>
        <p style={styles.title}>{`${data.day}`}</p>
        <p style={styles.item}>{`Sent: ${data.sent}`}</p>
        <p style={styles.item}>{`Opened: ${data.opened}`}</p>
        <p style={styles.item}>{`Replied: ${data.replied}`}</p>
        <p style={styles.item}>{`Clicked: ${data.clicked}`}</p>
        <p style={styles.item}>{`Bounced: ${data.bounced}`}</p>
        <p style={styles.item}>{`Unsubscribed: ${data.unsubscribed}`}</p>
      </div>
    )
  }
  return null
}

export default CustomTooltip

const styles = {
  tooltipContainer: {
    backgroundColor: '#ffffff',
    padding: '10px 15px',
    border: '1px solid #cccccc',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  },
  title: {
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold' as 'bold',
  },
  item: {
    fontSize: '12px',
    marginBottom: '5px',
  },
}
