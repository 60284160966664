import { handleActions } from 'redux-actions'
import { message } from 'antd'
import history from '../router/history'
import fetch from '../services/fetch'
import logger from '../services/errorlogger'

import { actions as UserActions } from './UserReducer'

export const actionTypes = {
  USER_LOGOUT: 'USER_LOGOUT',
  // TODO: add reset state on USER_LOGOUT to all reducers
  INVOICES_LOADING: 'INVOICES_LOADING',
  INVOICES_SUCCESS: 'INVOICES_SUCCESS',
  INVOICES_ERROR: 'INVOICES_ERROR',
  INVOICE_PAYMENT_LOADING: 'INVOICE_PAYMENT_LOADING',
  INVOICE_PAYMENT_SUCCESS: 'INVOICE_PAYMENT_SUCCESS',
  INVOICE_PAYMENT_ERROR: 'INVOICE_PAYMENT_ERROR',
  CANCEL_ACCOUNT_LOADING: 'CANCEL_ACCOUNT_LOADING',
  CANCEL_ACCOUNT_SUCCESS: 'CANCEL_ACCOUNT_SUCCESS',
  CANCEL_ACCOUNT_ERROR: 'CANCEL_ACCOUNT_ERROR',
  ABORT_CANCEL_ACCOUNT_LOADING: 'ABORT_CANCEL_ACCOUNT_LOADING',
  ABORT_CANCEL_ACCOUNT_SUCCESS: 'ABORT_CANCEL_ACCOUNT_SUCCESS',
  ABORT_CANCEL_ACCOUNT_ERROR: 'ABORT_CANCEL_ACCOUNT_ERROR',
  PAUSE_ACCOUNT_LOADING: 'PAUSE_ACCOUNT_LOADING',
  PAUSE_ACCOUNT_SUCCESS: 'PAUSE_ACCOUNT_SUCCESS',
  PAUSE_ACCOUNT_ERROR: 'PAUSE_ACCOUNT_ERROR',
}

export const actions = {
  fetchInvoices: () => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.INVOICES_LOADING })
      const result = await fetch.get(`/billing/details/invoices/`)
      const { invoices } = result.data
      dispatch({ type: actionTypes.INVOICES_SUCCESS, invoices })
    } catch (error: any) {
      console.log('error', error)
      logger.logError(error)
      dispatch({ type: actionTypes.INVOICES_ERROR })
    }
  },
  payInvoice: (invoiceId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.INVOICE_PAYMENT_LOADING })
      const result = await fetch.post(`/billing/charge/invoices/${invoiceId}/`)
      dispatch({ type: actionTypes.INVOICE_PAYMENT_SUCCESS, result })
      message.success('Payment Successful')
      dispatch(UserActions.getUserData()) // this does not fetch the update user data
    } catch (error: any) {
      message.error(error.message)
      console.log('error', error)
      logger.logError(error)
      dispatch({ type: actionTypes.INVOICE_PAYMENT_ERROR })
    }
  },
  cancelAccount: (reasons: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.CANCEL_ACCOUNT_LOADING })
      const result = await fetch.post(`/billing/charge/cancel/`, reasons)
      console.log(result)
      dispatch({ type: actionTypes.CANCEL_ACCOUNT_SUCCESS })
      dispatch(UserActions.getUserData())
      // history.push('/app/dashboard/')
    } catch (error: any) {
      console.log('error', error)
      logger.logError(error)
      dispatch({ type: actionTypes.CANCEL_ACCOUNT_ERROR })
    }
  },
  pauseAccount: (term: string, planId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.PAUSE_ACCOUNT_LOADING })
      const result = await fetch.post(`/billing/charge/pause/`, { term, planId })
      console.log(result)
      dispatch(UserActions.getUserData())
      dispatch({ type: actionTypes.PAUSE_ACCOUNT_SUCCESS })
      // history.push('/app/dashboard/');
    } catch (error: any) {
      console.log('error', error)
      logger.logError(error)
      dispatch({ type: actionTypes.PAUSE_ACCOUNT_ERROR })
    }
  },
  abortCancelAccount: () => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_LOADING })
      const result = await fetch.put(`/billing/charge/cancel/`)
      console.log(result)
      dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_SUCCESS })
      dispatch(UserActions.getUserData())
      // history.push('/app/dashboard/')
    } catch (error: any) {
      console.log('error', error)
      logger.logError(error)
      dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_ERROR })
    }
  },
}

export interface BillingState {
  invoices: any[]
  invoicesLoading: boolean
  invoicesSuccess: boolean
  invoicesError: boolean
  cancelAccountLoading: boolean
  cancelAccountError: boolean
  cancelAccountSuccess: boolean
  abortCancelAccountLoading: boolean
  abortCancelAccountError: boolean
  abortCancelAccountSuccess: boolean
  pauseAccountLoading: boolean
  pauseAccountError: boolean
  pauseAccountSuccess: boolean
}

export const getDefaultState = (): BillingState => ({
  invoices: [],
  invoicesLoading: false,
  invoicesSuccess: false,
  invoicesError: false,

  cancelAccountLoading: false,
  cancelAccountError: false,
  cancelAccountSuccess: false,

  abortCancelAccountLoading: false,
  abortCancelAccountError: false,
  abortCancelAccountSuccess: false,

  pauseAccountLoading: false,
  pauseAccountError: false,
  pauseAccountSuccess: false,
})

export const BillingReducer = handleActions(
  {
    [actionTypes.USER_LOGOUT]: () => ({ ...getDefaultState() }),
    [actionTypes.INVOICES_LOADING]: (state) => ({
      ...state,
      invoicesLoading: true,
      invoicesError: false,
      invoicesSuccess: false,
    }),
    [actionTypes.INVOICES_SUCCESS]: (state, { invoices }: any) => {
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: true,
        invoicesSuccess: false,
        invoices,
      }
    },
    [actionTypes.INVOICES_ERROR]: (state) => {
      return { ...state, invoicesLoading: false, invoicesError: true, invoicesSuccess: false }
    },
    [actionTypes.INVOICE_PAYMENT_LOADING]: (state) => ({
      ...state,
      invoicePaymentLoading: true,
      invoicePaymentError: false,
      invoicePaymentSuccess: false,
    }),
    [actionTypes.INVOICE_PAYMENT_SUCCESS]: (state) => {
      return {
        ...state,
        invoicePaymentLoading: false,
        invoicePaymentError: true,
        invoicePaymentSuccess: false,
      }
    },
    [actionTypes.INVOICE_PAYMENT_ERROR]: (state) => {
      return {
        ...state,
        invoicePaymentLoading: false,
        invoicePaymentError: true,
        invoicePaymentSuccess: false,
      }
    },
    [actionTypes.CANCEL_ACCOUNT_LOADING]: (state) => ({
      ...state,
      cancelAccountLoading: true,
      cancelAccountError: false,
      cancelAccountSuccess: false,
    }),
    [actionTypes.CANCEL_ACCOUNT_SUCCESS]: (state) => ({
      ...state,
      cancelAccountLoading: false,
      cancelAccountError: false,
      cancelAccountSuccess: true,
    }),
    [actionTypes.CANCEL_ACCOUNT_ERROR]: (state) => ({
      ...state,
      cancelAccountLoading: false,
      cancelAccountError: true,
      cancelAccountSuccess: false,
    }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_LOADING]: (state) => ({
      ...state,
      abortCancelAccountLoading: true,
      abortCancelAccountError: false,
      abortCancelAccountSuccess: false,
    }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_SUCCESS]: (state) => ({
      ...state,
      abortCancelAccountLoading: false,
      abortCancelAccountError: false,
      abortCancelAccountSuccess: true,
    }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_ERROR]: (state) => ({
      ...state,
      abortCancelAccountLoading: false,
      abortCancelAccountError: true,
      abortCancelAccountSuccess: false,
    }),
    [actionTypes.PAUSE_ACCOUNT_LOADING]: (state) => ({
      ...state,
      pauseAccountLoading: true,
      pauseAccountError: false,
      pauseAccountSuccess: false,
    }),
    [actionTypes.PAUSE_ACCOUNT_SUCCESS]: (state) => ({
      ...state,
      pauseAccountLoading: false,
      pauseAccountError: false,
      pauseAccountSuccess: true,
    }),
    [actionTypes.PAUSE_ACCOUNT_ERROR]: (state) => ({
      ...state,
      pauseAccountLoading: false,
      pauseAccountError: true,
      pauseAccountSuccess: false,
    }),
  },
  getDefaultState()
)
