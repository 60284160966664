import { Button, Drawer, Spin } from 'antd'
import { useFetchProspect, useFetchProspects } from '../../api/prospectsQueries'
import ProspectEditForm from './ProspectEditForm'
import { useFetchCustomProperties } from '../../api/customPropsQueries'
import { useUpdateProspect } from '../../api/prospectsMutations'
import { useState } from 'react'
import AddVariableModal from '../AddVariableModal/AddVariableModal'

interface Props {
  prospectId: string | undefined
  onClose: () => void
}

const ProspectEditorContainer: React.FC<Props> = ({ prospectId, onClose }) => {
  const [isAddVariableModalOpen, setAddVariableModalOpen] = useState<boolean>(false)

  const { prospect, isLoading, isError } = useFetchProspect(prospectId)

  const { properties, isLoading: fetchPropsLoading } = useFetchCustomProperties()
  const { updateProspect, isLoading: updateProspectLoading } = useUpdateProspect()

  const onUpdateProspect = (values: any) => {
    if (!prospectId) return
    updateProspect({ prospectId, prospectProps: { properties: values } })
  }

  const loading = fetchPropsLoading || isLoading

  return (
    <Drawer
      title="Prospect Editor"
      onClose={onClose}
      keyboard={false}
      open={Boolean(prospectId)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
      width={550}
      style={{ maxWidth: 550, margin: '0 auto' }}
    >
      {loading && <Spin />}
      {!loading && (
        <ProspectEditForm
          prospect={prospect}
          properties={properties}
          onUpdateProspect={onUpdateProspect}
          onAddCustomProperty={() => setAddVariableModalOpen(true)}
        />
      )}
      <AddVariableModal
        isOpen={isAddVariableModalOpen}
        onClose={() => setAddVariableModalOpen(false)}
      />
    </Drawer>
  )
}

export default ProspectEditorContainer
