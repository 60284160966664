import { useState } from 'react'
import { useGenerateIcebreaker } from '../api/icebreakerMutations'
import { Alert, Button, Card, Input, Space, Spin, Typography } from 'antd'

const { Title, Text } = Typography

const SingleIBToolContainer = () => {
  const [websiteUrl, setWebsiteUrl] = useState<string>('')
  const { generateIcebreaker, icebreaker, isLoading, isError } = useGenerateIcebreaker()

  const onGenerate = async () => {
    if (!websiteUrl) return
    generateIcebreaker(websiteUrl)
  }

  return (
    <Space direction="vertical" style={{ width: '100%', padding: '24px' }}>
      <Title level={2}>Generate Icebreaker</Title>
      <Text type="secondary">Enter the website URL below to generate an icebreaker.</Text>
      <Input
        placeholder="Website URL"
        value={websiteUrl}
        onChange={(e) => setWebsiteUrl(e.target.value)}
        style={{ width: '400px' }}
      />
      <Button type="primary" onClick={onGenerate} loading={isLoading} disabled={!websiteUrl}>
        Generate
      </Button>
      <>
        <Text strong>Icebreaker:</Text>
        {isError && (
          <Alert message="There has been an error creating an icebreaker for this website" />
        )}
        {isLoading && <Spin />}
        {icebreaker?.map((i: string) => (
          <Card>{i}</Card>
        ))}
      </>
    </Space>
  )
}

export default SingleIBToolContainer
