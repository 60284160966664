import { useMutation, useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'

const fetchInvoices = async () => {
  const { data: invoices } = await fetch.get(`/billing/manage/invoices/`)
  return invoices
}

export const useFetchInvoices = () => {
  const { isLoading, isError, isSuccess, error, data } = useQuery(
    ['invoices'],
    () => fetchInvoices(),
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
      onSuccess: () => {},
    }
  )

  return {
    invoices: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const payInvoice = async (invoiceId: string) => {
  const result = await fetch.post(`/billing/charge/invoices/${invoiceId}/`)
  message.success('Payment Successful')
  return result
}

export const usePayInvoice = () => {
  const { mutate, isLoading, isError, isSuccess, error, data } = useMutation(
    (invoiceId: string) => payInvoice(invoiceId),
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
      onSuccess: () => {
        message.success('Invoice payment successful.', 10)
      },
    }
  )

  return {
    result: data,
    payInvoice: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
