import { FC } from 'react'
import { Steps, Spin } from 'antd'
import { SequenceBuilder } from '../../Sequence'
import { Prospects } from '../../Prospects'
import Settings from './Settings/SettingsContainer'
import { Preview } from '../../Preview'
import CampaignCreatorHeader from './Header/CampaignCreatorHeader'
import { Campaign } from '@mailarrow/types'

interface Props {
  campaign?: Campaign
  currentStep: number
  onDeleteCampaign: () => void
  onStartCampaign: () => void
  onChangeStep: (step: number) => void
}

const CampaignCreator: FC<Props> = ({
  campaign,
  currentStep,
  onDeleteCampaign,
  onStartCampaign,
  onChangeStep,
}) => {
  if (!campaign) return <Spin />

  return (
    <div>
      <CampaignCreatorHeader
        campaign={campaign}
        currentStep={currentStep}
        onChangeStep={onChangeStep}
        onDeleteCampaign={onDeleteCampaign}
        onStartCampaign={onStartCampaign}
      />
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={onChangeStep}
        items={[
          { title: 'Sequence' },
          { title: 'Prospects' },
          { title: 'Settings' },
          { title: 'Preview' },
        ]}
        style={{ margin: '5px 0 20px 0', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}
      />
      {currentStep === 0 && <SequenceBuilder />}
      {currentStep === 1 && <Prospects campaignId={campaign.campaignId} />}
      {currentStep === 2 && <Settings campaignId={campaign.campaignId} />}
      {currentStep === 3 && <Preview />}
    </div>
  )
}
export default CampaignCreator
