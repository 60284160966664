import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useFetchMailboxes } from '../api/mailboxesQueries'
import {
  useRemoveMailbox,
  useSendTestEmail,
  useTestMailboxConnection,
  useUpdateMailbox,
} from '../api/mailboxesMutations'
import EditMailbox from './EditMailbox'
import { Spin, message } from 'antd'
import history from '../../../router/history'
import { Mailbox } from '@mailarrow/types'
import { useFetchSendError } from '../../Errors'

const EditMailboxContainer: React.FC = () => {
  const [allowRedirect, setRedirectAllowed] = useState(false)
  const { mailboxId } = useParams<{ mailboxId: string }>()
  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()
  const {
    removeMailbox,
    isLoading: removeMailboxLoading,
    isSuccess: removeMailboxSuccess,
    isError: removeMailboxError,
  } = useRemoveMailbox()
  const {
    updateMailbox,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateMailbox()
  const { sendTestEmail, isLoading: sendTestEmailLoading } = useSendTestEmail()
  const {
    testMailboxConnection,
    reset: resetTestMailboxConnection,
    isSuccess: isTestMailboxSuccess,
  } = useTestMailboxConnection()

  useEffect(() => {
    if (isTestMailboxSuccess) resetTestMailboxConnection()
  }, [isTestMailboxSuccess, resetTestMailboxConnection])

  const { sendErrors, isLoading } = useFetchSendError({ mailboxId })

  const onCheckMailboxStatus = (mailboxId: string) => {
    testMailboxConnection({ mailboxId })
  }

  const onSendTextEmail = (mailboxId: string) => {
    sendTestEmail({ mailboxId })
  }

  const mailbox = mailboxes?.find((m) => m.mailboxId === mailboxId)

  const onUpdateMailbox = (mailboxId: string, mailboxProps: Partial<Mailbox>) => {
    updateMailbox({ mailboxId: mailboxId, mailboxProps })
  }

  const onRemoveMailbox = async (id: string) => {
    removeMailbox(id)
    setRedirectAllowed(true)
  }

  // redirect after success remove
  React.useEffect(() => {
    if (!allowRedirect) return
    if (removeMailboxSuccess) {
      message.success('Mailbox has been removed')
      return history.push('/app/settings/mailboxes')
    }
    if (removeMailboxError) {
      setRedirectAllowed(false)
      return message.error('Something went wrong while updating')
    }
  }, [allowRedirect, removeMailboxSuccess, removeMailboxError])

  return (
    <EditMailbox
      mailbox={mailbox}
      sendErrorCount={sendErrors?.length}
      loading={fetchMailboxesLoading}
      updateMailbox={onUpdateMailbox}
      updateLoading={isUpdateLoading}
      removeMailboxLoading={removeMailboxLoading}
      removeMailbox={onRemoveMailbox}
      sendTestEmailLoading={sendTestEmailLoading}
      sendTestEmail={onSendTextEmail}
      checkMailboxStatusLoading={isTestMailboxSuccess}
      onCheckMailboxStatus={onCheckMailboxStatus}
    />
  )
}

export default EditMailboxContainer
