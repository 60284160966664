import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'

export interface FilterEmailSettings {
  emailId?: string
  campaignId?: string
}

export interface PaginationSettings {
  page?: number
  pageSize?: number
}

export interface FetchEmailProps extends FilterEmailSettings, PaginationSettings {}

export const fetchEmails = async (config: FetchEmailProps) => {
  const { emailId, campaignId, page, pageSize } = config
  const params = new URLSearchParams()
  if (campaignId) {
    params.append('campaignId', campaignId)
  }
  if (emailId) {
    params.append('emailId', emailId)
  }
  if (page) {
    params.append('page', page.toString())
  }
  if (pageSize) {
    params.append('pageSize', pageSize.toString())
  }
  const response = await fetch.get(`/emails/?${params}`)
  const { docs, totalDocs, page: currentPage } = response.data
  return {
    emails: docs as any[],
    total: totalDocs as number,
    page: currentPage,
  }
}

export const useFetchEmail = (filter: FilterEmailSettings, pagination?: PaginationSettings) => {
  const { isLoading, isError, error, data, isFetching, isPreviousData, isSuccess, refetch } =
    useQuery(['emails', filter, pagination], () => fetchEmails({ ...filter, ...pagination }), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    })

  return {
    emails: data?.emails,
    total: data?.total,
    isLoading,
    isFetching,
    isPreviousData,
    isError,
    isSuccess,
    error,
    refetch,
  }
}
