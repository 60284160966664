import React, { useState } from 'react'
import { Layout, Button, Dropdown, Badge } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/store'
import { actions as UserActions } from '../../store/UserReducer'

export const ImpersonateUserNotice = () => {
  const { impersonateUserId } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  if (!impersonateUserId) return null

  return (
    <div
      style={{
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'antiquewhite',
      }}
    >
      <div>
        Impersonating:
        <span style={{ marginLeft: 10 }}> {impersonateUserId}</span>
      </div>
      <Button
        type="text"
        title="Stop"
        style={{ marginLeft: 20 }}
        onClick={() => dispatch(UserActions.monitorImpersonateOff())}
        icon={<CloseCircleOutlined />}
      >
        exit
      </Button>
    </div>
  )
}
