import React, { useState } from 'react'
import { Select, Button, Table, Tag, Space, Divider, Alert } from 'antd'
import { getExampleData } from './get-example'
import { truncate } from 'lodash'
import { LeftOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons'

const { Option } = Select

interface Props {
  data: string[][]
  customProperties: any[]
  mapping: Record<number, string>
  setMapping: (setter: any) => void
  onAddVariable: () => void
  onPrevStep: () => void
  onNextStep: () => void
}

const MatchColumns: React.FC<Props> = ({
  customProperties,
  data,
  mapping,
  setMapping,
  onPrevStep,
  onNextStep,
  onAddVariable,
}) => {
  const selectedVariables = Object.values(mapping)
  const hasSelectedEmail = selectedVariables.some((k: string) => k === 'email')

  const header = data[0]

  const examplesPerCol = 5
  const exampleData = getExampleData(data, examplesPerCol)

  const getSelectedSlug = (index: number): string => {
    const keys = Object.keys(mapping)
    if (!keys.length) return ''

    return mapping[index] || ''
  }

  const handleChange = (columnIndex: number, customProperty: string) => {
    setMapping((prevMapping: any) => {
      const newMapping = { ...prevMapping, [columnIndex]: customProperty }
      return newMapping
    })
  }

  const columns = [
    {
      title: 'CSV Column',
      dataIndex: 'csvColumn',
      key: 'csvColumn',
      render: (csvColumn: string) => {
        return <div style={{ maxWidth: 100 }}>{csvColumn}</div>
      },
    },
    {
      title: 'Mailarrow Variable',
      dataIndex: 'dataModelKey',
      key: 'dataModelKey',
      render: (text: string, record: any, index: number) => (
        <Select
          style={{ width: 200, fontSize: 12 }}
          dropdownStyle={{ fontSize: 12 }}
          size="small"
          onChange={(customProperty: string) => handleChange(index, customProperty)}
          placeholder="Select a data model key"
          value={getSelectedSlug(index)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="default"
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={onAddVariable}
                  style={{ fontSize: 13 }}
                >
                  Add new variable
                </Button>
              </Space>
            </>
          )}
        >
          {customProperties.map((property, index) => (
            <Option
              key={`${property.slug}${index}`}
              value={property.slug}
              disabled={selectedVariables.includes(property.slug)}
              style={{ fontSize: 12 }}
            >
              {property.slug === 'email' ? `${property.name} (required)` : property.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Examples',
      dataIndex: 'exampleData',
      key: 'exampleData',
      render: (example: string[]) => {
        return (
          <div>
            {example?.map((c) => (
              <Tag style={{ fontSize: 11 }}>{truncate(c, { length: 60 })}</Tag>
            ))}
          </div>
        )
      },
    },
  ]

  const dataSource = header.map((columnKey: string, index: number) => ({
    key: `${columnKey}${index}`,
    csvColumn: columnKey,
    dataModelKey: '',
    exampleData: exampleData[columnKey],
  }))

  return (
    <div>
      <div style={{ marginBottom: 10, fontSize: 13 }}>
        Use the table below to match columns from CSV file to prospect variables inside of
        Mailarrow:
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered={false}
        size="small"
        className="borderless-table"
      />
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onPrevStep} style={{ marginTop: 16 }} icon={<LeftOutlined />}>
          Back
        </Button>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          {!hasSelectedEmail && (
            <Alert
              message="Please match email to continue"
              style={{ height: 30, marginRight: 10 }}
            />
          )}
          <Button onClick={onNextStep} style={{ marginTop: 16 }} disabled={!hasSelectedEmail}>
            Continue <RightOutlined style={{ fontSize: 12 }} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MatchColumns
