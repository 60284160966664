import { useMutation, useQueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import fetch from '../../../services/fetch'

const generateIcebreaker = async (websiteUrl: string) => {
  const response = await fetch.post('/generate/icebreaker/', { websiteUrl })
  return response.data
}

export const useGenerateIcebreaker = () => {
  const queryClient = useQueryClient()

  const { mutate, data, isLoading, isError, isSuccess, error } = useMutation(generateIcebreaker, {
    onSuccess: (data) => {
      queryClient.setQueryData(['icebreaker', data.websiteUrl], data)
      message.success('Icebreaker generated successfully!', 3)
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    generateIcebreaker: mutate,
    icebreaker: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const genProspectsIcebreakers = async (prospectIds: string[]) => {
  const response = await fetch.post('/generate/icebreaker/prospects/', { prospectIds })
  return response.data
}

export const useGenProspectsIcebreakers = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: { prospectIds: string[] }) => genProspectsIcebreakers(args.prospectIds),
    {
      onSuccess: () => {
        message.success('Icebreakers started generating', 3)
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    genProspectsIcebreakers: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
