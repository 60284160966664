import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { WindowsOutlined } from '@ant-design/icons'
import * as uuid from 'uuid'

import history from '../../../../../router/history'
import { generateCodeChallenge, generateCodeVerifier } from './util'
import { getBaseUrl } from '../../../../../store/environment'

interface Props {
  open: boolean
  mailboxId?: string
  onClose: () => void
}

const clientId = '87f2ba1e-7888-4887-8ca7-a1b8a757da38'
const redirectUri = `${getBaseUrl()}app/settings/mailboxes/callback/outlook`

const AddExchangeMailbox: React.FC<Props> = ({ open, onClose, mailboxId }) => {
  let [url, setUrl] = React.useState('')

  useEffect(() => {
    const createUrl = async () => {
      const clientStateToken = uuid.v1()
      const codeVerifier = generateCodeVerifier(100, clientStateToken)
      const codeChallenge = await generateCodeChallenge(codeVerifier)

      const scopes = [
        'email',
        'IMAP.AccessAsUser.All',
        'Mail.ReadWrite',
        'Mail.Send',
        'MailboxSettings.ReadWrite',
        'offline_access',
        'openid',
        'POP.AccessAsUser.All',
        'profile',
        'SMTP.Send',
        'User.Read',
      ]

      const state = new URLSearchParams({
        clientStateToken,
      })
      if (mailboxId) {
        state.append('mailboxId', mailboxId)
      }

      const params = new URLSearchParams({
        client_id: clientId,
        response_type: 'code',
        redirect_uri: redirectUri,
        scope: scopes.join(' '),
        response_mode: 'query',
        state: state.toString(),
        code_challenge: `${codeChallenge}`,
        code_verifier: codeVerifier,
        code_challenge_method: 'S256',
      })

      const urlOne = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`
      setUrl(urlOne)
    }
    createUrl()
  }, [mailboxId])

  return (
    <Modal
      title="Add Exchange Mailbox Account"
      open={open}
      onCancel={onClose}
      footer={null}
      style={{ textAlign: 'center' }}
    >
      <Button
        onClick={() => history.push(url)}
        style={{ marginTop: 20 }}
        icon={<WindowsOutlined />}
      >
        Authenticate with Exchange
      </Button>
      <br />
    </Modal>
  )
}

export default AddExchangeMailbox
