import React, { useState, FC, useMemo, useEffect } from 'react'
import { Modal, Spin, TablePaginationConfig } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useFetchJourneys } from './api/journeysQueries'
import JourneysTable from './components/JourneysTable'
import { useFetchMailboxes } from '../Mailboxes'
import ProspectEditorContainer from '../Prospects/components/ProspectEditor/ProspectEditorContainer'
import { useRemoveProspects, useUpdateProspect } from '../Prospects'
import JourneysTableToolbar from './components/JourneysTableToolbar'
import AddProspectsManual from '../Prospects/components/AddProspectManual/AddProspectManual'
import AddProspectsCSV from '../Prospects/components/AddProspectsCSV/AddProspectsCSV'
import AddExistingProspects from '../Prospects/components/AddExistingProspects/AddExistingProspects'
import AddVariableModal from '../Prospects/components/AddVariableModal/AddVariableModal'

interface Props {
  campaignId?: string
}

const JourneysTableContainer: React.FC<Props> = ({ campaignId }) => {
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<Record<string, any>>({})
  const [isImportExistingsOpen, setImportExistingOpen] = useState<boolean>(false)
  const [isImportCSVOpen, setImportCSVOpen] = useState<boolean>(false)
  const [isAddManualOpen, setAddManualOpen] = useState<boolean>(false)
  const [isAddVariableModalOpen, setAddVariableModalOpen] = useState<boolean>(false)

  const [selectedProspects, setSelectedProspects] = useState<string[]>([])
  const [modal, contextHolder] = Modal.useModal()
  const [editProspectId, setEditProspectId] = useState<string | undefined>(undefined)
  const [currentPage, setJourneysPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()

  const { updateProspect, isLoading: updateProspectLoading } = useUpdateProspect()
  const {
    removeProspects,
    isLoading: isRemoveProspectsLoading,
    isSuccess: isRemoveProspectsSuccess,
  } = useRemoveProspects()
  const { journeys, total, isFetching, isLoading, isSuccess, isError, error } = useFetchJourneys(
    { search: searchFilter, campaignId, ...statusFilter },
    { page: currentPage, pageSize }
  )

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const currentPage = pagination.current || 1
    setJourneysPage(currentPage)
    pagination.pageSize && pagination.pageSize !== pageSize && setPageSize(pagination.pageSize)
    setSelectedProspects([])
  }

  const onEditProspect = (journeyId: string) => {
    setEditProspectId(journeyId)
  }

  const onPauseProspect = (prospectId: string, isPaused: boolean) => {
    updateProspect({ prospectId, prospectProps: { isPaused } })
  }

  const onUnsubscribeProspect = (prospectId: string, isUnsubscribed: boolean) => {
    updateProspect({ prospectId, prospectProps: { isUnsubscribed } })
  }

  const onRemoveProspects = (prospectIds: string[]) => {
    if (!prospectIds || !prospectIds.length) return

    removeProspects({ campaignId, prospectIds })
    setSelectedProspects([])
  }

  const onRemoveProspectClick = (prospectId: string) => {
    modal.confirm({
      title: 'Confirm removing the prospect',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ fontSize: 13 }}>
          Prospect will be detached from this campaign but remain in the database. For full removal,
          go to the Prospects page.
        </span>
      ),
      okText: 'Remove from campaign',
      onOk: () => onRemoveProspects([prospectId]),
      cancelText: 'Cancel',
    })
  }

  return (
    <>
      <JourneysTableToolbar
        setSearchFilter={setSearchFilter}
        setImportCSVOpen={setImportCSVOpen}
        setAddManualOpen={setAddManualOpen}
        setImportExistingOpen={setImportExistingOpen}
        onRemoveProspects={onRemoveProspects}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        isRemoveProspectsLoading={isRemoveProspectsLoading}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      <JourneysTable
        isLoading={
          isLoading ||
          isFetching ||
          updateProspectLoading ||
          isRemoveProspectsLoading ||
          fetchMailboxesLoading
        }
        prospects={journeys}
        totalProspects={total || 0}
        onTableChange={handleTableChange}
        currentPage={currentPage}
        pageSize={pageSize}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        mailboxes={mailboxes}
        onEditProspect={onEditProspect}
        onPauseProspect={onPauseProspect}
        onUnsubscribeProspect={onUnsubscribeProspect}
        onRemoveProspect={onRemoveProspectClick}
      />
      <ProspectEditorContainer
        prospectId={editProspectId}
        onClose={() => setEditProspectId(undefined)}
      />
      <AddProspectsManual
        isOpen={isAddManualOpen}
        onClose={() => setAddManualOpen(false)}
        campaignId={campaignId}
      />
      <AddProspectsCSV
        isOpen={isImportCSVOpen}
        onClose={() => setImportCSVOpen(false)}
        campaignId={campaignId}
        onAddVariable={() => setAddVariableModalOpen(true)}
      />
      <AddExistingProspects
        isOpen={isImportExistingsOpen}
        onClose={() => setImportExistingOpen(false)}
        campaignId={campaignId}
      />
      <AddVariableModal
        isOpen={isAddVariableModalOpen}
        onClose={() => setAddVariableModalOpen(false)}
      />
      {contextHolder}
    </>
  )
}

export default JourneysTableContainer
