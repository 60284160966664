import React, { useEffect, useRef } from 'react'
import { Layout, Menu, Typography } from 'antd'

import { getNavigationItems } from './NavItems'
import { Link, useLocation, useParams } from 'react-router-dom'
import history from '../../router/history'
import useAuthState from '../../modules/Auth/use-auth-state'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { usePermissions } from '../../modules/User/Permissions'

import './Sidebar.scss'
import Logo from '../Logo/Logo'
import { SidebarFooter } from './SIdebarFooter'

interface SidebarProps {
  collapsed: boolean
}

const { Sider } = Layout
const { Title } = Typography

const getActiveId = (menu: any, path: string): string[] => {
  return menu.reduce((acc: string[], item: any) => {
    if (path.includes(item.key)) {
      acc.push(item.key)
    }
    if (item.children) {
      acc = [...acc, ...getActiveId(item.children, path)]
    }
    return acc
  }, [])
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed }) => {
  const permissions = usePermissions()
  const location = useLocation()
  const { user } = useSelector((state: RootState) => state.user)
  const navigationItems = getNavigationItems(history.push, permissions, user)
  const selectedKeys = getActiveId(navigationItems, location.pathname)

  const ref = useRef()

  const { isLoggedIn } = useAuthState()

  const hideSidebar = !isLoggedIn || !user?.isVerified

  useEffect(() => {
    if (hideSidebar) {
      document.body.classList.remove('has-sidebar')
    } else {
      document.body.classList.add('has-sidebar')
    }
  }, [hideSidebar])

  useEffect(() => {
    return () => {
      if (ref.current) return
      document.body.classList.remove('has-sidebar')
    }
  }, [])

  if (hideSidebar) return null

  const logoTextHeaderItems = [
    {
      key: '22',
      icon: (
        <Logo
          style={{
            marginRight: '7.5px',
            color: 'black',
            marginBottom: 5,
            // transform: 'rotate(90deg)',
            width: 40,
            ...(collapsed && { marginTop: '12.5px' }),
          }}
        />
      ),
      label: (
        <Title level={4} style={{ marginBottom: 5, marginTop: 0 }}>
          mailarrow
        </Title>
      ),
      style: {
        borderBottom: '1px solid #e1e1e1',
        height: 52,
        borderRadius: 0,
        margin: '4px 0',
        width: '100%',
      },
      title: undefined,
    },
  ]

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="sidebar-container"
      width={230}
      style={{
        backgroundColor: '#fbfafa',
        overflowY: 'scroll',
        height: '100vh',
        position: 'fixed',
      }}
    >
      <div style={{ height: 'calc(100% - 50px)', borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
        <Menu
          mode="inline"
          selectable={false}
          items={logoTextHeaderItems}
          className="sidebar-menu-logo"
        />
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          items={navigationItems}
          className="sidebar-menu-main"
        />
      </div>
      {!collapsed && <SidebarFooter />}
    </Sider>
  )
}

export default Sidebar
