import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import useAuthState from './use-auth-state'

import { actions as authActions } from './reducer'

interface Props {
  children: React.ReactNode;
}

function AuthenticationMonitor(props: Props) {
  const dispatch = useDispatch()
  const [isMonitoring, setIsMonitoring] = useState(false)

  useEffect(() => {
    if (isMonitoring) return undefined
    dispatch(authActions.monitorLogin())
    setIsMonitoring(true)
  }, [dispatch, isMonitoring])

  const { isLoading } = useAuthState()

  if (isLoading) {
    return (
      <span style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
        <Spin
          type="large"
          indicator={<LoadingOutlined style={{ fontSize: 40, marginBottom: 20 }} spin />}
          tip={<span style={{ fontSize: 15, fontWeight: 600 }}>Loading</span>}
        />
      </span>
    )
  }

  return <>{props.children}</>
}

export default AuthenticationMonitor
