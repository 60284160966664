import React, { useEffect, useRef } from 'react'
import { Spin, Typography, Card } from 'antd'
import { useSelector } from 'react-redux'
import { useConfirmCharge } from '../api/paymentQueries'

const { Title } = Typography

const PaymentConfirmation = () => {
  const { confirmCharge } = useConfirmCharge()
  const { user } = useSelector((state: any) => state.user)
  const ref = useRef()

  document.body.classList.add('full-screen')
  document.title = 'Payment Confirmation | Mailarrow App'

  useEffect(() => {
    confirmCharge()
    console.log('confirming charge')
  }, [user.userId, confirmCharge])

  useEffect(() => {
    return () => {
      if (ref.current) return
      document.body.classList.remove('full-screen')
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Card
        style={{ boxShadow: '0 2px 11px -4px rgba(0,23,62,0.2), 0 0 1px 0 #a8b9d5', marginTop: 10 }}
        bodyStyle={{ padding: '30px 28px' }}
      >
        <Title level={4}>Your payment is processing</Title>
        <div style={{ fontWeight: 600, marginBottom: 25, fontSize: 17 }}>
          <div style={{ fontSize: 15 }}>
            It might take a few seconds, please don't leave this page.
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Spin size="large" />
        </div>
      </Card>
    </div>
  )
}

export default PaymentConfirmation
