import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'

export const fetchSchedules = async (scheduleId?: string) => {
  if (!scheduleId) return undefined
  const { data: schedules } = await fetch.get(`/schedules/${scheduleId}/`)
  return _.reverse(schedules)
}

export const useFetchSchedule = (scheduleId?: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['schedules', scheduleId],
    () => fetchSchedules(scheduleId),
    {
      enabled: !!scheduleId,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    schedule: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
