import type { FC } from 'react'

import Settings from './components/Settings'
import { useFetchCampaigns } from '../../api/campaignQueries'
import { useEditCampaign } from '../../api/campaignsMutations'

interface Props {
  campaignId: string
}

const SettingsContainer: FC<Props> = ({ campaignId }) => {
  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()
  const campaign = campaigns?.find((c: any) => c.campaignId === campaignId)

  const { editCampaign, isLoading: editCampaignLoading } = useEditCampaign()

  if (!campaignId) return <></>

  const onEditCampaign = (props: any) => {
    editCampaign({ campaignId, campaignProps: props })
  }

  return (
    <Settings
      campaign={campaign}
      onEditCampaign={onEditCampaign}
      fetchLoading={fetchCampaignsLoading}
      editLoading={editCampaignLoading}
    />
  )
}

export default SettingsContainer
