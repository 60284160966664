import { FC, useState } from 'react'
import { Layout } from 'antd'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { Provider } from 'react-redux'
import classNames from 'classnames'
import { uniqueId } from 'lodash'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import store from './store/store'

import ProtectedRoute from './modules/Auth/ProtectedRoute'
import AuthenticationMonitor from './modules/Auth/AuthMonitor'

import Login from './pages/Auth/Login/Login'
import Logout from './pages/Auth/Logout'
import Signup from './pages/Auth/Signup/Signup'
import AccountInactive from './pages/Auth/AccountInactive'

import Pricing from './modules/Billing/Pricing/PricingContainer'
// import Checkout from './pages/Checkout/CheckoutContainer'
// import Payment from './pages/Checkout/Payment'

// import Onboarding from './pages/Onboarding/Onboarding'

import { Billing, Integrations, Profile, Team, Settings, General } from './pages/Settings'

import Header from './components/Header/Header'
import Sidebar from './components/Sidebar/Sidebar'

import Dashboard from './pages/Dashboard/Dashboard'
import { CampaignList, CampaignEditor, CampaignCreator } from './modules/Campaigns'
import { MailboxList } from './modules/Mailboxes'
import { ActivityPage } from './modules/Activity'

import { CustomRouter } from './router/router'
import customHistory from './router/history'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './store/queryClient'
import EditMailboxContainer from './modules/Mailboxes/EditMailbox/EditMailboxContainer'
import GmailRedirectHandler from './modules/Mailboxes/ConnectMailbox/mailboxes/gmail/GmailRedirectHandler'
import OutlookRedirectHandler from './modules/Mailboxes/ConnectMailbox/mailboxes/office/OutlookRedirectHandler'
import { ProspectsPage } from './modules/Prospects'

import './App.less'
import Tools from './modules/Tools/Tools'
import IcebreakerToolContainer from './modules/Icebreaker/IcebreakerTool/IcebreakerToolContainer'
import KWMapperContainer from './modules/Tools/Mapper/KwMapperContainer'
import PromptMakerContainer from './modules/Tools/PromptMaker/PromptMakerContainer'
import PaymentConfirmation from './modules/Billing/Payment/PaymentConfirmation'
import PaymentSuccess from './modules/Billing/Payment/PaymentSuccess'
import EmailVerification from './pages/Auth/Verify/EmailVerification'
import EmailVerified from './pages/Auth/Verify/EmailVerified'
import Monitor from './modules/Admin/Admin'
import { UserListMonitorCotnainer } from './modules/Admin/UserList/UserListMonitorContainer'
import { UserManagerMonitorCotnainer } from './modules/Admin/UserManager/UserManagerMonitorContainer'
import { JourneysMonitorContainer } from './modules/Admin/Journeys/JourneysMonitorContainer'
import { EmailsMonitorContainer } from './modules/Admin/Emails/EmailsMonitorContainer'
import OnboardingContainer from './modules/Onboarding/OnboardingContainer'
import { initiateCrisp } from './services/crisp'
import UnsubscribeContainer from './modules/Unsubscribe/UnsubscribeContainer'

const { Content } = Layout

const isProduction = process.env.NODE_ENV === 'production'

const App: FC = () => {
  const [collapsed, setCollapsed] = useState(false)

  // if (isProduction) initiateCrisp()
  initiateCrisp()
  if (localStorage && !localStorage.getItem('sessionId')) {
    localStorage.setItem('sessionId', uniqueId())
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        <div className="App">
          <CustomRouter history={customHistory}>
            <AuthenticationMonitor>
              <Layout style={{ minHeight: '100vh' }}>
                <Sidebar collapsed={collapsed} />
                <Layout
                  className={classNames('site-layout', {
                    'sidebar-collapsed': collapsed,
                    'sidebar-expanded': !collapsed,
                  })}
                >
                  <Header collapsed={collapsed} setCollapsed={setCollapsed} />
                  <Content className="app-canvas">
                    <Routes>
                      {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/sign-up" element={<Signup />} />
                      <Route path="/verify/:userId/:emailVerifyId" element={<EmailVerified />} />
                      <Route path="/verify" element={<EmailVerification />} />
                      <Route path="/inactive" element={<AccountInactive />} />

                      <Route
                        path="/app/pricing"
                        element={
                          <ProtectedRoute>
                            <Pricing />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/app/payment/confirm"
                        element={
                          <ProtectedRoute>
                            <PaymentConfirmation />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/app/payment/success"
                        element={
                          <ProtectedRoute>
                            <PaymentSuccess />
                          </ProtectedRoute>
                        }
                      />
                      {/* <Route exact path="/app/checkout" component={Checkout} /> */}

                      {/* {/* <PrivateRoute path="/app/dashboard" exact component={Dashboard} /> */}
                      {/* <PrivateRoute path="/app/getting-started" exact component={Onboarding} /> */}

                      <Route
                        path="/app/onboarding/"
                        element={
                          <ProtectedRoute>
                            <OnboardingContainer />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/app/dashboard/"
                        element={
                          <ProtectedRoute>
                            <Dashboard />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/app/campaigns/"
                        element={
                          <ProtectedRoute>
                            <CampaignList />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/app/campaigns/:campaignId/edit/:step?"
                        element={
                          <ProtectedRoute>
                            <CampaignEditor />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/app/campaigns/:campaignId/new/:step?"
                        element={
                          <ProtectedRoute>
                            <CampaignCreator />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/app/activity"
                        element={<ProtectedRoute>{<ActivityPage />}</ProtectedRoute>}
                      />

                      <Route
                        path="/app/prospects"
                        element={
                          <ProtectedRoute>
                            <Outlet />
                          </ProtectedRoute>
                        }
                      >
                        <Route path="all" element={<ProspectsPage />} />
                        <Route path="unsubscribes" element={<UnsubscribeContainer />} />
                      </Route>

                      <Route
                        path="/app/tools"
                        element={
                          <ProtectedRoute>
                            <Tools />
                          </ProtectedRoute>
                        }
                      >
                        <Route path="smoothliner" element={<IcebreakerToolContainer />} />
                        <Route path="kwmapper" element={<KWMapperContainer />} />
                        <Route path="content-prompts" element={<PromptMakerContainer />} />
                      </Route>

                      <Route
                        path="/app/monitor"
                        element={
                          <ProtectedRoute>
                            <Monitor />
                          </ProtectedRoute>
                        }
                      >
                        <Route path="userlist" element={<UserListMonitorCotnainer />} />
                        <Route path="usermanager" element={<UserManagerMonitorCotnainer />} />
                        <Route path="journeys" element={<JourneysMonitorContainer />} />
                        <Route path="emails" element={<EmailsMonitorContainer />} />
                      </Route>

                      <Route
                        path="/app/settings"
                        element={
                          <ProtectedRoute>
                            <Settings />
                          </ProtectedRoute>
                        }
                      >
                        <Route path="general" element={<General />} />
                        <Route path="mailboxes" element={<MailboxList />} />
                        <Route path="mailboxes/callback/gmail" element={<GmailRedirectHandler />} />
                        <Route
                          path="mailboxes/callback/outlook"
                          element={<OutlookRedirectHandler />}
                        />
                        <Route path="mailboxes/:mailboxId" element={<EditMailboxContainer />} />
                        <Route index path="profile" element={<Profile />} />
                        {/* <Route path="team" element={<Team />} /> */}
                        <Route path="billing" element={<Billing />} />
                        {/* <Route path="integrations" element={<Integrations />} /> */}
                      </Route>

                      <Route path="/" element={<Navigate to="/app/dashboard" replace />} />
                    </Routes>
                  </Content>
                </Layout>
              </Layout>
            </AuthenticationMonitor>
          </CustomRouter>
        </div>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
