import { Tag, Tooltip } from 'antd'
import { Mailbox } from '@mailarrow/types'

interface Props {
  mailboxes?: Mailbox[]
}

export const MailboxTag: React.FC<Props> = ({ mailboxes }) => {
  if (!mailboxes?.length) return <>N/A</>

  const mailboxEmails = mailboxes?.map((x) => x.email)
  const [firstEmail, ...rest] = mailboxEmails

  return (
    <>
      <Tag style={{ fontSize: 10 }}>{firstEmail}</Tag>
      {Boolean(rest?.length) && (
        <Tooltip title={<span style={{ fontSize: 12 }}>{rest.join(', ')}</span>}>
          <span
            style={{
              fontSize: 10,
              textDecoration: 'underline',
              textDecorationStyle: 'dashed',
              cursor: 'pointer',
              marginRight: 5,
            }}
            title="More mailboxes"
          >
            +{rest.length}
          </span>
        </Tooltip>
      )}
    </>
  )
}
