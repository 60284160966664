import { useMutation } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import logger from '../../../services/errorlogger'
import history from '../../../router/history'

const fetchConfirmation = async () => {
  const { data: result } = await fetch.get(`/billing/payment/confirm/`)
  if (result.chargeFinalized) {
    return result
  }
  throw new Error('Charge not finalized')
}

export const useConfirmCharge = () => {
  const { mutate, isLoading, isSuccess, isError, error, reset } = useMutation(fetchConfirmation, {
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log('confirm charge error', error)
      logger.logError(error)
    },
    onSuccess: (result) => {
      const { isTrial } = result
      const url = `/app/payment/success/?isTrial=${isTrial}`
      reset()
      history.push(url)
    },
  })

  const confirmCharge = () => {
    setTimeout(() => {
      mutate()
    }, 2500)
  }

  return { confirmCharge, isLoading, isSuccess, isError, error }
}
