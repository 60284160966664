export const spamWords = [
  'act',
  'additional',
  'apply',
  'bargain',
  'bonus',
  'buy',
  'cash',
  'congratulations',
  'double',
  'earn',
  'eliminate',
  'extra',
  'free',
  'full',
  'get',
  'hidden',
  'increase',
  'incredible',
  'join',
  'lose',
  'lowest',
  'make',
  'money',
  'no',
  'not',
  'password',
  'save',
  'see',
  'special',
  'unsecured',
  'weight',
  'while',
  'great',
  'spam',
  'friend',
  'sir',
  'life',
  'cost',
  'hello',
]

export const spamPhrases = [
  '100% free',
  '100% satisfied',
  'be your own boss',
  'big bucks',
  'billions of dollars',
  'cash bonus',
  'earn extra cash',
  'earn money',
  'eliminate bad credit',
  'extra income',
  'financial freedom',
  'free access',
  'free gift',
  'free hosting',
  'free info',
  'free investment',
  'free membership',
  'free preview',
  'free quote',
  'free trial',
  'full refund',
  'get out of debt',
  'giveaway',
  'increase sales',
  'increase traffic',
  'income from home',
  'lower rates',
  'make money',
  'money back',
  'no cost',
  'no fees',
  'save big money',
  'unsecured credit',
  'unsecured debt',
  'weight loss',
  'while supplies last',
  'will not believe your eyes',
]
