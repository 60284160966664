import { cloneDeep } from 'lodash'
import validator from 'validator'

interface Prospect {
  email?: string
  [key: string]: any
}

function sanitizeEmail(email: string): string {
  return (
    validator.normalizeEmail(email, {
      gmail_remove_subaddress: false,
      gmail_convert_googlemaildotcom: false,
      gmail_remove_dots: false,
      outlookdotcom_remove_subaddress: false,
      icloud_remove_subaddress: false,
    }) || ''
  )
}

function limitStringLength(input: string, maxLength: number): string {
  return input.length > maxLength ? input.slice(0, maxLength) : input
}

function sanitizeDefault(value: any, escape: boolean): any {
  if (typeof value === 'string') {
    // 1. Remove control characters (including newline and whitespace characters)
    let sanitized = validator.stripLow(value, true)

    // 2. Remove characters that have a Unicode value < 32 and > 127
    sanitized = validator.stripLow(sanitized, false)

    if (escape) {
      // 3. Remove HTML tags and encode characters with special meaning in HTML
      sanitized = validator.escape(sanitized)
    }

    // 4. Remove leading and trailing whitespaces
    sanitized = validator.trim(sanitized)

    // 5. Replace multiple whitespaces with a single whitespace
    sanitized = sanitized.replace(/\s\s+/g, ' ')

    // 7. Limit the length of the input string
    const maxLength = 500 // Set the maximum allowed length
    sanitized = limitStringLength(sanitized, maxLength)

    return sanitized
  }
  if (typeof value === 'number') {
    return value
  }
  return ''
}

const sanitizeObject =
  (escape: boolean) =>
  (obj: Prospect): Prospect => {
    const sanitizedObj: Prospect = cloneDeep(obj)

    for (const key in sanitizedObj) {
      if (key === 'email' && typeof sanitizedObj[key] === 'string') {
        sanitizedObj[key] = sanitizeEmail(sanitizedObj[key])
      } else {
        sanitizedObj[key] = sanitizeDefault(sanitizedObj[key], escape)
      }
    }

    return sanitizedObj
  }

export function sanitizeProspects(data: Prospect[], escape = true): Prospect[] {
  return data.map(sanitizeObject(escape))
}
