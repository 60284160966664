import React from 'react'
import { Button } from 'antd'
import ProspectCSVImporter from './ProspectsImportCSV'
import FileInfo from './FileInfo'
import { RightOutlined } from '@ant-design/icons'

interface Props {
  fileName: string
  data: string[][]
  onUploadComplete: (prospects: any, fileName: string) => void
  onNextStep: () => void
  onRemoveUpload: () => void
}

const FileUploadStep: React.FC<Props> = ({
  fileName,
  data,
  onNextStep,
  onUploadComplete,
  onRemoveUpload,
}) => {
  const hasUploaded = Boolean(data.length)

  return (
    <div style={{ height: 250 }}>
      {!hasUploaded && <ProspectCSVImporter onUploadComplete={onUploadComplete} />}
      {hasUploaded && <FileInfo fileName={fileName} data={data} onRemoveUpload={onRemoveUpload} />}
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onNextStep} disabled={!hasUploaded}>
          Continue <RightOutlined style={{ fontSize: 12 }} />
        </Button>
      </div>
    </div>
  )
}

export default FileUploadStep
