import React, { FC } from 'react'
import { Button, Typography } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { SegmentationForm } from './SegmentationForm' // Assume this is imported correctly

const { Title } = Typography

interface SegmentationQuizProps {
  onNextStep: (data: any) => void;
}

export const SegmentationQuiz: FC<SegmentationQuizProps> = ({ onNextStep }) => {
  return (
    <div>
      <Title level={4} style={{ marginBottom: 20, textAlign: 'center' }}>
        Introduce Yourself
      </Title>

      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'left',
        }}
      >
        <div style={{ width: 600 }}>
          <SegmentationForm onSave={onNextStep} />
        </div>
      </div>
    </div>
  )
}

export default SegmentationQuiz
