import { Form, Typography, FormInstance, Input } from 'antd'
import { Prospect } from '../../api/prospectsMutations'
import { useState } from 'react'
import { rules } from './formValidations'
import './ProspectFormItem.scss'

const { Text } = Typography

const getPropValue = (propertySlug: string, prospect?: Prospect): any => {
  return (prospect?.properties || {})[propertySlug]
}

interface Props {
  form: FormInstance
  slug: string
  properties?: any
  prospect: any
  hideHeader?: boolean
}

const ProspectFormItem: React.FC<Props> = ({ form, slug, properties, prospect, hideHeader }) => {
  const property = properties?.find((prop: any) => prop.slug === slug)

  const initialValue = getPropValue(property?.slug, prospect)
  const formValue = form.getFieldValue(property?.slug)
  const value = formValue || initialValue

  // this is used to control placeholder, which is not implemented in the component
  // so had to make my own
  const [isEdit, setIsEdit] = useState(false)

  const text = isEdit ? value : value || `Add ${property?.name}`
  const tooltipText = value ? `Edit ${property?.name}` : `Add ${property?.name}`

  if (!property) {
    console.log('property is missing', slug)
    // happens when the property is added in the backend but the user's migration has not been applied yet
    return null
  }

  return (
    <Form.Item
      label={hideHeader ? '' : property.name}
      className="prospect-form-item"
      name={property.slug}
      initialValue={value}
      rules={rules[slug]}
    >
      <Text
        placeholder={`Add ${property.name}`}
        className="prospect-form-item__text"
        editable={{
          editing: isEdit,
          onChange: async (newText: string) => {
            setIsEdit(false)
            if ((!value && !newText) || value === newText) {
              // do not save / submit when there is no text
              // and there was nothing here in the first place
              return
            }
            form.setFieldValue(property.slug, newText)
            // submit form on every change to save immidiately
            try {
              await form.validateFields()
              form.submit()
            } catch (error: any) {
              setIsEdit(true)
              // console.log(error)
              if (error.errorFileds?.length) {
                return
              }
            }
          },
          onStart: () => {
            // console.log('start', value, initialValue)
            setIsEdit(true)
          },
          onCancel: () => {
            // console.log('cancel', value, initialValue)
            setIsEdit(false)
            // form.setFieldValue(property.slug, initialValue)
          },
          onEnd: () => {
            // console.log('end', value, initialValue)
            // form.setFieldValue(property.slug, initialValue)
            setIsEdit(false)
          },
          tooltip: tooltipText,
          triggerType: ['icon', 'text'],
        }}
      >
        {text}
      </Text>
    </Form.Item>
  )
}

export default ProspectFormItem
