import React, { useState } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'

interface EmailsFilterProps {
  onFilter: (filters: any) => void
}

const EmailsFilter: React.FC<EmailsFilterProps> = ({ onFilter }) => {
  const [localFilters, setLocalFilters] = useState({
    prospectEmail: undefined as string | undefined,
    mailboxId: undefined as string | undefined,
    emailId: undefined as string | undefined,
    campaignId: undefined as string | undefined,
    userId: undefined as string | undefined,
    journeyId: undefined as string | undefined,
    status: undefined as string | undefined,
  })

  const handleFilterClick = () => {
    onFilter(localFilters)
  }

  return (
    <Form layout="inline">
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Form.Item label="Prospect Email">
            <Input
              style={{ width: 200 }}
              placeholder="Prospect Email"
              value={localFilters.prospectEmail}
              onChange={(e) => setLocalFilters({ ...localFilters, prospectEmail: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Mailbox ID">
            <Input
              style={{ width: 200 }}
              placeholder="Mailbox ID"
              value={localFilters.mailboxId}
              onChange={(e) => setLocalFilters({ ...localFilters, mailboxId: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Email ID">
            <Input
              style={{ width: 200 }}
              placeholder="Email ID"
              value={localFilters.emailId}
              onChange={(e) => setLocalFilters({ ...localFilters, emailId: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Campaign ID">
            <Input
              style={{ width: 200 }}
              placeholder="Campaign ID"
              value={localFilters.campaignId}
              onChange={(e) => setLocalFilters({ ...localFilters, campaignId: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="User ID">
            <Input
              style={{ width: 200 }}
              placeholder="User ID"
              value={localFilters.userId}
              onChange={(e) => setLocalFilters({ ...localFilters, userId: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Journey ID">
            <Input
              style={{ width: 200 }}
              placeholder="Journey ID"
              value={localFilters.journeyId}
              onChange={(e) => setLocalFilters({ ...localFilters, journeyId: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 10, width: '100%' }}>
        <Col span={4}>
          <Form.Item label="Status">
            <Select
              style={{ width: 200 }}
              placeholder="Select Status"
              value={localFilters.status}
              onChange={(value) => setLocalFilters({ ...localFilters, status: value })}
            >
              <Select.Option value="scheduled">Scheduled</Select.Option>
              <Select.Option value="sent">Sent</Select.Option>
              <Select.Option value="failed">Failed</Select.Option>
              <Select.Option value="sending">Sending</Select.Option>
              <Select.Option value="paused">Paused</Select.Option>
              <Select.Option value="cancelled">Cancelled</Select.Option>
              <Select.Option value="draft">Draft</Select.Option>
              <Select.Option value="">Empty</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col span={4}>
          <Button type="primary" onClick={handleFilterClick}>
            Filter
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default EmailsFilter
