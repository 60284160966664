import React, { useState } from 'react'
import { Progress, Tooltip, Spin, Typography } from 'antd'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import {
  calculateSubjectLength,
  calculateWordCount,
  calculatePersonalization,
  calculateSpamWords,
  calculateQuestionCount,
  calculateReadingTime,
} from './scoring'
import { SequenceStep } from '@mailarrow/types'

const { Title } = Typography

interface SpamScoreProps {
  steps: SequenceStep[]
  activeStepIndex: number
}

const SpamScore: React.FC<SpamScoreProps> = ({ steps, activeStepIndex }) => {
  const step = steps.find((step: any) => step.stepIndex === activeStepIndex)

  if (!step) return <Spin />

  const { body, subject } = step.variations[0]

  const subjectLength = calculateSubjectLength(subject)
  const wordCount = calculateWordCount(body)
  const personalization = calculatePersonalization(body)
  const spamWords = calculateSpamWords(body)
  const questionCount = calculateQuestionCount(body)
  const readingTime = calculateReadingTime(body)

  // TODO: give info to customer about recommended values in the UI
  // Define desirable ranges for each metric
  const desirableRanges = {
    subjectLength: { min: 20, max: 50 },
    wordCount: { min: 30, max: 75 },
    personalization: { min: 4, max: 8 },
    spamWords: { min: 0, max: 3 },
    questionCount: { min: 1, max: 2 },
    readingTime: { min: 3, max: 15 },
  }

  // Determine the color of each progress bar
  const getProgressBarColor = (value: number, min: number, max: number) => {
    return value >= min && value <= max ? '#87d068' : '#ff4d4f'
  }

  return (
    <div style={{ margin: '0 10px' }}>
      <Title level={5} style={{ textAlign: 'center' }}>
        Spam Score
      </Title>

      <span>Subject Length: {subjectLength}</span>
      <Progress
        strokeColor={getProgressBarColor(
          subjectLength,
          desirableRanges.subjectLength.min,
          desirableRanges.subjectLength.max
        )}
        percent={(subjectLength / 100) * 100}
        showInfo={false}
      />

      <span>Word Count: {wordCount}</span>
      <Progress
        strokeColor={getProgressBarColor(
          wordCount,
          desirableRanges.wordCount.min,
          desirableRanges.wordCount.max
        )}
        percent={(wordCount / 150) * 100}
        showInfo={false}
      />

      <span>Personalization: {personalization}</span>
      <Progress
        strokeColor={getProgressBarColor(
          personalization,
          desirableRanges.personalization.min,
          desirableRanges.personalization.max
        )}
        percent={(personalization / 20) * 100}
        showInfo={false}
      />

      <span>
        Spam Words: {spamWords.length}
        {spamWords.length ? (
          <Tooltip title={spamWords.join(', ')}>
            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        ) : (
          ''
        )}
      </span>
      <Progress
        strokeColor={getProgressBarColor(
          spamWords.length,
          desirableRanges.spamWords.min,
          desirableRanges.spamWords.max
        )}
        percent={(spamWords.length / 15) * 100}
        showInfo={false}
      />

      <span>Question Count: {questionCount}</span>
      <Progress
        strokeColor={getProgressBarColor(
          questionCount,
          desirableRanges.questionCount.min,
          desirableRanges.questionCount.max
        )}
        percent={(questionCount / 10) * 100}
        showInfo={false}
      />

      <span>Reading Time: {readingTime} sec</span>
      <Progress
        strokeColor={getProgressBarColor(
          readingTime,
          desirableRanges.readingTime.min,
          desirableRanges.readingTime.max
        )}
        percent={(readingTime / 30) * 100}
        showInfo={false}
      />
    </div>
  )
}

export default SpamScore
