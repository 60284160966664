import React, { useState } from 'react'
import { Form, Input, Button, Card, Typography, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { actions as UserActions } from '../../../store/UserReducer'

const TrackingDomain: React.FC = () => {
  const [trackingDomain, setTrackingDomain] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const { user, updateLoading } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const isTrackingDomainInputValid = (domain: string) => {
    if (domain.includes('https://') || domain.includes('http://')) {
      return false
    }
    return true
  }

  const onSave = () => {
    if (!isTrackingDomainInputValid) {
      setError('Please format your tracking domain as: *.yourdomain.com')
      return false
    }
    const domain = {
      domain: trackingDomain,
    }
    // does not do anything because it is disabled now
    // dispatch(UserActions.updateUser({ trackingDomain: domain }))
  }

  if (!user) return null

  return (
    <div>
      <Card title={<span>Tracking Domain</span>} size="small">
        <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginBottom: 10 }}>
          Use your own tracking domain to improve deliverability. Add and verify an account-level
          custom tracking domain.
        </div>
        <div style={{ display: 'flex' }}>
          <Input
            size="middle"
            placeholder="hi.yourdomain.com"
            value={trackingDomain}
            onChange={(e) => setTrackingDomain(e.target.value)}
            style={{ marginRight: 10 }}
          />
          <Button onClick={onSave} loading={updateLoading}>
            Verify & Save
          </Button>
          {/* <div style={{ marginLeft: 10, width: 300 }}>
            Status: <Tag>Not added</Tag>
          </div> */}
        </div>
        {error}
        <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginTop: 10 }}>
          Please add a CNAME record in your DNS (e.g. hi.yourdomain.com) that points to{' '}
          <Typography.Text copyable>hi.tailarrow.com</Typography.Text>
          <br /> <br /> We recommend to avoid using email-related words like tracking, mail, link,
          custom, etc. in the name of your tracking domain.
        </div>
      </Card>
    </div>
  )
}

export default TrackingDomain
