import React from 'react'
import {
  MailOutlined,
  LockOutlined,
  ThunderboltOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Form, Input, Button } from 'antd'

interface LoginFormProps {
  onSubmit: (values: { email: string; password: string }) => void
  signupLoading: boolean
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, signupLoading }) => {
  const handleSubmit = (values: { email: string; password: string }) => {
    onSubmit(values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const formItemLayout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  }

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  }

  return (
    <Form
      {...formItemLayout}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      className="login-form"
      style={{ textAlign: 'left' }}
    >
      <Form.Item
        label="Email"
        name="email"
        className="login-form-item"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input
          className="login-form-input"
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        className="login-form-item"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          className="login-form-input"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item className="login-form-item" {...tailFormItemLayout}>
        <Button
          className="login-form-input"
          style={{ width: '100%', marginTop: '10px' }}
          icon={signupLoading && <LoadingOutlined />}
          // type="primary"
          htmlType="submit"
          disabled={signupLoading}
        >
          Verify Email <RightOutlined />
        </Button>
        <div style={{ marginTop: 15, fontSize: 10 }}>
          By registering, you confirm you have read and agree to the{' '}
          <a
            href="https://mailarrow.com/tos/"
            target="__blank"
            style={{ color: 'unset', textDecoration: 'underline', textDecorationStyle: 'dashed' }}
          >
            Terms of Service
          </a>{' '}
          and to storing & processing of your personal data by Mailarrow as described in the{' '}
          <a
            href="https://mailarrow.com/privacy-policy-2/"
            target="__blank"
            style={{ color: 'unset', textDecoration: 'underline', textDecorationStyle: 'dashed' }}
          >
            Privacy Statement
          </a>
          , including for marketing purposes.
        </div>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
