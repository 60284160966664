// url can be https://example.com, http://example.com, example.com,
// if it does not have http or https it needs to be added upfront

export const ensureWebsiteUrl = (url?: string): string => {
  if (!url) return ''
  if (url.includes('http://') || url.includes('https://')) {
    return url
  }
  return `https://${url}`
}
