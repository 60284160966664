import React, { ReactNode, useEffect, useState } from 'react'
import { Drawer, Progress } from 'antd'
import FileUploadStep from './FileUpload/FileUploadStep'
import MatchColumns from './MatchColumns/MatchColumns'
import { useFetchCustomProperties } from '../../api/customPropsQueries'
import UploadProspectsStep from './UploadProspects/UploadProspectsStep'

interface Props {
  isOpen: boolean
  onAddVariable: () => void
  onClose: () => void
  campaignId?: string
}

const AddProspectsCSV: React.FC<Props> = ({ isOpen, onClose, campaignId, onAddVariable }) => {
  const [step, setStep] = useState(0)
  const [parsedCSVData, setParsedCSVData] = useState([])
  const [errorCSVData, setErrorCSVData] = useState([])
  const [metaCSVData, setMetaCSVData] = useState([])
  const [fileName, setFileName] = useState('')
  const [mapping, setMapping] = useState<Record<number, string>>({})

  const { properties } = useFetchCustomProperties()

  const onUploadComplete = (parsed: any, fileName: string) => {
    const { data, error, meta } = parsed
    setParsedCSVData(data)
    setErrorCSVData(error)
    setMetaCSVData(meta)
    setFileName(fileName)
    setMapping({})
  }

  const onRemoveUpload = () => {
    console.log('removing')
    setFileName('')
    setParsedCSVData([])
    setErrorCSVData([])
    setMetaCSVData([])
    setMapping({})
  }

  const onCloseModal = () => {
    onRemoveUpload()
    setStep(0)
    onClose()
  }

  const getTitle = (): ReactNode => {
    const titles: Record<number, string> = {
      0: 'Upload your CSV file',
      1: 'Match Columns',
      2: 'Import Prospects',
      3: 'Import Completed',
    }
    const offsetCurrentStep = step + 1
    const totalSteps = 4
    const percentageProgress = (offsetCurrentStep / totalSteps) * 100
    return (
      <>
        <Progress type="circle" percent={percentageProgress} size={20} steps={totalSteps} />
        <span style={{ margin: '0 10px 0 5px' }}>
          {offsetCurrentStep}/{totalSteps}:
        </span>
        {titles[step]}
      </>
    )
  }

  return (
    <Drawer
      title={getTitle()}
      onClose={onCloseModal}
      open={isOpen}
      keyboard={false}
      width={765}
      style={{ margin: '0 auto' }}
    >
      {step === 0 && (
        <FileUploadStep
          data={parsedCSVData}
          fileName={fileName}
          onUploadComplete={onUploadComplete}
          onRemoveUpload={onRemoveUpload}
          onNextStep={() => setStep(step + 1)}
        />
      )}
      {step === 1 && (
        <MatchColumns
          onAddVariable={onAddVariable}
          onPrevStep={() => setStep(step - 1)}
          onNextStep={() => setStep(step + 1)}
          mapping={mapping}
          setMapping={setMapping}
          data={parsedCSVData}
          customProperties={properties}
        />
      )}
      {(step === 2 || step === 3) && (
        <UploadProspectsStep
          onPrevStep={() => setStep(step - 1)}
          onNextStep={() => setStep(step + 1)}
          onClose={onCloseModal}
          campaignId={campaignId}
          fileName={fileName}
          mapping={mapping}
          data={parsedCSVData}
          customProperties={properties}
        />
      )}
    </Drawer>
  )
}

export default AddProspectsCSV
