import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchProspects } from '../Prospects/api/prospectsQueries'
import { useFetchSequence } from '../Sequence'
import { useFetchCampaigns } from '../Campaigns/api/campaignQueries'
import Preview from './components/Preview'
import { createEmail } from './util/make-email'
import { useFetchMailboxes } from '../Mailboxes'
import ProspectEditorContainer from '../Prospects/components/ProspectEditor/ProspectEditorContainer'

interface Props {}

const PreviewContainer: React.FC<Props> = () => {
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const [prospectId, setProspectId] = useState<string | undefined>(undefined)
  const [editProspectId, setEditProspectId] = useState<string | undefined>(undefined)
  const [stepIndex, setStepIndex] = useState<number | undefined>(0)

  const { campaignId } = useParams<{ campaignId: string }>()
  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()
  const campaign = campaigns?.find((c: any) => c.campaignId === campaignId)

  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()

  const campaignMailboxes = mailboxes?.filter((m) => campaign?.mailboxIds?.includes(m.mailboxId))

  const {
    prospects,
    total,
    isLoading: fetchProspectsLoading,
    isFetching: fetchProspectsLoading2,
    isSuccess: fetchProspectsSuccess,
  } = useFetchProspects(
    {
      campaignId,
      search: filter,
    },
    { page, pageSize }
  )

  const onPageChange = (nextPage: any, nextPageSize: any) => {
    setPage(nextPage)
    nextPageSize !== pageSize && setPageSize(pageSize)
  }

  const { sequence, isLoading: fetchSequenceLoading } = useFetchSequence(
    campaign && campaign.sequenceId
  )

  useEffect(() => {
    if (!prospects || !fetchProspectsSuccess) return

    const prospect = prospects?.find((prospect: any) => prospect.prospectId === prospectId)
    if (!prospect && prospects[0]) {
      prospects[0] ? setProspectId(prospects[0].prospectId) : setProspectId('')
    }
  }, [fetchProspectsSuccess, prospectId, prospects])

  useEffect(() => {
    if (!prospects || !prospects.length) return
    if (!prospectId) setProspectId(prospects[0].prospectId)
  }, [prospectId, prospects])

  useEffect(() => {
    if (!sequence) return
    if (!Boolean(stepIndex)) setStepIndex(0)
  }, [prospectId, prospects, sequence, stepIndex])

  const onSelectProspect = (id: string) => {
    setProspectId(id)
  }

  const onChangeStepIndex = (index: number) => {
    setStepIndex(index)
  }

  const onEditProspect = (prospectId: string) => {
    setEditProspectId(prospectId)
  }

  return (
    <>
      <ProspectEditorContainer
        prospectId={editProspectId}
        onClose={() => setEditProspectId(undefined)}
      />
      <Preview
        sequence={sequence}
        prospects={prospects}
        loading={
          fetchProspectsLoading ||
          fetchProspectsLoading2 ||
          fetchCampaignsLoading ||
          fetchSequenceLoading ||
          fetchMailboxesLoading
        }
        mailboxes={campaignMailboxes}
        selectedProspectId={prospectId}
        onSelectProspect={onSelectProspect}
        onEditProspect={onEditProspect}
        stepIndex={stepIndex}
        onChangeStepIndex={onChangeStepIndex}
        filter={filter}
        setFilter={setFilter}
        total={total || 0}
        onPageChange={onPageChange}
        page={page}
        pageSize={pageSize}
      />
    </>
  )
}

export default PreviewContainer
