// MonthlyChart.tsx
import React, { useEffect, useState } from 'react'
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'
import moment from 'moment'

interface MonthlyChartProps {
  users: any[]
}

const MonthlyChart: React.FC<MonthlyChartProps> = ({ users }) => {
  const [monthlyData, setMonthlyData] = useState<any[]>([])

  useEffect(() => {
    const monthlyObj: { [key: string]: number } = {}
    users.forEach((user) => {
      const month = moment(user.createdAt).format('MM-YY')
      if (!monthlyObj[month]) {
        monthlyObj[month] = 0
      }
      monthlyObj[month]++
    })
    const monthlyArray = Object.keys(monthlyObj).map((month) => ({
      month,
      count: monthlyObj[month],
    }))
    setMonthlyData(monthlyArray)
  }, [users])

  return (
    <BarChart width={500} height={300} data={monthlyData}>
      <Bar dataKey="count" fill="#8884d8" />
      <CartesianGrid stroke="transparent" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
    </BarChart>
  )
}

export default MonthlyChart
