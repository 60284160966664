import React from 'react'
import { Button, List, Tag, Card } from 'antd'
import dayjs from 'dayjs'
import { useFetchInvoices, usePayInvoice } from '../../../modules/Billing/api/invoices'

interface Invoice {
  url: string
  id: string
  status: string
  created: number
  amount: number
}

const BillingHistory: React.FC<{}> = () => {
  const { invoices, isLoading: invoicesLoading } = useFetchInvoices()
  const { payInvoice, isLoading: invoicePaymentLoading } = usePayInvoice()

  const openInvoice = (url: string) => {
    window.open(url)
  }

  const handleInvoicePayment = (id: string) => {
    payInvoice(id)
  }

  const allowedInvoices = invoices?.filter((i: any) => i.status !== 'draft' || i.amount > 0) || []

  return (
    <Card style={{ textAlign: 'left', overflow: 'hidden' }} title="Billing History" size="small">
      <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        Please allow up to 24 hours for your new invoices to become available.
      </div>
      <div>
        <List
          header={<strong>Invoices</strong>}
          itemLayout="horizontal"
          dataSource={allowedInvoices}
          loading={invoicesLoading}
          size="small"
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: false,
            size: 'small',
          }}
          renderItem={(invoice: Invoice) => {
            const canDownloadInvoice = invoice.status === 'paid'
            const canPayInvoice =
              false &&
              invoice.status !== 'paid' &&
              invoice.status !== 'void' &&
              invoice.status !== 'draft'
            const actions = [
              canDownloadInvoice && (
                <Button type="dashed" size="small" onClick={() => openInvoice(invoice.url)}>
                  Download
                </Button>
              ),
              canPayInvoice && (
                <Button
                  type="primary"
                  size="small"
                  disabled
                  onClick={() => handleInvoicePayment(invoice.id)}
                  style={{ marginLeft: '10px' }}
                  loading={invoicePaymentLoading}
                >
                  Pay Now
                </Button>
              ),
            ].filter(Boolean)

            return (
              <List.Item key={invoice.url} actions={actions} className="billing-list-item">
                <List.Item.Meta
                  title={
                    <span>
                      {dayjs.unix(invoice.created).format('DD MMMM YYYY')} - ${invoice.amount / 100}
                    </span>
                  }
                />
                <div>
                  <Tag
                    color={invoice.status === 'paid' ? 'green' : undefined}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {invoice.status}
                  </Tag>
                </div>
              </List.Item>
            )
          }}
        />
      </div>
    </Card>
  )
}

export default BillingHistory
