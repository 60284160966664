import { Select, Input, Tag, Button, Card, Switch, message, Checkbox } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { isUndefined } from 'lodash'
import { Campaign } from '@mailarrow/types'

const { TextArea } = Input

interface Props {
  campaign: Campaign
  fetchLoading: boolean
  editLoading: boolean
  onEditCampaign: (props: any) => void
}

const UnsubscribeSettings: React.FC<Props> = ({
  onEditCampaign,
  campaign,
  fetchLoading,
  editLoading,
}) => {
  const [enableUnsub, setEnableUnsub] = useState<boolean | undefined>(undefined)
  const [unsubText, setUnsubText] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!campaign) return
    if (isUndefined(unsubText)) setUnsubText(campaign.sendConfig.unsubscribeText)
    if (isUndefined(enableUnsub)) setEnableUnsub(campaign.sendConfig.enableUnsubscribeText)
  }, [campaign, unsubText, enableUnsub])

  const onEnableUnsubChange = useCallback(
    (e: any) => {
      if (!e.target.checked) {
        setUnsubText(campaign.sendConfig.unsubscribeText)
      }
      setEnableUnsub(e.target.checked)
    },
    [campaign.sendConfig.unsubscribeText]
  )

  const onSaveClick = useCallback(() => {
    if (
      !unsubText ||
      !unsubText.includes('{{unsubscribe_link}}') ||
      !unsubText.includes('{{/unsubscribe_link}}')
    ) {
      return message.warning(
        'Please provide unsubscribe text and valid {{unsubscribe_link}}{/unsubscribe_link}} parameters'
      )
    }
    const updates = {
      sendConfig: {
        ...campaign.sendConfig,
        enableUnsubscribeText: enableUnsub,
        unsubscribeText: unsubText,
      },
    }
    onEditCampaign(updates)
  }, [campaign.sendConfig, enableUnsub, onEditCampaign, unsubText])

  return (
    <div>
      <Card title={<div>Unsubscribe</div>} style={{ marginBottom: 20 }} size="small">
        <div>
          <div style={{ marginBottom: 10 }}>
            <Checkbox
              checked={enableUnsub}
              onChange={onEnableUnsubChange}
              style={{ marginRight: 10 }}
            />
            Attach unsubscribe text to every email
          </div>
          Unsubscribe text:
          <TextArea
            disabled={!enableUnsub}
            value={unsubText}
            onChange={(e) => setUnsubText(e.target.value)}
          />
          <div style={{ fontSize: 13 }}>
            {
              'The opt-out link will be placed at the end of the email, between the {{unsubscribe_link}} and {{/unsubscribe_link}} tags.'
            }
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onSaveClick}
            loading={fetchLoading || editLoading}
            size="small"
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default UnsubscribeSettings
