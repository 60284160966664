import React from 'react'
import { Form, Input, Button, Card } from 'antd'
import { RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { actions as AuthActions } from '../../../modules/Auth/reducer'

const ChangePasswordForm: React.FC = () => {
  const dispatch = useDispatch()
  const { user, updateLoading } = useSelector((state: RootState) => state.user)

  const handleSubmit = ({ password, newPassword }: { password: string; newPassword: string }) => {
    dispatch(AuthActions.updatePassword({ password, newPassword }))
  }

  const passwordValidator = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (value && value.length < 8) {
        return Promise.reject('Passwords should be at least 8 symbols')
      }
      return Promise.resolve()
    },
  })

  const confirmPasswordValidator = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve()
      }
      return Promise.reject('Passwords should match')
    },
  })

  if (!user) return null
  const isSSO = user.signupType !== 'email'

  const formItemLayout = {
    labelCol: { xs: { span: 7 }, sm: { span: 7 } },
    wrapperCol: { xs: { span: 10 }, sm: { span: 10 } },
  }

  const tailFormItemLayout = {
    wrapperCol: { xs: { span: 10, offset: 7 }, sm: { span: 10, offset: 7 } },
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <Card title={<span>Password</span>} size="small">
        {isSSO ? (
          <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)' }}>
            Changing your password is not possible if you signed up with a social account.
          </div>
        ) : (
          <Form {...formItemLayout} layout="horizontal" onFinish={handleSubmit}>
            <Form.Item
              label="Old Password"
              rules={[{ required: true, message: 'Please input your old password' }]}
              name="password"
            >
              <Input placeholder="Old Password" type="password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              rules={[
                { required: true, message: 'Please input your new password' },
                passwordValidator,
              ]}
              name="newPassword"
              hasFeedback
            >
              <Input placeholder="New Password" type="password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              rules={[
                { required: true, message: 'Please confirm your new password' },
                confirmPasswordValidator,
              ]}
              dependencies={['newPassword']}
              name="confirmPassword"
              hasFeedback
            >
              <Input placeholder="Confirm Your Password" type="password" />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateLoading}
                disabled={updateLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  )
}

export default ChangePasswordForm
