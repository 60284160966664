import React, { useCallback, useMemo } from 'react'
import { Button, Table, Modal, Tag, Tooltip, TablePaginationConfig } from 'antd'
import { capitalizeFirstLetter } from '../../util/capitalize-first-letter'
import ReactJson from 'react-json-view'
import dayjs from 'dayjs'
import { getTimeAgo } from '../../util/date'
import { Campaign } from '@mailarrow/types'

interface Props {
  emails?: any[]
  total: number
  currentPage: number
  pageSize: number
  onTableChange: (config: TablePaginationConfig) => void
  campaigns?: Campaign[]
  isAdminView?: boolean
  loading: boolean
}

const EmailsTable: React.FC<Props> = ({
  emails,
  total,
  onTableChange,
  currentPage,
  pageSize,
  loading,
  campaigns,
  isAdminView,
}) => {
  const getCampaignName = useCallback(
    (campaignId: string) => {
      if (!campaigns) return ''
      const campaign = campaigns?.find((c) => c.campaignId === campaignId)
      if (!campaign) return ''
      return campaign.name
    },
    [campaigns]
  )

  const columns = [
    {
      title: 'Prospect',
      dataIndex: 'prospectEmail',
      key: 'prospectEmail',
    },
    {
      title: 'Campaign',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: (campaignId: any, record: any, index: any) => <>{getCampaignName(campaignId)}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: any, record: any, index: any) => (
        <>
          <Tag style={{ marginLeft: 20 }}>{capitalizeFirstLetter(status)}</Tag>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      // TODO: this is most likely incorrect time due to timezone shift for daylight savings
      render: (updatedAt: any, record: any, index: any) => (
        <>
          <Tooltip title={dayjs(updatedAt).format('DD MMM YYYY HH:mm:ss')}>
            {/* {getTimeAgo(updatedAt)} */}
            {dayjs(updatedAt).format('DD MMM YYYY HH:mm:ss')}
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Opens',
      dataIndex: 'opens',
      key: 'opens',
      render: (status: any, record: any, index: any) => <>0</>,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      render: (status: any, record: any, index: any) => <>0</>,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        dataSource={emails}
        columns={columns}
        rowKey="emailId"
        loading={loading}
        expandable={{
          expandedRowRender: (record) => <ReactJson src={record} />,
          rowExpandable: () => (isAdminView ? true : false),
        }}
        pagination={{
          position: ['bottomRight'],
          current: currentPage,
          pageSize,
          simple: true,
          hideOnSinglePage: true,
          size: 'small',
          total,
        }}
        onChange={onTableChange}
        size="middle"
      />
    </div>
  )
}

export default EmailsTable
