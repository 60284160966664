import React, { ImgHTMLAttributes, CSSProperties } from 'react'
import logo2 from './logo-mailarrow.png'

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  style?: CSSProperties
}

const Logo: React.FC<Props> = ({ style, ...rest }) => {
  return (
    <img
      src={logo2}
      alt="logo"
      style={{
        ...style,
      }}
      {...rest} // Spread other img attributes
    />
  )
}

export default Logo
