import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MailOutlined, LockOutlined, ThunderboltOutlined, GoogleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Card, Divider } from 'antd'
import { RootState } from '../../../store/store'
import { actions as UserActions } from '../../../store/UserReducer'
import { actions as AuthActions } from '../../../modules/Auth/reducer'

import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import history from '../../../router/history'

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const [isResetPassword, setIsResetPassword] = useState(false)

  useEffect(() => {
    document.title = 'Login | Mailarrow App'
    if (user?.userId) {
      history.push('/app/dashboard/')
    }
  }, [user])

  const loginUser = (values: any) => {
    dispatch(AuthActions.loginUser(values))
  }

  const loginUserGoogle = () => {
    dispatch(AuthActions.loginUserGoogle())
  }

  const resetPassword = (values: any) => {
    dispatch(AuthActions.resetPassword(values))
  }

  return (
    <div className="signup-form">
      {!isResetPassword && (
        <Card
          title={<div style={{ textAlign: 'center' }}>Log In</div>}
          className="signup-card"
          headStyle={{ fontSize: 20 }}
        >
          <LoginForm onSubmit={loginUser} onResetPassword={() => setIsResetPassword(true)} />
          <Button
            style={{ width: '100%' }}
            className="login-form-input"
            type="dashed"
            onClick={() => setIsResetPassword(true)}
          >
            Forgot Password
          </Button>
          <Divider>OR</Divider>
          <Button
            style={{ width: '100%' }}
            icon={<GoogleOutlined />}
            size="large"
            onClick={loginUserGoogle}
          >
            Log In With Google
          </Button>
        </Card>
      )}
      {isResetPassword && (
        <Card
          title={<div style={{ textAlign: 'center' }}>Reset Password</div>}
          className="signup-card"
          headStyle={{ fontSize: 20 }}
        >
          <ResetPasswordForm onSubmit={resetPassword} />
          {/* TODO: Make sure that state changes accordingly */}
        </Card>
      )}
      <div>
        Do not have an account yet? <Link to="/sign-up/">Sign Up</Link>
      </div>
    </div>
  )
}

export default Login
