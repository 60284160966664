import { Select, InputNumber, Tag, Button, Card } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { isUndefined } from 'lodash'
import { Campaign } from '@mailarrow/types'

interface Props {
  campaign: Campaign
  fetchLoading: boolean
  editLoading: boolean
  onEditCampaign: (props: any) => void
}

const SendingSettings: React.FC<Props> = ({
  onEditCampaign,
  campaign,
  fetchLoading,
  editLoading,
}) => {
  const [enrolls, setEnrolls] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!campaign) return
    if (isUndefined(enrolls)) setEnrolls(campaign.sendConfig.maxNewProspectsPerDay)
  }, [campaign, enrolls])

  const onSaveClick = useCallback(() => {
    const updates = {
      sendConfig: {
        ...campaign.sendConfig,
        maxNewProspectsPerDay: enrolls,
      },
    }
    onEditCampaign(updates)
  }, [campaign.sendConfig, enrolls, onEditCampaign])

  return (
    <Card title={<div>Sending</div>} size="small">
      <div style={{ margin: '0 0 10px 0' }}>
        <div>Enroll max new prospects per day:</div>
        <InputNumber
          style={{ display: 'block', margin: '10px 0' }}
          min={5}
          max={500}
          value={enrolls}
          step={5}
          onChange={(v) => v && setEnrolls(v)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={onSaveClick}
          loading={fetchLoading || editLoading}
          size="small"
          icon={<SaveOutlined />}
        >
          Save
        </Button>
      </div>
    </Card>
  )
}

export default SendingSettings
