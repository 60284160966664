import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import CustomTooltip from './Tooltip'

type DayData = {
  day: string
  sent: number
  replied: number
  opened: number
  clicked: number
  bounced: number
  unsubscribed: number
}

type ChartProps = {
  data: DayData[]
}

const StackedBarChart: React.FC<ChartProps> = ({ data }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ResponsiveContainer width="80%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          {/* <YAxis /> */}
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="sent" stackId="a" fill="#8884d8" />
          <Bar dataKey="replied" stackId="a" fill="#82ca9d" />
          <Bar dataKey="opened" stackId="a" fill="#ffc658" />
          <Bar dataKey="clicked" stackId="a" fill="#8dd1e1" />
          <Bar dataKey="bounced" stackId="a" fill="#a4de6c" />
          <Bar dataKey="unsubscribed" stackId="a" fill="#d0ed57" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StackedBarChart
