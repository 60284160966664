import { handleActions } from 'redux-actions'
import axios from 'axios'

import { message } from 'antd'
import fetch from '../services/fetch'
import logger from '../services/errorlogger'

import { actions as AuthActions, actionTypes as authActionTypes } from '../modules/Auth/reducer'
import { AdditionalBillingInformation, Billing, FeatureToggle, User } from '@mailarrow/types'
import history from '../router/history'

const isProd = process.env.NODE_ENV === 'production'

export const actionTypes = {
  FETCH_USER_IP: 'FETCH_USER_IP',

  SET_IMPERSONATE_MODE_ON: 'SET_IMPERSONATE_MODE_ON',
  SET_IMPERSONATE_MODE_OFF: 'SET_IMPERSONATE_MODE_OFF',

  APPLY_BETA_DONE: 'APPLY_BETA_DONE',
  APPLY_BETA_LOADING: 'APPLY_BETA_LOADING',

  CONFIRMATION_RESEND_LOADING: 'CONFIRMATION_RESEND_LOADING',
  CONFIRMATION_RESEND_DONE: 'CONFIRMATION_RESEND_DONE',
  CONFIRMATION_DONE: 'CONFIRMATION_DONE',
  CONFIRMATION_FAILED: 'CONFIRMATION_FAILED',

  CAPTCHA_VERIFICATION_SUCCESS: 'CAPTCHA_VERIFICATION_SUCCESS',
  CAPTCHA_VERIFICATION_LOADING: 'CAPTCHA_VERIFICATION_LOADING',
  CAPTCHA_VERIFICATION_FAILED: 'CAPTCHA_VERIFICATION_FAILED',

  FETCH_USER_DATA_LOADING: 'FETCH_USER_DATA_LOADING',
  FETCH_USER_DATA_DONE: 'FETCH_USER_DATA_DONE',
  FETCH_USER_DATA_ERROR: 'FETCH_USER_DATA_ERROR',

  USER_UPDATE_LOADING: 'USER_UPDATE_LOADING',
  USER_UPDATE_DONE: 'USER_UPDATE_DONE',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
}

export const actions = {
  getUserData:
    (timeout = 0) =>
    async (dispatch: any, getState: any) => {
      try {
        console.log('getting user data')
        const { signupLoading } = getState().auth
        const { fetchUserDataLoading } = getState().user
        if (signupLoading || fetchUserDataLoading) {
          return
        }

        dispatch({ type: actionTypes.FETCH_USER_DATA_LOADING })

        const getData = async () =>
          await fetch
            .get(`/users/`)
            .then(({ data: user }) => dispatch({ type: actionTypes.FETCH_USER_DATA_DONE, user }))
            .catch((error) => {
              isProd
                ? dispatch(AuthActions.signOutUser())
                : dispatch({ type: actionTypes.FETCH_USER_DATA_ERROR })
              logger.handleError(error)
            })

        setTimeout(getData, timeout)
        // timeout allows us to show loading screen longer than usual to make sure that webhooks have time to process. better than long polling
      } catch (e: any) {
        logger.handleError(e)
      }
    },
  updateUser: (user: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.USER_UPDATE_LOADING })
      await fetch.patch(`/users/`, { userData: user })
      dispatch({ type: actionTypes.USER_UPDATE_DONE })
      dispatch(actions.getUserData())
    } catch (e: any) {
      dispatch({ type: actionTypes.USER_UPDATE_ERROR })
      // TODO: Error handling not working, not showing even shareable errors
      logger.handleError(e)
    }
  },
  monitorImpersonateOn: (impersonateUserId: string) => async (dispatch: any, getState: any) => {
    try {
      const originalUserId = localStorage && localStorage.getItem('userId')
      localStorage && localStorage.setItem('userId', impersonateUserId)
      dispatch({ type: actionTypes.SET_IMPERSONATE_MODE_ON, originalUserId, impersonateUserId })
      history.push('/app/dashboard/')
      // does not work
      // dispatch(actions.getUserData())
    } catch (e) {
      logger.handleError(e)
    }
  },
  monitorImpersonateOff: () => async (dispatch: any, getState: any) => {
    try {
      const originalUserId = getState().user.originalUserId
      localStorage.setItem('userId', originalUserId)
      dispatch({ type: actionTypes.SET_IMPERSONATE_MODE_OFF })
      history.push('/app/monitor/userlist')
    } catch (e) {
      logger.handleError(e)
    }
  },
  applyBeta: (email: string, siteUrl: string) => async (dispatch: any, getState: any) => {
    try {
      const userData = { siteUrl, email }
      dispatch({ type: actionTypes.APPLY_BETA_LOADING })

      await fetch.put(`/users/apply`, { userData })
      dispatch({ type: actionTypes.APPLY_BETA_DONE })
    } catch (e: any) {
      logger.handleError(e)
    }
  },
  sendConfirmation: () => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.CONFIRMATION_RESEND_LOADING })
      await fetch.post(`/users/send-confirmation/`)
      dispatch({ type: actionTypes.CONFIRMATION_RESEND_DONE })
    } catch (e: any) {
      logger.handleError(e)
    }
  },
  completeEmailVerification:
    (id?: string, userId?: string) => async (dispatch: any, getState: any) => {
      try {
        if (!id || !userId) {
          return message.error('Please make sure your URL is correct')
        }
        await fetch.post(`/users/verify/${userId}/${id}`)
        dispatch({ type: actionTypes.CONFIRMATION_DONE })
        setTimeout(() => {
          dispatch(actions.getUserData())
        }, 1000)
      } catch (e: any) {
        dispatch({ type: actionTypes.CONFIRMATION_FAILED })
        logger.handleError(e)
      }
    },
  addEmailToList:
    (email: string, siteUrl: string, source = '') =>
    async (dispatch: any, getState: any) => {
      try {
        await fetch.put(`/users/add-lead/`, { email, siteUrl, source })
      } catch (e: any) {
        logger.handleError(e)
      }
    },
  verifyCaptcha:
    (token: string, siteUrl: string, email: string) => async (dispatch: any, getState: any) => {
      try {
        dispatch({ type: actionTypes.CAPTCHA_VERIFICATION_LOADING })
        const ip = getState().user.userIp
        const sessionId = localStorage && localStorage.getItem('sessionId')
        const { data: isValid } = await fetch.post(`/users/verify-captcha/`, {
          token,
          ip,
          sessionId,
          siteUrl,
          email,
        })
        if (!isValid) {
          message.error(`Verification failed, redirecting to home page...`, 10)
          if (isProd) setTimeout(() => (window.location.href = 'https://amzwatcher.com'), 1000)
          return dispatch({ type: actionTypes.CAPTCHA_VERIFICATION_FAILED })
        }
        dispatch({ type: actionTypes.CAPTCHA_VERIFICATION_SUCCESS })
      } catch (e: any) {
        dispatch({ type: actionTypes.CAPTCHA_VERIFICATION_FAILED })
        logger.handleError(e)
      }
    },
  fetchUserIp: () => async (dispatch: any, getState: any) => {
    try {
      const response = await axios('https://api6.ipify.org?format=json')
      const userIp = response.data.ip
      dispatch({ type: actionTypes.FETCH_USER_IP, userIp })
    } catch (e: any) {
      // most probably an adblocker
      // logger.handleError(e);
    }
  },
}

export interface UserState {
  user: (User & Billing & AdditionalBillingInformation) | undefined

  impersonateUserId: string | null
  originalUserId: string | null

  fetchUserDataLoading: boolean
  fetchUserDataError: boolean
  fetchUserDataSuccess: boolean

  updateLoading: boolean
  updateError: boolean

  applyBetaLoading: boolean
  applyBetaSuccess: boolean

  confirmationResendLoading: boolean
  confirmationResendDone: boolean

  confirmationDone: boolean
  confirmationFailed: boolean

  tokenVerificationSuccess: boolean
  tokenVerificationFailed: boolean
  tokenVerificationLoading: boolean
}

export const getDefaultState = (): UserState => ({
  // TODO: always put data onto the leaf
  user: undefined,

  impersonateUserId: null,
  originalUserId: null,

  fetchUserDataLoading: false,
  fetchUserDataError: false,
  fetchUserDataSuccess: false,

  updateLoading: false,
  updateError: false,

  applyBetaLoading: false,
  applyBetaSuccess: false,

  confirmationResendLoading: false,
  confirmationResendDone: false,

  confirmationDone: false,
  confirmationFailed: false,

  tokenVerificationSuccess: false,
  tokenVerificationFailed: false,
  tokenVerificationLoading: false,
})

export const UserReducer = handleActions(
  {
    [authActionTypes.USER_LOGOUT]: (state) => ({ ...getDefaultState() }),
    [actionTypes.SET_IMPERSONATE_MODE_ON]: (state, { impersonateUserId, originalUserId }: any) => ({
      ...state,
      impersonateUserId,
      originalUserId,
      isImpersonateMode: true,
    }),
    [actionTypes.SET_IMPERSONATE_MODE_OFF]: (state) => ({
      ...state,
      impersonateUserId: null,
      originalUserId: null,
      isImpersonateMode: false,
    }),
    [actionTypes.FETCH_USER_DATA_LOADING]: (state) => ({
      ...state,
      loading: true,
      fetchUserDataLoading: true,
      fetchUserDataError: false,
      fetchUserDataSuccess: false,
    }),
    [actionTypes.FETCH_USER_DATA_DONE]: (state, { user }: any) => {
      if (!user) return state
      return {
        ...state,
        user,
        loading: false,
        fetchUserDataLoading: false,
        fetchUserDataError: false,
        fetchUserDataSuccess: true,
      }
    },
    [actionTypes.FETCH_USER_DATA_ERROR]: (state) => ({
      ...state,
      loading: false,
      fetchUserDataLoading: false,
      fetchUserDataError: true,
      fetchUserDataSuccess: false,
    }),

    [actionTypes.USER_UPDATE_LOADING]: (state) => ({ ...state, updateLoading: true }),
    [actionTypes.USER_UPDATE_DONE]: (state) => ({
      ...state,
      updateLoading: false,
      updateError: false,
    }),
    [actionTypes.USER_UPDATE_ERROR]: (state) => ({
      ...state,
      updateLoading: false,
      updateError: true,
    }),

    [actionTypes.APPLY_BETA_LOADING]: (state) => ({
      ...state,
      applyBetaLoading: true,
      applyBetaSuccess: false,
    }),
    [actionTypes.APPLY_BETA_DONE]: (state) => ({
      ...state,
      applyBetaLoading: false,
      applyBetaSuccess: true,
    }),

    [actionTypes.CONFIRMATION_RESEND_LOADING]: (state) => ({
      ...state,
      confirmationResendLoading: true,
      confirmationResendDone: false,
    }),
    [actionTypes.CONFIRMATION_RESEND_DONE]: (state) => ({
      ...state,
      confirmationResendLoading: false,
      confirmationResendDone: true,
    }),
    [actionTypes.CONFIRMATION_DONE]: (state) => ({
      ...state,
      confirmationDone: true,
      confirmationFailed: false,
    }),
    [actionTypes.CONFIRMATION_FAILED]: (state) => ({
      ...state,
      confirmationDone: false,
      confirmationFailed: true,
    }),
    [actionTypes.CAPTCHA_VERIFICATION_FAILED]: (state) => ({
      ...state,
      tokenVerificationLoading: false,
      tokenVerificationFailed: true,
      tokenVerificationSuccess: false,
    }),
    [actionTypes.CAPTCHA_VERIFICATION_LOADING]: (state) => ({
      ...state,
      tokenVerificationLoading: false,
      tokenVerificationFailed: false,
      tokenVerificationSuccess: false,
    }),
    [actionTypes.CAPTCHA_VERIFICATION_SUCCESS]: (state) => ({
      ...state,
      tokenVerificationLoading: false,
      tokenVerificationFailed: false,
      tokenVerificationSuccess: true,
    }),
    [actionTypes.FETCH_USER_IP]: (state, { userIp }: any) => ({ ...state, userIp }),
  },
  getDefaultState()
)
