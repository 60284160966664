import React, { useState } from 'react'
import { Button, Card, InputNumber } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { send } from 'process'

interface Props {
  maxPerDay: number
  setMaxPerDay: (perDay: number) => void
  sendDelay: number
  setSendDelay: (delay: number) => void
  handleSave: () => void
  loading: boolean
}

const SendingSettings: React.FC<Props> = ({
  maxPerDay,
  setMaxPerDay,
  sendDelay,
  setSendDelay,
  handleSave,
  loading,
}) => {
  return (
    <Card style={{ marginTop: 20 }} size="small" title={<span>Sending Settings</span>}>
      <div style={{ display: 'flex', gap: '10' }}>
        <div style={{ marginBottom: 20 }}>
          <label htmlFor="maxPerDay" style={{ display: 'block', fontSize: 13, marginBottom: 10 }}>
            Max emails per day:
          </label>
          <InputNumber
            id="maxPerDay"
            value={maxPerDay}
            max={1000}
            onChange={(v) => v && setMaxPerDay(v)}
          />
          <div style={{ marginTop: 5, fontSize: 13, color: 'grey' }}>
            Emails are spread evenly across the sending window.{' '}
            <a
              href="https://mailarrow.notion.site/Sending-Schedules-Explained-443bdb6efd644b93996fffd08f68e22e"
              target="_blank"
              style={{ color: 'gray', textDecoration: 'underline' }}
              rel="noreferrer"
            >
              Learn more.
            </a>
          </div>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: 5 }}>
          <label htmlFor="sendDelay" style={{ display: 'block', fontSize: 13, marginBottom: 10 }}>
            Min. delay between emails (seconds):
          </label>
          <InputNumber
            id="sendDelay"
            value={sendDelay}
            min={20}
            max={300}
            onChange={(v) => v && setSendDelay(v)}
          />
        </div>
        <span style={{ fontSize: 13, color: 'grey' }}>
          Up to {sendDelay ? Math.floor(3600 / sendDelay) : 0} emails per hour. Varies by campaign
          settings.
        </span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={handleSave} loading={loading} size="small" icon={<SaveOutlined />}>
          Save
        </Button>
      </div>
    </Card>
  )
}

export default SendingSettings
