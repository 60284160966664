import React from 'react'

import EditProfile from './EditProfile'
import EditEmail from './EditEmail'
import ChangePassword from './ChangePassword'

const Profile: React.FC = () => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: '50%' }}>
        <EditProfile />
        <EditEmail />
      </div>
      <div style={{ width: '50%' }}>
        <ChangePassword />
      </div>
    </div>
  )
}

export default Profile
