import React, { useEffect, useRef, useState } from 'react'
// import Confetti from 'react-confetti'
import { Button, Typography } from 'antd'
import { SmileOutlined } from '@ant-design/icons'
import history from '../../../router/history'
import Confetti from 'react-dom-confetti'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions as UserActions } from '../../../store/UserReducer'

const { Title } = Typography

const PaymentSuccess: React.FC = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const [confetti, setConfetti] = useState(false)
  const [searchParams] = useSearchParams()
  const isTrial = searchParams.get('isTrial') === 'true'

  document.body.classList.add('full-screen')
  document.title = 'Payment Success | Mailarrow App'

  useEffect(() => {
    return () => {
      if (ref.current) return
      document.body.classList.remove('full-screen')
    }
  }, [])

  useEffect(() => {
    setConfetti(true)
    const timer = setTimeout(() => {
      setConfetti(false)
    }, 2000) // Explode confetti for 2 seconds

    return () => clearTimeout(timer)
  }, [])

  const goToDashboard = () => {
    dispatch(UserActions.getUserData())
    history.push('/app/dashboard/')
  }

  const confettiConfig = {
    angle: 90,
    spread: 180,
    startVelocity: 50,
    elementCount: 200,
    dragFriction: 0.1,
    duration: 8000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Confetti config={confettiConfig} active={confetti} />
      <SmileOutlined style={{ fontSize: '48px', color: '#52c41a', marginBottom: '20px' }} />
      <Title level={2} style={{ marginBottom: 0 }}>
        {isTrial ? 'Your trial has started' : 'Payment Successful'}
      </Title>
      <Title level={5} style={{ marginTop: 10 }}>
        Thanks a bunch!
      </Title>
      <Button style={{ marginTop: 10 }} onClick={goToDashboard}>
        Go to Dashboard
      </Button>
    </div>
  )
}

export default PaymentSuccess
