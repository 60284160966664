import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
  logger: {
    log: (message: any) => {
      console.log('[query-logger]', message)
    },
    warn: (message: any) => {
      console.log('[query-logger]', message)
    },
    error: (error: any) => {
      console.log('[query-logger]', error)
    },
  },
})
