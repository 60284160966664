import React, { FC, useEffect } from 'react'
import { Button, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const { Title } = Typography

interface Props {
  onNextStep: () => void
}

const BookDemo: FC<Props> = ({ onNextStep }) => {
  const { user } = useSelector((state: RootState) => state.user)
  const { firstName, lastName, email } = user || { firstName: '', lastName: '', email: '' }

  useEffect(() => {
    // Initialize SavvyCal Script
    const savvyCalScript = document.createElement('script')
    savvyCalScript.src = 'https://embed.savvycal.com/v1/embed.js'
    savvyCalScript.async = true
    document.body.appendChild(savvyCalScript)

    savvyCalScript.onload = () => {
      window.SavvyCal =
        window.SavvyCal ||
        function () {
          ;(window.SavvyCal.q = window.SavvyCal.q || []).push(arguments)
        }
      window.SavvyCal('init')
      window.SavvyCal('inline', {
        link: 'serge-builds/mailarrow',
        selector: '#booking-page',
        displayName: `${firstName} ${lastName}`,
        email: email,
      })
    }

    return () => {
      // Cleanup - remove the script if component is unmounted
      document.body.removeChild(savvyCalScript)
    }
  }, [])

  return (
    <div>
      <Title level={3} style={{ textAlign: 'center' }}>
        Welcome to Mailarrow
      </Title>
      <div style={{ textAlign: 'center', marginBottom: 20 }}>Would you like a private tour?</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div style={{ width: '900px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onNextStep} style={{ marginBottom: 5, fontSize: 12 }} size="small">
            Go To Dashboard <RightOutlined style={{ marginLeft: 5, fontSize: 10 }} />
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div id="booking-page" style={{ width: '900px', display: 'flex' }}></div>
      </div>
    </div>
  )
}

export default BookDemo
