export const initiateCrisp = (): void => {
  ;(window as any).$crisp = []
  ;(window as any).CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_KEY
  ;(function () {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = true
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}
