import React from 'react'
import { useFetchStatistics } from '../api/statisticsQueries'
import { useParams } from 'react-router-dom'
import Analytics from './Analytics'

interface Props {}

const AnalyticsContainer: React.FC<Props> = () => {
  const { campaignId } = useParams<{ campaignId: string }>()
  const { statistics, isLoading } = useFetchStatistics(campaignId)

  return <Analytics statistics={statistics} isLoading={isLoading} showStepView />
}

export default AnalyticsContainer
