import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Video } from './components/Video'
import { Sider } from './components/Sider'
import { SegmentationQuiz } from './components/Segmentation/SegmentationQuiz'
import { useHash } from '../../util/use-hash'
import history from '../../router/history'
import { useSaveOnboardingQuiz } from './api/onboardingMutations'
import { Onboarding } from '@mailarrow/types'
import BookDemo from './components/BookDemo'
import FinishOnboarding from './components/OnboardingSuccess'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { actions as UserActions } from '../../store/UserReducer'

const OnboardingContainer: React.FC = () => {
  const [step, setStepValue] = useState<number>(0)
  const [hash, setHash] = useHash()
  const ref = useRef()
  const { confirmationResendDone, confirmationResendLoading, user } = useSelector(
    (state: RootState) => state.user
  )
  const dispatch = useDispatch()

  const { saveOnboardingQuiz } = useSaveOnboardingQuiz()

  document.body.classList.add('full-screen')

  useEffect(() => {
    return () => {
      if (ref.current) return
      document.body.classList.remove('full-screen')
    }
  }, [])

  const setStep = useCallback(
    (step: number) => {
      setStepValue(step)
      setHash(String(step))
    },
    [setHash]
  )

  useEffect(() => {
    const parsedHash = hash.replace(/^#/, '')
    if (Number(parsedHash) !== step) {
      setStep(Number(parsedHash))
    }
  }, [hash, setStep, step])

  const onSaveOnboardingQuiz = (data: Onboarding) => {
    saveOnboardingQuiz(data)
    setStep(step + 1)
  }

  const onFinish = () => {
    dispatch(UserActions.getUserData())
    history.push('/app/dashboard/')
  }

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return <SegmentationQuiz onNextStep={onSaveOnboardingQuiz} />
      case 1:
        return <BookDemo onNextStep={() => onFinish()} />
      // return <BookDemo onNextStep={() => setStep(step + 1)} />
      // case 1:
      //   return <Video onNextStep={() => setStep(step + 1)} />
      case 2:
        return <FinishOnboarding onNextStep={() => history.push('/app/dashboard/')} />
      default:
        setStep(0)
        return null
    }
  }

  const onBookDemo = () => window.open('https://savvycal.com/serge-builds/mailarrow', '_blank')

  return (
    <div style={{ display: 'flex', marginTop: 20 }}>
      <div style={{ width: 350 }}>
        <Sider step={step} setStep={setStep} onBookDemo={onBookDemo} />
      </div>
      <div style={{ width: '100%' }}>{renderStepContent()}</div>
    </div>
  )
}

export default OnboardingContainer
