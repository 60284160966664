import React, { useState } from 'react'
import { Input, message, Modal, Dropdown, Button, Select } from 'antd'

import { useFetchCustomProperties } from '../../../../Prospects/api/customPropsQueries'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSetVariable: (slug: string, failback: string) => void
}

const VariableModal: React.FC<Props> = ({ isOpen, onClose, onSetVariable }) => {
  const [selectedProperty, setSelectedProperty] = useState('')
  const [variableDefault, setDefault] = useState('')

  const { properties, isLoading: fetchPropsLoading } = useFetchCustomProperties()

  const onFinish = () => {
    onSetVariable(selectedProperty, variableDefault)
    setDefault('')
    setSelectedProperty('')
    onClose()
  }

  return (
    <Modal
      title="Use Custom Variable"
      onCancel={onClose}
      open={isOpen}
      okButtonProps={{ loading: fetchPropsLoading, disabled: !Boolean(selectedProperty) }}
      okText="Insert"
      onOk={onFinish}
      style={{ maxWidth: 348, margin: '0 auto' }}
    >
      <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 5 }}>Variable Name:</div>
        <Select
          size="large"
          loading={fetchPropsLoading}
          onChange={(id) => setSelectedProperty(id)}
          style={{
            width: 300,
          }}
          value={selectedProperty}
          defaultValue={selectedProperty}
          options={properties?.map(({ slug: value, name: label }: any) => ({ value, label }))}
        />
      </div>
      <div style={{ marginBottom: 15, width: 300 }}>
        <div style={{ marginBottom: 5 }}>Fallback:</div>
        <Input
          name="name"
          value={variableDefault}
          size="large"
          onChange={(event) => setDefault(event.target.value)}
        />
      </div>
    </Modal>
  )
}

export default VariableModal
