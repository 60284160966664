import React, { useState } from 'react'
import UnsubscribeTable from './UnsubscribeTable'
import { Modal, Spin, TablePaginationConfig } from 'antd'
import { useFetchProspects } from '../Prospects/api/prospectsQueries'
import { useFetchCampaigns } from '../Campaigns/api/campaignQueries'

interface Props {
  isLoading: boolean
  searchFilter: string
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
}

const UnsubscribeTableContainer: React.FC<Props> = ({
  searchFilter,
  selectedProspects,
  setSelectedProspects,
}) => {
  const [currentPage, setProspectsPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()

  const { prospects, total, isFetching, isLoading, isSuccess, isError, error } = useFetchProspects(
    { search: searchFilter, isUnsubscribed: true },
    { page: currentPage, pageSize }
  )

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const currentPage = pagination.current || 1
    setProspectsPage(currentPage)
    pagination.pageSize && pagination.pageSize !== pageSize && setPageSize(pagination.pageSize)
    setSelectedProspects([])
  }

  return (
    <>
      {isLoading || isFetching ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <UnsubscribeTable
          isLoading={isLoading || isFetching || fetchCampaignsLoading}
          prospects={prospects}
          totalProspects={total || 0}
          onTableChange={handleTableChange}
          currentPage={currentPage}
          pageSize={pageSize}
          selectedProspects={selectedProspects}
          setSelectedProspects={setSelectedProspects}
          campaigns={campaigns}
        />
      )}
    </>
  )
}

export default UnsubscribeTableContainer
