import React, { FC } from 'react'
import { Button, Card, Input, Popconfirm, Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import cn from 'classnames'
import EmailStep from './Email/EmailStepContainer'
import { SequenceStep, SequenceStepVariation, isEmailSequenceStep } from '@mailarrow/types'

interface SequenceActionStepProps {
  steps: SequenceStep[]
  stepIndex: number
  stepVariationIndex: number
  isCurrentStepUnsaved: boolean
  editSequenceLoading: boolean
  onSetSteps: (steps: SequenceStep[]) => void
  onSave: () => void
}

const SequenceActionStep: FC<SequenceActionStepProps> = ({
  steps,
  stepIndex,
  stepVariationIndex,
  isCurrentStepUnsaved,
  editSequenceLoading,
  onSetSteps,
  onSave,
}) => {
  const step = steps.find((step: any) => step.stepIndex === stepIndex)
  if (!step) return <Spin />

  if (isEmailSequenceStep(step)) {
    return (
      <EmailStep
        steps={steps}
        stepIndex={stepIndex}
        stepVariationIndex={stepVariationIndex}
        isCurrentStepUnsaved={isCurrentStepUnsaved}
        editSequenceLoading={editSequenceLoading}
        onSetSteps={onSetSteps}
        onSave={onSave}
      />
    )
  }
  // when i make other steps, place them here with a switch case
  return <div>Something went wrong. Please reach out to customer support</div>
}

export default SequenceActionStep
