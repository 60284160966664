import React from 'react'
import { Card, Avatar, Space, Typography, Tag } from 'antd'
import { getGravatarUrl } from '../../../../util/get-avatar'

const { Title } = Typography

interface Props {
  firstName?: string
  lastName?: string
  email?: string
}

const CustomHeader: React.FC<Props> = ({ firstName, lastName, email }) => {
  const getName = (item: any) => {
    if (item?.firstName || item?.lastName) return `${item.firstName || ''} ${item.lastName || ''}`
    return item?.email?.split('@')[0]
  }
  return (
    <Card style={{ maxWidth: '500px', margin: '0 auto' }} size="small">
      <Space align="start" size="middle" direction="vertical" style={{ width: '100%' }}>
        <Space align="center" size="large">
          <Avatar size={48} src={email && getGravatarUrl(email)} alt={email} />
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              {getName({ firstName, lastName, email })}
            </Title>
            <div>{email}</div>
          </div>
        </Space>
      </Space>
    </Card>
  )
}

export default CustomHeader
