import { useQuery } from '@tanstack/react-query'
import { message } from 'antd'
import fetch from '../../../services/fetch'

import { PaginationSettings } from '../../../types/common'
import { EnrichedJourney } from '../types'

export interface FilterSettings {
  campaignId?: string
  search?: string
  isUnsubscribed?: boolean
  isBounced?: boolean
  isFinished?: boolean
  isPaused?: boolean
}

export interface FetchJourneysProps extends FilterSettings, PaginationSettings {}

export const buildFilterQuery = ({
  campaignId,
  page,
  pageSize,
  search,
  isBounced,
  isUnsubscribed,
  isFinished,
  isPaused,
}: FetchJourneysProps): string => {
  const params = new URLSearchParams()
  if (campaignId) {
    params.append('campaignId', campaignId)
  }
  if (page) {
    params.append('page', page.toString())
  }
  if (pageSize) {
    params.append('pageSize', pageSize.toString())
  }
  if (search) {
    params.append('search', search)
  }
  if (typeof isUnsubscribed === 'boolean') {
    params.append('isUnsubscribed', String(isUnsubscribed))
  }
  if (typeof isFinished === 'boolean') {
    params.append('isFinished', String(isFinished))
  }
  if (typeof isBounced === 'boolean') {
    params.append('isBounced', String(isBounced))
  }
  if (typeof isPaused === 'boolean') {
    params.append('isPaused', String(isPaused))
  }
  return `${params}`
}

export const fetchJourneys = async (filter: FetchJourneysProps) => {
  try {
    const params = buildFilterQuery(filter)
    const response = await fetch.get(`/journeys/?${params}`)
    const { docs, totalDocs, page } = response.data

    return {
      journeys: docs as EnrichedJourney[],
      total: totalDocs as number,
      page: page as number,
    }
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchJourneys = (filter: FilterSettings, pagination?: PaginationSettings) => {
  const { isLoading, isError, error, data, isFetching, isPreviousData, isSuccess } = useQuery(
    ['journeys', pagination?.page, pagination?.pageSize, JSON.stringify(filter)],
    () => fetchJourneys({ ...pagination, ...filter }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    journeys: data?.journeys,
    total: data?.total,
    isLoading,
    isFetching,
    isPreviousData,
    isError,
    isSuccess,
    error,
  }
}
