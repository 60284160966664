import React from 'react'
import {
  SettingOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Card, Dropdown, InputNumber, MenuProps, Modal, Select } from 'antd'
import StepTimer from './StepTimer'
import { DelayMetric, SequenceStep, isEmailSequenceStep } from '@mailarrow/types'
import { MailOutlined } from '@ant-design/icons'

interface Props {
  steps: SequenceStep[]
  onEditStepDelay: (stepIndex: number, delayMetric: DelayMetric, delayLength: number) => void
  onRemoveStep: (index: number) => void
  activeStepIndex: number
  editSequenceLoading: boolean
  onChangeActiveStep: (index: number) => void
  onAddStep: () => void
}

const StepList: React.FC<Props> = ({
  steps,
  onEditStepDelay,
  onRemoveStep,
  activeStepIndex,
  editSequenceLoading,
  onChangeActiveStep,
  onAddStep,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const onDelete = (stepIndex: number) => {
    modal.confirm({
      title: 'Please confirm deleting step',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: <>This step will be removed. This action is irreversible</>,
      okText: 'Delete this step',
      onOk: () => onRemoveStep(stepIndex),
      cancelText: 'Cancel',
    })
  }

  const getStepItems: (key: any) => MenuProps['items'] = (key: any) => [
    // {
    //   key: '1',
    //   icon: <CopyOutlined />,
    //   label: 'Copy step',
    // },
    {
      key: '2',
      icon: <DeleteOutlined />,
      label: 'Delete step',
      onClick: (info) => {
        // TODO: fix, does not work
        info.domEvent.stopPropagation()
        onDelete(Number(key))
      },
    },
  ]

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          {steps.map((step: SequenceStep, index, array) => {
            if (!isEmailSequenceStep(step)) {
              // other steps not supported yet
              return null
            }

            const { stepIndex, variations } = step

            const { subject, continueThread } = variations[0]

            const isActiveCard = stepIndex === activeStepIndex

            return (
              <div key={stepIndex}>
                <Card
                  size="small"
                  key={stepIndex}
                  style={{
                    ...(isActiveCard
                      ? { borderColor: '#fab8af', outline: '1px solid #fab8af' }
                      : { cursor: 'pointer' }),
                  }}
                  headStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                  }}
                  onClick={() => !isActiveCard && onChangeActiveStep(stepIndex)}
                  title={
                    <>
                      <MailOutlined style={{ color: 'grey' }} />
                      <span style={{ marginLeft: 10 }}>Step {stepIndex + 1}</span>
                    </>
                  }
                  extra={
                    <Dropdown menu={{ items: getStepItems(stepIndex) }}>
                      <Button type="ghost" icon={<SettingOutlined />} />
                    </Dropdown>
                  }
                >
                  <span>
                    {continueThread ? (
                      <span style={{ fontSize: 13 }}>
                        {'<'}same thread follow-up{'>'}
                      </span>
                    ) : (
                      subject || 'Email Subject'
                    )}
                  </span>
                </Card>
                {index !== array.length - 1 && (
                  <StepTimer
                    step={step}
                    onEditStepDelay={onEditStepDelay}
                    editSequenceLoading={editSequenceLoading}
                  />
                )}
              </div>
            )
          })}
        </div>
        <div style={{ marginTop: 15, textAlign: 'center' }}>
          <Button
            onClick={onAddStep}
            style={{ marginRight: '10px' }}
            icon={<PlusOutlined />}
            loading={editSequenceLoading}
            // size="small"
          >
            Add Step
          </Button>
        </div>
      </div>
      {contextHolder}
    </>
  )
}

export default StepList
