import React, { FC } from 'react'
import { Button, Radio } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

interface ExpertLevelProps {
  onNextStep: () => void
  experienceLevel: string
  setExperienceLevel: (level: string) => void
  emailsPerMonth: string
  setEmailsPerMonth: (emails: string) => void
  sendingMailboxes: string
  setSendingMailboxes: (mailboxes: string) => void
}

export const ExpertLevel: FC<ExpertLevelProps> = ({
  onNextStep,
  experienceLevel,
  setExperienceLevel,
  emailsPerMonth,
  setEmailsPerMonth,
  sendingMailboxes,
  setSendingMailboxes,
}) => {
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}>
          <strong>How would you describe yourself?</strong>
        </div>
        <div>
          <Button
            size="middle"
            style={{ marginBottom: 10 }}
            onClick={() => setExperienceLevel('beginner')}
            type={experienceLevel === 'beginner' ? 'dashed' : undefined}
            icon={experienceLevel === 'beginner' ? <CheckCircleFilled /> : undefined}
          >
            I've never used a cold email software before
          </Button>
          <br />
          <Button
            size="middle"
            style={{ marginBottom: 10 }}
            onClick={() => setExperienceLevel('medior')}
            type={experienceLevel === 'medior' ? 'dashed' : undefined}
            icon={experienceLevel === 'medior' ? <CheckCircleFilled /> : undefined}
          >
            I've run some campaigns in the past
          </Button>
          <br />
          <Button
            size="middle"
            onClick={() => setExperienceLevel('expert')}
            type={experienceLevel === 'expert' ? 'dashed' : undefined}
            icon={experienceLevel === 'expert' ? <CheckCircleFilled /> : undefined}
          >
            I'm experienced with sending cold emails at scale
          </Button>
        </div>
      </div>
      {experienceLevel !== '' && (
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <strong>How many emails do you plan to send per month?</strong>
          </div>
          <Radio.Group onChange={(e) => setEmailsPerMonth(e.target.value)} value={emailsPerMonth}>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="Less than 500">
              Less than 500
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="501 - 1,000">
              501 - 1,000
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="1,001 - 5,000">
              1,001 - 5,000
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="5,001 - 20,000">
              5,001 - 20,000
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="20,000+">
              20,000+
            </Radio>
          </Radio.Group>
        </div>
      )}
      {experienceLevel !== '' && (
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 10 }}>
            <strong>How many sending mailboxes do you have?</strong>
          </div>
          <Radio.Group
            onChange={(e) => setSendingMailboxes(e.target.value)}
            value={sendingMailboxes}
          >
            <Radio style={{ display: 'block', marginBottom: 5 }} value="None yet">
              None yet
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="1 - 2">
              1 - 2
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="3 - 10">
              3 - 10
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="11 - 20">
              11 - 20
            </Radio>
            <Radio style={{ display: 'block', marginBottom: 5 }} value="20+">
              20+
            </Radio>
          </Radio.Group>
        </div>
      )}
      <Button
        type="primary"
        onClick={onNextStep}
        disabled={!experienceLevel || !sendingMailboxes || !emailsPerMonth}
      >
        Next
      </Button>
    </div>
  )
}
