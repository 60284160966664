export const createExampleContent = (stepIndex: number, stepVariationId: string) => {
  if (false)
    return `Hello,\nreaching out \nEmail: ${stepIndex} \nA/B Variation: ${stepVariationId} \nS`

  const firstEmailCopy = `<div>Hello, friend from {{ city | default: "London" }}</div>
<p>Reaching out to you regarding our last meeting.</p>
<p>This is my ${stepIndex + 1} email</p>
<p>Hoping you are doing great</p>
<p>Sometimes i write variations: ${stepVariationId}</p>
<p>Cheers, my friend.</p>
<p>PS. Enjoy life to the fullest.</p>
`

  if (stepIndex === 0) return firstEmailCopy

  return `<div>Bumping this up in your inbox
<p>This is my ${stepIndex + 1} email</p>
<p>Hoping you are doing great</p>
<p>Sometimes i write variations: ${stepVariationId}</p>
<p>Cheers</p>
`
}

export const createExampleSubject = () => `Reaching out to you`
