import { RuleObject } from 'antd/lib/form'

const validateLinkedInUrl = (rule: RuleObject, value: string): Promise<void> => {
  // This regex pattern:
  // 1. Optionally captures the 'www.' prefix.
  // 2. Optionally captures one to three-letter subdomains (like 'in.' or 'abc.') before the LinkedIn domain.
  // 3. Captures 'linkedin.' followed by one to three-letter TLDs.
  // 4. Ensures the URL has '/in/' followed by one or more word characters (and hyphens), with an optional trailing slash.
  const websiteUrlRegex = /^https:\/\/(www\.)?([a-z]{1,3}\.)?linkedin\.([a-z]{1,3})\/in\/[\w-]+\/?$/

  if (!value || websiteUrlRegex.test(value)) {
    return Promise.resolve()
  }
  return Promise.reject('Please enter a valid LinkedIn profile URL')
}

export const rules: Record<string, any> = {
  linkedin: [
    {
      validator: validateLinkedInUrl,
    },
  ],
}
