import React, { FC } from 'react'
import { Button, Typography, Tabs, Spin, Popconfirm, Dropdown, Menu, Tooltip, Tag } from 'antd'
import {
  EditOutlined,
  PauseOutlined,
  RightCircleOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownOutlined,
} from '@ant-design/icons'

import { SendingCalendar } from '../../../Calendar'
import CampaignMenuOptions from './CampaignMenuOptions'
import { Analytics } from '../../../Analytics'
import { ErrorsContainer } from '../../../Errors'
import ProspectsContainer from '../../../Prospects/ProspectsContainer'
import { SequenceBuilder } from '../../../Sequence'
import SettingsContainer from '../../CampaignCreator/Settings/SettingsContainer'
import { Preview } from '../../../Preview'
import { ActivitySection } from '../../../Activity'
import { usePermissions } from '../../../User/Permissions'
import { Campaign as CampaignT, Mailbox } from '@mailarrow/types'
import { MailboxTag } from '../../../Mailboxes/components/MailboxTag/MailboxTag'
import JourneysContainer from '../../../Journeys/JourneysContainer'

const { Title } = Typography

interface CampaignProps {
  // TODO: fix types
  loading: boolean
  mailboxes?: Mailbox[]
  campaign?: CampaignT
  changeCampaignStatus: (status: boolean) => void
  copyCampaign: () => void
  removeCampaign: () => void
  campaignId: string
  currentTabIndex: number
  onChangeTabIndex: (tabIndex: number) => void
}

const Campaign: FC<CampaignProps> = ({
  mailboxes,
  loading,
  campaign,
  changeCampaignStatus,
  copyCampaign,
  removeCampaign,
  campaignId,
  currentTabIndex,
  onChangeTabIndex,
}) => {
  const { staff } = usePermissions()
  if (loading) {
    return <Spin />
  }

  const tabList: any = [
    { key: '0', label: 'Overview', children: <Analytics /> },
    staff && { key: '4', label: 'Activity', children: <ActivitySection campaignId={campaignId} /> },
    // { key: '5', label: 'Prospects', children: <ProspectsContainer campaignId={campaignId} /> },
    { key: '5', label: 'Prospects', children: <JourneysContainer campaignId={campaignId} /> },
    { key: '6', label: 'Sequence', children: <SequenceBuilder /> },
    { key: '7', label: 'Settings', children: <SettingsContainer campaignId={campaignId} /> },
    staff && { key: '8', label: 'Errors', children: <ErrorsContainer campaignId={campaignId} /> },
    // staff && { key: '9', label: 'Calendar', children: <SendingCalendar /> },
    // staff && { key: '10', label: 'Preview', children: <Preview /> },
  ].filter(Boolean)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Tooltip title={campaign?.isPaused ? `Start Campaign` : `Pause Campaign`}>
            <Button
              type="primary"
              icon={campaign?.isPaused ? <RightCircleOutlined /> : <PauseOutlined />}
              onClick={() => changeCampaignStatus(!campaign?.isPaused)}
              style={{ marginRight: '10px' }}
            />
          </Tooltip>
          <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
            {campaign?.name}
          </Title>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 5,
          }}
        >
          <span>
            Status:{' '}
            {campaign?.isPaused ? (
              <Tag color="orange">Paused</Tag>
            ) : (
              <Tag color="green">Active</Tag>
            )}
          </span>
          <span>
            Send From: <MailboxTag mailboxes={mailboxes} /> <br />
          </span>

          <Dropdown
            overlay={
              <CampaignMenuOptions
                {...{ campaign, copyCampaign, removeCampaign }}
                changeCampaignStatus={() => {}}
              />
            }
          >
            <Button size="small">
              Options <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div style={{ margin: '10px 0', display: 'none' }}>
        <Button
          icon={campaign?.isPaused ? <RightCircleOutlined /> : <PauseOutlined />}
          onClick={() => changeCampaignStatus(!campaign?.isPaused)}
          style={{ marginRight: '10px' }}
        >
          {campaign?.isPaused ? `Start` : `Pause`} Campaign
        </Button>
        <Popconfirm
          title={
            <>
              Are you sure delete this campaign? <br /> This action is irreversible.
            </>
          }
          placement="bottom"
          onConfirm={removeCampaign}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteOutlined />} style={{ marginRight: '10px' }}>
            Remove Campaign
          </Button>
        </Popconfirm>
        <Button icon={<CopyOutlined />} onClick={copyCampaign} style={{ marginRight: '10px' }}>
          Copy Campaign
        </Button>
      </div>
      <Tabs
        onChange={(key) => onChangeTabIndex(Number(key))}
        activeKey={String(currentTabIndex)}
        items={tabList}
      ></Tabs>
    </div>
  )
}

export default Campaign
