import React, { useState, FC, useMemo, useEffect } from 'react'
import ProspectsTable from './ProspectsTable'
import { useUpdateProspect } from '../../api/prospectsMutations'
import { Modal, Spin, TablePaginationConfig } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ProspectEditorContainer from '../ProspectEditor/ProspectEditorContainer'
import { filterProspects } from './filter'
import { Prospect } from '@mailarrow/types'
import { useFetchProspects } from '../../api/prospectsQueries'

interface Props {
  campaignId?: string
  isLoading: boolean
  searchFilter: string
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
  onRemoveProspects: (prospects: any) => void
}

const ProspectsTableContainer: React.FC<Props> = ({
  campaignId,
  searchFilter,
  selectedProspects,
  setSelectedProspects,
  onRemoveProspects,
}) => {
  const [modal, contextHolder] = Modal.useModal()
  const [editProspectId, setEditProspectId] = useState<string | undefined>(undefined)
  const [currentPage, setProspectsPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)

  const { updateProspect, isLoading: updateProspectLoading } = useUpdateProspect()
  const { prospects, total, isFetching, isLoading, isSuccess, isError, error } = useFetchProspects(
    { search: searchFilter, campaignId },
    { page: currentPage, pageSize }
  )

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    const currentPage = pagination.current || 1
    setProspectsPage(currentPage)
    pagination.pageSize && pagination.pageSize !== pageSize && setPageSize(pagination.pageSize)
    setSelectedProspects([])
  }

  const onEditProspect = (prospectId: string) => {
    setEditProspectId(prospectId)
  }

  const onPauseProspect = (prospectId: string, isPaused: boolean) => {
    updateProspect({ prospectId, prospectProps: { isPaused } })
  }

  const onUnsubscribeProspect = (prospectId: string, isUnsubscribed: boolean) => {
    updateProspect({ prospectId, prospectProps: { isUnsubscribed } })
  }

  const onRemoveProspectClick = (prospectId: string) => {
    modal.confirm({
      title: campaignId ? 'Confirm removing prospect from campaign' : 'Confirm deleting prospect',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: campaignId ? (
        <>
          This prospect will be removed from this campaign. If you want to delete the prospect
          completely, you can do it on the Prospects page
        </>
      ) : (
        <>This prospect will be removed from your database. This action is irreversible.</>
      ),
      okText: 'Remove',
      onOk: () => onRemoveProspects([prospectId]),
      cancelText: 'Cancel',
    })
  }

  return (
    <>
      {isLoading || isFetching ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <ProspectsTable
          isLoading={isLoading || isFetching}
          prospects={prospects}
          totalProspects={total || 0}
          onTableChange={handleTableChange}
          currentPage={currentPage}
          pageSize={pageSize}
          onRemoveProspect={onRemoveProspectClick}
          onEditProspect={onEditProspect}
          selectedProspects={selectedProspects}
          setSelectedProspects={setSelectedProspects}
          onPauseProspect={onPauseProspect}
          onUnsubscribeProspect={onUnsubscribeProspect}
        />
      )}
      <ProspectEditorContainer
        prospectId={editProspectId}
        onClose={() => setEditProspectId(undefined)}
      />
      {contextHolder}
    </>
  )
}

export default ProspectsTableContainer
