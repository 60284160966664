// import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'

// not being encrypted for now
const queryEncryptionKey = 'marshmellow'

export const encodeQueryString = (queryParams: Record<string, string | number>) => {
  const queryString = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParams)) {
    queryString.append(key, value.toString())
  }

  const queryStringStr = queryString.toString()
  if (!queryStringStr) return ''

  // Convert the encrypted object to a string
  const encryptedQueryString = Buffer.from(queryStringStr).toString('base64url')

  return encryptedQueryString
}

export const decodeQueryString = (encodedQuery: string): null | Record<string, any> => {
  if (!encodedQuery) return null

  const decodedURI = Buffer.from(encodedQuery, 'base64url').toString()

  const params = new URLSearchParams(decodedURI)
  const result: Record<string, string | number> = {}
  for (const [key, value] of params.entries()) {
    result[key] = value
  }

  return result
}
