import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input

interface Props {
  setProspects: (prospects: string) => void
  prospects: string
  disabled: boolean
}

const ProspectsTextArea: React.FC<Props> = ({ prospects, setProspects, disabled }) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <div style={{ marginBottom: '10px' }}>
        Provide one email address per line. Feel free to paste emails from a CSV file. Invalid
        emails won't be uploaded.
      </div>
      <TextArea
        autoSize={{ minRows: 4, maxRows: 20 }}
        onChange={(event) => setProspects(event.target.value)}
        style={{ fontFamily: 'Source Code Pro,sans-serif' }}
        defaultValue={prospects}
        value={prospects}
        allowClear
        placeholder="Type email addresses manually"
        showCount
        disabled={disabled}
        maxLength={10000}
      />
    </div>
  )
}

export default ProspectsTextArea
