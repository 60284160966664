import React from 'react'
import { List, Modal } from 'antd'
import { orderBy } from 'lodash'

interface Props {
  isOpen: boolean
  onClose: () => void
  errors?: any[]
}

const ImportErrorsModal: React.FC<Props> = ({ isOpen, onClose, errors }) => {
  return (
    <Modal
      title="Import errors"
      onCancel={onClose}
      open={isOpen}
      footer={<></>}
      style={{ maxWidth: 600, margin: '0 auto' }}
    >
      <div>The following prospects will not be uploaded. Only first 50 errors shown.</div>
      <List
        dataSource={orderBy(errors?.slice(0, 50), 'rowIndex')}
        renderItem={(item: any) => (
          <List.Item>
            <strong style={{ minWidth: 75, display: 'inline-block' }}>
              Row {item.rowIndex + 1}:
              {/*
                +1 to compensate for the index offset starting with 0
               */}
            </strong>
            <span style={{ width: '100%', display: 'inline-block', overflow: 'hidden' }}>
              {item.errors[0]}
              <br />({item.email})
            </span>
          </List.Item>
        )}
        pagination={{ position: 'both', align: 'end' }}
      />
    </Modal>
  )
}

export default ImportErrorsModal
