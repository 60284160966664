import React from 'react'
import moment from 'moment'
import { Badge, Table, Button } from 'antd'
import ReactJson from 'react-json-view'
import _ from 'lodash'
import dayjs from 'dayjs'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { actions as UserActions } from '../../../store/UserReducer'
import { useDispatch } from 'react-redux'

interface UsersListTableProps {
  users?: any[]
  isLoading: boolean
}

const UsersListTable: React.FC<UsersListTableProps> = ({ users, isLoading }) => {
  const dispatch = useDispatch()

  const expandedRowRender = (record: any) => (
    <div>
      <ReactJson src={record} />
    </div>
  )

  const getColumns = () => [
    {
      title: 'UserId',
      dataIndex: 'userId',
      key: 'userId',
      render: (r: any, item: any) => <div style={{ fontSize: 12 }}>{item.userId}</div>,
    },
    {
      title: 'Personal Data',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      render: (r: any, item: any) => (
        <>
          {item.email}
          <br />
          {item.firstName} {item.lastName}
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (r: any, item: any) => dayjs(item.createdAt).format('D/MM, hh:mm'),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (v: any, r: any) => (
        <>
          {r.isActive ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'yellow' }} />
          )}
        </>
      ),
    },
    {
      title: 'Verified',
      dataIndex: 'Verified',
      key: 'Verified',
      render: (v: any, r: any) => (
        <>
          {r.isVerified ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          )}
        </>
      ),
    },
    {
      title: 'Susp.',
      dataIndex: 'suspended',
      key: 'suspended',
      render: (v: any, r: any) => (
        <>
          {r.isBlocked && 'Blocked'}
          {r.isCancelled && 'Cancelled'}
          {r.isRemoved && 'Removed'}
        </>
      ),
    },
    {
      title: 'Onboarding',
      dataIndex: 'onboarding',
      key: 'onboarding',
      render: (v: any, r: any) => (
        <>
          {r.onboarding ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'grey' }} />
          )}
        </>
      ),
    },
    {
      title: 'Signup Info',
      dataIndex: 'signupType',
      key: 'signupType',
      render: (r: any, item: any) => (
        <>
          {item.signupType}
          <br />
          {item.signupSource}
        </>
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (r: any, item: any) => (
        <>
          <Button
            size="small"
            onClick={() => dispatch(UserActions.monitorImpersonateOn(item.userId))}
          >
            I
          </Button>
        </>
      ),
    },
  ]

  const data = users || []
  const loading = isLoading

  console.log('about to render table with data', data)

  return (
    <Table
      dataSource={_.sortBy(data, 'created').reverse()}
      style={{ fontSize: '12px !important' }}
      columns={getColumns()}
      pagination={{
        pageSize: 30,
        hideOnSinglePage: true,
      }}
      expandedRowRender={expandedRowRender}
      loading={loading}
      rowKey="dateScheduled"
      size="small"
      bordered={false}
    />
  )
}

export default UsersListTable
