import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { FeatureToggles } from '@mailarrow/types'

export const featureConfig: Record<FeatureToggles, boolean> = {
  [FeatureToggles.ShortSendDelay]: false,
  [FeatureToggles.GmailSSO]: false,
  [FeatureToggles.OutlookSSO]: false,
}

const isAdmin = (email: string | undefined): boolean => {
  if (!email) return false

  const allowedPartials = [
    'sergiy.shlykov@gmail.com',
    // '@luga.cc',
    'razzeond@gmail.com',
  ]
  return allowedPartials.some((p) => email.includes(p))
}

export function useFeatureToggle(feature: FeatureToggles): boolean {
  const { user } = useSelector((state: RootState) => state.user)

  if (isAdmin(user?.email)) {
    // for admin all feature toggles are enabled
    // return true
  }

  // const featureToggles = [{ name: 'ShortSendDelay', isEnabled: true }]
  const mappedOverrides = user?.featureToggles.reduce((obj, item) => {
    return {
      ...obj,
      [FeatureToggles[item.name as keyof typeof FeatureToggles]]: item.isEnabled,
    }
  }, {} as Record<FeatureToggles, boolean>)

  const toggles = {
    ...featureConfig,
    ...mappedOverrides,
  }

  return toggles[feature]
}
