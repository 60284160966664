import { flatten, groupBy, partition } from 'lodash'
import { isEmailAddressValid } from './is-email-valid'

export const validateProspects = (prospects: any[]): [any[], any] => {
  if (!prospects || !prospects.length) return [[], []]

  const addError = (errors: string[] | undefined, error?: string) => {
    if (!error) return errors
    return (errors || []).concat(error)
  }

  const prospectsWithErrors = prospects.map((p) => {
    const isEmailValid = isEmailAddressValid(p.email)
    if (isEmailValid) return p
    return { ...p, errors: addError(p.errors, 'Email is not valid') }
  })

  const groupedByEmail = groupBy(prospectsWithErrors, 'email')
  const dedupedProspects = Object.values(groupedByEmail).map((group: any) => {
    const [firstProspect, ...duplicates] = group

    const duplicatesWithErrors = duplicates.map((p) => {
      const errors = addError(p.errors, 'Duplicate prospect email')
      return { ...p, errors }
    })

    return [firstProspect, ...duplicatesWithErrors]
  })

  const validatedProspects = flatten(dedupedProspects)

  const [validProspects, invalidProspects] = partition(
    validatedProspects,
    (p) => !p.errors || p.errors.length === 0
  )

  return [validProspects, invalidProspects]
}
