import { Button, Spin, TablePaginationConfig, Typography } from 'antd'
import { useFetchEmail } from './api/emailQueries'
import EmailsTable from './EmailsTable'
import { ReloadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useFetchCampaigns } from '../Campaigns/api/campaignQueries'

const { Title } = Typography

interface Props {
  campaignId?: string
}

const ActivityContainer: React.FC<Props> = ({ campaignId }) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const {
    emails,
    total,
    isLoading: fetchEmailsLoading,
    isFetching: fetchEmails2Loading,
    refetch,
  } = useFetchEmail({ campaignId }, { page: currentPage, pageSize })
  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()

  const onTableChange = async (pagination: TablePaginationConfig) => {
    const currentPage = pagination.current || 1
    setCurrentPage(currentPage)
    pagination.pageSize && pagination.pageSize !== pageSize && setPageSize(pagination.pageSize)
  }

  const onRefresh = () => {
    setCurrentPage(1)
    refetch()
  }

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={onRefresh} icon={<ReloadOutlined />}>
          Refresh
        </Button>
      </div>

      <EmailsTable
        emails={emails}
        total={total || 0}
        onTableChange={onTableChange}
        currentPage={currentPage}
        pageSize={pageSize}
        campaigns={campaigns}
        loading={fetchEmailsLoading || fetchEmails2Loading || fetchCampaignsLoading}
        isAdminView
      />
    </div>
  )
}

export default ActivityContainer
