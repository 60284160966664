import React, { useState } from 'react'
import {
  Button,
  Input,
  Avatar,
  Typography,
  Breadcrumb,
  message,
  Space,
  Checkbox,
  Select,
  Spin,
  List,
} from 'antd'

import { Card, Divider } from 'antd'
import ProspectList from './ProspectList'
import EmailPreview from './EmailPreview'
import { Mailbox, Prospect, Sequence } from '@mailarrow/types'

interface Props {
  sequence?: Sequence
  prospects?: Prospect[]
  loading: boolean
  selectedProspectId: string | undefined
  onSelectProspect: (prospectId: string) => void
  onEditProspect: (prospectId: string) => void
  stepIndex: number | undefined
  onChangeStepIndex: (index: number) => void
  filter: string
  setFilter: (filter: string) => void
  total: number
  onPageChange: (one: any, two: any) => void
  page: number
  pageSize: number
  mailboxes?: Mailbox[]
}

const Preview: React.FC<Props> = ({
  sequence,
  prospects,
  loading,
  selectedProspectId,
  onSelectProspect,
  onEditProspect,
  stepIndex,
  onChangeStepIndex,
  filter,
  setFilter,
  total,
  onPageChange,
  page,
  pageSize,
  mailboxes,
}) => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: '30%' }}>
        <ProspectList
          prospects={prospects}
          loading={loading}
          selectedProspectId={selectedProspectId}
          onSelectProspect={onSelectProspect}
          onEditProspect={onEditProspect}
          filter={filter}
          setFilter={setFilter}
          total={total || 0}
          onPageChange={onPageChange}
          page={page}
          pageSize={pageSize}
        />
      </div>
      <div>
        <Divider type="vertical" style={{ height: '100%' }} />
      </div>
      <div style={{ width: '70%' }}>
        <EmailPreview
          prospects={prospects}
          sequence={sequence}
          mailboxes={mailboxes}
          loading={loading}
          prospectId={selectedProspectId}
          stepIndex={stepIndex}
          onChangeStepIndex={onChangeStepIndex}
        />
      </div>
    </div>
  )
}

export default Preview
