import { Prospect, SequenceStep } from '@mailarrow/types'
import { Liquid } from 'liquidjs'
const engine = new Liquid()

const renderTemplate = async (template: any, prospect: any) => {
  const { email, properties } = prospect
  const variables = { email, ...properties }

  const tpl = engine.parse(template)
  const result = await engine.render(tpl, variables)
  return result
}

export const createEmail = async (
  sequenceStep: SequenceStep,
  prospect: Prospect,
  fromEmail: string
) => {
  const { stepIndex, variations } = sequenceStep
  const { subject, body } = variations[0]
  const rendedSubject = await renderTemplate(subject, prospect)
  const renderedBody = await renderTemplate(body, prospect)
  return {
    subject: rendedSubject,
    body: renderedBody,
    prospectEmail: prospect.email,
    stepIndex,
    fromEmail,
    // cc: [],
    // bcc: [],
    // attachments
  }
}
