import React, { useState } from 'react'
import { Button, Typography, Card } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

interface Props {
  fileName: string
  data: string[][]
  onRemoveUpload: () => void
}

const FileInfo: React.FC<Props> = ({ fileName, data, onRemoveUpload }) => {
  return (
    <Card
      title={<span style={{ fontSize: 14 }}>File Uploaded</span>}
      extra={
        <Button icon={<DeleteOutlined />} onClick={onRemoveUpload} style={{ marginLeft: 16 }}>
          Remove
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: 12 }}>
          <Typography.Text strong style={{ minWidth: 80, display: 'inline-block' }}>
            File Name:
          </Typography.Text>{' '}
          {fileName}
        </div>
        <div>
          <Typography.Text strong style={{ minWidth: 80, display: 'inline-block' }}>
            Rows:
          </Typography.Text>{' '}
          {data.length}
        </div>
      </div>
    </Card>
  )
}

export default FileInfo
