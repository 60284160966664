import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'antd'
import { CheckCircleOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons'
import { actions as UserActions } from '../../../store/UserReducer'
import { actions as AuthActions } from '../../../modules/Auth/reducer'
import { RootState } from '../../../store/store'

const EmailVerification: React.FC = () => {
  const { confirmationResendDone, confirmationResendLoading, user } = useSelector(
    (state: RootState) => state.user
  )
  const dispatch = useDispatch()

  const resendConfirmation = () => {
    dispatch(UserActions.sendConfirmation())
  }

  const handleSignOut = () => {
    dispatch(AuthActions.signOutUser())
  }

  return (
    <div className="signup-form">
      <Card
        title={<div style={{ textAlign: 'center' }}>You've Got Mail</div>}
        className="signup-card"
        headStyle={{ fontSize: 20 }}
        style={{ width: '500px' }}
      >
        <div style={{ textAlign: 'left' }}>
          <span style={{ margin: '10px 0 20px 0', display: 'block' }}>
            We have sent a confirmation link to <strong>{user?.email}</strong>. Verify your address
            and we'll get your account set up.
          </span>
          {confirmationResendDone ? (
            <Button style={{ width: '100%' }} icon={<CheckCircleOutlined />} type="dashed" disabled>
              Email sent
            </Button>
          ) : (
            <Button
              style={{ width: '100%' }}
              icon={confirmationResendLoading ? <LoadingOutlined /> : <MailOutlined />}
              type="dashed"
              onClick={resendConfirmation}
              disabled={confirmationResendLoading}
            >
              Resend Confirmation
            </Button>
          )}
        </div>
      </Card>
      <div>
        Not Your Email?&nbsp;
        <span style={{ cursor: 'pointer', color: '#1890ff' }} onClick={handleSignOut}>
          Logout
        </span>
      </div>
    </div>
  )
}

export default EmailVerification
