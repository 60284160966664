import React, { useState, useEffect } from 'react'
import { Button, Card, Statistic } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import MailboxListTable from './MailboxListTable'
import { useFetchMailboxes } from '../api/mailboxesQueries'
import { useRemoveMailbox } from '../api/mailboxesMutations'
import AddMailboxModal from '../ConnectMailbox/AddMailbox/AddMailboxModalContainer'

interface Props {}

const MailboxList: React.FC<Props> = () => {
  const [isAddMailboxModalVisible, setAddMailboxModalVisible] = useState(false)

  const fetchMailboxes = useFetchMailboxes()
  const { mailboxes, isLoading: fetchMailboxesLoading } = fetchMailboxes
  const { removeMailbox } = useRemoveMailbox()

  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Mail Accounts | MailArrow'
  }, [dispatch])
  const onRemoveMailbox = (mailboxId: string) => {
    removeMailbox(mailboxId)
  }

  const onModalClose = () => {
    setAddMailboxModalVisible(false)
  }

  return (
    <div style={{ width: '100%' }}>
      <Card style={{ width: 200, margin: '10px 0' }}>
        <Statistic
          title="Active Accounts"
          value={mailboxes?.length}
          suffix="/ ∞"
          loading={fetchMailboxesLoading}
        />
      </Card>

      <Button
        style={{ margin: '10px 10px 10px 10px' }}
        icon={<PlusOutlined />}
        onClick={() => setAddMailboxModalVisible(true)}
      >
        Connect New Mailbox
      </Button>
      <MailboxListTable
        loading={fetchMailboxesLoading}
        mailboxes={mailboxes}
        removeMailbox={onRemoveMailbox}
      />
      <AddMailboxModal visible={isAddMailboxModalVisible} onClose={onModalClose} />
    </div>
  )
}

export default MailboxList
