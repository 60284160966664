import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Tabs, Typography } from 'antd'

import './Settings.css'
import history from '../../router/history'

const { Title } = Typography

const SettingsTabs: React.FC = () => {
  const navigate = useNavigate()

  const tabs = [
    // { path: 'general', label: 'General' },
    { path: 'mailboxes', label: 'Mail Accounts' },
    { path: 'profile', label: 'Profile' },
    // { path: 'team', label: 'Team' },
    // { path: 'subscription', label: 'Subscription' },
    { path: 'billing', label: 'Billing' },
    // { path: 'integrations', label: 'Integrations' },
  ]

  const getActiveTopLevelRoutePath = () => {
    const currentPath = window.location.pathname
    const matchingTab = tabs.find((tab) => currentPath.startsWith(`/app/settings/${tab.path}`))
    return matchingTab ? matchingTab.path : ''
  }

  const activeRoutePath = getActiveTopLevelRoutePath()
  if (!activeRoutePath) {
    history.push(`/app/settings/${tabs[0].path}`)
  }

  const handleTabChange = (key: string) => {
    navigate(`/app/settings/${key}`)
  }

  return (
    <Tabs
      defaultActiveKey={activeRoutePath}
      activeKey={activeRoutePath}
      onChange={handleTabChange}
      items={tabs.map((tab) => ({ key: tab.path, label: tab.label }))}
    />
  )
}

const Settings: React.FC = () => {
  useEffect(() => {
    document.title = 'Account Settings | Mailarrow App'
  }, [])

  return (
    <div>
      <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
        Settings
      </Title>
      <div>
        <SettingsTabs />
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Settings
