import React, { useState } from 'react'
import { Button, Typography, Modal, Tooltip, Spin } from 'antd'
import {
  CloseOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Mailbox } from '@mailarrow/types'
import history from '../../../router/history'
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons'
import ReconnectMailboxModalContainer from '../ConnectMailbox/ReconnectMailbox/ReconnectMailboxModal'
import { ErrorsContainer } from '../../Errors'
import SendingSettings from './SendingSettings'
import MailboxDetails from './MailboxDetails'
import EditSMTPMailboxContainer from '../ConnectMailbox/mailboxes/smtp/EditSMTPMailbox'
import { usePermissions } from '../../User/Permissions'
import TrackingDomain from './TrackingDomain'
import { useFetchMailboxStats } from '../../Analytics/api/statisticsQueries'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const { Text, Title } = Typography

interface Props {
  mailbox?: Mailbox
  sendErrorCount?: number
  loading: boolean
  removeMailboxLoading: boolean
  updateMailbox: (mailboxId: string, mailbox: Partial<Mailbox>) => void
  updateLoading: boolean
  removeMailbox: (mailboxId: string) => void
  sendTestEmailLoading: boolean
  sendTestEmail: (mailboxId: string) => void
  checkMailboxStatusLoading: boolean
  onCheckMailboxStatus: (mailboxId: string) => void
}

const EditMailbox: React.FC<Props> = ({
  mailbox,
  sendErrorCount,
  loading,
  removeMailboxLoading,
  updateMailbox,
  updateLoading,
  removeMailbox,
  sendTestEmailLoading,
  sendTestEmail,
  checkMailboxStatusLoading,
  onCheckMailboxStatus,
}) => {
  const [modal, contextHolder] = Modal.useModal()
  const [isReconnectOpen, setReconnectOpen] = useState(false)
  const [isEditSMTPOpen, setEditSMTPOpen] = useState(false)
  const [name, setName] = useState(mailbox?.name || '')
  const [signature, setSignature] = useState(mailbox?.signature || '')
  const [isPaused, setPaused] = useState(mailbox?.isPaused || false)
  const [maxPerDay, setMaxPerDay] = useState(mailbox?.maxPerDay || 100)
  const [sendDelay, setSendDelay] = useState(mailbox?.sendDelay || 30)

  const date = dayjs().tz('GMT').startOf('day').toISOString()
  const mailboxIds = mailbox ? [mailbox.mailboxId] : []
  const { stats } = useFetchMailboxStats(mailboxIds, date)
  const stat = stats?.find((s: any) => dayjs(s.date).tz('UTC').isSame(date, 'day'))
  const sentToday = stat?.count || 0
  const permissions = usePermissions()

  const handleSave = () => {
    if (!mailbox) return

    updateMailbox(mailbox.mailboxId, {
      name,
      signature,
      isPaused,
      maxPerDay,
      sendDelay,
    })
  }

  const onReconnectMailbox = () => {
    setReconnectOpen(true)
  }

  const onDeleteMailbox = (mailboxId: string) => {
    modal.confirm({
      title: 'Please confirm removing this mailbox',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          This mailbox will be disconnected. All campaigns sent from this email address will be
          stopped.
        </>
      ),
      okText: 'Disconnect',
      onOk: () => removeMailbox(mailboxId),
      cancelText: 'Cancel',
    })
  }

  if (!mailbox) return <Spin />

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push('/app/settings/mailboxes/')}
          >
            Back
          </Button>
          <div style={{ marginLeft: 20 }}>
            Mailbox: <Text strong>{mailbox?.email}</Text>
            {mailbox?.connectionStatus === 'requires_reauth' && (
              <Tooltip title="Please Reconnect Mailbox">
                <ExclamationCircleOutlined
                  style={{ color: 'orange', fontSize: 12, marginLeft: 5 }}
                />
              </Tooltip>
            )}
          </div>
          {/* <div style={{ display: 'flex' }}>
            <Switch
              id="paused"
              disabled
              style={{ marginLeft: 10 }}
              checked={isPaused}
              onChange={(isPaused: boolean) => setPaused(isPaused)}
            />
            <label htmlFor="paused" style={{ marginLeft: 10 }}>
              Paused
            </label>
          </div> */}
        </div>
        <div>
          <Button
            onClick={() => mailbox && sendTestEmail(mailbox.mailboxId)}
            loading={sendTestEmailLoading}
            icon={<MailOutlined />}
            style={{ marginRight: 10 }}
            key="mail"
          >
            Send Test Email
          </Button>
          <Button
            disabled={!mailbox}
            loading={removeMailboxLoading}
            onClick={() => mailbox && onDeleteMailbox(mailbox?.mailboxId)}
            icon={<DeleteOutlined />}
          >
            Remove Mailbox
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 20 }}>
        <div style={{ width: '50%' }}>
          <MailboxDetails
            mailbox={mailbox}
            sendErrorCount={sendErrorCount}
            checkMailboxStatusLoading={checkMailboxStatusLoading}
            onCheckMailboxStatus={onCheckMailboxStatus}
            onReconnectMailbox={onReconnectMailbox}
            isPaused={isPaused}
            setPaused={setPaused}
            onEditSettings={() => setEditSMTPOpen(true)}
            sentToday={sentToday}
          />
          {permissions.staff && <ErrorsContainer mailboxId={mailbox.mailboxId} />}
        </div>
        <div style={{ width: '50%' }}>
          <SendingSettings
            maxPerDay={maxPerDay}
            setMaxPerDay={setMaxPerDay}
            sendDelay={sendDelay}
            setSendDelay={setSendDelay}
            handleSave={handleSave}
            loading={updateLoading}
          />
          <TrackingDomain mailbox={mailbox} />
        </div>
      </div>

      {/* <div style={{ marginBottom: 10 }}>
        <label htmlFor="name">From Name:</label>
        <Input id="name" value={Name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div style={{ marginBottom: 10 }}>
        <label htmlFor="signature">Signature:</label>
        <Input.TextArea
          id="signature"
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
        />
      </div>

      <div>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div> */}
      {contextHolder}
      <ReconnectMailboxModalContainer
        visible={isReconnectOpen}
        mailbox={mailbox}
        onClose={() => setReconnectOpen(false)}
      />
      <EditSMTPMailboxContainer
        open={isEditSMTPOpen}
        mailbox={mailbox}
        onClose={() => setEditSMTPOpen(false)}
      />
    </div>
  )
}

export default EditMailbox
