import { Campaign, Mailbox } from '@mailarrow/types'
import { ensureNumber } from '../../../util/ensure-number'

export const enrichCampaign = (campaign: Campaign, statistics: any, mailboxes: any) => {
  if (!campaign) return
  if (!statistics) return campaign

  const { campaignId } = campaign
  const campaignStats = statistics[campaignId]

  const { total, byDay, byStep } = campaignStats
  const { sent, replied, opened, clicked, bounced, unsubscribed } = total

  const openedPercentage = ensureNumber(Math.ceil((opened / sent) * 100))
  const repliedPercentage = ensureNumber(Math.ceil((replied / sent) * 100))
  const clickedPercentage = ensureNumber(Math.ceil((clicked / sent) * 100))
  const bouncedPercentage = ensureNumber(Math.ceil((bounced / sent) * 100))
  const unsubscribedPercentage = ensureNumber(Math.ceil((unsubscribed / sent) * 100))

  const mailboxEmails = mailboxes
    .filter((mailbox: Mailbox) => campaign.mailboxIds.includes(mailbox.mailboxId))
    .map((m: any) => m.email)

  return {
    ...campaign,
    mailboxEmails: mailboxEmails,
    sent,
    openedPercentage,
    repliedPercentage,
    clickedPercentage,
    bouncedPercentage,
    unsubscribedPercentage,
  }
}
