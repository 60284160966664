import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message, notification } from 'antd'
import { Mailbox } from '@mailarrow/types'

const createMailbox = async (mailboxDetails: Mailbox) => {
  await fetch.put(`/mailboxes/all/`, { mailboxDetails })
}

export const useCreateMailbox = () => {
  const queryClient = useQueryClient()

  const { reset, mutate, isLoading, isError, isSuccess, error } = useMutation(
    (mailboxDetails: Mailbox) => createMailbox(mailboxDetails),
    {
      onSuccess: () => {
        notification.success({
          message: 'Success',
          description: 'Your mailbox has been created',
        })
        queryClient.invalidateQueries(['mailboxes'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    createMailbox: mutate,
    reset,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const updateMailbox = async (mailboxId: string, mailboxProps: Partial<Mailbox>) => {
  // forcing connected status because mailbox is updated after validation in the UI
  await fetch.patch(`/mailboxes/all/${mailboxId}`, {
    mailboxProps,
  })
}

export const useUpdateMailbox = () => {
  const queryClient = useQueryClient()

  const { mutate, reset, isLoading, isError, isSuccess, error } = useMutation(
    (args: { mailboxId: string; mailboxProps: Partial<Mailbox> }) =>
      updateMailbox(args.mailboxId, args.mailboxProps),
    {
      onSuccess: () => {
        notification.success({
          message: 'Success',
          description: 'Your mailbox has been updated',
        })
        queryClient.invalidateQueries(['mailboxes'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    updateMailbox: mutate,
    reset,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const removeMailbox = async (mailboxId: string) => {
  await fetch.delete(`/mailboxes/all/${mailboxId}`)
}

export const useRemoveMailbox = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (mailboxId: string) => removeMailbox(mailboxId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['mailboxes'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    removeMailbox: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

interface SendTestEmailProps {
  mailboxId: string
  body?: string
  prospectEmail?: string
  subject?: string
}

const sendTestEmail = async ({ mailboxId, body, prospectEmail, subject }: SendTestEmailProps) => {
  return await fetch.put(`/mailboxes/test-email/${mailboxId}`, { body, prospectEmail, subject })
}

export const useSendTestEmail = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: SendTestEmailProps) => sendTestEmail(args),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['send-errors'])
        message.success('Test email sent')
      },
      onError: (error: any) => {
        queryClient.refetchQueries(['send-errors'])
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    sendTestEmail: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const testMailboxConnection = async (mailbox: Mailbox) => {
  return await fetch.put(`/mailboxes/test-connection/`, mailbox)
}

export const useTestMailboxConnection = () => {
  const queryClient = useQueryClient()

  const { data, reset, mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: any) => testMailboxConnection(args),
    {
      onSuccess: (result) => {
        queryClient.refetchQueries(['test-connection'])
        const { smtp, imap } = result.data
        if (smtp.status === 'OK' && imap.status === 'OK') {
          message.success('Connection successful!')
          // returning because it waits onSuccess to finish to refresh the state in the UI
          return
        } else {
          if (smtp.status === 'ERROR') {
            message.error('SMTP connection failed')
          }
          if (imap.status === 'ERROR') {
            message.error('IMAP connection failed')
          }
          return
        }
      },
      onError: (error: any) => {
        queryClient.refetchQueries(['test-connection'])
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  const hasError = data?.data.smtp.status === 'ERROR' || data?.data.imap.status === 'ERROR'

  return {
    testMailboxConnection: mutate,
    data,
    reset,
    isLoading,
    isError: isError || hasError,
    isSuccess,
    error,
  }
}

const initiateMailbox = async ({ mailboxId, mailboxProps }: any) => {
  await fetch.put(`/mailboxes/all/${mailboxId}`, { mailboxProps })
}

export const useInitiateMailbox = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(initiateMailbox, {
    onSuccess: () => {
      queryClient.invalidateQueries(['mailboxes'])
    },
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    initiateMailbox: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const setMailboxTrackingDomain = async (mailboxId: string, domain: string) => {
  const { data } = await fetch.patch(`/mailboxes/all/${mailboxId}/tracking`, {
    domain,
  })
  return data
}

export const useSetMailboxTrackingDomain = () => {
  const queryClient = useQueryClient()

  const { mutate, reset, data, isLoading, isError, isSuccess, error } = useMutation(
    (args: { mailboxId: string; domain: string }) =>
      setMailboxTrackingDomain(args.mailboxId, args.domain),
    {
      onSuccess: (result) => {
        if (typeof result === 'boolean' && result === false) {
          return
        }
        notification.success({
          message: 'Success',
          description: 'Your tracking domain has been added',
        })
        queryClient.invalidateQueries(['mailboxes'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    setMailboxTrackingDomain: mutate,
    data,
    reset,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const removeMailboxTrackingDomain = async (mailboxId: string) => {
  const { data } = await fetch.delete(`/mailboxes/all/${mailboxId}/tracking`)
  return data
}

export const useRemoveMailboxTrackingDomain = () => {
  const queryClient = useQueryClient()

  const { mutate, reset, isLoading, isError, isSuccess, error } = useMutation(
    (args: { mailboxId: string }) => removeMailboxTrackingDomain(args.mailboxId),
    {
      onSuccess: (result) => {
        notification.success({
          message: 'Success',
          description: 'Your tracking domain has been removed',
        })
        queryClient.invalidateQueries(['mailboxes'])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    removeMailboxTrackingDomain: mutate,
    reset,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
