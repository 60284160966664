import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import history from '../../../router/history'

import Campaign from './Campaign/Campaign'

import { useFetchMailboxes } from '../../Mailboxes'
import { useFetchCampaigns } from '../api/campaignQueries'
import { useEditCampaign, useRemoveCampaign } from '../api/campaignsMutations'

interface Props {}

const CampaignContainer: React.FC<Props> = () => {
  const { campaignId, step = '0' } = useParams<{ campaignId: string; step: string }>()
  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = useState<number>(Number(step))

  const { campaigns, isLoading: fetchCampaignsLoading } = useFetchCampaigns()
  const campaign = campaigns?.find((c: any) => c.campaignId === campaignId)

  const { editCampaign, isLoading: editCampaignLoading } = useEditCampaign()
  const { removeCampaign, isLoading: removeCampaignLoading } = useRemoveCampaign()

  const { mailboxes } = useFetchMailboxes()

  useEffect(() => {
    // TODO: add campaign name
    document.title = 'Campaign Editor | Mailarrow'
  }, [campaignId])

  useEffect(() => {
    if (campaign && !campaign.isSetupFinished) {
      history.push(`/app/campaigns/${campaignId}/new/`)
    }
  }, [campaign, campaignId, navigate])

  const onChangeTabIndex = useCallback(
    (tabIndex: number) => {
      setTabIndex(tabIndex)
      return history.push(`/app/campaigns/${campaignId}/edit/${tabIndex}`)
    },
    [campaignId]
  )

  useEffect(() => {
    if (Number(step) === tabIndex) return

    return onChangeTabIndex(step ? Number(step) : 0)
  }, [step, onChangeTabIndex, campaignId, tabIndex])

  if (!campaignId) {
    history.push('/app/campaigns/')
    return <></>
  }

  const changeCampaignStatus = (isPaused: boolean) => {
    editCampaign({ campaignId, campaignProps: { isPaused } })
  }

  const onRemoveCampaign = () => {
    removeCampaign(campaignId)
  }

  const copyCampaign = () => {
    console.log('copy campaign')
    // dispatch(CampaignsActions.copyCampaign(campaignId));
  }

  const campaignMailboxes = mailboxes?.filter((m) => campaign?.mailboxIds?.includes(m.mailboxId))

  return (
    <Campaign
      campaign={campaign}
      loading={fetchCampaignsLoading}
      mailboxes={campaignMailboxes}
      changeCampaignStatus={changeCampaignStatus}
      removeCampaign={onRemoveCampaign}
      copyCampaign={copyCampaign}
      campaignId={campaignId}
      currentTabIndex={tabIndex}
      onChangeTabIndex={onChangeTabIndex}
    />
  )
}

export default CampaignContainer
