import React, { FC, ReactElement } from 'react'
import { Spin, Card, Statistic } from 'antd'

// Define your props type
type InfoCardProps = {
  loading: boolean
  title: string
  metric: number
  suffix?: ReactElement
  icon?: ReactElement
}

const InfoCard: FC<InfoCardProps> = ({ loading, title, metric, suffix, icon }) => {
  return (
    <Card
      size="small"
      bodyStyle={{ display: 'flex', padding: '12px 4px' }}
      style={{ minWidth: '150px' }}
    >
      <div style={{ margin: '10px 12.5px', fontSize: '25px' }}>{icon}</div>
      {loading && <Spin />}
      {!loading && (
        <Statistic
          title={title}
          value={metric}
          valueStyle={{ color: '#595959', fontWeight: 400, fontSize: '20px' }}
          suffix={suffix}
        />
      )}
    </Card>
  )
}

export default InfoCard
