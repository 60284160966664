import React, { useEffect } from 'react'
import { Button, Card, Divider, List, Spin, Tag, Tooltip, Typography } from 'antd'
import history from '../../router/history'
import { useFetchMailboxes } from '../../modules/Mailboxes'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { MailboxIcon } from '../../modules/Mailboxes/components/MailboxIcon/MailboxIcon'
import { DashboardCard } from './DashboardCard'
import { useFetchCampaigns } from '../../modules/Campaigns/api/campaignQueries'
import { useFetchProspects } from '../../modules/Prospects/api/prospectsQueries'
import { useSaveOnboardingQuiz } from '../../modules/Onboarding/api/onboardingMutations'

import './Dashboard.scss'

const { Title, Text } = Typography

const Dashboard = () => {
  const { mailboxes } = useFetchMailboxes()
  const { campaigns } = useFetchCampaigns()
  const { prospects } = useFetchProspects({})
  const { isSuccess: isSaveOnboardingQuizSuccess } = useSaveOnboardingQuiz()
  const { user } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    document.title = 'Dashboard | Mailarrow'
  }, [])

  useEffect(() => {
    const shouldShowOmbording = !user?.onboarding && !isSaveOnboardingQuizSuccess
    if (shouldShowOmbording) {
      history.push('/app/onboarding/')
    }
  }, [campaigns, isSaveOnboardingQuizSuccess, user])

  if (!user) return <Spin />

  const { usedEmailCount } = user

  const hasConnectedMailbox = Boolean(mailboxes?.length)
  const hasCreatedCampaign = Boolean(campaigns?.length)
  const hasUploadedProspects = Boolean(prospects?.length)

  // todo: fix, this is active only on first message, not accurate on month reset
  const hasSentMessage = Boolean(usedEmailCount)
  const hasTrackingDomain = mailboxes?.some((mailbox) => Boolean(mailbox.trackingDomain)) || false

  const openCrisp = () => {
    const $crisp = window.$crisp
    if ($crisp && $crisp.is('chat:closed')) {
      window.$crisp.push(['do', 'chat:toggle'])
    }
  }

  return (
    <div style={{ width: '1000px', margin: '0 auto' }}>
      <Title level={3} style={{ marginBottom: 5, marginTop: 0, textAlign: 'center' }}>
        Dashboard
        {/* Hello {firstName || 'there'} */}
      </Title>
      <div>
        <div style={{ marginBottom: 10, fontSize: 13, color: 'grey', marginLeft: 5 }}>
          Get your account ready to send cold emails
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <DashboardCard
            index={1}
            isCompleted={hasCreatedCampaign}
            title="Create your first campaign"
            body="Create your first campaign to start sending emails."
            actionText="Create campaign"
            onClick={() => history.push('/app/campaigns/')}
          />
          <DashboardCard
            index={2}
            isCompleted={hasUploadedProspects}
            title="Upload your prospects"
            body="Upload your prospects list to start sending emails."
            actionText="Upload list"
            onClick={() => history.push('/app/campaigns/')}
          />
          <DashboardCard
            index={3}
            isCompleted={hasConnectedMailbox}
            title="Connect your mailbox"
            body="Connect your mailbox to send emails from your own email address."
            actionText="Connect mailbox"
            onClick={() => history.push('/app/settings/mailboxes/')}
          />
        </div>
        <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
          <DashboardCard
            index={4}
            isCompleted={hasTrackingDomain}
            title="Set up a tracking domain"
            body="Set up a custom tracking domain to improve deliverability."
            actionText="Set up domain"
            onClick={() => history.push('/app/settings/mailboxes/')}
          />
          <DashboardCard
            index={5}
            isCompleted={hasSentMessage}
            body="Send your first email from a cold email outreach campaign."
            title="Send first email"
            actionText="Open Campaigns"
            onClick={() => history.push('/app/campaigns/')}
          />
          <DashboardCard
            index={6}
            isCompleted={false}
            title={
              <>
                Invite your team{' '}
                <Tag style={{ marginLeft: 10, fontWeight: 400, fontSize: 10 }}>coming soon</Tag>
              </>
            }
            body="Invite your team to collaborate on campaigns. "
            actionText="Invite team"
            // onClick={() => {}}
          />
        </div>
        <Divider />
      </div>
      <div>
        <Title level={5}>Resources</Title>
        <div style={{ display: 'flex', gap: 10 }}>
          {/* <Card
            size="small"
            className="dashboard-resource-card"
            onClick={() => window.open('https://savvycal.com/serge-builds/mailarrow', '_blank')}
          >
            <span className="dashboard-resource-card-emoji">🎉</span>Get your demo
          </Card> */}
          <Card
            size="small"
            className="dashboard-resource-card"
            onClick={() =>
              window.open(
                'https://mailarrow.notion.site/mailarrow/d0be487a454f4d4fa0926258b818299c',
                '_blank'
              )
            }
          >
            <span className="dashboard-resource-card-emoji">📚</span> Knowledge base
          </Card>
          {/* <Card
            size="small"
            className="dashboard-resource-card"
            onClick={() =>
              window.open('https://savvycal.com/serge-builds/mailarrow-consult', '_blank')
            }
          >
            <span className="dashboard-resource-card-emoji">👩‍💼</span> Get outreach expert help
          </Card> */}
          <Card size="small" className="dashboard-resource-card" onClick={openCrisp}>
            <span className="dashboard-resource-card-emoji">💬</span> Contact support
          </Card>
          {/* <Card
            size="small"
            className="dashboard-resource-card"
            onClick={() =>
              window.open('https://savvycal.com/serge-builds/mailarrow-feedback', '_blank')
            }
          >
            <span className="dashboard-resource-card-emoji">👍</span> How is Mailarrow so far?
          </Card> */}
        </div>
        <Divider />
      </div>
      <div>
        <Title level={5}>Updates</Title>
        <span style={{ marginBottom: 10, fontSize: 13, color: 'grey', marginLeft: 5 }}>
          All caught up.
        </span>
        <Divider />
      </div>
      {/* <div>
        Free tools
        <div>tool 1</div>
        <div>tool 2</div>
        <div>tool 3</div>
      </div>
      <div>
        Content section
        <div>article 1</div>
        <div>article 2</div>
        <div>article 3</div>
      </div> */}
    </div>
  )
}

export default Dashboard
