import { FC, useState } from 'react'
import { useFetchMonitorUsers } from '../api/adminQueries'
import UsersListTable from './UserListTable'
import CumulativeChart from './CumulativeUserChart'
import MonthlyChart from './MonthlyUserChart'

export const UserListMonitorCotnainer = () => {
  const { users, isLoading } = useFetchMonitorUsers()

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <div>
          <h4 style={{ textAlign: 'center' }}>All Time Users Cumulative</h4>
          <CumulativeChart users={users || []} />
        </div>
        <div>
          <h4 style={{ textAlign: 'center' }}>All Time Users By Month</h4>
          <MonthlyChart users={users || []} />
        </div>
        <div>Total users: {users?.length}</div>
      </div>

      <UsersListTable users={users} isLoading={isLoading} />
    </div>
  )
}
