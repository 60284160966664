import React from 'react'
import { InputNumber, Select } from 'antd'
import { DelayMetric, FeatureToggles, SequenceStep } from '@mailarrow/types'
import { useFeatureToggle } from '../../../User'

interface Props {
  step: SequenceStep
  onEditStepDelay: (stepIndex: number, delayMetric: DelayMetric, delayLength: number) => void
  editSequenceLoading: boolean
}

const StepTimer: React.FC<Props> = ({ step, onEditStepDelay, editSequenceLoading }) => {
  const { stepIndex, delayLength, delayMetric } = step
  const showSendDelay = useFeatureToggle(FeatureToggles.ShortSendDelay)

  return (
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <span style={{ marginRight: '10px', fontSize: '13px' }}>Wait</span>
      <InputNumber
        min={1}
        max={30}
        defaultValue={delayLength}
        disabled={editSequenceLoading}
        size="small"
        onChange={(delayLength) =>
          delayLength && delayMetric && onEditStepDelay(stepIndex, delayMetric, delayLength)
        }
        style={{ marginRight: '10px', width: 50 }}
      />
      {showSendDelay ? (
        <Select
          defaultValue={delayMetric || 'days'}
          size="small"
          style={{ width: 80 }}
          loading={editSequenceLoading}
          onChange={(delayMetric) =>
            delayMetric && delayLength && onEditStepDelay(stepIndex, delayMetric, delayLength)
          }
          options={[
            { label: 'days', value: 'days' },
            { label: 'hours', value: 'hours' },
            { label: 'mins', value: 'minutes' },
          ]}
        />
      ) : (
        <span style={{ marginRight: '10px', fontSize: '13px' }}>days</span>
      )}
    </div>
  )
}

export default StepTimer
