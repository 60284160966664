import { Attachment, DelayMetric } from '../email/email'

export enum SequenceStepType {
  EMAIL = 'EMAIL',
  TASK = 'TASK',
  SMS = 'SMS',
  CALL = 'CALL',
  SLACK = 'SLACK',
  CHECKPOINT = 'CHECKPOINT',
  WEBHOOK = 'WEBHOOK',
}

interface BaseStepVariation {
  isPaused: boolean
  type: SequenceStepType
  stepVariationId: string
}

export interface EmailStepVariation extends BaseStepVariation {
  trackOpens: boolean
  trackClicks: boolean
  isPlainText: boolean
  continueThread: boolean
  preHeader?: string
  subject: string
  body: string
  attachments?: Attachment[]
}

export interface TaskStepVariation extends BaseStepVariation {
  assignee: string
  subject: string
  body: string
}

export type SequenceStepVariation = EmailStepVariation | TaskStepVariation

interface BaseSequenceStep {
  stepIndex: number
  delayMetric?: DelayMetric
  delayLength?: number
  type: SequenceStepType
}

export interface EmailSequenceStep extends BaseSequenceStep {
  variations: EmailStepVariation[]
}

export interface TaskSequenceStep extends BaseSequenceStep {
  variations: TaskStepVariation[]
}

export type SequenceStep = EmailSequenceStep | TaskSequenceStep

export interface Sequence {
  sequenceId: string
  campaignId: string
  userId: string
  steps: SequenceStep[]
  createdAt: Date
  updatedAt: Date
}
