import React, { useState } from 'react'
import { Button, Card, InputNumber, List, Switch, Tag } from 'antd'
import { Mailbox } from '@mailarrow/types'
import {
  SettingOutlined,
  SyncOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

interface Props {
  mailbox?: Mailbox
  sendErrorCount?: number
  sentToday?: number
  onReconnectMailbox: () => void
  onCheckMailboxStatus: (mailboxId: string) => void
  checkMailboxStatusLoading: boolean
  isPaused: boolean
  setPaused: (isPaused: boolean) => void
  onEditSettings: () => void
}

const MailboxDetails: React.FC<Props> = ({
  mailbox,
  sendErrorCount,
  sentToday,
  onCheckMailboxStatus,
  checkMailboxStatusLoading,
  onReconnectMailbox,
  isPaused,
  setPaused,
  onEditSettings,
}) => {
  const renderMailboxStatus = (mailbox?: Mailbox) => {
    if (mailbox?.connectionStatus === 'requires_reauth')
      return (
        <>
          <span>
            <ExclamationCircleOutlined style={{ color: 'orange', fontSize: 12, marginRight: 5 }} />
            Please Reconnect
          </span>
          {mailbox.config.mailboxType !== 'SMTP' && (
            <Button
              onClick={() => onReconnectMailbox()}
              key="mail"
              size="small"
              type="primary"
              icon={<ReloadOutlined />}
            >
              Reconnect Mailbox
            </Button>
          )}
        </>
      )
    return 'Connected'
  }

  const data: any = [
    {
      key: 'Email',
      component: <>{mailbox?.email}</>,
    },
    {
      key: 'Sender Name',
      component: <>{mailbox?.name}</>,
    },
    {
      key: 'Reply To',
      component: <>{mailbox?.email}</>,
    },
    {
      key: 'Status',
      component: <>{renderMailboxStatus(mailbox)} </>,
    },
    {
      key: 'Emails sent today',
      component: (
        <>
          {sentToday} of {mailbox?.maxPerDay}
        </>
      ),
    },
    {
      key: 'SPF Check',
      component: <Tag style={{ marginLeft: 5 }}>coming soon</Tag>,
    },
    {
      key: 'DKIM Check',
      component: <Tag style={{ marginLeft: 5 }}>coming soon</Tag>,
    },
    {
      key: 'Mailbox Errors',
      component: <>{sendErrorCount}</>,
    },
  ]

  return (
    <Card style={{ marginTop: 20 }} size="small" title={<span>Mailbox Details</span>}>
      <List
        dataSource={data}
        size="small"
        renderItem={(item: any, i) => (
          <List.Item key={`item.value${i}`} style={{ display: 'flex', width: '100%' }}>
            <span style={{ width: '200px' }}>{item.key}:</span>
            {item.component}
          </List.Item>
        )}
      />
      {mailbox?.config.mailboxType === 'SMTP' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            margin: '10px',
          }}
        >
          <Button
            icon={<SettingOutlined />}
            size="small"
            onClick={onEditSettings}
            type={mailbox?.connectionStatus === 'requires_reauth' ? 'primary' : 'default'}
          >
            {mailbox?.connectionStatus === 'requires_reauth'
              ? 'Reconnect Mailbox'
              : 'Edit Settings'}
          </Button>
          {/* <Button
            icon={<SyncOutlined />}
            size="small"
            loading={checkMailboxStatusLoading}
            onClick={() => mailbox && onCheckMailboxStatus(mailbox.mailboxId)}
          >
            Check Mailbox Status
          </Button> */}
        </div>
      )}
    </Card>
  )
}

export default MailboxDetails
