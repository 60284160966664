import React, { useState, useEffect, useCallback } from 'react'

type UseHashReturn = [string, (newHash: string) => void]

export const useHash = (): UseHashReturn => {
  const [hash, setHash] = useState<string>(() => window.location.hash)

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler)
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  }, [hashChangeHandler])

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash !== hash) {
        window.location.hash = newHash
        hashChangeHandler()
      }
    },
    [hash, hashChangeHandler]
  )

  return [hash, updateHash]
}
