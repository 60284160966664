import { Select, InputNumber, Tag, Button, Card, Switch } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { isUndefined } from 'lodash'
import { Campaign } from '@mailarrow/types'

interface Props {
  campaign: Campaign
  fetchLoading: boolean
  editLoading: boolean
  onEditCampaign: (props: any) => void
}

const SequenceSettings: React.FC<Props> = ({
  onEditCampaign,
  campaign,
  fetchLoading,
  editLoading,
}) => {
  const [reply, setReply] = useState<boolean | undefined>(undefined)
  const [click, setClick] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (!campaign) return
    if (isUndefined(reply)) setReply(campaign.sendConfig.stopAfterReply)
    if (isUndefined(click)) setClick(campaign.sendConfig.stopAfterLinkClick)
  }, [campaign, click, reply])

  const onSaveClick = useCallback(() => {
    const updates = {
      sendConfig: {
        ...campaign.sendConfig,
        stopAfterReply: reply,
        stopAfterLinkClick: click,
      },
    }
    onEditCampaign(updates)
  }, [campaign.sendConfig, click, onEditCampaign, reply])

  return (
    <div>
      <Card title={<div>Sequence</div>} style={{ marginBottom: 20 }} size="small">
        <div style={{ marginBottom: 10 }}>Stop sending sequence after:</div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Switch
            id="reply"
            style={{ marginRight: 10 }}
            checked={reply}
            onChange={(isActive: boolean) => setReply(isActive)}
          />
          <label htmlFor="reply" style={{ marginRight: 10 }}>
            Email reply
          </label>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Switch
            id="click"
            style={{ marginRight: 10 }}
            checked={click}
            onChange={(isActive: boolean) => setClick(isActive)}
          />
          <label htmlFor="click" style={{ marginRight: 10 }}>
            Any link click
          </label>
        </div>
        {!campaign.trackingConfig.trackClicks && (
          <div style={{ fontSize: 13 }}>Only works with click tracking on.</div>
        )}

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onSaveClick}
            loading={fetchLoading || editLoading}
            size="small"
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default SequenceSettings
