import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { filterFalsySearchParams } from '../../../util/filter-falsy'

const fetchStatistics = async (campaignId?: string, date?: string) => {
  if (!campaignId) return processAnalytics([])
  const query = new URLSearchParams(filterFalsySearchParams({ date: date?.toString() })).toString()
  const { data: statistics } = await fetch.get(`/stats/campaigns/${campaignId}?${query}`)
  return processAnalytics(statistics)
}

function getLastMonthDays(): string[] {
  let date = dayjs().startOf('day')
  const dates: string[] = []

  for (let i = 0; i < 30; i++) {
    const result = date.toISOString()
    dates.push(result)
    date = date.subtract(1, 'day')
  }
  return dates
}

const matchDay = (data: any, day: string) => {
  const match = data.find((s: any) => dayjs(s.date).isSame(dayjs(day), 'day'))
  return match ? match.count : 0
}

const getTotal = (data: any) => {
  return data.reduce((prev: number, curr: any) => curr.count + prev, 0)
}

const processAnalytics = (statistics: any) => {
  const { total, byStep } = statistics
  const { sent, replied, opened, clicked, bounced, unsubscribed } = total

  const lastMonthDays = getLastMonthDays()

  const byDay = lastMonthDays
    .map((d) => {
      return {
        day: dayjs(d).format('DD MMM'),
        sent: matchDay(sent, d),
        replied: matchDay(replied, d),
        opened: matchDay(opened, d),
        clicked: matchDay(clicked, d),
        bounced: matchDay(bounced, d),
        unsubscribed: matchDay(unsubscribed, d),
      }
    })
    .reverse()

  return {
    total: {
      sent: getTotal(sent),
      replied: getTotal(replied),
      opened: getTotal(opened),
      clicked: getTotal(clicked),
      bounced: getTotal(bounced),
      unsubscribed: getTotal(unsubscribed),
    },
    byDay,
    byStep,
  }
}

export const useFetchStatistics = (campaignId?: string, date?: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['statistics', 'campaignIds', campaignId],
    () => fetchStatistics(campaignId, date),
    {
      enabled: !!campaignId,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    statistics: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

export const useFetchAllCampaignsStatistics = (campaigns: Record<string, string>[] | undefined) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['statistics', 'campaigns', campaigns],
    async () => {
      if (!campaigns?.length) return {}

      const statisticsPromises = campaigns.map((c) => fetchStatistics(c.campaignId, c.date))
      const statisticsArray = await Promise.all(statisticsPromises)

      return campaigns.reduce((obj: Record<string, any>, campaign, index) => {
        obj[campaign.campaignId] = statisticsArray[index]
        return obj
      }, {})
    },
    {
      enabled: campaigns ? campaigns.length > 0 : false,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    statistics: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const fetchMailboxStats = async (mailboxIds: string[], date: string) => {
  try {
    const query = new URLSearchParams(
      filterFalsySearchParams({ date: date, mailboxIds })
    ).toString()
    const { data: statistics } = await fetch.get(`/stats/mailboxes?${query}`)

    return statistics
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchMailboxStats = (mailboxIds: string[], date: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['statistics', 'mailboxs', mailboxIds, date],
    () => fetchMailboxStats(mailboxIds, date),
    {
      enabled: mailboxIds ? mailboxIds.length > 0 : false,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    stats: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
