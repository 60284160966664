import React, { useCallback, useMemo, useState } from 'react'
import { Avatar, Typography, Space, List, Input, Divider, Button, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import './ProspectList.css'
import classNames from 'classnames'
import { Prospect } from '@mailarrow/types'
import { getAvatarUrl } from '../../../util/get-avatar-url'
import { ProspectAvatar } from '../../../components/ProspectAvatar/ProspectAvatar'

const { Text, Title, Paragraph } = Typography

interface Props {
  prospects?: Prospect[]
  loading: boolean
  selectedProspectId: string | undefined
  onSelectProspect: (prospectId: string) => void
  onEditProspect: (prospectId: string) => void
  filter: string
  setFilter: (filter: string) => void
  total: number
  onPageChange: (one: any, two: any) => void
  page: number
  pageSize: number
}

const ProspectList: React.FC<Props> = ({
  prospects,
  loading,
  selectedProspectId,
  onSelectProspect,
  onEditProspect,
  filter,
  setFilter,
  total,
  onPageChange,
  page,
  pageSize,
}) => {
  const [searchFilterLoc, setSearchFilterLoc] = useState<string>('')

  const getName = useCallback((prospect: any) => {
    // todo: refactor to the same as in prospect table
    return prospect.firstName || prospect.email.split('@')[0]
  }, [])

  const getAvatarUrlCb = useCallback(getAvatarUrl, [])

  const mappedProspects = useMemo(() => {
    if (!prospects || !prospects.length) return []
    return prospects.map((prospect) => {
      return {
        ...prospect,
        avatarUrl: getAvatarUrlCb(prospect.email),
        showName: getName(prospect),
      }
    })
  }, [getName, getAvatarUrlCb, prospects])

  return (
    <>
      <Title level={5}>Prospects</Title>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <Input.Search
          allowClear
          placeholder="Search by name or email.."
          value={searchFilterLoc}
          onChange={(e) => setSearchFilterLoc(e.target.value)}
          onSearch={() => setFilter(searchFilterLoc)}
        />
      </div>
      <Divider />
      {loading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={mappedProspects}
          loading={loading}
          pagination={{
            total,
            current: page,
            pageSize,
            onChange: onPageChange,
            hideOnSinglePage: true,
            simple: true,
          }}
          renderItem={(prospect) => (
            <List.Item
              className={classNames('prospect-list-item', {
                'prospect-list-item-selected': prospect.prospectId === selectedProspectId,
              })}
              onClick={() => onSelectProspect(prospect.prospectId)}
            >
              <List.Item.Meta
                className="prospect-list-item-meta"
                avatar={<ProspectAvatar prospect={prospect} />}
              />
              {prospect.prospectId === selectedProspectId && (
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation()
                    onEditProspect(prospect.prospectId)
                  }}
                />
              )}
            </List.Item>
          )}
        />
      )}
    </>
  )
}

export default ProspectList
