import React from 'react'

import TrackingDomain from './TrackingDomain'

const Profile: React.FC = () => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: '50%' }}>
        <TrackingDomain />
      </div>
      <div style={{ width: '50%' }}>{/* <ChangePassword /> */}</div>
    </div>
  )
}

export default Profile
