import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message, notification } from 'antd'
import { Onboarding } from '@mailarrow/types'

const saveOnboardingQuiz = async (values: Onboarding) => {
  await fetch.post(`/users/onboarding`, { ...values })
}

export const useSaveOnboardingQuiz = () => {
  const { mutate, reset, isLoading, isError, isSuccess, error } = useMutation(
    (values: Onboarding) => saveOnboardingQuiz(values),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    saveOnboardingQuiz: mutate,
    reset,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
