import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Space, Switch, Table, Modal, Tooltip, Tag } from 'antd'
import type { MenuProps } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  DownOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  CopyOutlined,
  AreaChartOutlined,
  FileDoneOutlined,
  PauseOutlined,
  RightCircleOutlined,
  FileExcelOutlined,
} from '@ant-design/icons'
import history from '../../../router/history'

interface Props {
  // this is not regular campaign, this is campaign enriched with statistics
  // not making a type for this for now.
  campaigns: any
  isLoading: boolean
  deleteCampaign: (id: string) => void
  onArchiveCampaign: (id: string, isArchived: boolean) => void
  togglePauseCampaign: (id: string, isEnabled: boolean) => void
}

const CampaignsTable: React.FC<Props> = ({
  campaigns,
  isLoading,
  togglePauseCampaign,
  onArchiveCampaign,
  deleteCampaign,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const onDeleteCampaign = (campaignId: string) => {
    modal.confirm({
      title: 'Please confirm deleting campaign',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: <>This campaign will be removed. This action is final and can not be recovered.</>,
      okText: 'Permanently delete this campaign',
      onOk: () => deleteCampaign(campaignId),
      cancelText: 'Cancel',
    })
  }

  const getCampaignUrl = (campaign: any) => {
    return `/app/campaigns/${campaign.campaignId}/${campaign.isSetupFinished ? 'edit/' : 'new/'}`
  }

  const getCampaignStaus = (campaign: any) => {
    if (campaign.status === 'draft') {
      return (
        <Tag color="lightgrey" style={{ marginTop: 10, fontSize: 10, lineHeight: '14px' }}>
          draft
        </Tag>
      )
    }
    if (campaign.isPaused) {
      return (
        <Tag color="orange" style={{ marginTop: 10, fontSize: 10, lineHeight: '14px' }}>
          paused
        </Tag>
      )
    }
    return (
      <Tag color="green" style={{ marginTop: 10, fontSize: 10, lineHeight: '14px' }}>
        active
      </Tag>
    )
  }

  const getColumns = () => {
    return [
      {
        title: '',
        dataIndex: 'action1',
        key: 'action1',
        width: 35,
        render: (value: string, campaign: any) => {
          return (
            <>
              <Tooltip title={campaign.isPaused ? `Start Campaign` : `Pause Campaign`}>
                <Button
                  type="primary"
                  icon={campaign.isPaused ? <RightCircleOutlined /> : <PauseOutlined />}
                  disabled={campaign.status === 'draft'}
                  onClick={() => togglePauseCampaign(campaign.campaignId, !campaign.isPaused)}
                />
              </Tooltip>
            </>
          )
        },
      },
      {
        title: 'Campaign',
        dataIndex: 'name',
        key: 'name',
        render: (value: string, campaign: any) => (
          <>
            <div style={{ minWidth: 150 }}>
              <Link to={getCampaignUrl(campaign)}>{campaign.name}</Link>
            </div>
            {getCampaignStaus(campaign)}
          </>
        ),
      },
      // TODO: disabled because analytics is not ready yet
      // {
      //   title: (
      //     <>
      //       Prospects
      //       <br /> <span style={{ fontSize: 10 }}>(total/sending/finished)</span>
      //     </>
      //   ),
      //   dataIndex: 'prospects',
      //   key: 'prospects',
      //   render: (one: string, campaign: any) => {
      //     // console.log('cstat', statistics[campaign.campaignId]?.total)
      //     return (
      //       <div>
      //         <Tooltip title="Total Prospects">
      //           <span
      //             style={{
      //               cursor: 'pointer',
      //               textDecoration: 'underline',
      //               textDecorationStyle: 'dashed',
      //             }}
      //           >
      //             {/* {statistics[campaign.campaignId]} */}
      //             100
      //           </span>
      //         </Tooltip>{' '}
      //         /{' '}
      //         <Tooltip title="In Sequence">
      //           <span
      //             style={{
      //               cursor: 'pointer',
      //               textDecoration: 'underline',
      //               textDecorationStyle: 'dashed',
      //             }}
      //           >
      //             35
      //           </span>
      //         </Tooltip>{' '}
      //         /{' '}
      //         <Tooltip title="Finished Sequence">
      //           <span
      //             style={{
      //               cursor: 'pointer',
      //               textDecoration: 'underline',
      //               textDecorationStyle: 'dashed',
      //             }}
      //           >
      //             40
      //           </span>
      //         </Tooltip>
      //       </div>
      //     )
      //   },
      // },
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
        render: (one: string, campaign: any) => {
          return <div>{campaign.sent}</div>
        },
      },
      {
        title: 'Opened',
        dataIndex: 'opens',
        key: 'opens',
        render: (one: string, campaign: any) => {
          return <div>{campaign.openedPercentage}%</div>
        },
      },
      {
        title: 'Replied',
        dataIndex: 'replies',
        key: 'replies',
        render: (one: string, campaign: any) => {
          return <div>{campaign.repliedPercentage}%</div>
        },
      },
      {
        title: 'Clicked',
        dataIndex: 'clicks',
        key: 'clicks',
        render: (one: string, campaign: any) => {
          return <div>{campaign.clickedPercentage}%</div>
        },
      },

      {
        title: 'Mailboxes',
        dataIndex: 'mailboxId',
        key: 'mailboxId',
        render: (one: string, campaign: any) => {
          if (!campaign || !campaign.mailboxEmails) return ''
          const [firstEmail, ...rest] = campaign.mailboxEmails
          return (
            <>
              <Tag style={{ fontSize: 10 }}>{firstEmail}</Tag>
              {Boolean(rest?.length) && (
                <Tooltip title={rest.join(', ')}>
                  <span
                    style={{
                      fontSize: 10,
                      textDecoration: 'underline',
                      textDecorationStyle: 'dashed',
                      cursor: 'pointer',
                    }}
                    title="More mailboxes"
                  >
                    +{rest.length}
                  </span>
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (one: string, campaign: any) => {
          return (
            <div
              title={dayjs(campaign.createdAt).format('DD/MM/YYYY HH:MM')}
              style={{ cursor: 'pointer' }}
            >
              {dayjs(campaign.createdAt).format('DD MMM')}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (one: string, campaign: any) => {
          const items: MenuProps['items'] = [
            {
              label: 'Open Stats',
              key: '1',
              style: { fontSize: 12 },
              onClick: () => history.push(getCampaignUrl(campaign)),
              disabled: !campaign.isSetupFinished,
              icon: <AreaChartOutlined />,
            },
            {
              label: 'Make a copy',
              key: '2',
              style: { fontSize: 12 },
              disabled: true,
              icon: <CopyOutlined />,
            },
            {
              label: campaign.isArchived ? 'Unarchive' : 'Archive',
              key: '3',
              style: { fontSize: 12 },
              onClick: () => onArchiveCampaign(campaign.campaignId, !campaign.isArchived),
              // icon: two.isArchived ? <EyeOutlined /> : <EyeInvisibleOutlined />,
              icon: campaign.isArchived ? <FileDoneOutlined /> : <FileExcelOutlined />,
            },
            {
              label: <>Delete</>,
              key: '4',
              style: { fontSize: 12 },
              onClick: () => onDeleteCampaign(campaign.campaignId),
              icon: <DeleteOutlined />,
              danger: true,
            },
          ]

          return (
            <>
              <div style={{ display: 'flex' }}>
                <Space>
                  <Dropdown menu={{ items }}>
                    <Button size="small" style={{ fontSize: 12 }}>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              </div>
            </>
          )
        },
      },
    ]
  }

  const tableData = _.sortBy(campaigns, 'date')

  return (
    <>
      <Table
        dataSource={tableData}
        columns={getColumns()}
        loading={isLoading}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true,
        }}
        rowKey="campaignId"
      />
      {contextHolder}
    </>
  )
}

export default CampaignsTable
