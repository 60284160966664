import { filter, groupBy, sortBy } from 'lodash'

export const groupData1 = (data: any, grouping: number) => {
  const grouped = groupBy(data, (v) => v[6])

  const result = Object.keys(grouped).map((url) => {
    const items = grouped[url]
    const sortedItems = sortBy(items, (i) => i[3]).reverse()
    const keywords = sortedItems.map((i) => i[0]).filter((s, i) => i < 20)
    const volume = sortedItems.reduce((prev, curr) => prev + curr[3], 0)

    // calculate the weighted kd
    const kd = Math.floor(sortedItems.reduce((prev, curr) => prev + curr[4] * curr[3], 0) / volume)

    return { url, main: keywords[0], keywords, volume, kd }
  })

  console.log('result', result)
  const filtered = filter(
    result,
    // (item) => item.url.includes('https://blog.hubspot.com/sales/') && item.volume > 200
    (item) => item.volume > 200
  )
  console.log('filtered', filtered)
  const sorted = sortBy(filtered, 'volume').reverse()

  console.log('sorted', sorted)
  return sorted
}

function findGroup(page: any, groups: any) {
  for (let group of groups) {
    if (group.includes(page)) {
      return group
    }
  }
  return null
}

function mergeGroups(groups: any) {
  let merged = []
  for (let group of groups) {
    let existingGroup = null
    for (let page of group) {
      let groupFound = findGroup(page, merged)
      if (groupFound) {
        existingGroup = groupFound
        break
      }
    }
    if (existingGroup) {
      for (let page of group) {
        if (!existingGroup.includes(page)) {
          existingGroup.push(page)
        }
      }
    } else {
      merged.push([...group])
    }
  }
  return merged
}

function mergeGroups2(groups: any) {
  let merged = []
  let pageToGroupMap = new Map()

  groups.sort((a: any, b: any) => a.length - b.length) // sort by length

  for (let group of groups) {
    let newGroup = []
    let smallestExistingGroup = null
    for (let page of group) {
      if (pageToGroupMap.has(page)) {
        let existingGroup = pageToGroupMap.get(page)
        if (!smallestExistingGroup || existingGroup.length < smallestExistingGroup.length) {
          smallestExistingGroup = existingGroup
        }
      } else {
        newGroup.push(page)
      }
    }
    if (smallestExistingGroup) {
      smallestExistingGroup.push(...newGroup)
      for (let page of newGroup) {
        pageToGroupMap.set(page, smallestExistingGroup)
      }
    } else {
      merged.push(newGroup)
      for (let page of newGroup) {
        pageToGroupMap.set(page, newGroup)
      }
    }
  }
  return merged
}

export const groupData2 = (data: any, grouping: number) => {
  const grouped = groupBy(data, (v) => v[0])

  // group pages together
  const result = Object.keys(grouped)
    .filter((k) => !!k)
    .map((url) => {
      const items = grouped[url]
      return items.map((i) => i[4]).filter((l) => !!l)
    })
    .filter((g) => g.length > 1)

  const pageGroups = mergeGroups2(result)
  const shortGroups = pageGroups.filter((g) => g.length < 10)
  const longGroups = pageGroups.filter((g) => g.length >= 10)
  // debugger
  // make groups

  console.log('result', pageGroups)
  return pageGroups
}
