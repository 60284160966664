import { useQuery } from '@tanstack/react-query'
import { reverse } from 'lodash'
import { message } from 'antd'
import fetch from '../../../services/fetch'
import { Campaign } from '@mailarrow/types'

// Fetch all campaigns
const fetchCampaigns = async () => {
  try {
    const response = await fetch.get(`/campaigns/`)
    return reverse(response.data) as Campaign[]
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchCampaigns = () => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(['campaigns'], fetchCampaigns, {
    onError: (error: any) => {
      message.error(`Error: ${error.message}`, 10)
      console.log(error)
    },
  })

  return {
    campaigns: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

// Fetch single campaign
const fetchSingleCampaign = async (campaignId?: string) => {
  try {
    if (!campaignId) return undefined
    const response = await fetch.get(`/campaigns/${campaignId}`)
    return response.data as Campaign
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchSingleCampaign = (campaignId?: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['campaign', campaignId],
    () => fetchSingleCampaign(campaignId),
    {
      enabled: !!campaignId,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    campaign: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
