import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { GoogleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { Button, Card, Divider } from 'antd'
import './Signup.css'
import { actions as AuthActions } from '../../../modules/Auth/reducer'
import { RootState } from '../../../store/store'

import history from '../../../router/history'

import SignupForm from './SignupForm'
import { ValueSider } from './ValueSlider'

interface SignupProps {}

const Signup: React.FC<SignupProps> = () => {
  const { user } = useSelector((state: RootState) => state.user)
  const { signupLoading } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    document.title = 'Signup | Mailarrow App'

    if (user?.userId) return history.push('/app/dashboard/')
  }, [user, location])

  const signupUser = (values: any) => {
    dispatch(AuthActions.signupUserApi(values))
  }

  const signupGoogle = () => {
    dispatch(AuthActions.loginUserGoogle())
  }

  useEffect(() => {
    if (user?.userId) return history.push('/app/dashboard/')
  }, [user])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        margin: '50px 0',
        justifyContent: 'center',
        gap: '100px',
      }}
      className="signup-container"
    >
      {/* <ValueSider /> */}
      <div>
        <div className="signup-form">
          <Card
            title={<div style={{ textAlign: 'center' }}>Create Your Account</div>}
            className="signup-card"
            headStyle={{ fontSize: 20 }}
          >
            {/* <SignupForm onSubmit={signupUser} signupLoading={signupLoading} />
            <Divider>OR</Divider>
            <Button
              style={{ width: '100%' }}
              icon={signupLoading ? <LoadingOutlined /> : <GoogleOutlined />}
              size="large"
              onClick={signupGoogle}
              disabled={signupLoading}
            >
              Sign Up With Google
            </Button> */}
            <Button
              icon={signupLoading ? <LoadingOutlined /> : <GoogleOutlined />}
              style={{ width: '100%', borderColor: '#1890ff', background: '#1890ff' }}
              size="large"
              type="primary"
              onClick={signupGoogle}
              disabled={signupLoading}
            >
              Continue With Google
            </Button>

            <Divider style={{ margin: '30px 0 ' }}>Or, sign up with email</Divider>
            <SignupForm onSubmit={signupUser} signupLoading={signupLoading} />
          </Card>
          <div>
            Already have an account? <Link to="/login/">Log in</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
