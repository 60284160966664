import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MailOutlined, LockOutlined, ThunderboltOutlined, GoogleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Card, Divider } from 'antd'

const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
}

const ResetPasswordForm: React.FC<{ onSubmit: Function }> = ({ onSubmit }) => {
  const handleSubmit = (values: any) => {
    onSubmit(values)
  }

  return (
    <Form
      onFinish={handleSubmit}
      className="login-form"
      {...formItemLayout}
      style={{ textAlign: 'left' }}
    >
      <Form.Item
        className="login-form-item"
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email' }]}
      >
        <Input
          className="login-form-input"
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item className="login-form-item" {...tailFormItemLayout}>
        <Button
          style={{ width: '100%', marginTop: '10px' }}
          className="login-form-input"
          type="primary"
          htmlType="submit"
        >
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordForm
