import React, { useState } from 'react'
import { Input, message, Modal } from 'antd'

import { useCreateCampaign } from '../api/campaignsMutations'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const CreateCampaignModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const [name, setName] = useState<string | undefined>(undefined)

  const { createCampaign, isLoading: createCampaignLoading } = useCreateCampaign()

  const onFinish = () => {
    if (!name) return message.error('Please provide a name')

    createCampaign({ name, mailboxIds: [] })
  }

  const onModalClose = () => {
    setName(undefined)
    onClose()
  }

  return (
    <Modal
      title="Create new campaign"
      onCancel={onModalClose}
      open={isOpen}
      okButtonProps={{ loading: createCampaignLoading }}
      okText="Create campaign"
      onOk={onFinish}
      style={{ maxWidth: 400, margin: '0 auto' }}
    >
      <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 5 }}>Campaign Name:</div>
        <Input
          name="name"
          value={name}
          size="middle"
          onChange={(event) => setName(event.target.value)}
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  )
}

export default CreateCampaignModal
