import React, { FC } from 'react'
import { Button, Spin } from 'antd'

import './SequenceStyles.css'

import SequenceActionStep from './StepEditor/StepEditor'
import StepList from './StepList/StepList'
import SpamScore from './SpamScorer/SpamScorer'
import { DelayMetric, SequenceStep, SequenceStepVariation } from '@mailarrow/types'

interface SequenceBuilderProps {
  editSequenceLoading: boolean
  steps: SequenceStep[]
  onSetSteps: (steps: SequenceStep[]) => void
  onEditStepDelay: (stepIndex: number, delayMetric: DelayMetric, delayLength: number) => void
  isCurrentStepUnsaved: boolean
  activeStepIndex: number
  onChangeActiveStep: (index: number) => void
  activeStepVariationIndex: number
  onRemoveStep: (stepIndex: number) => void
  onAddStep: () => void
  onSave: () => void
}

const SequenceBuilder: FC<SequenceBuilderProps> = ({
  editSequenceLoading,
  steps,
  onSetSteps,
  onEditStepDelay,
  isCurrentStepUnsaved,
  activeStepIndex,
  onChangeActiveStep,
  activeStepVariationIndex,
  onRemoveStep,
  onAddStep,
  onSave,
}) => {
  if (!steps) return <Spin />

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <div style={{ width: 250 }}>
        <StepList
          activeStepIndex={activeStepIndex}
          onChangeActiveStep={onChangeActiveStep}
          editSequenceLoading={editSequenceLoading}
          steps={steps}
          onEditStepDelay={onEditStepDelay}
          onRemoveStep={onRemoveStep}
          onAddStep={onAddStep}
        />
      </div>

      <div style={{ width: 'calc(100% - 500px)', minWidth: 300 }}>
        <SequenceActionStep
          steps={steps}
          stepIndex={activeStepIndex}
          stepVariationIndex={activeStepVariationIndex}
          isCurrentStepUnsaved={isCurrentStepUnsaved}
          editSequenceLoading={editSequenceLoading}
          onSetSteps={onSetSteps}
          onSave={onSave}
        />
      </div>
      <div style={{ width: 250 }}>
        <SpamScore activeStepIndex={activeStepIndex} steps={steps} />
      </div>
    </div>
  )
}

export default SequenceBuilder
