import { FC, useState, useEffect, useCallback } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import CampaignCreator from './CampaignCreator'
import history from '../../../router/history'
import StartCampaignModal from './StartCampaignModal/StartCampaignModal'
import { useFetchCampaigns } from '../api/campaignQueries'
import { useRemoveCampaign, useStartCampaign } from '../api/campaignsMutations'

interface Props {}

const CampaignCreatorContainer: FC<Props> = () => {
  const navigate = useNavigate()
  const { campaignId, step = '0' } = useParams()
  const [modal, contextHolder] = Modal.useModal()

  const [currentStep, setCurrentStep] = useState<number>(Number(step))
  const [isStartModalOpen, setStartModalOpen] = useState<boolean>(false)

  const { startCampaign, isLoading: startCampaignLoading } = useStartCampaign()
  const { removeCampaign } = useRemoveCampaign()

  const { campaigns } = useFetchCampaigns()
  const campaign = campaigns?.find((c: any) => c.campaignId === campaignId)

  useEffect(() => {
    document.title = `${campaign ? campaign.name : 'New Campaign'} | Mailarrow`
  }, [campaign])

  const onChangeStep = useCallback(
    (currentStep: number) => {
      setCurrentStep(currentStep)
      return history.push(`/app/campaigns/${campaignId}/new/${currentStep}`)
    },
    [campaignId]
  )

  useEffect(() => {
    if (campaign?.isSetupFinished) {
      // TODO: this does a back and forth redirect because campaingn does not
      // get enough time to update after an action dispatch
      navigate(`/app/campaigns/${campaignId}/edit/`)
    }
  }, [campaign, campaignId, navigate])

  useEffect(() => {
    if (Number(step) === currentStep) return

    return onChangeStep(step ? Number(step) : 0)
  }, [step, currentStep, onChangeStep, campaignId, navigate])

  const onStartCampaign = () => {
    if (!campaignId) return
    startCampaign(campaignId)
  }

  const onDeleteCampaign = () => {
    modal.confirm({
      title: 'Please confirm deleting campaign',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: <>This campaign will be removed. This action is final and can not be recovered.</>,
      okText: 'Permanently delete this campaign',
      onOk: () => campaignId && removeCampaign(campaignId),
      cancelText: 'Cancel',
    })
  }

  if (!campaignId) {
    history.push('/app/campaigns/')
    return null
  }

  return (
    <>
      <CampaignCreator
        campaign={campaign}
        currentStep={currentStep}
        onChangeStep={onChangeStep}
        onDeleteCampaign={onDeleteCampaign}
        onStartCampaign={() => setStartModalOpen(true)}
      />
      {isStartModalOpen && (
        <StartCampaignModal
          isOpen={isStartModalOpen}
          onClose={() => setStartModalOpen(false)}
          onStartCampaign={onStartCampaign}
          startCampaignLoading={startCampaignLoading}
          campaign={campaign}
        />
      )}
      {contextHolder}
    </>
  )
}
export default CampaignCreatorContainer
