import { Prospect } from '@mailarrow/types'
import { getAvatarUrl } from '../../util/get-avatar-url'
import { Avatar } from 'antd'
import { capitalizeFirstLetter } from '../../util/capitalize-first-letter'

const getSecondLine = (prospect: Prospect) => {
  const { properties } = prospect || {}
  if (!properties || !Object.keys(properties).length) {
    return null
  }

  let secondLine = `${properties.firstName || ''}`
  if (properties.lastName) {
    if (secondLine.trim()) {
      // Check if secondLine is not just whitespace
      secondLine += ` ${properties.lastName}`
    } else {
      secondLine += `${properties.lastName}`
    }
  }
  if (properties.companyName) {
    if (secondLine.trim()) {
      // Check if secondLine is not just whitespace
      secondLine += `, ${properties.companyName}`
    } else {
      secondLine += `${properties.companyName}`
    }
  }
  return secondLine.trim()
}

interface Props {
  prospect: Prospect
  onEditProspect?: () => void
}

export const ProspectAvatar: React.FC<Props> = ({ prospect, onEditProspect }) => {
  if (!prospect || !prospect.email) return null

  const secondLine = getSecondLine(prospect)
  const letter =
    prospect.properties?.firstName?.[0] || prospect.properties?.lastName?.[0] || prospect.email[0]

  const avatarUrl = getAvatarUrl(prospect.email)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: 8 }}>
        <Avatar
          src={avatarUrl}
          onClick={() => onEditProspect && onEditProspect()}
          className="prospects-table-avatar"
          style={{
            color: 'gray',
            cursor: 'unset',
          }}
          size={30}
        >
          {capitalizeFirstLetter(letter)}
        </Avatar>
      </div>
      <div>
        <span>{prospect.email}</span>
        <br />
        <span style={{ color: '#6a6a6a' }}>{secondLine}</span>
      </div>
    </div>
  )
}
