import React, { useMemo, useState } from 'react'
import { Button, Drawer, List, Progress, Tooltip } from 'antd'
import ProspectsTextArea from './ProspectsTextArea'
import { Prospect, useAddProspects } from '../../api/prospectsMutations'
import dayjs from 'dayjs'
import { CloudUploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { validateProspects, sanitizeProspects } from '@mailarrow/util'
import ImportErrorsModal from '../ImportErrorsModal/ImportErrorsModal'
import { usePermissions } from '../../../User/Permissions'

interface Props {
  isOpen: boolean
  onClose: () => void
  campaignId?: string
}

const AddProspectsManual: React.FC<Props> = ({ isOpen, onClose, campaignId }) => {
  const { staff } = usePermissions()
  const [showErrorModalOpen, setShowErrorModalOpen] = useState(false)
  const [prospects, setProspects] = useState('')

  const { addProspects, progress, result, isLoading, isSuccess, isError, reset } =
    useAddProspects(campaignId)

  const onDrawerClose = () => {
    setShowErrorModalOpen(false)
    setProspects('')
    reset()
    onClose()
  }

  const parseProspects = (prospects: string): Prospect[] => {
    return prospects
      .split('\n')
      .filter((s) => !!s)
      .map((s: string) => {
        const trimmedS = s.trim()
        const lastChar = trimmedS[trimmedS.length - 1]
        const email = [',', ';'].includes(lastChar) ? trimmedS.slice(0, -1) : trimmedS
        return { email }
      })
      .filter((s) => !!s.email)
  }

  const [validProspects, invalidProspects] = useMemo(() => {
    const parsedProspects = parseProspects(prospects)
    const date = dayjs().format('YYYY-MM-DDTHH:mm:ss:ms')
    const firstTenK = parsedProspects.slice(0, 10000)
    const mappedData = firstTenK.map((row, index) => {
      const result: any = {}
      result.email = row.email
      result.rowIndex = index + 1
      // to compensate for missing header
      result.importBatchName = `manual-import-${date}`

      return result
    })
    const sanitizedProspects = sanitizeProspects(mappedData, false)
    return validateProspects(sanitizedProspects)
  }, [prospects])

  const uploadTextAreaProspects = () => {
    addProspects(validProspects)
  }

  const setDummyData = () => {
    const date = dayjs().format('HHmm')
    const dummy = Array(20)
      .fill(0)
      .map((r, i) => `sergiy.shlykov+${i}${date}@gmail.com`)
      .join('\n')

    setProspects(dummy)
  }

  const getUploadStatus = (progress: number): string => {
    switch (true) {
      case progress > 0 && progress < 1:
        return 'Loading'
      case progress === 1:
        return 'Completed'
      case progress === 0:
      default:
        return 'Not started'
    }
  }

  const importSummaryData = [
    {
      title: <>Total emails</>,
      value: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{validProspects.length + invalidProspects.length}</span>
          </div>
        </>
      ),
    },
    {
      title: <>Invalid emails</>,
      value: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{invalidProspects.length}</span>
            <Button
              size="small"
              disabled={!invalidProspects.length && !isSuccess}
              onClick={() => setShowErrorModalOpen(true)}
            >
              Show errors
            </Button>
          </div>
        </>
      ),
    },
    {
      title: 'Status',
      value: (
        <>
          <div>{getUploadStatus(progress)}</div>
          <Progress percent={Math.ceil(progress * 100)} />
        </>
      ),
    },
  ]

  return (
    <Drawer
      title="Add Prospects Manually"
      keyboard={false}
      onClose={onDrawerClose}
      open={isOpen}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {staff && <Button onClick={setDummyData}>Set dummy data</Button>}
          {isSuccess ? (
            <Button onClick={onDrawerClose} type="primary" disabled={isLoading}>
              Close
            </Button>
          ) : (
            <Button
              onClick={uploadTextAreaProspects}
              disabled={!validProspects.length}
              loading={isLoading}
              type="primary"
            >
              Import {validProspects.length} Prospects{' '}
              <CloudUploadOutlined style={{ fontSize: 12 }} />
            </Button>
          )}
        </div>
      }
      width={765}
      style={{ maxWidth: 765, margin: '0 auto' }}
    >
      <ProspectsTextArea prospects={prospects} setProspects={setProspects} disabled={isSuccess} />
      <div>
        <List
          size="small"
          dataSource={importSummaryData}
          style={{ marginTop: 30 }}
          renderItem={(item) => (
            <List.Item>
              <strong style={{ width: 350, display: 'inline-block' }}>{item.title}:</strong>
              <span style={{ width: '100%', display: 'inline-block' }}>{item.value}</span>
            </List.Item>
          )}
        />
      </div>
      <ImportErrorsModal
        onClose={() => setShowErrorModalOpen(false)}
        isOpen={showErrorModalOpen}
        errors={invalidProspects}
      />
    </Drawer>
  )
}

export default AddProspectsManual
