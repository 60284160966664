import React, { useEffect, useState } from 'react'
import { Button, Typography, Spin, Card, Avatar, Tooltip, Pagination } from 'antd'
import { RightOutlined, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Mailbox, Prospect, Sequence } from '@mailarrow/types'
import { createEmail } from '../util/make-email'
const pluralize = require('pluralize') // this is a require because otherwise ts fails

const { Title, Text } = Typography

interface Props {
  prospects?: Prospect[]
  mailboxes?: Mailbox[]
  sequence?: Sequence
  loading: boolean
  stepIndex?: number
  prospectId?: string
  onChangeStepIndex: (index: number) => void
}

const EmailPreview: React.FC<Props> = ({
  sequence,
  prospects,
  mailboxes,
  stepIndex,
  prospectId,

  loading,
  onChangeStepIndex,
}) => {
  const [email, setEmail] = useState<any | undefined>(undefined)

  useEffect(() => {
    if (typeof stepIndex === 'undefined' || !prospectId) {
      return
    }

    const makeEmail = async () => {
      const step = sequence?.steps[stepIndex]
      const prospect = prospects?.find((prospect: any) => prospect.prospectId === prospectId)
      if (!prospect || !step) {
        return
      }

      // pick first mailbox
      const senderEmailAddress = mailboxes?.length ? mailboxes[0].email : 'youremail@example.com'
      const email = await createEmail(step, prospect, senderEmailAddress)
      setEmail(email)
    }

    makeEmail()
  }, [prospects, sequence, stepIndex, prospectId, mailboxes])

  if (loading) return <Spin />

  if (!email || !sequence) return <div></div>

  const hasMultipleMailboxes = mailboxes && mailboxes.length > 1

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <Title level={5} style={{ marginLeft: 10 }}>
          Step {email.stepIndex + 1}: Email
        </Title>
        <div style={{ display: 'flex', gap: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
            {/* <div>Sequence Steps</div> */}
          </div>
          <Pagination
            size="small"
            total={sequence.steps.length}
            pageSize={1}
            onChange={(page) => onChangeStepIndex(page - 1)}
            showTotal={() =>
              `${sequence.steps.length} ${pluralize('steps', sequence.steps.length)}`
            }
          />
        </div>
      </div>
      <div style={{ margin: 10, display: 'flex' }}>
        {/* <Avatar src={getAvatarUrl(email.fromEmail)} style={{}} /> */}
        <div style={{ marginRight: 20 }}>
          <Text strong>From:</Text> {email.fromEmail}{' '}
          {hasMultipleMailboxes && (
            <Tooltip
              title={
                <span style={{ fontSize: 12 }}>
                  Campaign mailboxes rotate automatically during send time
                </span>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: 2, marginRight: 5, fontSize: 12 }} />
            </Tooltip>
          )}
        </div>
        <div>
          <Text strong>To:</Text> {email.prospectEmail}
        </div>
      </div>

      <Card
        title={
          <div style={{ margin: '10px 0', fontSize: 14, fontWeight: 500 }}>
            <div>
              <Text>Subject:</Text> {email.subject}
            </div>
          </div>
        }
        style={{ width: '100%', minHeight: '250px' }}
        loading={loading}
      >
        <div dangerouslySetInnerHTML={{ __html: email.body }} />
      </Card>
    </div>
  )
}

export default EmailPreview
