import { PaginationSettings } from '../../../types/common'

export interface FilterSettings {
  campaignId?: string
  excludedCampaignIds?: string[]
  search?: string
  isUnsubscribed?: boolean
}

export interface FetchProspectsProps extends FilterSettings, PaginationSettings {}

export const buildFilterQuery = ({
  campaignId,
  excludedCampaignIds,
  page,
  pageSize,
  search,
  isUnsubscribed,
}: FetchProspectsProps): string => {
  const params = new URLSearchParams()
  if (campaignId) {
    params.append('campaignId', campaignId)
  }
  if (excludedCampaignIds && excludedCampaignIds.length) {
    params.append('excludedCampaignIds', excludedCampaignIds.join(','))
  }
  if (page) {
    params.append('page', page.toString())
  }
  if (pageSize) {
    params.append('pageSize', pageSize.toString())
  }
  if (search) {
    params.append('search', search)
  }
  if (typeof isUnsubscribed === 'boolean') {
    params.append('isUnsubscribed', String(isUnsubscribed))
  }
  return `${params}`
}
