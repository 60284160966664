import React, { useState } from 'react'

import { Button, Dropdown, Input, MenuProps, Typography } from 'antd'
import UnsubscribeTableContainer from './UnsubscribeTableContainer'
import { DownOutlined, DeleteOutlined } from '@ant-design/icons'
import { useUpdateProspects } from '../Prospects/api/prospectsMutations'

const { Title } = Typography

const UnsubscribeContainer = () => {
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [selectedProspects, setSelectedProspects] = useState<string[]>([])

  const onSetFilter = (filter: string) => {
    setSelectedProspects([])
    setSearchFilter(filter)
  }

  const onRemoveFromUnsubList = (prospectIds: string[]) => {
    updateProspects(prospectIds.map((prospectId) => ({ prospectId, isUnsubscribed: false })))
  }

  const { updateProspects, isLoading: updateProspectLoading } = useUpdateProspects()

  const actionItems: MenuProps['items'] = [
    {
      label: 'Remove from unsubscribe list',
      key: '1',
      icon: <DeleteOutlined />,
      onClick: () => onRemoveFromUnsubList(selectedProspects),
      disabled: !selectedProspects.length,
    },
  ]

  return (
    <>
      <Title level={3} style={{ marginBottom: 20, marginTop: 0 }}>
        Unsubscribed Prospects
      </Title>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Dropdown menu={{ items: actionItems }}>
          <Button style={{ marginRight: 15 }}>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>
        <Input.Search
          placeholder="Search by name or email.."
          style={{ width: 300, marginRight: 15 }}
          onSearch={(filter) => onSetFilter(filter)}
        />
      </div>
      <UnsubscribeTableContainer
        searchFilter={searchFilter}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        isLoading={updateProspectLoading}
      />
    </>
  )
}

export default UnsubscribeContainer
