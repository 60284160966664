import React from 'react'
import { Table, Tag } from 'antd'

import './ProspectsTable.scss'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { ProspectsTableItemActions } from './ProspectsTableItemActions'
import dayjs from 'dayjs'
import { ProspectAvatar } from '../../../../components/ProspectAvatar/ProspectAvatar'
import { Prospect } from '@mailarrow/types'

interface Props {
  prospects?: Prospect[]
  totalProspects: number
  currentPage: number
  pageSize: number
  onTableChange: (config: TablePaginationConfig) => void
  isLoading: boolean
  onRemoveProspect: any
  onEditProspect: any
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
  onPauseProspect: (prospectId: string, isPaused: boolean) => void
  onUnsubscribeProspect: (prospectId: string, isUnsubscribed: boolean) => void
}

const ProspectsTable: React.FC<Props> = ({
  prospects,
  totalProspects,
  onTableChange,
  currentPage,
  pageSize,
  isLoading,
  onRemoveProspect,
  onEditProspect,
  selectedProspects,
  setSelectedProspects,
  onPauseProspect,
  onUnsubscribeProspect,
}) => {
  const onRowClick = (one: any, rowIndex?: number) => {
    onEditProspect(one.prospectId)
  }

  const columns: ColumnsType<Prospect> = [
    {
      title: 'Email, Name',
      dataIndex: 'email',
      key: 'email',
      className: 'prospects-table-email',
      fixed: 'left',
      // width: 250,
      // ellipsis: true,
      // sorter: true,
      render: (email: string, prospect: Prospect) => {
        return <ProspectAvatar prospect={prospect} />
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'prospects-table-detail-column',
      width: 125,
      render: (status: any, item: any) => {
        const getStatusString = () => {
          if (item.isPaused) return 'Paused'
          if (item.isUnsubscribed) return 'Unsubscribed'
          if (item.isBounced) return 'Bounced'
          // TODO: add finished
          return 'Active'
        }
        return <Tag style={{ fontSize: 12 }}>{getStatusString()}</Tag>
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 100,
      ellipsis: true,
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        return item.properties?.companyName
      },
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      width: 200,
      ellipsis: true,
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        const ensureWebsiteUrl = (url?: string): string => {
          if (!url) return ''
          if (url.includes('http://') || url.includes('https://')) {
            return url
          }
          // url can be https://example.com, http://example.com, example.com,
          // if it does not have http or https it needs to be added upfront
          return `https://${url}`
        }
        const ensuredUrl = ensureWebsiteUrl(item.properties?.website)

        const hrefUrl = item.properties?.website ? ensuredUrl : item.properties?.website

        return (
          <>
            <a
              href={hrefUrl}
              target="_blank"
              style={{ textDecoration: 'underline', color: '#6a6a6a' }}
              rel="noreferrer"
            >
              {item.properties?.website}
            </a>
          </>
        )
      },
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedin',
      key: 'linkedin',
      width: 200,
      ellipsis: true,
      className: 'prospects-table-detail-column',
      render: (key: any, item: any) => {
        const url = item.properties?.linkedinUrl
        const visibleUrlParial = url?.includes('linkedin.com') && url?.split('linkedin.com')[1]
        return visibleUrlParial ? (
          <a
            href={url}
            target="_blank"
            style={{ textDecoration: 'underline', color: '#6a6a6a' }}
            rel="noreferrer"
          >
            ...{visibleUrlParial}
          </a>
        ) : (
          ''
        )
      },
    },
    {
      title: 'Icebreaker',
      dataIndex: 'icebreaker',
      key: 'icebreaker',
      className: 'prospects-table-detail-column prospects-table-detail-column-icebreaker',
      ellipsis: true,
      width: 150,
      render: (key: any, item: any) => {
        return <span>{item.properties?.icebreaker}</span>
      },
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      className: 'prospects-table-detail-column',
      render: (key: any, prospect: Prospect) => {
        return prospect.createdAt && dayjs(prospect.createdAt).format('DD MMM, HH:mm')
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 30,
      className: 'prospects-table-detail-column',
      render: (one: any, two: any) => {
        return (
          <ProspectsTableItemActions
            prospectId={two.prospectId}
            isPaused={two.isPaused}
            isUnsubscribed={two.isUnsubscribed}
            onPauseProspect={onPauseProspect}
            onUnsubscribeProspect={onUnsubscribeProspect}
            onEditProspect={onEditProspect}
            onRemoveProspect={onRemoveProspect}
          />
        )
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedProspects(selectedRowKeys as string[])
    },
    getCheckboxProps: (record: any) => ({
      name: record?.name,
    }),
    renderCell: (checked: boolean, record: any, index: any, originNode: any) => {
      // this piece of code stops propagation for the click event
      // it is caused to remove annoying effect of opening the drawer
      // which is called on onRowClick, which is triggered when you miss the checkbox
      // by a couple of pixels
      return <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
    },
    selectedRowKeys: selectedProspects,
  }

  return (
    <Table
      dataSource={prospects}
      columns={columns as any}
      loading={isLoading}
      pagination={{
        // position: ['topRight'],
        current: currentPage,
        pageSize: pageSize,
        hideOnSinglePage: true,
        // simple: true,
        total: totalProspects,
      }}
      onChange={onTableChange}
      rowKey="prospectId"
      size="small"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => onRowClick(record, rowIndex), // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        }
      }}
      rowClassName="prospects-table-row"
      rowSelection={rowSelection}
    />
  )
}

export default ProspectsTable
