import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'
import { Sequence } from '@mailarrow/types'

const fetchSequence = async (sequenceId?: string) => {
  try {
    if (!sequenceId) return null
    const response = await fetch.get(`/sequences/${sequenceId}`)
    return response.data as Sequence
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const useFetchSequence = (sequenceId?: string) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['sequence', sequenceId],
    () => fetchSequence(sequenceId),
    {
      enabled: !!sequenceId,
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        // TODO: add sentry here
        console.log(error)
      },
    }
  )

  return {
    sequence: data || undefined,
    isSuccess,
    isLoading,
    isError,
    error,
  }
}
