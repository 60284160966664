import { EmailSequenceStep, SequenceStepType } from '@mailarrow/types'
import * as uuid from 'uuid'

export const createEmailSequenceStep = ({
  stepIndex = 0,
  subject = '',
  body = '',
} = {}): EmailSequenceStep => {
  return {
    stepIndex,
    delayLength: 0,
    delayMetric: 'days',
    type: SequenceStepType.EMAIL,
    variations: [
      {
        stepVariationId: uuid.v4(),
        subject,
        preHeader: '',
        body,
        attachments: [],
        isPlainText: true,
        isPaused: false,
        type: SequenceStepType.EMAIL,
        trackOpens: false,
        trackClicks: false,
        continueThread: stepIndex > 0,
      },
    ],
  }
}
