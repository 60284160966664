import { handleActions } from 'redux-actions'
import fire from '../../services/fire'
import firebase from 'firebase'
import { message } from 'antd'
import fetch from '../../services/fetch'
import logger from '../../services/errorlogger'
import tracker from '../../services/eventTracking'
import { getStoredProps } from './utils/utils'

import { actions as UserActions } from '../../store/UserReducer'
import history from '../../router/history'

const isProd = process.env.NODE_ENV === 'production'

export const actionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',

  SET_USER_PROVIDER: 'SET_USER_PROVIDER',

  USER_SINGNUP_LOADING: 'USER_SINGNUP_LOADING',
  USER_SINGNUP_DONE: 'USER_SINGNUP_DONE',
  USER_SINGNUP_ERROR: 'USER_SINGNUP_ERROR',

  USER_UPDATE_LOADING: 'USER_UPDATE_LOADING',
  USER_UPDATE_DONE: 'USER_UPDATE_DONE',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
}

interface IUserData {
  email?: string
  newEmail?: string
  password?: string
  newPassword?: string
}

export const actions = {
  monitorLogin: () => async (dispatch: any, getState: any) => {
    try {
      let timeout: any = null
      const ensureUserData = async (user: firebase.User | null) => {
        if (!user) {
          dispatch({ type: actionTypes.USER_LOGOUT })
          return localStorage && localStorage.removeItem('userId')
        }
        const { email, uid, providerData } = user
        const provider = providerData && providerData[0] && providerData[0].providerId

        dispatch({ type: actionTypes.SET_USER_PROVIDER, provider })
        logger.setUser(email, uid)
        window.heap && window.heap.identify(uid)
        window.heap && window.heap.addUserProperties({ email })
        localStorage && localStorage.setItem('userId', user.uid)

        dispatch({ type: actionTypes.USER_LOGIN })

        dispatch(UserActions.getUserData())
        refreshToken()
      }

      const refreshToken = async () => {
        const currentUser = await fire.auth().currentUser
        if (!currentUser) return null

        const token = await currentUser.getIdToken(true)
        localStorage && localStorage.setItem('token', token)
        console.log('token refreshed')

        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
        timeout = setTimeout(() => {
          console.log('Refreshing token...')
          refreshToken()
        }, 55 * 60 * 1000)
      }

      await fire.auth().onAuthStateChanged(ensureUserData)
    } catch (e: any) {
      message.error(`Error: ${e.message}`)
      logger.handleError(e, false)
    }
  },
  loginUser: (userData: IUserData) => async (dispatch: any, getState: any) => {
    try {
      const { email, password } = userData
      if (!email || !password) {
        return message.error('Please provide an email and password')
      }

      await fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

      await fire.auth().signInWithEmailAndPassword(email, password)

      dispatch({ type: actionTypes.USER_LOGIN })
      history.push('/app/dashboard/')
    } catch (e: any) {
      message.error(`Error: ${e.message}`)
      logger.handleError(e, false)
    }
  },
  signupUserApi: (userData: IUserData) => async (dispatch: any, getState: any) => {
    try {
      const { inviteId, campaign, analyticsId, source } = getStoredProps()
      const { email, password } = userData
      if (!email || !password) {
        return message.error('Please provide an email and password')
      }

      dispatch({ type: actionTypes.USER_SINGNUP_LOADING })

      const { data: user } = await fetch.put(`/users/`, {
        ...userData,
        inviteId,
        source,
        campaign,
        analyticsId,
      })
      await fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await fire.auth().signInWithEmailAndPassword(email, password)
      dispatch({ type: actionTypes.USER_SINGNUP_DONE })
      dispatch(UserActions.getUserData())
      await tracker.completeRegistration()

      setTimeout(() => {
        // enable adding site here because we dont need to do confirmation anymore
        // dispatch(SitesActions.addSite(siteUrl, user.userId, { isFirstSite: true }))
      }, 1000)
    } catch (error: any) {
      const messageText =
        error.response && error.response.data ? error.response.data.message : error.message
      message.error(`Error: ${messageText}`)
      dispatch({ type: actionTypes.USER_SINGNUP_ERROR })
      logger.handleError(error, false)
    }
  },
  loginUserGoogle: () => async (dispatch: any, getState: any) => {
    try {
      const { inviteId, campaign, analyticsId, source } = getStoredProps()
      dispatch({ type: actionTypes.USER_SINGNUP_LOADING })
      const provider = new firebase.auth.GoogleAuthProvider()
      const response = await fire.auth().signInWithPopup(provider)

      if (!response.additionalUserInfo?.isNewUser) {
        dispatch({ type: actionTypes.USER_SINGNUP_DONE })
        dispatch({ type: actionTypes.USER_LOGIN })
        dispatch(UserActions.getUserData())
        return history.push('/app/dashboard/')
      }

      if (!response.user || !response.additionalUserInfo.profile) {
        throw new Error('Try another email')
      }

      const token = (response.credential as firebase.auth.OAuthCredential).accessToken

      const { family_name, given_name } = response.additionalUserInfo.profile as any
      const { email, uid } = response.user
      const userData = {
        email,
        userId: uid,
        token,
        firstName: given_name,
        lastName: family_name,
        name: `${given_name} ${family_name}`,
        inviteId,
        source,
        campaign,
        analyticsId,
      }

      await fetch.put(`/users/`, userData)
      dispatch({ type: actionTypes.USER_SINGNUP_DONE })
      await tracker.completeRegistration()
      dispatch(UserActions.getUserData())
      dispatch({ type: actionTypes.USER_LOGIN })
      setTimeout(() => {
        // dispatch(SitesActions.addSite(siteUrl, user.userId, { isFirstSite: true }))
      }, 1000)
    } catch (error: any) {
      dispatch({ type: actionTypes.USER_SINGNUP_ERROR })

      console.log(error)
      error && error.response && message.error(`Error: ${error.response.data}`, 10)
      logger.handleError(error, false)
    }
  },
  updatePassword: (userData: IUserData) => async (dispatch: any, getState: any) => {
    try {
      const { password, newPassword } = userData
      if (!password || !newPassword) {
        return message.error('Please provide the password')
      }
      dispatch({ type: actionTypes.USER_UPDATE_LOADING })
      const { email } = getState().user?.user
      await fire.auth().signInWithEmailAndPassword(email, password)
      await fire.auth().currentUser?.updatePassword(newPassword)
      dispatch({ type: actionTypes.USER_UPDATE_DONE })
      message.success(`Password Updated`, 1)
    } catch (e: any) {
      message.error(`Old Password is Incorrect`, 10)
      dispatch({ type: actionTypes.USER_UPDATE_ERROR })
      logger.handleError(e, false)
    }
  },
  resetPassword: (userData: IUserData) => async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: actionTypes.USER_UPDATE_LOADING })
      const { email } = userData
      if (!email) {
        return message.error('Please provide an email')
      }
      await fire.auth().sendPasswordResetEmail(email)
      dispatch({ type: actionTypes.USER_UPDATE_DONE })
      // TODO: add specific state, maybe even move to a hook
      message.success(`Recovery Email Has Been Sent If User Exists, Refreshing...`, 15)
      setTimeout(() => window.location.reload(), 4000)
    } catch (e: any) {
      dispatch({ type: actionTypes.USER_UPDATE_ERROR })
      logger.handleError(e)
    }
  },
  updateUserEmail: (userData: IUserData) => async (dispatch: any, getState: any) => {
    try {
      const { email, password, newEmail } = userData
      if (!email || !password || !newEmail) {
        return message.error('Please provide the password')
      }
      dispatch({ type: actionTypes.USER_UPDATE_LOADING })
      await fire.auth().signInWithEmailAndPassword(email, password)
      await fire.auth().currentUser!.updateEmail(newEmail)
      await fetch.patch(`/users/`, { userData: { email: newEmail } })
      dispatch({ type: actionTypes.USER_UPDATE_DONE })
      message.success(`Email Updated`, 1)
      dispatch(UserActions.getUserData())
    } catch (e: any) {
      dispatch({ type: actionTypes.USER_UPDATE_ERROR })
      message.error(`Failed Updating Email: ${e.message}`, 10)
      logger.handleError(e, false)
    }
  },
  signOutUser: () => async (dispatch: any, getState: any) => {
    try {
      await fire.auth().signOut()
      // console.log('LOGGED OUT', user);
      localStorage && localStorage.removeItem('userId')
      localStorage && localStorage.removeItem('token')
      dispatch({ type: actionTypes.USER_LOGOUT })
      // debugg
      history.push('/login')
    } catch (e: any) {
      logger.handleError(e)
    }
  },
}

export enum LoginState {
  Initial = 'INITIAL',
  LoggedIn = 'LOGGED_IN',
  LoggingOut = 'LOGGING_OUT',
  LoggedOut = 'LOGGED_OUT',
}

export interface AuthState {
  providerData: firebase.UserInfo | null
  loading: boolean

  updateLoading: boolean
  updateError: boolean

  signupLoading: boolean

  loginState: LoginState
}

export const getDefaultState = (): AuthState => {
  const defaultState: AuthState = {
    providerData: null,
    loading: true,
    updateLoading: false,
    updateError: false,
    signupLoading: false,
    loginState: LoginState.Initial,
  }
  return defaultState
}

export const AuthReducer = handleActions(
  {
    [actionTypes.SET_USER_PROVIDER]: (state, { provider }: any) => ({ ...state, provider }),
    [actionTypes.USER_LOGOUT]: () => ({
      ...getDefaultState(),
      loading: false,
      loginState: LoginState.LoggedOut,
    }),
    [actionTypes.USER_LOGIN]: (state) => ({
      ...state,
      loading: false,
      loginState: LoginState.LoggedIn,
    }),

    [actionTypes.USER_SINGNUP_LOADING]: (state) => ({ ...state, signupLoading: true }),
    [actionTypes.USER_SINGNUP_DONE]: (state) => ({ ...state, signupLoading: false }),
    [actionTypes.USER_SINGNUP_ERROR]: (state) => ({ ...state, signupLoading: false }),

    [actionTypes.USER_UPDATE_LOADING]: (state) => ({ ...state, updateLoading: true }),
    [actionTypes.USER_UPDATE_DONE]: (state) => ({
      ...state,
      updateLoading: false,
      updateError: false,
    }),
    [actionTypes.USER_UPDATE_ERROR]: (state) => ({
      ...state,
      updateLoading: true,
      updateError: true,
    }),
  },
  getDefaultState()
)
