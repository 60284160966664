import React, { useCallback, useMemo } from 'react'
import { Avatar, Button, Table, Tag, Tooltip } from 'antd'

import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { EnrichedJourney } from '../types'
import dayjs from 'dayjs'
import { Journey, Mailbox, Prospect } from '@mailarrow/types'
import { SendOutlined, MailOutlined } from '@ant-design/icons'

import './JourneysTable.scss'
import { JourneysTableActions } from './JourneysTableActions'
import { ProspectAvatar } from '../../../components/ProspectAvatar/ProspectAvatar'

interface Props {
  mailboxes?: Mailbox[]
  prospects?: EnrichedJourney[]
  totalProspects: number
  currentPage: number
  pageSize: number
  onTableChange: (config: TablePaginationConfig) => void
  isLoading: boolean
  onRemoveProspect: (prospectId: string) => void
  onEditProspect: (prospectId: string) => void
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
  onPauseProspect: (prospectId: string, isPaused: boolean) => void
  onUnsubscribeProspect: (prospectId: string, isUnsubscribed: boolean) => void
}

const JourneysTable: React.FC<Props> = ({
  mailboxes,
  prospects,
  totalProspects,
  onTableChange,
  currentPage,
  pageSize,
  isLoading,
  onRemoveProspect,
  onEditProspect,
  selectedProspects,
  setSelectedProspects,
  onPauseProspect,
  onUnsubscribeProspect,
}) => {
  const mailboxMap = useMemo(() => {
    const map = new Map<string, Mailbox>()
    mailboxes?.forEach((mailbox) => {
      map.set(mailbox.mailboxId, mailbox)
    })
    return map
  }, [mailboxes])

  const findMailbox = useCallback(
    (mailboxId: string) => {
      return mailboxMap.get(mailboxId)
    },
    [mailboxMap]
  )

  const getSecondLine = (prospect: Prospect) => {
    const { properties } = prospect || {}
    if (!properties || !Object.keys(properties).length) {
      return null
    }

    let secondLine = `${properties.firstName || ''}`
    if (properties.lastName) {
      secondLine += ` ${properties.lastName || ''}`
    }
    if (properties.companyName) {
      secondLine += `, ${properties.companyName || ''}`
    }
    return secondLine.trim()
  }

  const columns: ColumnsType<EnrichedJourney> = [
    {
      title: 'Email',
      dataIndex: 'prospectEmail',
      key: 'prospectEmail',
      className: 'prospects-table-email',
      fixed: 'left',
      // width: 300,
      // ellipsis: true,
      // sorter: true,
      render: (email: string, journey: EnrichedJourney) => {
        const prospect = journey.prospect
        return <ProspectAvatar prospect={prospect} />
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'prospects-table-detail-column',
      width: 125,
      render: (status: any, journey: EnrichedJourney) => {
        const getStatusString = () => {
          if (journey.isPaused) return 'Paused'
          if (journey.isUnsubscribed) return 'Unsubscribed'
          if (journey.isBounced) return 'Bounced'
          if (journey.isCancelled) return 'Cancelled'
          if (journey.isError) return 'Error'
          if (journey.isFinished) return 'Finished'
          // TODO: test this
          return 'Active'
        }
        return <Tag style={{ fontSize: 12 }}>{getStatusString()}</Tag>
      },
    },
    {
      title: (
        <>
          <Tooltip title="Latest completed step in the sequence and step type (email, call, task, etc.)">
            <span style={{ cursor: 'pointer' }}>Latest Step</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'stepIndex',
      key: 'stepIndex',
      width: 100,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        if (typeof journey.compeletedStepIndex !== 'number') return null

        return (
          <>
            #{journey.compeletedStepIndex + 1} <MailOutlined />
          </>
        )
      },
    },
    {
      title: (
        <>
          <Tooltip title="Time of the latest completed step">
            <span style={{ cursor: 'pointer' }}>Step Time</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'completedStepTime',
      key: 'completedStepTime',
      width: 120,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        return journey.completedStepTime && dayjs(journey.completedStepTime).format('DD MMM, HH:mm')
      },
    },
    {
      title: (
        <>
          <Tooltip title="Time of the next step in the sequence. Can be in the past if campaign is paused, prospect is paused, unsubscribed, error, etc. Can be empty as well.">
            <span style={{ cursor: 'pointer' }}>Next Step Time</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'nextStepTime',
      key: 'nextStepTime',
      width: 120,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        if (!journey.nextStepTime || journey.isFinished) return null
        const isInFuture = dayjs(journey.nextStepTime).isAfter(dayjs())
        if (isInFuture) {
          return dayjs(journey.nextStepTime).format('DD MMM, HH:mm')
        }
        return null
      },
    },
    {
      title: (
        <>
          <Tooltip title="Opens of all emails sent to this prospect during the entire journey">
            <span style={{ cursor: 'pointer' }}>Opens</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'interactions',
      key: 'interactions',
      width: 60,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        return journey.opens ? journey.opens : ''
      },
    },
    {
      title: (
        <>
          <Tooltip title="Clicks of all links sent to this prospect during the entire journey">
            <span style={{ cursor: 'pointer' }}>Clicks</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'interactions',
      key: 'interactions',
      width: 60,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        return journey.clicks ? journey.clicks : ''
      },
    },
    {
      title: (
        <>
          <Tooltip title="All replies by this prospect during the entire journey">
            <span style={{ cursor: 'pointer' }}>Replies</span>
          </Tooltip>
        </>
      ),
      dataIndex: 'interactions',
      key: 'interactions',
      width: 60,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        return journey.replies ? journey.replies : ''
      },
    },
    // {
    //   title: 'Sender',
    //   dataIndex: 'mailbox',
    //   key: 'mailbox',
    //   className: 'prospects-table-detail-column',
    //   render: (key: any, journey: any) => {
    //     const email = journey.mailboxId && findMailbox(journey.mailboxId)?.email
    //     if (!email) return null

    //     const splitEmail = email.split('@')
    //     return (
    //       <>
    //         {splitEmail[0]}
    //         <br />
    //         <span style={{ fontSize: 12, color: '#6a6a6a' }}>@{splitEmail[1]}</span>
    //       </>
    //     )
    //   },
    // },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      className: 'prospects-table-detail-column',
      render: (key: any, journey: EnrichedJourney) => {
        return journey.createdAt && dayjs(journey.createdAt).format('DD MMM, HH:mm')
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 30,
      className: 'prospects-table-detail-column',
      render: (one: any, two: EnrichedJourney) => {
        return (
          <JourneysTableActions
            prospectId={two.prospectId}
            isPaused={two.isPaused || false}
            isUnsubscribed={two.isUnsubscribed || false}
            onPauseProspect={onPauseProspect}
            onEditProspect={onEditProspect}
            onUnsubscribeProspect={onUnsubscribeProspect}
            onRemoveProspect={onRemoveProspect}
          />
        )
      },
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedProspects(selectedRowKeys as string[])
    },
    getCheckboxProps: (record: any) => ({
      name: record?.name,
    }),
    renderCell: (checked: boolean, record: any, index: any, originNode: any) => {
      // this piece of code stops propagation for the click event
      // it is caused to remove annoying effect of opening the drawer
      // which is called on onRowClick, which is triggered when you miss the checkbox
      // by a couple of pixels
      return <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
    },
    selectedRowKeys: selectedProspects,
  }

  return (
    <Table
      dataSource={prospects}
      columns={columns as any}
      loading={isLoading}
      pagination={{
        position: ['bottomRight'],
        current: currentPage,
        pageSize: pageSize,
        hideOnSinglePage: true,
        // simple: true,
        total: totalProspects,
        showTotal: (total) => `Total ${total} prospects`,
      }}
      onChange={onTableChange}
      rowKey="prospectId"
      size="small"
      onRow={(record, rowIndex) => {
        return {
          // onClick: (event) => onRowClick(record, rowIndex), // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        }
      }}
      // rowClassName="prospects-table-row"
      rowSelection={rowSelection}
    />
  )
}

export default JourneysTable
