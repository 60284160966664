import { Button, Divider, Select, Space, Tooltip } from 'antd'
import { Mailbox } from '@mailarrow/types'
import { MailboxIcon } from '../MailboxIcon/MailboxIcon'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SizeType } from 'antd/es/config-provider/SizeContext'

interface Props {
  mailboxes?: Mailbox[]
  onSelectMailbox: (mailboxIds: string[]) => void
  onAddMailbox: () => void
  loading: boolean
  value: string[]
  size: SizeType
  style?: React.CSSProperties
}

export const MailboxSelector: React.FC<Props> = ({
  size,
  style,
  mailboxes,
  loading,
  onSelectMailbox,
  onAddMailbox,
  value,
}) => {
  const mailboxOptions = mailboxes?.map(({ mailboxId, email, config, connectionStatus }) => ({
    value: mailboxId,
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          <MailboxIcon type={config.mailboxType} style={{ marginRight: 10 }} />
          {email}
        </span>
        {connectionStatus === 'requires_reauth' && (
          <Tooltip title="Please Reconnect Mailbox">
            <ExclamationCircleOutlined style={{ color: 'orange', fontSize: 12, marginLeft: 5 }} />
          </Tooltip>
        )}
      </div>
    ),
  }))

  return (
    <Select
      size={size}
      loading={loading}
      onChange={(id: string[]) => {
        onSelectMailbox(id)
      }}
      style={style}
      value={value}
      mode="multiple"
      defaultValue={value}
      options={mailboxOptions}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!mailboxOptions?.length && (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Button type="text" icon={<PlusOutlined />} onClick={onAddMailbox}>
                  Add Mailbox
                </Button>
              </Space>
            </>
          )}
        </>
      )}
    />
  )
}
