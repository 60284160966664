import React from 'react'
import BillingDashboard from './BillingDashboard'
import BillingHistory from './BillingHistory'
import Subscription from './Subscription'

const Billing = () => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: '50%' }}>
        <BillingDashboard />
        <Subscription />
      </div>
      <div style={{ width: '50%' }}>
        <BillingHistory />
      </div>
    </div>
  )
}

export default Billing
