import React, { useState } from 'react'

import ProspectsTable from './ProspectsTable/ProspectsTableContainer'
import AddProspectsManual from './AddProspectManual/AddProspectManual'
import AddProspectsCSV from './AddProspectsCSV/AddProspectsCSV'
import AddVariableModal from './AddVariableModal/AddVariableModal'
import ProspectsTableToolbar from './ProspectsTableToolbar/ProspectsTableToolbar'
import AddExistingProspects from './AddExistingProspects/AddExistingProspects'

interface ProspectsProps {
  onAddProspects: (prospects: string[]) => void
  onRemoveProspects: (prospects: string[]) => void
  onPersonalizeProspects: (prospects: string[]) => void
  loading: boolean
  campaignId?: string
  selectedProspects: string[]
  setSelectedProspects: (prospects: string[]) => void
}

const Prospects: React.FC<ProspectsProps> = ({
  onAddProspects,
  onRemoveProspects,
  onPersonalizeProspects,
  loading,
  campaignId,
  selectedProspects,
  setSelectedProspects,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [isImportExistingsOpen, setImportExistingOpen] = useState<boolean>(false)
  const [isImportCSVOpen, setImportCSVOpen] = useState<boolean>(false)
  const [isAddManualOpen, setAddManualOpen] = useState<boolean>(false)
  const [isAddVariableModalOpen, setAddVariableModalOpen] = useState<boolean>(false)

  return (
    <>
      <ProspectsTableToolbar
        setSearchFilter={setSearchFilter}
        setImportCSVOpen={setImportCSVOpen}
        setAddManualOpen={setAddManualOpen}
        setImportExistingOpen={setImportExistingOpen}
        onRemoveProspects={onRemoveProspects}
        onPersonalizeProspects={onPersonalizeProspects}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        isRemoveProspectsLoading={loading}
      />
      <ProspectsTable
        isLoading={loading}
        campaignId={campaignId}
        searchFilter={searchFilter}
        selectedProspects={selectedProspects}
        setSelectedProspects={setSelectedProspects}
        onRemoveProspects={onRemoveProspects}
      />
      <AddProspectsManual
        isOpen={isAddManualOpen}
        onClose={() => setAddManualOpen(false)}
        campaignId={campaignId}
      />
      <AddProspectsCSV
        isOpen={isImportCSVOpen}
        onClose={() => setImportCSVOpen(false)}
        campaignId={campaignId}
        onAddVariable={() => setAddVariableModalOpen(true)}
      />
      <AddExistingProspects
        isOpen={isImportExistingsOpen}
        onClose={() => setImportExistingOpen(false)}
        campaignId={campaignId}
      />
      <AddVariableModal
        isOpen={isAddVariableModalOpen}
        onClose={() => setAddVariableModalOpen(false)}
      />
    </>
  )
}

export default Prospects
