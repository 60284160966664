import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { PermissionsLevel } from '@mailarrow/types'

export interface UserPermissions {
  admin: boolean
  staff: boolean
}

const isAdminEmail = (email?: string): boolean => {
  if (!email) return false

  const allowedPartials = [
    'sergiy.shlykov@gmail.com',
    // '@luga.cc',
    'razzeond@gmail.com',
  ]
  return allowedPartials.some((p) => email.includes(p))
}

export function usePermissions(): UserPermissions {
  const { user } = useSelector((state: RootState) => state.user)
  const admin = Boolean(
    isAdminEmail(user?.email) || user?.permissions.includes(PermissionsLevel.ADMIN)
  )
  const staff = admin || Boolean(user?.permissions.includes(PermissionsLevel.SUPPORT_STAFF))
  return {
    admin,
    staff,
  }
}
