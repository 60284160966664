import { useState } from 'react'
import { Alert, Button, Card, Input, Space, Spin, Typography } from 'antd'
import * as XLSX from 'xlsx'
import { groupData1, groupData2 } from './grouping'
import { verifiedGroups } from './verified-groups'
import { groupBy, sortBy } from 'lodash'

const { Title, Text } = Typography

const includes = []

const KWMapperContainer = () => {
  const [data, setData] = useState<any>({})
  const [cluster, setCluster] = useState<any>([])
  const [grouping, setGrouping] = useState<number>(0)

  const group = (data: any[]) => {
    const result = groupData2(data, grouping)
    setData(result)

    const byPage = groupBy(data, (d) => d[4])

    const byCluster = verifiedGroups.map((group) => {
      const grouped = group.map((url) => {
        const keywords = byPage[url]
          .map((kw) => {
            return {
              keyword: kw[0],
              volume: kw[1],
              kd: kw[2],
              url: kw[4],
              traffic: kw[5],
            }
          })
          .filter((kw) => kw.traffic > 0)

        const sortedKWs = sortBy(keywords, 'traffic').reverse()

        return {
          url,
          keywords: sortedKWs,
        }
      })

      return grouped
    })

    setCluster(byCluster)
  }

  const onMap = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const buffer = new Uint8Array(reader.result as ArrayBuffer)
      const workbook = XLSX.read(buffer, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      group(jsonData)
    }
    reader.readAsArrayBuffer(file)
  }

  const onDownload = () => {
    const flattenedData = data.map((item: any) => ({
      ...item,
      keywords: item.keywords.join(', '),
    }))

    const ws = XLSX.utils.json_to_sheet(flattenedData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, 'data.xlsx')
  }

  console.log(cluster)

  return (
    <Space direction="vertical" style={{ width: '100%', padding: '24px' }}>
      <Title level={2}>KW Mapper </Title>
      <Input type="file" onChange={onMap} />
      <Button onClick={onDownload}>Download</Button>
      {cluster &&
        cluster.map((c: any) => {
          return (
            <>
              {c.map((url: any) => {
                return (
                  <>
                    <div>
                      <b>{url.url}</b>
                      {url.keywords.map((kw: any) => (
                        <div>
                          {kw.keyword} - {kw.volume} - {kw.traffic} - {kw.kd}
                        </div>
                      ))}
                    </div>
                  </>
                )
              })}
              <hr />
            </>
          )
        })}
      {/* <div>{cluster}</div> */}
      <></>
    </Space>
  )
}

export default KWMapperContainer
