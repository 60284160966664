import React, { useState } from 'react'
import { Input, message, Modal } from 'antd'

import { useAddCustomProperty } from '../../api/customPropsMutations'
import { toCamelCase } from '../../../../util/to-case'
import { useFetchCustomProperties } from '../../api/customPropsQueries'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AddVariableModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('')
  const [variableDefault, setDefault] = useState('')

  const { properties, isLoading: fetchPropsLoading } = useFetchCustomProperties()

  const { addProperty, isLoading: addPropLoading } = useAddCustomProperty()

  const isUnique = (slug: string): boolean => {
    if (!properties) return true
    return !properties.some((s: any) => s.slug === slug)
  }

  const onFinish = () => {
    const slug = toCamelCase(name)
    if (!isUnique(slug)) {
      return message.info('Please pick another property name. This one is already used.')
    }
    const property = { name, slug: toCamelCase(name), default: variableDefault }

    addProperty(property)
    setName('')
    setDefault('')
    onClose()
  }

  // TODO: show existing variable so that ppl do neot end up creatring
  // more variables than they need

  return (
    <Modal
      title="Add Custom Property"
      onCancel={onClose}
      open={isOpen}
      okButtonProps={{ loading: fetchPropsLoading || addPropLoading, disabled: !Boolean(name) }}
      okText="Save"
      onOk={onFinish}
      style={{ maxWidth: 600, margin: '0 auto' }}
    >
      <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 5 }}>Property Name:</div>
        <Input
          name="name"
          value={name}
          size="large"
          onChange={(event) => setName(event.target.value)}
          width={300}
        />
      </div>
      {/* <div style={{ marginBottom: 15 }}>
        <div style={{ marginBottom: 5 }}>Default Value:</div>
        <Input
          name="name"
          value={variableDefault}
          size="large"
          onChange={(event) => setDefault(event.target.value)}
          width={300}
        />
      </div> */}
    </Modal>
  )
}

export default AddVariableModal
