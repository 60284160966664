import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'

export const addSchedules = async (campaignId: string, schedules: any[] = []) => {
  const userId = '123' // replace with actual user ID from state
  if (!userId) return null

  await fetch.put(`/schedules/${campaignId}/`, schedules)
}

export const useAddSchedules = (campaignId: string) => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (schedules: any[]) => addSchedules(campaignId, schedules),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules', campaignId])
      },
      onError: (error: any) => {
        // todo: log all failed queries
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    addSchedules: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

export const editSchedule = async (scheduleId: string, scheduleProps: Partial<any>) => {
  return await fetch.patch(`/schedules/${scheduleId}/`, scheduleProps)
}

export const useEditSchedule = (scheduleId: string) => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: { scheduleId: string; scheduleProps: Partial<any> }) =>
      editSchedule(args.scheduleId, args.scheduleProps),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules', scheduleId])
        queryClient.refetchQueries(['schedules', scheduleId])
        message.success(`Schedule has been saved`, 10)
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    editSchedule: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

export const removeSchedule = async (campaignId: string, scheduleId: string) => {
  if (!scheduleId) return

  return await fetch.delete(`/schedules/${campaignId}/${scheduleId}/`)
}

export const useRemoveSchedule = (campaignId: string) => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (scheduleId: string) => removeSchedule(campaignId, scheduleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules', campaignId])
        queryClient.refetchQueries(['schedules', campaignId])
      },
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )
  console.log('isLoading remove', isLoading)

  return {
    removeSchedule: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
