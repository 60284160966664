import React from 'react'
import { Form, Input, Button, Card } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { actions as UserActions } from '../../../store/UserReducer'

const EditProfile: React.FC = () => {
  const { user, updateLoading } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const handleSubmit = (values: any) => {
    dispatch(UserActions.updateUser(values))
  }

  if (!user) return null

  const { firstName, lastName } = user

  const formItemLayout = {
    labelCol: { xs: { span: 7 }, sm: { span: 7 } },
    wrapperCol: { xs: { span: 10 }, sm: { span: 10 } },
  }

  const tailFormItemLayout = {
    wrapperCol: { xs: { span: 10, offset: 7 }, sm: { span: 10, offset: 7 } },
  }

  return (
    <div>
      <Card title={<span>Personal Information</span>} size="small">
        <div style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)', marginBottom: 20 }}>
          View and change your personal information
        </div>
        <Form
          {...formItemLayout}
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={{ firstName, lastName }}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input your name' }]}
          >
            <Input placeholder="John" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input your name' }]}
          >
            <Input placeholder="Smith" />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={updateLoading}
              disabled={updateLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default EditProfile
