// Import required modules
import React, { useState } from 'react'
import { Card, Button, Typography, Switch } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { usePermissions } from '../../modules/User/Permissions'

interface Props {
  onSelectPlan: (planName: string) => void
  isLoading: boolean
}

// Define your component
const Pricing: React.FC<Props> = ({ onSelectPlan, isLoading }) => {
  const { staff } = usePermissions()
  const [yearly, setYearly] = useState(false)

  const handleSwitchChange = (checked: boolean) => {
    setYearly(checked)
  }

  const Yes = () => (
    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '16px', marginRight: 5 }} />
  )
  const No = () => (
    <CloseCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px', marginRight: 5 }} />
  )
  const LowQuality = () => (
    <CheckCircleTwoTone twoToneColor="lightgrey" style={{ fontSize: '16px' }} />
  )

  return (
    <div style={{ marginTop: '30px' }}>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={3}>Pricing</Typography.Title>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Card style={{ margin: '10px', width: 300 }}>
          <Typography.Title level={4}>Basic Plan</Typography.Title>
          <Typography.Title level={5}>
            {yearly ? '$30 / month, paid yearly' : '$39 / month'}
          </Typography.Title>
          <div style={{ margin: '10px 0' }}>
            <div>
              <Yes /> 2 000 active prospects
            </div>
            <div>
              <Yes /> 6 000 emails / mo
            </div>
            <div>
              <Yes /> Unlimited email accounts
            </div>
            <div>
              <No /> AI Email personalization
            </div>
            <div>
              <Yes /> Unlimited sequences and follow-ups
            </div>
          </div>
          <Button type="primary" onClick={() => onSelectPlan('basic')} loading={isLoading}>
            Subscribe
          </Button>
        </Card>
        <Card style={{ margin: '10px', width: 300 }}>
          <Typography.Title level={4}>Professional Plan</Typography.Title>
          <Typography.Title level={5}>
            {yearly ? '$49 / month, paid yearly' : '$59 / month'}
          </Typography.Title>
          <div style={{ margin: '10px 0' }}>
            <div>
              <Yes /> 20 000 active prospects
            </div>
            <div>
              <Yes /> 60 000 emails / mo
            </div>
            <div>
              <Yes /> Unlimited email accounts
            </div>
            <div>
              <Yes /> AI Email personalization
            </div>
            <div>
              <Yes /> Unlimited sequences and follow-ups
            </div>
          </div>
          <Button type="primary" onClick={() => onSelectPlan('standard')} loading={isLoading}>
            Subscribe
          </Button>
        </Card>
        <Card style={{ margin: '10px', width: 300 }}>
          <Typography.Title level={4}>Growth Plan</Typography.Title>
          <Typography.Title level={5}>
            {yearly ? '$70 / month, paid yearly' : '$99 / month'}
          </Typography.Title>
          <div style={{ margin: '10px 0' }}>
            <div>
              <Yes /> 50 000 active prospects
            </div>
            <div>
              <Yes /> 150 000 emails / mo
            </div>
            <div>
              <Yes /> Unlimited email accounts
            </div>
            <div>
              <Yes /> AI Email personalization
            </div>
            <div>
              <Yes /> Unlimited sequences and follow-ups
            </div>
          </div>
          <Button type="primary" onClick={() => onSelectPlan('growth')} loading={isLoading}>
            Subscribe
          </Button>
        </Card>
      </div>
      {staff && (
        <div style={{ marginTop: '40px' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography.Title level={4}>Test Pricing</Typography.Title>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
            <Card style={{ margin: '10px', width: 300 }}>
              <Typography.Title level={4}>Test 1$ Plan</Typography.Title>
              <Typography.Title level={5}>
                {yearly ? '$1 / month, paid yearly' : '$1 / month'}
              </Typography.Title>
              <div style={{ margin: '10px 0' }}>
                <div>
                  <Yes /> 1 active prospects
                </div>
                <div>
                  <Yes /> 1 emails / mo
                </div>
                <div>
                  <Yes /> Unlimited email accounts
                </div>
                <div>
                  <Yes /> AI Email personalization
                </div>
                <div>
                  <Yes /> Unlimited sequences and follow-ups
                </div>
              </div>
              <Button type="primary" onClick={() => onSelectPlan('dollar')} loading={isLoading}>
                Subscribe
              </Button>
            </Card>
          </div>
        </div>
      )}
    </div>
  )
}

export default Pricing
