import React, { useState } from 'react'

import AddSMTPMailboxContainer from '../mailboxes/smtp/AddSMTPMailbox'
import AddOfficeMailbox from '../mailboxes/office/AddOutlookMailbox'
import AddExchangeMailbox from '../mailboxes/office/AddExchangeMailbox'
import AddGmailMailbox from '../mailboxes/gmail/AddGmailMailbox'
import { Mailbox } from '@mailarrow/types'

interface Props {
  visible: boolean
  mailbox?: Mailbox
  onClose: () => void
}

const ReconnectMailboxModalContainer: React.FC<Props> = ({ visible, onClose, mailbox }) => {
  if (!mailbox) return null

  const { config, mailboxId } = mailbox

  const onModalClose = () => {
    onClose()
  }

  return (
    <div style={{ width: '100%' }}>
      <AddSMTPMailboxContainer
        open={visible && config.mailboxType === 'SMTP'}
        onClose={() => {
          onModalClose()
        }}
      />
      <AddOfficeMailbox
        open={visible && config.mailboxType === 'OUTLOOK'}
        mailboxId={mailboxId}
        onClose={() => {
          onModalClose()
        }}
      />
      <AddGmailMailbox
        open={visible && config.mailboxType === 'GMAIL'}
        mailboxId={mailboxId}
        onClose={() => onModalClose()}
      />
      <AddExchangeMailbox
        open={visible && config.mailboxType === 'EXCHANGE'}
        onClose={() => {
          onModalClose()
        }}
      />
    </div>
  )
}

export default ReconnectMailboxModalContainer
