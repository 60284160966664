import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import * as Sentry from '@sentry/react'
import { composeWithDevTools } from '@redux-devtools/extension'

import crashReporter from './middlewares/crashReporter'

import { UserState, UserReducer } from './UserReducer'
import { BillingState, BillingReducer } from './BillingReducer'
import { AuthState, AuthReducer } from '../modules/Auth/reducer'

export interface RootState {
  user: UserState
  billing: BillingState
  auth: AuthState
}

const makeRootReducer = () =>
  combineReducers({
    user: UserReducer,
    billing: BillingReducer,
    auth: AuthReducer,
  })

const createReduxStore = () => {
  const middleware = [
    thunk as ThunkMiddleware<RootState, any>,
    // disabled because of an error with hooks (unknown error)
    // todo: replace with something more suitable (or not)
    // process.env.NODE_ENV === 'development' && createLogger({ collapsed: true }),
    crashReporter,
  ].filter((mw) => !!mw)

  const initialState = {}

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: RootState) => {
      const transformedState = {
        ...state,
      }
      return transformedState
    },
  })

  const composeEnhancers = composeWithDevTools([sentryReduxEnhancer] as any)

  const store = createStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...(middleware as any)) as any) as any
  )

  return store
}

const store = createReduxStore()

export default store
