import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message, notification } from 'antd'

type Sequence = any

const editSequence = async (sequenceId: string, sequenceProps: Sequence) => {
  await fetch.patch(`/sequences/${sequenceId}`, sequenceProps)
}

export const useEditSequence = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    (args: { sequenceId: string; sequenceProps: Sequence }) =>
      editSequence(args.sequenceId, args.sequenceProps),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sequence'])
        queryClient.refetchQueries(['sequence'])
        // TODO: move elsewhere
        message.config({ maxCount: 2 })
        message.success({ content: 'Sequence has been updated' })
      },
      onError: (error: any) => {
        notification.error({
          message: 'There has been an error',
          description: `${error.message}`,
          duration: 10,
        })
        console.log(error)
      },
    }
  )

  return {
    editSequence: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
