import { Button, Card, Input, message } from 'antd'
import { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { useFetchMailboxes } from '../../../../Mailboxes'
import { useEditCampaign } from '../../../api/campaignsMutations'
import { MailboxSelector } from '../../../../Mailboxes/components/MailboxSelector/MailboxSelector'
import history from '../../../../../router/history'
import { Campaign } from '@mailarrow/types'

interface Props {
  campaign: Campaign
}

const GeneralSettings: React.FC<Props> = ({ campaign }) => {
  const [name, setName] = useState<string | undefined>(undefined)
  const [mailboxIds, setMailboxIds] = useState<string[]>([])

  useEffect(() => {
    if (campaign) {
      setName(campaign.name)
      setMailboxIds(campaign.mailboxIds)
    }
  }, [campaign])

  const { mailboxes, isLoading: fetchMailboxesLoading } = useFetchMailboxes()

  const { editCampaign, isLoading: editCampaignLoading } = useEditCampaign()

  const onFinish = () => {
    if (!name) return message.error('Please provide a name')
    if (!mailboxIds.length) return message.error('Please select at least one mailbox')

    editCampaign({ campaignId: campaign.campaignId, campaignProps: { name, mailboxIds } })
  }

  return (
    <div>
      <Card title={<div>General</div>} style={{ marginBottom: 20 }} size="small">
        <div style={{ marginBottom: 15, width: 300 }}>
          <div style={{ marginBottom: 5 }}>Campaign Name:</div>
          <Input
            name="name"
            value={name}
            // size="small"
            onChange={(event) => setName(event.target.value)}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ marginBottom: 35 }}>
          <div style={{ marginBottom: 5 }}>Mailboxes:</div>
          <MailboxSelector
            size="middle"
            style={{ width: '100%' }}
            value={mailboxIds}
            mailboxes={mailboxes}
            onSelectMailbox={(ids) => setMailboxIds(ids)}
            onAddMailbox={() => history.push('/app/settings/mailboxes')}
            loading={fetchMailboxesLoading}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onFinish}
            loading={fetchMailboxesLoading || editCampaignLoading}
            size="small"
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default GeneralSettings
