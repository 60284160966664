import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { Mailbox } from '@mailarrow/types'
import { message } from 'antd'
import { reverse } from 'lodash'
import useAuthState from '../../Auth/use-auth-state'

const getMailboxes = async () => {
  const { data } = await fetch.get('/mailboxes/all/')
  return reverse(data) as Mailbox[]
}

export const useFetchMailboxes = () => {
  const { isLoggedIn } = useAuthState()
  const { data, isLoading, isError, isSuccess, error, refetch } = useQuery(
    ['mailboxes'],
    getMailboxes,
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
      enabled: isLoggedIn,
    }
  )

  return {
    mailboxes: data,
    refetch,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
