import { MailOutlined } from '@ant-design/icons'
import {
  FunnelPlotOutlined,
  UploadOutlined,
  RocketOutlined,
  DesktopOutlined,
  UserDeleteOutlined,
  PartitionOutlined,
  SettingOutlined,
  ShakeOutlined,
  DollarOutlined,
  CreditCardOutlined,
  MessageOutlined,
  ThunderboltOutlined,
  SendOutlined,
  TeamOutlined,
  ApiOutlined,
  AppstoreOutlined,
} from '@ant-design/icons'
import type { MenuProps, MenuTheme } from 'antd/es/menu'
import { Link } from 'react-router-dom'
import { UserPermissions } from '../../modules/User/Permissions'
import { RootState } from '../../store/store'

type MenuItem = Required<MenuProps>['items'][number]

export const getNavigationItems = (
  navigate: any,
  permissions: UserPermissions,
  user: RootState['user']['user']
): MenuItem[] => {
  return [
    // {
    //   key: '/app/getting-started',
    //   icon: <ThunderboltOutlined />,
    //   label: 'Getting Started',
    //   onClick: () => navigate('/app/getting-started'),
    // },
    {
      key: '/app/dashboard',
      icon: <AppstoreOutlined />,
      label: 'Dashboard',
      onClick: () => navigate('/app/dashboard'),
    },
    // {
    //   key: '/app/pricing',
    //   icon: <UploadOutlined />,
    //   label: 'Pricing',
    //   onClick: () => navigate('/app/pricing'),
    // },
    {
      key: '/app/campaigns',
      icon: <PartitionOutlined />,
      label: 'Campaigns',
      onClick: () => navigate('/app/campaigns'),
    },
    permissions.staff
      ? {
          key: '/app/activity',
          icon: <SendOutlined />,
          label: 'Activity',
          onClick: () => navigate('/app/activity'),
        }
      : null,
    {
      key: '/app/prospects',
      icon: <TeamOutlined />,
      label: 'Prospects',
      // onClick: () => navigate('/app/prospects/all'),
      children: [
        {
          key: '/app/prospects/all',
          icon: <TeamOutlined />,
          label: 'All Prospects',
          onClick: () => navigate('/app/prospects/all'),
        },
        {
          key: '/app/prospects/unsubscribes',
          icon: <UserDeleteOutlined />,
          label: 'Unsubscribed',
          onClick: () => navigate('/app/prospects/unsubscribes'),
        },
      ],
    },
    permissions.staff
      ? {
          key: '/app/tools',
          icon: <AppstoreOutlined />,
          label: 'Tools',
          // onClick: () => navigate('/app/tools'),
          children: [
            {
              key: '/app/settings/smoothliner',
              icon: <ShakeOutlined />,
              label: 'SmoothLiner',
              onClick: () => navigate('/app/tools/smoothliner'),
            },
          ],
        }
      : null,
    {
      key: '/app/settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      onClick: () => navigate('/app/settings'),
      // children: [
      //   {
      //     key: '/app/settings/mailboxes',
      //     icon: <MailOutlined />,
      //     label: 'Mailboxes',
      //     onClick: () => navigate('/app/settings/mailboxes'),
      //   },
      //   {
      //     key: '/app/billing',
      //     icon: <CreditCardOutlined />,
      //     label: 'Billing',
      //     onClick: () => navigate('/app/billing'),
      //   },
      // ],
    },
    permissions.staff || user?.isTrial
      ? {
          key: '/app/pricing',
          icon: <DollarOutlined />,
          label: 'Pricing',
          onClick: () => navigate('/app/pricing'),
        }
      : null,
    permissions.admin
      ? {
          key: '/app/monitor',
          icon: <FunnelPlotOutlined />,
          label: 'Monitor',
          onClick: () => navigate('/app/monitor'),
        }
      : null,
  ].filter(Boolean)
}
