import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { message, notification } from 'antd'
import fire from './fire'
import history from '../router/history'

const isProduction = process.env.NODE_ENV === 'production'

const logger = {
  init: (): void => {
    if (!isProduction) return
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY_2!,
      normalizeDepth: 5,
      maxBreadcrumbs: 20,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
    Sentry.configureScope((scope) => {
      scope.setTag('env', 'frontend')
    })
  },
  logError: (err: Error): void => {
    if (!isProduction) return
    Sentry.captureException(err)
  },
  setUser: (email: string | null | undefined, userId: string | null | undefined): void => {
    if (!isProduction || !email) return
    Sentry.configureScope((scope) => {
      scope.setUser({ email, userId })
    })
  },
  handleError: async (err: any, showMessage = true): Promise<any> => {
    try {
      if (err.response && err.response.status === 401) {
        await fire.auth().signOut()
        localStorage?.removeItem('userId')
        localStorage?.removeItem('token')
        history.push('/login')
        logger.logError(err)
        return message.info(`Session Expired. Please log in again`)
      }
      console.log('Error Occured')
      console.log(err)
      showMessage &&
        notification.error({
          message: `Looks like we've encountered an error:`,
          description: (
            <>
              {err.message} <br />
              Please reach out to support if the error persists.
            </>
          ),
        })
      logger.logError(err)
    } catch (e: any) {
      console.log('Sentry Error Reporting Failed')
      console.log(e)
      logger.logError(e)
    }
  },
}

export default logger
