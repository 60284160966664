// EmailsMonitorContainer.tsx
import React, { useState } from 'react'
import { Button, TablePaginationConfig } from 'antd'
import EmailsFilter from './EmailsFilter' // make sure to import EmailsFilter
import EmailsTable from './EmailsTable'
import { useFetchMonitorEmails } from '../api/adminQueries'

export const EmailsMonitorContainer: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(100)
  const [filters, setFilters] = useState({
    prospectEmail: undefined as string | undefined,
    mailboxId: undefined as string | undefined,
    emailId: undefined as string | undefined,
    campaignId: undefined as string | undefined,
    userId: undefined as string | undefined,
    journeyId: undefined as string | undefined,
    status: undefined as string | undefined,
  })
  const { emails, total, isLoading, isFetching, refetch } = useFetchMonitorEmails(filters, {
    page: currentPage,
    pageSize: pageSize,
  })

  const onTableChange = (pagination: TablePaginationConfig) => {
    const newCurrentPage = pagination.current || 1
    setCurrentPage(newCurrentPage)
    if (pagination.pageSize && pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize)
    }
  }

  const onRefresh = () => {
    setCurrentPage(1)
    setFilters({
      prospectEmail: undefined,
      mailboxId: undefined,
      emailId: undefined,
      campaignId: undefined,
      userId: undefined,
      journeyId: undefined,
      status: undefined,
    })
    refetch()
  }

  const onFilter = (newFilters: any) => {
    setCurrentPage(1)
    setFilters(newFilters)
    refetch()
  }

  return (
    <div>
      <EmailsFilter onFilter={onFilter} />
      <div style={{ margin: '10px 0', display: 'flex', alignItems: 'baseline' }}>
        <Button onClick={onRefresh}>Reset</Button>
        <div style={{ marginLeft: 10 }}>Total Emails: {total}</div>
      </div>

      <EmailsTable
        emails={emails}
        total={total || 0}
        onTableChange={onTableChange}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={isLoading || isFetching}
      />
    </div>
  )
}
