import React, { useEffect } from 'react'
import Pricing from '../../../components/Pricing/Pricing'
import { useCreateCheckoutLink } from '../api/billingMutations'
import history from '../../../router/history'

const PricingContainer: React.FC = () => {
  const { createCheckoutLink, link, isLoading } = useCreateCheckoutLink()
  const onGenerateLink = (planName: string) => {
    createCheckoutLink({ planName })
  }
  useEffect(() => {
    if (link) {
      history.push(link)
    }
  }, [link])

  return <Pricing onSelectPlan={onGenerateLink} isLoading={isLoading} />
}

export default PricingContainer
