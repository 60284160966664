import React, { Component, useEffect } from 'react'
import { Button, Progress, Card, Tooltip } from 'antd'
import history from '../../../router/history'
import { useCreateBillingSession } from '../../../modules/Billing/api/billingMutations'

const BillingDashboard: React.FC = () => {
  const { createBillingSession, link, isLoading } = useCreateBillingSession()
  const onGenerateLink = () => {
    createBillingSession()
  }

  useEffect(() => {
    if (link) {
      history.push(link)
    }
  }, [link])

  return (
    <Card style={{ marginBottom: 20 }} title="Billing Dashboard" size="small">
      <div style={{ marginBottom: '10px' }}>Click here to make changes to your subscription</div>
      <Button onClick={onGenerateLink} loading={isLoading} type="primary">
        Change Subscription
      </Button>
    </Card>
  )
}

export default BillingDashboard
