import React from 'react'
import { Button, Table, Modal, Tag } from 'antd'
import { CloseOutlined, ExclamationCircleOutlined, SettingOutlined } from '@ant-design/icons'
import history from '../../../router/history'
import { MailboxIcon } from '../components/MailboxIcon/MailboxIcon'

interface Props {
  mailboxes?: any[]
  removeMailbox: (id: string) => void
  loading: boolean
}

const MailboxListTable: React.FC<Props> = ({ mailboxes, removeMailbox, loading }) => {
  const [modal, contextHolder] = Modal.useModal()

  const onDeleteMailbox = (mailboxId: string) => {
    modal.confirm({
      title: 'Please confirm removing this mailbox',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          This mailbox will be disconnected. All campaigns sent from this email address will be
          stopped.
        </>
      ),
      okText: 'Disconnect',
      onOk: () => removeMailbox(mailboxId),
      cancelText: 'Cancel',
    })
  }

  const columns = [
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (email: any, record: any, index: any) => (
        <>
          <MailboxIcon
            type={record.config.mailboxType}
            style={{ fontSize: 16 }}
            title={record.config.mailboxType}
          />
          <span style={{ marginLeft: 10 }}>{email}</span>{' '}
          {record?.connectionStatus === 'requires_reauth' && (
            <Tag
              style={{ marginLeft: 20 }}
              icon={<ExclamationCircleOutlined style={{ color: 'orange' }} />}
            >
              Error: Please Reconnect
            </Tag>
          )}
        </>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'errors',
    //   key: 'errors',
    //   render: (email: any, record: any) => (
    //     <>
    //       <WarningOutlined style={{ color: 'orange' }} />
    //     </>
    //   ),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: any) => (
        <>
          {/* <Button
            onClick={() => sendTestEmail(record.mailboxId)}
            icon={<MailOutlined />}
            style={{ marginRight: 10 }}
            key="mail"
          >
            Send Test Email
          </Button> */}
          <Button
            // onClick={() => editMailbox(record.mailboxId)}
            onClick={() => history.push(`/app/settings/mailboxes/${record.mailboxId}`)}
            icon={<SettingOutlined />}
            // disabled={record.config.mailboxType !== 'SMTP'}
            style={{ marginRight: 10 }}
            key="edit"
          >
            Settings
          </Button>
          <Button
            onClick={() => onDeleteMailbox(record.mailboxId)}
            style={{ marginRight: 10 }}
            icon={<CloseOutlined />}
            key="close"
          >
            Disconnect
          </Button>
          {/* <Link to={`/app/settings/mailboxes/${record.mailboxId}`}>Edit Mailbox</Link> */}
        </>
      ),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        dataSource={mailboxes}
        columns={columns}
        rowKey="mailboxId"
        loading={loading}
        pagination={false}
        size="middle"
      />
      {contextHolder}
    </div>
  )
}

export default MailboxListTable
