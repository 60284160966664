import { spamWords, spamPhrases } from './spam-strings'

export function calculateSubjectLength(subject: string): number {
  return subject.length
}

export function calculateWordCount(content: string): number {
  if (!content.length) return 0
  const strippedContent = content.replace(/(<([^>]+)>)/gi, '') // Regular expression to remove HTML tags
  const words = strippedContent.split(' ')
  return words.length
}

export function calculatePersonalization(content: string): number {
  const regex = /{{(.*?)}}/g // Regular expression that matches anything within {{ }}
  const matches = content.match(regex)
  return matches ? matches.length : 0
}

export function calculateSpamWords(content: string): string[] {
  let foundSpam = []
  const lowerCaseContent = content.toLowerCase()

  for (let word of spamWords) {
    const regex = new RegExp(`\\b${word}\\b`, 'g')
    const matches = lowerCaseContent.match(regex)
    if (matches) {
      foundSpam.push(word)
    }
  }

  for (let phrase of spamPhrases) {
    const regex = new RegExp(phrase, 'g')
    const matches = lowerCaseContent.match(regex)
    if (matches) {
      foundSpam.push(phrase)
    }
  }

  return foundSpam
}

export function calculateQuestionCount(content: string): number {
  const questions = content.split('?')
  return questions.length - 1 // Subtract 1 because split on '?' will always have at least 1 item
}

export function calculateReadingTime(content: string): number {
  if (!content.length) return 0
  const words = content.split(' ')
  const minutes = words.length / 200 // Average reading speed is 200 WPM
  const seconds = minutes * 60 // Convert to seconds
  return parseFloat(seconds.toFixed(2)) // Return reading time rounded to 2 decimal places
}
