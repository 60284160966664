import React, { Component } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { Card, List } from 'antd'
import { BillingFrequency } from '@mailarrow/types'
import dayjs from 'dayjs'

const Subscription = () => {
  const { user } = useSelector((state: RootState) => state.user)

  if (!user) return null

  const {
    isCancelled,
    isPaused,
    willCancel,
    isTrial,
    plan,
    allowedProspectCount,
    usedProspectCount,
    allowedEmailCount,
    usedEmailCount,
    billingFrequency,
    trialEndDate,
  } = user

  const getSubscriptionStatus = () => {
    if (isTrial) return 'Trial'
    if (isCancelled) return 'Cancelled'
    if (willCancel) return 'Will Cancel End Of Billing Cycle'
    if (isPaused) return 'Paused'
    // if (willPause) return 'Will Pause End Of Billing Cycle'
    return 'Active'
  }

  const data: any = [
    {
      key: 'Plan',
      component: <span style={{ textTransform: 'capitalize' }}>{plan || 'Free'}</span>,
    },
    {
      key: 'Billing Frequency',
      component: billingFrequency === BillingFrequency.YEARLY ? 'Yearly' : 'Monthly',
    },
    {
      key: 'Subscription Status',
      component: <span style={{ textTransform: 'capitalize' }}>{getSubscriptionStatus()}</span>,
    },
    isTrial && {
      key: 'Trial Ends On',
      component: `${dayjs(trialEndDate).format('DD-MMM-YYYY')}`,
    },
    // !isFree &&
    //   !isCancelled && {
    //     key: willCancel && !isPaused ? 'Subscription Ends On' : 'Payment Coming Up',
    //     render: () => `${moment.unix(periodEnd).format('DD MMMM YYYY')}`,
    //   },
    // !isFree && {
    //   key: 'Subscription Paid Until',
    //   render: () => `${moment.unix(periodEnd).format('DD MMMM YYYY')}`,
    // },
    {
      key: 'Allowed Prospects',
      component: `${allowedProspectCount}`,
    },
    {
      key: 'Used Prospects',
      component: `${usedProspectCount}`,
    },
    {
      key: 'Allowed Emails',
      component: `${allowedEmailCount} / month`,
    },
    {
      key: 'Used Emails Quota (this month)',
      component: `${usedEmailCount}`,
    },
  ].filter(Boolean)

  return (
    <Card title={<span>Subscription Status</span>} size="small">
      <List
        dataSource={data}
        size="small"
        renderItem={(item: any, i) => (
          <List.Item key={`item.value${i}`} style={{ display: 'flex', width: '100%' }}>
            <span style={{ width: '300px' }}>{item.key}:</span>
            {item.component}
          </List.Item>
        )}
      />
    </Card>
  )
}

export default Subscription
