import { useQuery } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import _ from 'lodash'
import { message } from 'antd'
import history from '../../../router/history'
import logger from '../../../services/errorlogger'
import { PaginationSettings } from '../../../types/common'

export const fetchMonitorUsers = async () => {
  const response = await fetch.get(`/monitor/users/`)
  return response.data
}

export const useFetchMonitorUsers = () => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(
    ['monitor', 'users'],
    () => fetchMonitorUsers(),
    {
      onError: (error: any) => {
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access to monitor')
          logger.logError(error)
          return history.push('/app/dashboard/')
        }
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
    }
  )

  return {
    users: data,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

export interface FilterEmailSettings {
  prospectEmail?: string
  mailboxId?: string
  emailId?: string
  campaignId?: string
  userId?: string
  journeyId?: string
  status?: string
}

export interface FetchEmailProps extends FilterEmailSettings, PaginationSettings {}

export const fetchMonitorEmails = async (config: FetchEmailProps) => {
  const {
    status,
    emailId,
    userId,
    prospectEmail,
    mailboxId,
    journeyId,
    campaignId,
    page,
    pageSize,
  } = config
  const params = new URLSearchParams()
  if (userId) {
    params.append('userId', userId)
  }
  if (prospectEmail) {
    params.append('prospectEmail', prospectEmail)
  }
  if (mailboxId) {
    params.append('mailboxId', mailboxId)
  }
  if (status) {
    params.append('status', status)
  }
  if (journeyId) {
    params.append('journeyId', journeyId)
  }
  if (campaignId) {
    params.append('campaignId', campaignId)
  }
  if (emailId) {
    params.append('emailId', emailId)
  }
  if (page) {
    params.append('page', page.toString())
  }
  if (pageSize) {
    params.append('pageSize', pageSize.toString())
  }
  const response = await fetch.get(`/monitor/emails/?${params}`)
  const { docs, totalDocs, page: currentPage } = response.data
  return {
    emails: docs as any[],
    total: totalDocs as number,
    page: currentPage,
  }
}

export const useFetchMonitorEmails = (
  filter: FilterEmailSettings,
  pagination?: PaginationSettings
) => {
  const { isLoading, isError, error, data, isFetching, isPreviousData, isSuccess, refetch } =
    useQuery(
      ['monitor', 'emails', filter, pagination],
      () => fetchMonitorEmails({ ...filter, ...pagination }),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          message.error(`Error: ${error.message}`, 10)
          console.log(error)
        },
      }
    )

  return {
    emails: data?.emails,
    total: data?.total,
    isLoading,
    isFetching,
    isPreviousData,
    isError,
    isSuccess,
    error,
    refetch,
  }
}

// import { handleActions } from 'redux-actions'
// import { message } from 'antd'
// import fetch from '../services/fetch'
// import history from '../history'
// import logger from '../services/errorlogger'

// export const a = () => {}

// export const actionTypes = {
//   FETCH_MONITOR_LOADING: 'FETCH_MONITOR_LOADING',
//   FETCH_MONITOR_STATE_DONE: 'FETCH_MONITOR_STATE_DONE',
//   FETCH_MONITOR_USERS_DONE: 'FETCH_MONITOR_USERS_DONE',
//   FETCH_MONITOR_TRANSACTIONS_DONE: 'FETCH_MONITOR_TRANSACTIONS_DONE',
//   SET_MONITOR_VALID: 'SET_MONITOR_VALID',
//   FETCH_MONITOR_ANALYTICS_DONE: 'FETCH_MONITOR_ANALYTICS_DONE',
//   FETCH_MONITOR_BILLING_DONE: 'FETCH_MONITOR_BILLING_DONE',
//   FETCH_MONITOR_SITES_DONE: 'FETCH_MONITOR_SITES_DONE',
//   FETCH_MONITOR_SCHEDULE_DONE: 'FETCH_MONITOR_SCHEDULE_DONE',
//   SET_IMPERSONATE_MODE_ON: 'SET_IMPERSONATE_MODE_ON',
//   SET_IMPERSONATE_MODE_OFF: 'SET_IMPERSONATE_MODE_OFF',
//   FETCH_MONITOR_JOB_LIST_DONE: 'FETCH_MONITOR_JOB_LIST_DONE',
//   FETCH_MONITOR_USERS_BILLING_DONE: 'FETCH_MONITOR_USERS_BILLING_DONE',
// }

// export const actions = {
//   validateMonitorAccess: () => async (dispatch, getState) => {
//     try {
//       const { userId } = getState().settings
//       if (!userId) return history.push('/app/dashboard/')

//       const { data } = await fetch.get(`/monitor/`)
//       if (data !== 'OK') {
//         return history.push('/app/dashboard/')
//       }

//       dispatch({ type: actionTypes.SET_MONITOR_VALID })
//     } catch (e) {
//       history.push('/app/dashboard/')
//       logger.handleError(e, false)
//     }
//   },
//   fetchMonitorUsers: () => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: users } = await fetch.get(`/monitor/users/`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_USERS_DONE, users })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorUsersBilling: () => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: usersBilling } = await fetch.get(`/monitor/users/billing/`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_USERS_BILLING_DONE, usersBilling })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   updateMonitorBilling: (userId, props) => async (dispatch, getState) => {
//     try {
//       if (!userId || !props || !Object.keys(props).length)
//         return message.error('No params provided')

//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       await fetch.post(`/monitor/users/billing/${userId}`, props)
//       dispatch(actions.fetchMonitorUserBilling(userId))
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   createMonitorBillingTransaction: (userId, props) => async (dispatch, getState) => {
//     try {
//       if (!userId || !props || !Object.keys(props).length)
//         return message.error('No params provided')

//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       await fetch.post(`/monitor/users/transactions/${userId}`, props)
//       dispatch(actions.fetchMonitorUserBilling(userId))
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorUserTransactions: (userId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: transactions } = await fetch.get(`/monitor/users/transactions/${userId}`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_TRANSACTIONS_DONE, transactions, userId })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorUserBilling: (userId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: billing } = await fetch.get(`/monitor/users/billing/${userId}`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_BILLING_DONE, billing, userId })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorUserSites: (userId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: sites } = await fetch.get(`/monitor/users/sites/${userId}`)
//       let nextSites = []
//       if (sites && Object.keys(sites).length > 0) {
//         nextSites = Object.keys(sites).map((k) => ({
//           ...sites[k],
//           siteId: k,
//         }))
//       }
//       dispatch({ type: actionTypes.FETCH_MONITOR_SITES_DONE, sites: nextSites })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorUserSite: (userId, siteId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: site } = await fetch.get(`/monitor/users/sites/${userId}/${siteId}/`)
//       console.log(site)
//       let nextSites = [{ ...site, siteId }]
//       dispatch({ type: actionTypes.FETCH_MONITOR_SITES_DONE, sites: nextSites })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorState: () => async (dispatch, getState) => {
//     try {
//       const { userId } = getState().settings
//       if (!userId) return
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       // console.log('url',`${ApiUrl}/api/monitor/${userId}/jobs`);
//       const { data: activeJobs } = await fetch.get(`/monitor/state/`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_STATE_DONE, activeJobs })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorAnalytics: (userId) => async (dispatch, getState) => {
//     try {
//       if (!userId) return
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: analytics } = await fetch.get(`/monitor/analytics/${userId}`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_ANALYTICS_DONE, analytics, userId })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorSchedule: () => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       const { data: schedule } = await fetch.get(`/monitor/schedule/`)
//       dispatch({ type: actionTypes.FETCH_MONITOR_SCHEDULE_DONE, schedule })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   monitorAddScheduleJob: (siteId, userId, date) => async (dispatch, getState) => {
//     try {
//       await fetch.put(`/monitor/schedule/`, { date, userId, siteId })
//       dispatch(actions.fetchMonitorSchedule())
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   monitorEditScheduleJob: (scheduleJobId, params) => async (dispatch, getState) => {
//     try {
//       await fetch.patch(`/monitor/schedule/${scheduleJobId}`, { ...params })
//       dispatch(actions.fetchMonitorSchedule())
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   monitorKillScheduleJob: (siteId, scheduleJobId) => async (dispatch, getState) => {
//     try {
//       fetch.delete(`/monitor/schedule/${scheduleJobId}`)
//       dispatch(actions.fetchMonitorSchedule())
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   monitorImpersonateOn: (impersonateUserId) => async (dispatch, getState) => {
//     try {
//       const originalUserId = localStorage && localStorage.getItem('userId')
//       localStorage && localStorage.setItem('userId', impersonateUserId)
//       dispatch({ type: actionTypes.SET_IMPERSONATE_MODE_ON, originalUserId, impersonateUserId })
//       history.push('/app/dashboard/')
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   monitorImpersonateOff: () => async (dispatch, getState) => {
//     try {
//       const originalUserId = getState().monitor.originalUserId
//       localStorage.setItem('userId', originalUserId)
//       dispatch({ type: actionTypes.SET_IMPERSONATE_MODE_OFF })
//       history.push('/app/admin')
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   fetchMonitorJobs: (userId, siteId) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: actionTypes.FETCH_MONITOR_LOADING })
//       // console.log('fetching monitor jobs');
//       const { data: jobs } = await fetch.post(`/monitor/jobs/`, { siteId, userId })
//       dispatch({ type: actionTypes.FETCH_MONITOR_JOB_LIST_DONE, jobs })
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
//   banUser: (sessionId, ip) => async (dispatch, getState) => {
//     try {
//       await fetch.put(`/monitor/blacklist/`, { sessionId, ip })
//       console.log('DONE PUT TO BLACKLIST', sessionId, ip)
//     } catch (e) {
//       logger.handleError(e)
//     }
//   },
// }

// export const getDefaultState = () => ({
//   loading: true,
//   isValid: false,
//   activeJobs: [],
//   jobs: [],
//   users: [],
//   transactions: {},
//   analytics: {},
//   billing: {},
//   sites: [],
//   schedule: [],
//   isImpersonateMode: false,
//   impersonateUserId: null,
//   originalUserId: null,
// })

// export default handleActions(
//   {
//     [actionTypes.SET_MONITOR_VALID]: (state) => ({ ...state, isValid: true }),
//     [actionTypes.SET_IMPERSONATE_MODE_ON]: (state, { impersonateUserId, originalUserId }) => ({
//       ...state,
//       impersonateUserId,
//       originalUserId,
//       isImpersonateMode: true,
//     }),
//     [actionTypes.SET_IMPERSONATE_MODE_OFF]: (state) => ({
//       ...state,
//       impersonateUserId: null,
//       originalUserId: null,
//       isImpersonateMode: false,
//     }),
//     [actionTypes.FETCH_MONITOR_LOADING]: (state) => ({ ...state, loading: true }),
//     [actionTypes.FETCH_MONITOR_STATE_DONE]: (state, { activeJobs }) => {
//       return { ...state, activeJobs, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_USERS_DONE]: (state, { users }) => {
//       const nextUsers = Object.keys(users).map((k) => users[k])
//       return { ...state, users: nextUsers, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_USERS_BILLING_DONE]: (state, { usersBilling }) => {
//       const nextBilling = { ...state.billing, ...usersBilling }
//       return { ...state, billing: nextBilling, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_TRANSACTIONS_DONE]: (state, { transactions, userId }) => {
//       const userTransactions = Object.keys(transactions || {}).map((k) => ({
//         transactionId: k,
//         ...transactions[k],
//       }))
//       const nextTransactions = { ...state.transactions, [userId]: userTransactions }
//       return { ...state, transactions: nextTransactions, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_ANALYTICS_DONE]: (state, { analytics, userId }) => {
//       const nextAnalytics = { ...analytics, [userId]: analytics }
//       return { ...state, analytics: nextAnalytics, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_BILLING_DONE]: (state, { billing, userId }) => {
//       const nextBilling = { ...state.billing, [userId]: billing }
//       return { ...state, billing: nextBilling, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_SITES_DONE]: (state, { sites }) => {
//       return { ...state, sites, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_SCHEDULE_DONE]: (state, { schedule }) => {
//       const nextSchedule = Object.keys(schedule || {}).map((k) => ({
//         scheduleJobId: k,
//         ...schedule[k],
//       }))
//       return { ...state, schedule: nextSchedule, loading: false }
//     },
//     [actionTypes.FETCH_MONITOR_JOB_LIST_DONE]: (state, { jobs }) => {
//       const nextJobs = Object.keys(jobs || {}).map((k) => ({ jobId: k, ...jobs[k] }))
//       return { ...state, jobs: nextJobs, loading: false }
//     },
//   },
//   getDefaultState()
// )
