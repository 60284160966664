import React, { useEffect, useState, useRef } from 'react'
import SMTPMailboxForm from './SMTPMailboxForm'
import { Modal } from 'antd'
import { useTestMailboxConnection, useUpdateMailbox } from '../../../api/mailboxesMutations'
import { Mailbox } from '@mailarrow/types'

interface Props {
  open: boolean
  mailbox: Mailbox
  onClose: () => void
}

const EditSMTPMailboxContainer: React.FC<Props> = ({ open, mailbox, onClose }) => {
  const [hasEdited, setHasEdited] = useState(false)
  const [openTime, setOpenTime] = useState<Date | undefined>(undefined)
  const [values, setValues] = useState<any>(null)
  const {
    testMailboxConnection,
    reset: resetTestMailboxConnection,
    isSuccess: isTestMailboxSuccess,
    isError: isTestMailboxError,
    isLoading: isTestMailboxLoading,
  } = useTestMailboxConnection()
  const {
    updateMailbox,
    reset: resetUpdateMailbox,
    isLoading: updateMailboxLoading,
    isSuccess: updateMailboxSuccess,
    isError: updateMailboxError,
  } = useUpdateMailbox()

  const formRef = useRef<any>(null)

  useEffect(() => {
    if (open) setOpenTime(new Date())
  }, [open])

  useEffect(() => {
    const handleClose = () => {
      setHasEdited(false)
      onClose()
      setValues({})
    }

    if (!hasEdited || updateMailboxLoading || isTestMailboxLoading) return
    if (updateMailboxSuccess) {
      return handleClose()
    }

    if (isTestMailboxSuccess && !isTestMailboxError && !updateMailboxError) {
      return updateMailbox({
        mailboxId: mailbox.mailboxId,
        mailboxProps: { ...values, connectionStatus: 'connected' },
      })
    }
    if (isTestMailboxError) {
      return setHasEdited(false)
    }
  }, [
    hasEdited,
    onClose,
    isTestMailboxSuccess,
    isTestMailboxError,
    values,
    isTestMailboxLoading,
    updateMailboxLoading,
    updateMailboxSuccess,
    updateMailbox,
    updateMailboxError,
    mailbox.mailboxId,
  ])

  useEffect(() => {
    if (mailbox || open) {
      setValues(mailbox)
    }
  }, [mailbox, open])

  const gmail = mailbox.config?.smtpHost?.includes('gmail')

  const onSave = (values: any) => {
    const nextMailbox = {
      ...values,
      config: {
        ...mailbox.config,
        ...values.config,
      },
    }
    resetUpdateMailbox()
    testMailboxConnection(nextMailbox.config)
    setValues(nextMailbox)
    setHasEdited(true)
  }

  const onTestMailbox = (values: any) => {
    testMailboxConnection(values)
  }

  useEffect(() => {
    if (!open) {
      formRef.current && formRef.current.resetForm()
      resetUpdateMailbox()
      resetTestMailboxConnection()
    }
  }, [open, resetUpdateMailbox, resetTestMailboxConnection])

  return (
    <Modal open={open} onCancel={onClose} footer={null} title="Edit Mailbox">
      {open && (
        <SMTPMailboxForm
          ref={formRef}
          onSave={onSave}
          gmail={gmail}
          loading={updateMailboxLoading}
          isTestMailboxLoading={isTestMailboxLoading}
          onTestMailbox={onTestMailbox}
          mailbox={values}
          openTime={openTime}
        />
      )}
    </Modal>
  )
}

export default EditSMTPMailboxContainer
