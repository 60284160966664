import React, { useEffect } from 'react'

import { Button, Card, Divider } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

import './DashboardCard.scss'

interface Props {
  index?: number
  isCompleted: boolean
  body?: string | React.ReactElement
  title: string | React.ReactElement
  actionText: string
  onClick?: () => void
}

export const DashboardCard: React.FC<Props> = ({
  index,
  isCompleted,
  body,
  title,
  actionText,
  onClick,
}) => {
  return (
    <Card
      size="small"
      style={{ width: '325px' }}
      className="dashboard-card"
      title={
        <div className="dashboard-card-header">
          <span className="dashboard-card-header-index">{index}</span>
          <span>{title}</span>
        </div>
      }
    >
      <div style={{ textAlign: 'left', fontSize: 13, color: 'grey', margin: '15px 0' }}>{body}</div>
      <Divider style={{ margin: '12px 0' }} />
      {isCompleted ? (
        <Button
          icon={<CheckCircleFilled style={{ color: 'green ' }} />}
          style={{ width: '100%', color: 'green', backgroundColor: '#e5fce5' }}
          disabled
        >
          Completed
        </Button>
      ) : (
        <Button
          // todo: remove backgroundColor once invite team is implemented
          style={{ width: '100%', backgroundColor: onClick ? 'rgb(0, 174, 251)' : 'initial' }}
          onClick={onClick}
          type="primary"
          disabled={!onClick}
        >
          {actionText}
        </Button>
      )}
    </Card>
  )
}
