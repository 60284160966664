import { initializeApp } from 'firebase/app'

// TODO: move to .env
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_1_APIKEY,
//   authDomain: process.env.REACT_APP_FIREBASE_1_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_1_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_1_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_1_STORAGE_BUCKET,
//   messagingMailboxId: process.env.REACT_APP_FIREBASE_1_MAILBOX_ID,
//   appId: process.env.REACT_APP_FIREBASE_1_APP_ID,
// }

const firebaseConfig = {
  apiKey: 'AIzaSyD11Y2djSq7cO9ohIiV_gJkJX3NiohZLUU',
  authDomain: 'mail-arrow.firebaseapp.com',
  databaseURL: 'https://mail-arrow.firebaseio.com',
  projectId: 'mail-arrow',
  storageBucket: 'mail-arrow.appspot.com',
  messagingMailboxId: '767479827474',
  appId: '1:767479827474:web:80a1fc8f0683e5a9049ccb',
}

const fire = initializeApp(firebaseConfig)

export default fire
