import React, { useEffect, useMemo, useState } from 'react'
import { Button, List, Card, Tag, Tooltip, Progress, Modal } from 'antd'
import { LeftOutlined, CloudUploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useAddProspects } from '../../../api/prospectsMutations'
import ImportErrorsModal from '../../ImportErrorsModal/ImportErrorsModal'
import { validateProspects, sanitizeProspects } from '@mailarrow/util'
import dayjs from 'dayjs'

interface Props {
  campaignId?: string
  mapping: Record<number, string>
  data: string[][]
  customProperties: any[]
  fileName: string
  onPrevStep: () => void
  onNextStep: () => void
  onClose: () => void
}

const UploadProspectsStep: React.FC<Props> = ({
  onPrevStep,
  onNextStep,
  onClose,
  campaignId,
  fileName,
  data,
  mapping,
  customProperties,
}) => {
  const [showErrorModalOpen, setShowErrorModalOpen] = useState(false)
  const [uploading, setUploading] = useState(false)

  const { addProspects, progress, result, isLoading, isSuccess, isError } =
    useAddProspects(campaignId)

  const [csvHeader, ...csvBody] = data
  const [validProspects, invalidProspects] = useMemo(() => {
    const date = dayjs().format('YYYY-MM-DDTHH:mm:ss:ms')
    const firstTenK = csvBody.slice(0, 10000)
    const mappedData = firstTenK.map((row, index) => {
      const result: any = {}
      Object.entries(mapping).forEach(([columnIndex, property]) => {
        result[property] = row.at(Number(columnIndex))
      })
      result.rowIndex = index + 1 // offset for header
      result.importBatchName = `${fileName}-${date}`

      return result
    })
    const sanitizedProspects = sanitizeProspects(mappedData, false)
    return validateProspects(sanitizedProspects)
  }, [csvBody, fileName, mapping])

  const noEmailRows = invalidProspects.filter((p: any) => p.errors.includes('Email is not valid'))
  const duplicateEmailRows = invalidProspects.filter((p: any) =>
    p.errors.includes('Duplicate prospect email')
  )

  const onStartUpload = () => {
    addProspects(validProspects)
    setUploading(true)
  }

  useEffect(() => {
    if (uploading && isSuccess) {
      setUploading(false)
      onNextStep()
    }
  }, [isSuccess, onNextStep, uploading])

  const getUploadStatus = (progress: number): string => {
    switch (true) {
      case progress > 0 && progress < 1:
        return 'Loading'
      case progress === 1:
        return 'Completed'
      case progress === 0:
      default:
        return 'Not started'
    }
  }

  const importSummaryData = [
    {
      title: (
        <>
          Rows
          {/* <Tooltip title="Only first 3000 prospects will be imported"> */}
          <Tooltip title="Row count excluding header">
            <QuestionCircleOutlined style={{ marginLeft: 2, marginRight: 5, fontSize: 12 }} />
          </Tooltip>
        </>
      ),
      value: csvBody.length,
    },
    {
      title: 'Errors',
      value: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{invalidProspects.length || 0}</span>
            <Button
              size="small"
              disabled={!invalidProspects.length && !isSuccess}
              onClick={() => setShowErrorModalOpen(true)}
            >
              Show errors
            </Button>
          </div>
        </>
      ),
    },
    {
      title: (
        <>
          Missing Emails
          <Tooltip title="Prospects without emails won't be imported">
            <QuestionCircleOutlined style={{ marginLeft: 2, marginRight: 5, fontSize: 12 }} />
          </Tooltip>
        </>
      ),
      value: <>{noEmailRows.length}</>,
    },
    {
      title: (
        <>
          Duplicate Emails
          <Tooltip title="Prospects with duplicate emails won't be imported">
            <QuestionCircleOutlined style={{ marginLeft: 2, marginRight: 5, fontSize: 12 }} />
          </Tooltip>
        </>
      ),
      value: <>{duplicateEmailRows.length}</>,
    },
    {
      title: (
        <>
          Valid Prospects
          <Tooltip title="Only these prospects will be imported">
            <QuestionCircleOutlined style={{ marginLeft: 2, marginRight: 5, fontSize: 12 }} />
          </Tooltip>
        </>
      ),
      value: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{validProspects.length || 0}</span>
            {/* TODO: add a modal which shows the prospects which are about
            to be imported */}
            {/* <Button
              size="small"
              disabled={!invalidProspects.length && !isSuccess}
              onClick={() => setShowErrorModalOpen(true)}
            >
              Show errors
            </Button> */}
          </div>
        </>
      ),
    },
    { title: 'Prospects Created', value: <>{result?.createdCount || 0}</> },
    { title: 'Prospects Uploaded', value: <>{result?.updatedCount || 0}</> },
    {
      title: 'Status',
      value: (
        <>
          <div>{getUploadStatus(progress)}</div>
          <Progress percent={Math.ceil(progress * 100)} />
        </>
      ),
    },
  ]
  console.log('isLoading', isLoading)

  return (
    <>
      <Card title="Summary" style={{ width: '100%' }} size="small">
        <List
          size="small"
          dataSource={importSummaryData}
          renderItem={(item) => (
            <List.Item>
              <strong style={{ width: 250, display: 'inline-block' }}>{item.title}:</strong>
              <span style={{ width: '100%', display: 'inline-block' }}>{item.value}</span>
            </List.Item>
          )}
        />
      </Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
        <Button onClick={onPrevStep} icon={<LeftOutlined />} disabled={isSuccess || isLoading}>
          Back
        </Button>
        {isSuccess ? (
          <Button onClick={onClose} type="primary">
            Close
          </Button>
        ) : (
          <Button
            onClick={onStartUpload}
            loading={isLoading}
            type="primary"
            disabled={!validProspects.length}
          >
            Import {validProspects.length} Prospects{' '}
            <CloudUploadOutlined style={{ fontSize: 12 }} />
          </Button>
        )}

        {/* <Button onClick={onStartUpload} loading={isLoading} type="primary">
          Import Prospects <CloudUploadOutlined style={{ fontSize: 12 }} />
        </Button> */}
      </div>
      <ImportErrorsModal
        onClose={() => setShowErrorModalOpen(false)}
        isOpen={showErrorModalOpen}
        errors={invalidProspects}
      />
    </>
  )
}

export default UploadProspectsStep
