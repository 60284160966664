import { useMutation } from '@tanstack/react-query'
import fetch from '../../../services/fetch'
import { message } from 'antd'

interface CreateCheckoutLinkProps {
  planName: string
}

const createCheckoutLink = async ({ planName }: CreateCheckoutLinkProps) => {
  if (!planName) return ''
  const { data: link } = await fetch.post(`/billing/checkout/generate-link`, { planName })
  return link
}

export const useCreateCheckoutLink = () => {
  const { mutate, isLoading, isError, isSuccess, error, data } = useMutation(
    (variables: CreateCheckoutLinkProps) => createCheckoutLink(variables),
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
      // If you want to notify the user of successful mutation
      onSuccess: () => {
        // message.success('Checkout link created successfully.', 10)
      },
    }
  )

  return {
    link: data,
    createCheckoutLink: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}

const createBillingSession = async () => {
  const { data: link } = await fetch.post(`/billing/manage/generate-portal-session`)
  return link
}

export const useCreateBillingSession = () => {
  const { mutate, isLoading, isError, isSuccess, error, data } = useMutation(
    () => createBillingSession(),
    {
      onError: (error: any) => {
        message.error(`Error: ${error.message}`, 10)
        console.log(error)
      },
      // If you want to notify the user of successful mutation
      onSuccess: () => {
        // message.success('Checkout link created successfully.', 10)
      },
    }
  )

  return {
    link: data,
    createBillingSession: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  }
}
