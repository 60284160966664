import { Dayjs } from 'dayjs'

type DayRangeInMinutes = number // 0 - 1440 minutes starting from 0:00 and ending at 24:00

export const dayRangeInMinutesToDate = (
  dayRangeInMinutes: DayRangeInMinutes,
  day: Dayjs
): Dayjs => {
  const minutesInDay = 1440
  const minutes = dayRangeInMinutes % minutesInDay
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  return day.startOf('day').add(hours, 'hour').add(remainingMinutes, 'minute')
}

export const dateToDayRangeInMinutes = (date: Dayjs): DayRangeInMinutes => {
  const startOfDay = date.startOf('day')
  const diffInMinutes = date.diff(startOfDay, 'minute')
  const minutesInDay = 1440

  return diffInMinutes < 0 ? diffInMinutes + minutesInDay : diffInMinutes
}
