import React, { useState, useEffect } from 'react'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import { EditorContent, useEditor } from '@tiptap/react'

import './RichEditor.scss'

import MenuBar from './MenuBar'
import VariableModal from '../../VariableModal/VariableModal'

interface Props {
  stepIndex: number
  textHTML: string | undefined
  onChange: any
  isModalOpen: boolean
  setModalOpen: (isOpen: boolean) => void
  enableUnsubscribeText?: boolean
  unsubscribeText?: string
}

const RichEditor: React.FC<Props> = ({
  stepIndex,
  textHTML,
  onChange,
  isModalOpen,
  setModalOpen,
  enableUnsubscribeText,
  unsubscribeText,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Highlight,
      TaskList,
      TaskItem,
      CharacterCount.configure({
        limit: 10000,
      }),
      Image,
      Link,
    ],
    parseOptions: {
      preserveWhitespace: 'full',
    },
    content: textHTML,
    onUpdate: ({ editor }) => {
      const nextText = editor.getHTML()
      onChange(nextText)
    },
  })

  useEffect(() => {
    if (editor && textHTML !== null) {
      const { from, to } = editor.state.selection
      editor.commands.clearContent()
      editor.commands.setContent(textHTML || '', false, { preserveWhitespace: 'full' })

      editor.commands.setTextSelection({ from, to })
    }
  }, [stepIndex, editor, textHTML])

  const insertVariable = (slug: string, failback: string) => {
    const formattedSlug = failback ? `{{ ${slug} | default: "${failback}" }}` : `{{ ${slug} }}`
    editor?.chain().focus().insertContent(formattedSlug).run()
  }

  return (
    <>
      <div className="tiptap" key={stepIndex}>
        <div className="editor">
          {editor && <MenuBar editor={editor} />}
          <EditorContent className="editor__content" editor={editor} />
          {enableUnsubscribeText && <div className="editor__unsubscribe">{unsubscribeText}</div>}
          <div className="editor__footer">
            <div className="editor__name">
              {`Unsubscribe text can be ${
                enableUnsubscribeText ? 'changed' : 'added'
              } in campaign settings`}
            </div>
          </div>
        </div>
      </div>
      <VariableModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSetVariable={insertVariable}
      />
    </>
  )
}

export default RichEditor
