import { Config } from 'imap-simple'
export enum MailboxType {
  SMTP = 'SMTP',
  GMAIL = 'GMAIL',
  OUTLOOK = 'OUTLOOK',
  EXCHANGE = 'EXCHANGE',
}

export type MailboxConnectionStatus = 'connected' | 'disconnected' | 'requires_reauth'

interface TrackingDomain {
  domain: string
  status: 'connected' | 'disconnected'
  lastChecked: Date
}

interface SMTPMailboxConfig {
  mailboxType: MailboxType.SMTP
  smtpHost: string
  smtpPort: number
  smtpUsername: string
  smtpPassword: string
  imapHost: string
  imapPort: number
  imapUsername: string
  imapPassword: string
}

interface GoogleMailboxConfig {
  mailboxType: MailboxType.GMAIL
  clientId?: string
  clientSecret?: string
  refreshToken: string
}

interface OutlookMailboxConfig {
  mailboxType: MailboxType.OUTLOOK
  clientId: string
  clientSecret: string
  refreshToken: string
}

type MailboxConfig = SMTPMailboxConfig | GoogleMailboxConfig | OutlookMailboxConfig

interface Config {
  mailboxType: MailboxType

  smtpHost?: string
  smtpPort?: number
  smtpUsername?: string
  smtpPassword?: string
  imapHost?: string
  imapPort?: number
  imapUsername?: string
  imapPassword?: string

  clientId?: string
  clientSecret?: string
  refreshToken?: string
}

interface Warmup {
  isEnabled: boolean
  isAllowed: boolean
  isPaused: boolean
  pausedReason?: string
  error?: string
  poolId?: string
  timezone?: string
  scrore?: number
  correlationId?: string

  emailsPerDay?: number
  maxEmailsPerDay?: number
  dailyIncrease?: number
}

export interface Mailbox {
  mailboxId: string
  email: string
  teamId?: string
  userId: string

  name?: string
  connectionStatus: MailboxConnectionStatus
  config: Config
  trackingDomain?: TrackingDomain
  sendDelay: number
  maxPerDay: number
  signature?: string

  warmup: Warmup

  syncedAt?: Date
  // this is for the user when he wants to pause a mailbox
  // but keep the connection intact
  isPaused: boolean

  // disabled by system / company for whatever reason
  isDisabled: boolean

  updatedAt: Date
  createdAt: Date
}

export const isSMTPMailbox = (mailbox: Mailbox) => {
  return mailbox.config.mailboxType === MailboxType.SMTP
}

export const isGoogleMailbox = (mailbox: Mailbox) => {
  return mailbox.config.mailboxType === MailboxType.GMAIL
}

export const isOutlookMailbox = (mailbox: Mailbox) => {
  return mailbox.config.mailboxType === MailboxType.OUTLOOK
}
