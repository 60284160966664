import React, { useEffect, useState, FC } from 'react'
import { Button, Typography } from 'antd'
import Confetti from 'react-dom-confetti'
import { RightOutlined } from '@ant-design/icons'

const { Title } = Typography

interface Props {
  onNextStep: () => void
}

export const FinishOnboarding: FC<Props> = ({ onNextStep }) => {
  const [confetti, setConfetti] = useState(false)

  useEffect(() => {
    setConfetti(true)
    const timer = setTimeout(() => {
      setConfetti(false)
    }, 3000) // Explode confetti for 3 seconds

    return () => clearTimeout(timer)
  }, [])

  const confettiConfig = {
    angle: 90,
    spread: 180,
    startVelocity: 50,
    elementCount: 200,
    dragFriction: 0.1,
    duration: 8000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Confetti config={confettiConfig} active={confetti} />
      <Title level={3} style={{ marginBottom: 20 }}>
        Success!
      </Title>
      <div style={{ marginBottom: 30 }}>
        <div>
          {/* It takes around 10 minutes to complete your first free crawl. You'll get an email once it
          is done. */}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <Button onClick={onNextStep} type="primary" style={{ marginBottom: 30 }}>
            Go to Dashboard <RightOutlined style={{ marginLeft: 5, fontSize: 12 }} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FinishOnboarding
