import React, { FC } from 'react'
import { Button, Radio } from 'antd'

interface Props {
  onNextStep: () => void
  setTeamSize: (size: string) => void
  teamSize: string
  setHasLeads: (size: string) => void
  hasLeads: string
}

export const AdditionaInfo: FC<Props> = ({
  onNextStep,
  setTeamSize,
  teamSize,
  hasLeads,
  setHasLeads,
}) => {
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}>
          <strong>What is the size of your team?</strong>
        </div>
        <Radio.Group onChange={(e) => setTeamSize(e.target.value)} value={teamSize}>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="Solo">
            Solo
          </Radio>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="2 - 5 members">
            2 - 5 members
          </Radio>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="6 - 20 members">
            6 - 20 members
          </Radio>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="21+ members">
            21+ members
          </Radio>
        </Radio.Group>
      </div>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}>
          <strong>Do you currently have a database of leads?</strong>
        </div>
        <Radio.Group onChange={(e) => setHasLeads(e.target.value)} value={hasLeads}>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="Yes">
            Yes
          </Radio>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="No">
            No
          </Radio>
          <Radio style={{ display: 'block', marginBottom: 5 }} value="Currently building one">
            Currently building one
          </Radio>
        </Radio.Group>
      </div>
      <Button type="primary" onClick={onNextStep} disabled={!teamSize || !hasLeads}>
        Complete
      </Button>
    </div>
  )
}
