import React, { FC } from 'react'
import { Popconfirm, Menu } from 'antd'
import {
  EditOutlined,
  PauseOutlined,
  RightCircleOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons'

interface CampaignMenuOptionsProps {
  campaign?: any
  copyCampaign: () => void
  changeCampaignStatus: (isPaused: boolean) => void
  removeCampaign: () => void
}

const CampaignMenuOptions: FC<CampaignMenuOptionsProps> = ({
  campaign,
  copyCampaign,
  changeCampaignStatus,
  removeCampaign,
}) => {
  if (!campaign) return null

  return (
    <Menu>
      <Menu.Item key="4" disabled>
        {/* <Popconfirm
          title={
            <>
              Are you sure you want to delete this campaign? <br /> This action is irreversible.
            </>
          }
          placement="bottom"
          onConfirm={removeCampaign}
          okText="Yes"
          cancelText="No"
        > */}
        <DeleteOutlined style={{ marginRight: 5 }} />
        Remove Campaign
        {/* </Popconfirm> */}
      </Menu.Item>
    </Menu>
  )
}

export default CampaignMenuOptions
