import React, { useState, FC } from 'react'
import { AdditionaInfo } from './AdditionaInfo'
import { ExpertLevel } from './ExpertLevel'

interface SegmentationFormProps {
  onSave: (data: any) => void
}

export const SegmentationForm: FC<SegmentationFormProps> = ({ onSave }) => {
  const [section, setSection] = useState<number>(0)
  const [experienceLevel, setExperienceLevel] = useState<string>('')
  const [teamSize, setTeamSize] = useState<string>('')
  const [hasLeads, setHasLeads] = useState<string>('')
  const [emailsPerMonth, setEmailsPerMonth] = useState<string>('')
  const [sendingMailboxes, setSendingMailboxes] = useState<string>('')

  const onFinish = () => {
    onSave({
      experienceLevel,
      teamSize,
      hasLeads,
      emailsPerMonth,
      sendingMailboxes,
    })
  }

  if (section === 0) {
    return (
      <ExpertLevel
        experienceLevel={experienceLevel}
        setExperienceLevel={setExperienceLevel}
        emailsPerMonth={emailsPerMonth}
        setEmailsPerMonth={setEmailsPerMonth}
        sendingMailboxes={sendingMailboxes}
        setSendingMailboxes={setSendingMailboxes}
        onNextStep={() => setSection(section + 1)}
      />
    )
  }

  if (section === 1) {
    return (
      <AdditionaInfo
        onNextStep={onFinish}
        teamSize={teamSize}
        setTeamSize={setTeamSize}
        hasLeads={hasLeads}
        setHasLeads={setHasLeads}
      />
    )
  }

  return <div></div>
}

export default SegmentationForm
