import React, { useCallback, useEffect } from 'react'
import fetch from '../../../../../services/fetch'
import { Modal, message } from 'antd'
import history from '../../../../../router/history'

interface GoogleSignInProps {
  open: boolean
  mailboxId?: string
  onClose: () => void
}

const CLIENT_ID = '767479827474-qvs2nc1oqo2a43evt2b4oom82but4n9b.apps.googleusercontent.com'

const AddGmailMailbox: React.FC<GoogleSignInProps> = ({ open, onClose, mailboxId }) => {
  const getAuthUrl = useCallback(
    async (idToken: string) => {
      try {
        const response = await fetch.post('/mailboxes/gmail/auth-url/', { idToken, mailboxId })
        history.push(response.data)
        message.info('Auth Url got successfully: ' + response.data)
      } catch (error) {
        console.error('Error getAuthUrl:', error)
        message.warning('Error getAuthUrl')
      }
    },
    [mailboxId]
  )

  useEffect(() => {
    if (!open) return
    // check if it exists
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    const handleCredentialResponse = (response: any) => {
      const idToken = response.credential
      getAuthUrl(idToken)
    }

    script.onload = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: CLIENT_ID,
          callback: handleCredentialResponse,
        })

        window.google.accounts.id.renderButton(document.getElementById('gsiButtonContainer'), {
          theme: 'outline',
          size: 'large',
          width: '240px',
        })
      }
    }
  }, [getAuthUrl, open])

  return (
    <Modal
      title={mailboxId ? 'Reconnect Gmail Account' : 'Add Gmail Account'}
      open={open}
      onCancel={onClose}
      footer={null}
      style={{ textAlign: 'center' }}
    >
      <div id="gsiButtonContainer"></div>
    </Modal>
  )
}

export default AddGmailMailbox
