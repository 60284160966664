import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Alert, Button, Divider, Input, Typography } from 'antd'
import { SendOutlined, SaveOutlined } from '@ant-design/icons'
import { useFeatureToggle } from '../../../../User'
import { usePermissions } from '../../../../User/Permissions'
import { google } from 'googleapis'

const { Title } = Typography

interface Props {
  onSave: (values: any) => void
  onTestMailbox: (values: any) => void
  gmail?: boolean
  mailbox?: any
  loading: boolean
  isTestMailboxLoading: boolean
  openTime?: Date
}

const configs = {
  mailgun: {
    name: 'Test Sender',
    email: 'test-sender@mail.retainrocket.com',
    smtpHost: 'smtp.mailgun.org',
    smtpPort: '465',
    smtpUsername: 'test-sender@mail.retainrocket.com',
    smtpPassword: '9b5e349bf8222c69815d156e0dd212e3-30344472-81ce7c67',
  },
  sendgrid: {
    smtpHost: 'smtp.sendgrid.net',
    smtpPort: '465',
    // 25, 587 (for unencrypted/TLS connections)
    // 465 (for SSL connections)
    smtpUsername: 'apikey',
    smtpPassword: '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',
  },
  outlook: {
    name: 'Serge Shlykov',
    email: 'worraliam@outlook.com',
    smtpHost: 'smtp-mail.outlook.com',
    smtpPort: '587',
    smtpUsername: 'worraliam@outlook.com',
    smtpPassword: '0VoYgJUkOC2f*)srjC?4',
    imapHost: 'outlook.office365.com',
    imapPort: '993',
    imapUsername: 'worraliam@outlook.com',
    imapPassword: '0VoYgJUkOC2f*)srjC?4',
    senderName: 'Serge Shlykov',
  },
  fornex: {
    smtpHost: 'm119114.hostde18.fornex.host',
    smtpPort: '465',
    smtpUsername: 'testing@m119114.hostde18.fornex.host',
    smtpPassword: 'iOmG&p&cTraLh0gW',
    imapHost: 'm119114.hostde18.fornex.host',
    imapPort: '993',
    imapUsername: 'testing@m119114.hostde18.fornex.host',
    imapPassword: 'iOmG&p&cTraLh0gW',
    senderName: 'Testing Bro',
  },
  fm: {
    name: 'Sender Bro',
    email: 'dallas_s@fastmail.com',
    smtpHost: 'smtp.fastmail.com',
    smtpPort: '465',
    smtpUsername: 'dallas_s@fastmail.com',
    smtpPassword: 'zprtfwrvujvscv84',
    imapHost: 'imap.fastmail.com',
    imapPort: '993',
    imapUsername: 'dallas_s@fastmail.com',
    imapPassword: 'zprtfwrvujvscv84',
  },
  gmail1: {
    name: 'Serge Shlykov',
    email: 'sergiy.shlykov@gmail.com',
    smtpHost: 'smtp.gmail.com',
    smtpPort: '465',
    smtpUsername: 'sergiy.shlykov@gmail.com',
    smtpPassword: 'apeffjknmfnlakfv',
    imapHost: 'imap.gmail.com',
    imapPort: '993',
    imapUsername: 'sergiy.shlykov@gmail.com',
    imapPassword: 'apeffjknmfnlakfv',
  },
}

const SMTPMailboxForm: React.ForwardRefRenderFunction<any, Props> = (
  { onSave, onTestMailbox, openTime, mailbox, gmail, loading, isTestMailboxLoading },
  ref
) => {
  const permissions = usePermissions()

  const [smtpHost, setSmtpHost] = useState('')
  const [smtpPort, setSmtpPort] = useState('465')
  const [smtpUsername, setSmtpUsername] = useState('')
  const [smtpPassword, setSmtpPassword] = useState('')
  const [imapHost, setImapHost] = useState('')
  const [imapPort, setImapPort] = useState('993')
  const [imapUsername, setImapUsername] = useState('')
  const [imapPassword, setImapPassword] = useState('')
  const [senderName, setSenderName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')

  const resetForm = () => {
    setSmtpHost('')
    setSmtpPort('465')
    setSmtpUsername('')
    setSmtpPassword('')
    setImapHost('')
    setImapPort('993')
    setImapUsername('')
    setImapPassword('')
    setSenderName('')
    setSenderEmail('')
  }

  useImperativeHandle(ref, () => ({
    resetForm,
  }))

  useEffect(() => {
    if (mailbox) {
      setSmtpHost(mailbox.config?.smtpHost || '')
      setSmtpPort(mailbox.config?.smtpPort || '')
      setSmtpUsername(mailbox.config?.smtpUsername || '')
      setSmtpPassword(mailbox.config?.smtpPassword || '')
      setImapHost(mailbox.config?.imapHost || '')
      setImapPort(mailbox.config?.imapPort || '')
      setImapUsername(mailbox.config?.imapUsername || '')
      setImapPassword(mailbox.config?.imapPassword || '')
      setSenderName(mailbox.name || '')
      setSenderEmail(mailbox.email || '')
    }

    if (gmail) {
      setSmtpHost('smtp.gmail.com')
      setSmtpPort('465')
      setImapHost('imap.gmail.com')
      setImapPort('993')
    }
  }, [gmail, mailbox, openTime])

  const testMailbox = () => {
    onTestMailbox({
      smtpHost,
      smtpPort,
      smtpUsername,
      smtpPassword,
      imapPort,
      imapHost,
      imapUsername,
      imapPassword,
      senderName,
      senderEmail,
    })
  }

  const handleSave = () => {
    onSave({
      config: {
        smtpHost,
        smtpPort,
        smtpUsername,
        smtpPassword,
        imapPort,
        imapHost,
        imapUsername,
        imapPassword,
        mailboxType: 'SMTP',
      },
      name: senderName,
      email: smtpUsername,
    })
  }

  const setMailbox = (config: any) => {
    setSenderName(config.name || '')
    setSenderEmail(config.email || '')
    setSmtpHost(config.smtpHost || '')
    setSmtpPort(config.smtpPort || '')
    setSmtpPassword(config.smtpPassword || '')
    setSmtpUsername(config.smtpUsername || '')
    setImapHost(config.imapHost || '')
    setImapPort(config.imapPort || '')
    setImapUsername(config.imapUsername || '')
    setImapPassword(config.imapPassword || '')
  }

  return (
    <>
      {gmail ? (
        <>
          <div style={{ margin: '10px 0' }}>
            Google Email
            <Input
              placeholder="example@gmail.com"
              onChange={(event) => {
                setSmtpUsername(event.target.value)
                setImapUsername(event.target.value)
              }}
              value={smtpUsername}
            />
            <span style={{ fontSize: 13, color: 'gray', marginLeft: 2 }}>
              Your Google email address
            </span>
          </div>
          <div style={{ margin: '10px 0' }}>
            App Password
            <Input.Password
              placeholder="password"
              onChange={(event) => {
                setSmtpPassword(event.target.value)
                setImapPassword(event.target.value)
              }}
              value={smtpPassword}
            />
            <span style={{ fontSize: 13, color: 'gray', marginLeft: 2 }}>
              Not your Google password.{' '}
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    'https://mailarrow.notion.site/How-to-add-Google-mailbox-with-an-App-Password-78e4fc4772d549c6ab857222ddf6c03e',
                    '_blank'
                  )
                }
              >
                Here's how you generate it.
              </span>
            </span>
          </div>
          <div style={{ margin: '10px 0' }}>
            Sender Name
            <Input
              placeholder="John Doe"
              onChange={(event) => setSenderName(event.target.value)}
              value={senderName}
            />
            <span style={{ fontSize: 13, color: 'gray', marginLeft: 2 }}>
              How you would like to appear to email recipients
            </span>
          </div>
        </>
      ) : (
        <div>
          <div style={{ display: 'flex', gap: 10 }}>
            <div style={{ width: '50%' }}>
              Sender Email
              <Input
                placeholder="john@example.com"
                onChange={(event) => setSenderEmail(event.target.value)}
                value={senderEmail}
              />
              <span style={{ fontSize: 13, color: 'gray', marginLeft: 2 }}>
                Your sending email address
              </span>
            </div>
            <div style={{ width: '50%' }}>
              Sender Name
              <Input
                placeholder="John Doe"
                onChange={(event) => setSenderName(event.target.value)}
                value={senderName}
              />
              <span style={{ fontSize: 13, color: 'gray', marginLeft: 2 }}>
                How you would like to appear to email recipients
              </span>
            </div>
          </div>
          <Divider style={{ margin: '15px 0' }} />
          <div style={{ display: 'flex', gap: 10 }}>
            <div style={{ width: '50%' }}>
              <div>
                SMTP Username (or email)
                <Input
                  placeholder="sender email"
                  onChange={(event) => setSmtpUsername(event.target.value)}
                  value={smtpUsername}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                SMTP Server
                <Input
                  placeholder="Host"
                  onChange={(event) => setSmtpHost(event.target.value)}
                  value={smtpHost}
                />
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <div>
                SMTP Password
                <Input.Password
                  placeholder="password"
                  onChange={(event) => setSmtpPassword(event.target.value)}
                  value={smtpPassword}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                SMTP Port
                <Input
                  placeholder="Port"
                  onChange={(event) => setSmtpPort(event.target.value)}
                  value={smtpPort}
                />
              </div>
            </div>
          </div>
          <Divider style={{ margin: '15px 0' }} />
          <div style={{ display: 'flex', gap: 10 }}>
            <div style={{ width: '50%' }}>
              <div>
                IMAP Username (or email)
                <Input
                  placeholder="sender email"
                  onChange={(event) => setImapUsername(event.target.value)}
                  value={imapUsername}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                IMAP Server
                <Input
                  placeholder="Host"
                  onChange={(event) => setImapHost(event.target.value)}
                  value={imapHost}
                />
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <div>
                IMAP Password
                <Input.Password
                  placeholder="password"
                  onChange={(event) => setImapPassword(event.target.value)}
                  value={imapPassword}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                IMAP Port
                <Input
                  placeholder="Port"
                  onChange={(event) => setImapPort(event.target.value)}
                  value={imapPort}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Divider style={{ margin: '15px 0' }} />
      <div style={{ display: 'flex', gap: 10 }}>
        <Button
          icon={<SendOutlined />}
          loading={loading || isTestMailboxLoading}
          onClick={testMailbox}
        >
          Test Connection
        </Button>
        <Button
          icon={<SaveOutlined />}
          loading={isTestMailboxLoading || loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      {permissions.admin && (
        <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
          <Button onClick={() => setMailbox(configs.mailgun)}>MG</Button>
          <Button onClick={() => setMailbox(configs.sendgrid)}>SG</Button>
          <Button onClick={() => setMailbox(configs.outlook)}>Outlook</Button>
          <Button onClick={() => setMailbox(configs.fornex)}>FX</Button>
          <Button onClick={() => setMailbox(configs.fm)}>Fastmail</Button>
          <Button onClick={() => setMailbox(configs.gmail1)}>Gmail</Button>
        </div>
      )}
    </>
  )
}

export default forwardRef(SMTPMailboxForm)
