import { Spin, Typography } from 'antd'
import React, { useState } from 'react'

import { SchedulePicker } from '../../../../Schedules'
import SendingSettings from './SendingSettings'
import TrackingSettings from './TrackingSettings'
import SequenceSettings from './SequenceSettings'
import UnsubscribeSettings from './UnsubscribeSettings'
import GeneralSettings from './GeneralSettings'
import { Campaign } from '@mailarrow/types'

const { Title } = Typography

type Props = {
  campaign?: Campaign
  fetchLoading: boolean
  editLoading: boolean
  onEditCampaign: (props: any) => void
}

const Settings: React.FC<Props> = ({ campaign, onEditCampaign, fetchLoading, editLoading }) => {
  if (!campaign) return <Spin />

  return (
    <div style={{ margin: '0 auto', paddingBottom: 40 }}>
      <div style={{ display: 'flex', gap: 20 }}>
        <div style={{ width: '50%' }}>
          <GeneralSettings campaign={campaign} />
          <SchedulePicker campaign={campaign} />
          <SendingSettings
            campaign={campaign}
            onEditCampaign={onEditCampaign}
            fetchLoading={fetchLoading}
            editLoading={editLoading}
          />
        </div>
        <div style={{ width: '50%' }}>
          <SequenceSettings
            campaign={campaign}
            onEditCampaign={onEditCampaign}
            fetchLoading={fetchLoading}
            editLoading={editLoading}
          />
          <TrackingSettings
            campaign={campaign}
            onEditCampaign={onEditCampaign}
            fetchLoading={fetchLoading}
            editLoading={editLoading}
          />
          <UnsubscribeSettings
            campaign={campaign}
            onEditCampaign={onEditCampaign}
            fetchLoading={fetchLoading}
            editLoading={editLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default Settings
