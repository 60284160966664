import React, { useEffect, useState } from 'react'
import { MailOutlined, LockOutlined, ThunderboltOutlined } from '@ant-design/icons'
import { Form, Input, Button, Card, Divider } from 'antd'

const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
}

const LoginForm: React.FC<{ onSubmit: Function; onResetPassword: Function }> = ({
  onSubmit,
  onResetPassword,
}) => {
  const handleSubmit = (values: any) => {
    onSubmit(values)
  }

  return (
    <Form onFinish={handleSubmit} className="login-form" {...formItemLayout}>
      <Form.Item
        label="Email"
        name="email"
        className="login-form-item"
        rules={[{ required: true, message: 'Please input your email' }]}
      >
        <Input
          className="login-form-input"
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        className="login-form-item"
        rules={[{ required: true, message: 'Please input your Password' }]}
      >
        <Input
          className="login-form-input"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item className="login-form-item" {...tailFormItemLayout}>
        <Button
          style={{ width: '100%', marginTop: '10px' }}
          icon={<ThunderboltOutlined />}
          type="primary"
          htmlType="submit"
        >
          Sign In
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
