import React, { useState } from 'react'
import { Button, Input, Select, MenuProps, Dropdown, Space, Tag, Modal } from 'antd'
import {
  DownOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'

interface Props {
  setSearchFilter: (filter: string) => void
  setImportCSVOpen: (isOpen: boolean) => void
  setAddManualOpen: (isOpen: boolean) => void
  setImportExistingOpen: (isOpen: boolean) => void
  selectedProspects: string[]
  onRemoveProspects: (prospectIds: string[]) => void
  onPersonalizeProspects: (prospectIds: string[]) => void
  setSelectedProspects: (prospects: string[]) => void
  isRemoveProspectsLoading: boolean
}

const ProspectsTableToolbar: React.FC<Props> = ({
  setSearchFilter,
  setImportCSVOpen,
  setAddManualOpen,
  setImportExistingOpen,
  selectedProspects,
  onRemoveProspects,
  setSelectedProspects,
  onPersonalizeProspects,
  isRemoveProspectsLoading,
}) => {
  const [modal, contextHolder] = Modal.useModal()

  const onSetFilter = (filter: string) => {
    setSelectedProspects([])
    setSearchFilter(filter)
  }

  const importItems: MenuProps['items'] = [
    {
      label: 'Add existing prospects',
      key: '1',
      onClick: () => setImportExistingOpen(true),
      // icon: <UserOutlined />,
    },
    {
      label: 'Import from CSV',
      key: '2',
      onClick: () => setImportCSVOpen(true),
      // icon: <UserOutlined />,
    },
    // {
    //   label: 'Import from integrations',
    //   key: '3',
    //   icon: <UserOutlined />,
    //   danger: true,
    // },
    {
      label: 'Import manually',
      key: '4',
      onClick: () => setAddManualOpen(true),
      // icon: <UserOutlined />,
    },
  ]

  const onRemoveProspectClick = () => {
    modal.confirm({
      title: 'Confirm deleting prospect',
      style: { width: 500 },
      icon: <ExclamationCircleOutlined />,
      content: <>This prospect will be removed from your database. This action is irreversible.</>,
      okText: 'Remove',
      onOk: () => onRemoveProspects(selectedProspects),
      cancelText: 'Cancel',
    })
  }

  const actionItems: MenuProps['items'] = [
    {
      label: 'Delete selected prospects',
      key: '10',
      icon: <DeleteOutlined />,
      onClick: () => onRemoveProspectClick(),
      disabled: !selectedProspects.length,
    },
  ]

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Dropdown menu={{ items: actionItems }}>
          <Button style={{ marginRight: 15 }}>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>

        <Dropdown menu={{ items: importItems }}>
          <Button style={{ marginRight: 15 }}>
            Add Prospects
            <DownOutlined />
          </Button>
        </Dropdown>

        <Button
          style={{ marginRight: 15 }}
          icon={<ThunderboltOutlined />}
          onClick={() => onPersonalizeProspects(selectedProspects)}
          disabled={!selectedProspects.length}
        >
          Personalize
        </Button>
        <Input.Search
          placeholder="Search by name or email.."
          style={{ width: 300, marginRight: 15 }}
          onSearch={(filter) => onSetFilter(filter)}
        />
      </div>
      {contextHolder}
    </>
  )
}

export default ProspectsTableToolbar
