import { Typography } from 'antd'
import React, { useState } from 'react'
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse'

const { Link } = Typography

const GREY = '#CCC'
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)'
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40)
const GREY_DIM = '#686868'

interface Props {
  onUploadComplete: (prospects: any, fileName: string) => void
}

const ProspectCSVImporter: React.FC<Props> = ({ onUploadComplete }) => {
  const { CSVReader } = useCSVReader()
  const [zoneHover, setZoneHover] = useState(false)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR)

  const onUploadAccepted = (results: any, file: File) => {
    console.log('---------------------------')
    console.log(results, file.name)
    onUploadComplete(results, file.name)
    console.log('---------------------------')
    setZoneHover(false)
  }

  return (
    <CSVReader
      config={{
        skipEmptyLines: true,
      }}
      onUploadAccepted={onUploadAccepted}
      onDragOver={(event: DragEvent) => {
        event.preventDefault()
        setZoneHover(true)
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault()
        setZoneHover(false)
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
        <>
          <div
            {...getRootProps()}
            style={{
              alignItems: 'center',
              background: `#d3d3d329`,
              border: `2px dashed ${zoneHover ? GREY_DIM : GREY}`,
              borderRadius: 20,
              display: 'flex',
              cursor: 'pointer',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            {acceptedFile ? (
              <>
                <div
                  style={{
                    background: 'linear-gradient(to bottom, #EEE, #DDD)',
                    borderRadius: 20,
                    display: 'flex',
                    height: 120,
                    width: 120,
                    position: 'relative',
                    zIndex: 10,
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: GREY_LIGHT,
                        borderRadius: 3,
                        marginBottom: '0.5em',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span
                      style={{
                        backgroundColor: GREY_LIGHT,
                        borderRadius: 3,
                        fontSize: 12,
                        marginBottom: '0.5em',
                      }}
                    >
                      {acceptedFile.name}
                    </span>
                  </div>
                  <div
                    style={{
                      bottom: 14,
                      position: 'absolute',
                      width: '100%',
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    style={{
                      height: 23,
                      position: 'absolute',
                      right: 6,
                      top: 6,
                      width: 23,
                    }}
                    onMouseOver={(event: Event) => {
                      event.preventDefault()
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                    }}
                    onMouseOut={(event: Event) => {
                      event.preventDefault()
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                    }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <div>
                  Drop CSV file here or click <Link>Upload</Link>
                </div>
                <span style={{ fontSize: 11 }}>
                  Only .csv files are accepted - Maximum 10,000 prospects
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </CSVReader>
  )
}

export default ProspectCSVImporter
