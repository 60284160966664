export const getEnv = () => {
  const isProd = window.location.host.includes('app.mailarrow.com')
  const isStaging = window.location.host.includes('staging.mailarrow.com')
  const isDev = window.location.host.includes('localhost')

  return {
    isProd,
    isStaging,
    isDev,
  }
}

export const getBaseUrl = () => {
  const env = getEnv()
  console.log('ENVIRONMENTS', env)
  if (env.isProd) return 'https://app.mailarrow.com'
  if (env.isStaging) return 'https://staging.mailarrow.com'
  return 'http://localhost:3006'
}

export const getStripeKey = () => {
  const env = getEnv()
  if (env.isProd) return process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
  if (env.isStaging) return process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
  return process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
}

const plans = [
  // PROD
  {
    planId: 'price_1J7L4RFCXlPl6ZgVnty6IV8o',
    name: 'hibernate',
    isYearly: false,
    env: 'prod',
  },
  {
    planId: 'price_1J6y1SFCXlPl6ZgV1Ma1txvH',
    name: 'starter',
    isYearly: false,
    env: 'prod',
  },
  {
    planId: 'price_1J6y2DFCXlPl6ZgV3pdCAf2Q',
    name: 'business',
    isYearly: false,
    env: 'prod',
  },

  // DEV
  {
    planId: 'price_1J7L4BFCXlPl6ZgVOWf80X8f',
    name: 'hibernate',
    isYearly: false,
    env: 'dev',
  },
  {
    planId: 'price_1J7JEsFCXlPl6ZgVnJY0cOLA',
    name: 'starter',
    isYearly: false,
    env: 'dev',
  },
  {
    planId: 'price_1J7JEbFCXlPl6ZgV6Wfq3QD9',
    name: 'business',
    isYearly: false,
    env: 'dev',
  },
]

export const getStripePlanData = (name: string, isYearly = false) => {
  const env = getEnv()
  const envPlans = plans.filter((p) => p.env === (env.isStaging || env.isDev ? 'dev' : 'prod'))
  const periodPlans = envPlans.filter((p) => p.isYearly === isYearly)
  const plan = periodPlans.find((p) => p.name === name)
  return plan
}
