import { Button, Col, Form, Input, Row, Divider, message } from 'antd'
import CustomHeader from './ProspectHeader'
import ProspectFormItem from './ProspectFormItem'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { flatten } from 'lodash'

interface Props {
  prospect: any
  properties: any
  onUpdateProspect: (values: any) => void
  onAddCustomProperty: () => void
}

const EditLeadForm: React.FC<Props> = ({
  prospect,
  properties,
  onUpdateProspect,
  onAddCustomProperty,
}) => {
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    onUpdateProspect(values)
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const errors = errorInfo.errorFields.map((f) => f.errors)
    message.error(`Changes not saved: ${flatten(errors).join(' ')}`)
  }

  return (
    <Form
      layout="vertical"
      style={{ maxWidth: '500px', margin: '0 auto' }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <CustomHeader
        firstName={prospect?.properties?.firstName}
        lastName={prospect?.properties?.lastName}
        email={prospect?.email}
      />
      <div style={{ marginTop: 10 }}>
        <h4 style={{ fontWeight: 600 }}>Profile</h4>

        <Row gutter={16}>
          <Col md={12}>
            <ProspectFormItem
              slug={'firstName'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'lastName'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'position'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'phone'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'linkedin'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'twitter'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        <h4 style={{ fontWeight: 600 }}>Icebreaker</h4>
        <Row gutter={16}>
          <Col md={24}>
            <ProspectFormItem
              slug={'icebreaker'}
              prospect={prospect}
              properties={properties}
              form={form}
              hideHeader
            />
          </Col>
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        <h4 style={{ fontWeight: 600 }}>Company</h4>
        <Row gutter={16}>
          <Col md={12}>
            <ProspectFormItem
              slug={'email'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'companyName'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'industry'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'website'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        <h4 style={{ fontWeight: 600 }}>Address</h4>
        <Row gutter={16}>
          <Col md={12}>
            <ProspectFormItem
              slug={'address'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'city'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'zip'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'state'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>
          <Col md={12}>
            <ProspectFormItem
              slug={'country'}
              prospect={prospect}
              properties={properties}
              form={form}
            />
          </Col>

          {/* <Col span={12}>
            <Form.Item label="Country" name="country">
              <Select>
                {countries.map((country) => (
                  <Option key={country.code} value={country.code}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        <div style={{ display: 'flex' }}>
          <h4 style={{ marginRight: 20, fontWeight: 600 }}>Custom Properties</h4>
          <Button size="small" onClick={onAddCustomProperty}>
            Add
          </Button>
        </div>

        <Row gutter={16}>
          {properties
            .filter((property: any) => property.isCustom)
            .map((property: any) => (
              <Col md={12} key={property.slug}>
                <ProspectFormItem
                  slug={property.slug}
                  prospect={prospect}
                  properties={properties}
                  form={form}
                />
              </Col>
            ))}
        </Row>
      </div>
    </Form>
  )
}

export default EditLeadForm
