import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const isProd = process.env.NODE_ENV === 'production'

const getBaseUrl = (): string => {
  if (isProd) return 'https://api.mailarrow.com/api/'
  return 'http://localhost:5001/api/'
}

const instance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
  // TODO: change back to something more sensible
  timeout: 1000000,
})

const getToken = (): string | null => localStorage.getItem('userId')
const getUserToken = (): string | null => localStorage.getItem('token')

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token: string | null = getToken()
    if (token) {
      config.headers['userid'] = token
    }

    const userToken: string | null = getUserToken()
    if (userToken) {
      config.headers['userToken'] = userToken
    }
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)

export default instance
