import { Button, Tag } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import dayjs from 'dayjs'
import history from '../../router/history'

const TrialNotice: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user)

  if (!user || !user.isTrial || !user.trialEndDate) return <></>

  const makeMessage = (trialEndDate?: Date) => {
    if (!trialEndDate) return null
    const differenceInDays = dayjs(trialEndDate).diff(dayjs(), 'day')
    if (differenceInDays > 0) {
      return <>Your trial ends in {differenceInDays ? `${differenceInDays} days` : `a few hours`}</>
    }
    return <>Your trial has expired</>
  }

  return (
    <div style={{ margin: '0 20px' }}>
      <Tag style={{ marginRight: 20 }}>{makeMessage(user.trialEndDate)}</Tag>
      <Button
        size="small"
        type="primary"
        onClick={() => history.push('/app/pricing/')}
        style={{ backgroundColor: 'rgb(0, 174, 251)' }}
      >
        View plans
      </Button>
    </div>
  )
}

export default TrialNotice
