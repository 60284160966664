import { FC } from 'react'
import { Button, List, Skeleton, Typography } from 'antd'
import dayjs from 'dayjs'
import { useFetchSendError } from './api/sendErrorQueries'

interface Props {
  campaignId?: string
  mailboxId?: string
}

const ErrorsContainer: FC<Props> = ({ campaignId, mailboxId }) => {
  const { sendErrors, isLoading } = useFetchSendError({ campaignId, mailboxId })

  return (
    <List
      style={{ marginTop: 20 }}
      size="small"
      header={<div>Mailbox Errors</div>}
      bordered
      loading={isLoading}
      dataSource={sendErrors}
      renderItem={(item: any) => (
        <List.Item>
          Date: {dayjs(item.createdAt).format('HH:mm; DD-MM-YY')}
          <br />
          To: <Typography.Text>{item.prospectEmail}</Typography.Text>
          <br />
          <Typography.Text>
            Message: <i>{item.errorMessage}</i>
          </Typography.Text>
        </List.Item>
      )}
    />
  )
}

export default ErrorsContainer
