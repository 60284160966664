import React, { useCallback, useMemo } from 'react'
import { Button, Table, Modal, Tag, Tooltip, TablePaginationConfig } from 'antd'
import { capitalizeFirstLetter } from '../../../util/capitalize-first-letter'
import ReactJson from 'react-json-view'
import dayjs from 'dayjs'

interface Props {
  emails?: any[]
  total: number
  currentPage: number
  pageSize: number
  onTableChange: (config: TablePaginationConfig) => void
  loading: boolean
}

const EmailsTable: React.FC<Props> = ({
  emails,
  total,
  onTableChange,
  currentPage,
  pageSize,
  loading,
}) => {
  const columns = [
    {
      title: 'Prospect',
      dataIndex: 'prospectEmail',
      key: 'prospectEmail',
      render: (prospectEmail: any) => <span style={{ fontSize: 12 }}>{prospectEmail}</span>,
    },

    {
      title: 'Campaign',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: (campaignId: any) => <span style={{ fontSize: 12 }}>{campaignId}</span>,
    },
    {
      title: 'Mailbox',
      dataIndex: 'mailboxId',
      key: 'mailboxId',
      render: (mailboxId: any) => <span style={{ fontSize: 12 }}>{mailboxId}</span>,
    },
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      render: (userId: any) => <span style={{ fontSize: 12 }}>{userId}</span>,
    },
    {
      title: 'Step',
      dataIndex: 'stepIndex',
      key: 'stepIndex',
      render: (stepIndex: any, record: any, index: any) => (
        <span style={{ fontSize: 12 }}>{record.stepIndex}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: any, record: any, index: any) => (
        <>
          <Tag style={{ marginLeft: 20 }}>{capitalizeFirstLetter(status)}</Tag>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      // TODO: this is most likely incorrect time due to timezone shift for daylight savings
      render: (updatedAt: any, record: any, index: any) => (
        <span style={{ fontSize: 12 }}>
          <Tooltip title={dayjs(updatedAt).format('DD MMM YY HH:mm:ss')}>
            {dayjs(updatedAt).format('DD MMM YY HH:mm:ss')}
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Opens',
      dataIndex: 'opens',
      key: 'opens',
      render: (opens: any, record: any, index: any) => (
        <span style={{ fontSize: 12 }}>{opens?.length}</span>
      ),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      render: (clicks: any, record: any, index: any) => (
        <span style={{ fontSize: 12 }}>{clicks?.length}</span>
      ),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        dataSource={emails}
        columns={columns}
        rowKey={(item: any) => item.emailId}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => <ReactJson src={record} />,
          // rowExpandable: () => (isAdminView ? true : false),
        }}
        pagination={{
          position: ['bottomRight'],
          current: currentPage,
          pageSize,
          simple: true,
          hideOnSinglePage: true,
          size: 'small',
          total,
        }}
        onChange={onTableChange}
        size="middle"
      />
    </div>
  )
}

export default EmailsTable
